import { useState, useEffect } from 'react';
import { calculateWeeklyTotals } from '../../../../utils/helpers/TotalHoursFormat';

const usePayrollTotals = (payrollWeekValidation) => {
  const [totals, setTotals] = useState({
    totalRegHours: 0,
    totalOTHours: 0,
    totalBillAmount: 0,
    totalOTBillAmount: 0,
    totalAmount: 0,
  });

  useEffect(() => {
    if (payrollWeekValidation.length > 0) {
      const { totalRegHours, totalOTHours, totalBillAmount, totalOTBillAmount, totalAmount } =
        calculateWeeklyTotals(payrollWeekValidation);

      // Actualizar solo si los totales han cambiado
      if (
        totalRegHours !== totals.totalRegHours ||
        totalOTHours !== totals.totalOTHours ||
        totalBillAmount !== totals.totalBillAmount ||
        totalOTBillAmount !== totals.totalOTBillAmount ||
        totalAmount !== totals.totalAmount
      ) {
        setTotals({
          totalRegHours,
          totalOTHours,
          totalBillAmount,
          totalOTBillAmount,
          totalAmount,
        });
      }
    }
  }, [payrollWeekValidation, totals]);

  return totals;
};

export default usePayrollTotals;