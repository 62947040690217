import { Col, Row } from 'react-bootstrap';
import TranslatedLabel from '../../../../components/common/TranslatedLabel/TranslatedLabel';
import { Field, useFormikContext } from 'formik';
import DropdownList from '../../../../components/common/Dropdown/DropdownList';
import { Translate } from 'react-localize-redux';
import { normalizeZipCode } from '../../../../utils/ReGexs';

const ClientSecondPage = ({ editClient = false, countryList, stateList, setStateList }) => {
   const { setFieldValue, values, errors, touched } = useFormikContext();

   const handleChange = (name, event) => {
      const { value } = event.target;
      const sanitizedValue = value.replace(/[^a-zA-ZÀ-ú .,-]+/g, '')?.toUpperCase();
      setFieldValue(name, sanitizedValue);
   };

   const handleChangeAlfaNumbers = (name, event) => {
      const { value } = event.target;
      const sanitizedValue = value.replace(/[^a-zA-ZÀ-ú .,\-# 0-9]+/g, '')?.toUpperCase();
      setFieldValue(name, sanitizedValue);
   };
   return (
      <>
         <div className="new-client-header">
            <h2>
               {!editClient ? (
                  <Translate id="com.tempedge.client.title.createclient"></Translate>
               ) : (
                  <Translate id="com.tempedge.client.title.updateclient"></Translate>
               )}
            </h2>
         </div>
         <div className="new-clients-contents">
            <div className="client-contents">
               <Row>
                  <Col sm={12} md={7} lg={7} className="form-group">
                     <TranslatedLabel id="com.tempedge.msg.label.country" />
                     <Field
                        name="clientcountry"
                        data={countryList}
                        valueField="countryId"
                        textField="name"
                        value={{ countryId: values.clientcountry }}
                        className={` tempEdge-input-box form-control ${errors.clientcountry && touched.clientcountry ? 'has-error' : ''}`}
                        component={DropdownList}
                        onChange={(value) => {
                           setFieldValue('clientcountry', value.countryId);
                           const country = countryList.filter((country) => {
                              return country.countryId === value.countryId;
                           })[0];
                           setStateList(country.regionEntityList);
                           setFieldValue('clientstate', '');
                        }}
                        isInvalid={errors.clientcountry && touched.clientcountry}
                     />
                  </Col>
                  <Col sm={12} md={5} lg={5} className="form-group">
                     <TranslatedLabel id="com.tempedge.msg.label.city" />
                     <Field
                        id="clientcity"
                        name="clientcity"
                        type="text"
                        maxLength="20"
                        className={` tempEdge-input-box form-control ${errors.clientcity && touched.clientcity ? 'has-error' : ''}`}
                        onChange={(e) => handleChange('clientcity', e)}
                     />
                  </Col>
               </Row>
               <Row>
                  <Col sm={12} md={7} lg={7} className="form-group mt-2">
                     <TranslatedLabel id="com.tempedge.msg.label.agencyaddress" />
                     <Field
                        id="clientaddress"
                        name="clientaddress"
                        type="text"
                        maxLength="60"
                        className={` tempEdge-input-box form-control ${errors.clientaddress && touched.clientaddress ? 'has-error' : ''}`}
                        onChange={(e) => handleChangeAlfaNumbers('clientaddress', e)}
                     />
                  </Col>
                  <Col sm={12} md={5} lg={5} className="form-group mt-2">
                     <TranslatedLabel id="com.tempedge.msg.label.agencyaddress2" />
                     <Field
                        id="clientaddress2"
                        name="clientaddress2"
                        type="text"
                        className="tempEdge-input-box form-control"
                        maxLength="40"
                        onChange={(e) => handleChangeAlfaNumbers('clientaddress2', e)}
                     />
                  </Col>
               </Row>
               <Row>
                  <Col sm={12} md={7} lg={7} className="form-group mt-2">
                     <TranslatedLabel id="com.tempedge.msg.label.state" />
                     <Field
                        name="clientstate"
                        data={stateList}
                        valueField="regionId"
                        textField="name"
                        value={{ regionId: values.clientstate }}
                        className={` tempEdge-input-box form-control ${errors.clientstate && touched.clientstate ? 'has-error' : ''}`}
                        component={DropdownList}
                        onChange={(value) => {
                           setFieldValue('clientstate', value.regionId);
                        }}
                        isInvalid={errors.clientstate && touched.clientstate}
                     />
                  </Col>
                  <Col sm={12} md={5} lg={5} className="form-group mt-2">
                     <TranslatedLabel id="com.tempedge.msg.label.agencyzipcode" />
                     <Field
                        id="clientzipcode"
                        name="clientzipcode"
                        type="text"
                        onChange={(e) => setFieldValue('clientzipcode', (e.target.value = normalizeZipCode(e.target.value)))}
                        className={` tempEdge-input-box form-control ${errors.clientzipcode && touched.clientzipcode ? 'has-error' : ''}`}
                     />
                  </Col>
               </Row>
            </div>
         </div>
      </>
   );
};

export default ClientSecondPage;
