import '../../../assets/styles/components/tablepayroll.css';
import '../../../assets/styles/components/table-inputs.css';
import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Translate } from 'react-localize-redux';
import AttendanceTableHeader from './AttendanceTableHeader';
import EditableCell from './EditableCell.js';
import ThreeStateSwitch from '../../../components/common/ThreeStateSwitch/ThreeStateSwitch';
import { TotalAttendanceWeek, TotalSumAttendanceByWeek, TotalSumByWeekDay } from '../../../utils/helpers/TotalHoursFormat';
import moment from 'moment';
import useHeaderDate from '../PayrollByWeek/useHeaderDate';
import { useSelector } from 'react-redux';

export const AttendanceTable = ({ attendanceWeekList, translate }) => {
   const startDate = useSelector((store) => store.attendance.startDate);
   const findDate  = useHeaderDate(startDate);
   const [applyValue, setApplyValue] = useState(null);
   const [triggerUpdate, setTriggerUpdate] = useState(0);

   const handleApplyToAll = () => {
      setTriggerUpdate((prev) => prev + 1);
   };

   return (
      <>
         <Row>
            <Col className="form-group" sm={12} md={12} lg={12}>
               <h2>
                  <Translate id={'com.tempedge.msg.label.attendancevalidation'} />
               </h2>
            </Col>
         </Row>

         <Row
            className="time-sheet__body"
            style={{
               borderTop: '1px dashed #cccccc',
               borderBottom: '1px dashed #cccccc',
               paddingTop: '10px',
               paddingBottom: '10px',
               justifyContent: 'flex-end'
            }}
         >
            <Col sm={3} md={3} lg={2} className="text-end" style={{ marginLeft: '200px' }}>
               <label className="text-left label-p"style={{ marginLeft: '19px' }}>
                  <Translate id="com.tempedge.msg.label.attendance" />
               </label>
               <div className="form-group">
                  <ThreeStateSwitch
                     size={'md'}
                     name="globalSwitch"
                     currentValue={applyValue}
                     onClick={(value) => setApplyValue(value)}
                  />
               </div>
            </Col>

            <Col sm={3} md={3} lg={3} className="text-end">
               <div className="form-group" style={{ marginTop: '25px' }}>
                  <button
                     type="button"
                     onClick={handleApplyToAll}
                     className="btn btn-green w-100"
                  >
                     {translate("com.tempedge.msg.label.update")}
                  </button>
               </div>
            </Col>
         </Row>

         <Row>
            <Col className="form-group" md={6} lg={3} sm={12}>
               <p style={{ textAlign: 'left', marginTop: '10px' }}>
                  <Translate id={'com.tempedge.msg.label.listsize'} /> <span className="badge">{attendanceWeekList.length}</span>
               </p>
            </Col>
         </Row>

         <Row>
            <Col className="form-check" sm={12} md={12} lg={12}>
               <table className="responsive-table">
                  <thead>{findDate && <AttendanceTableHeader findDate={findDate} />}</thead>
                  <tbody>
                     {attendanceWeekList?.map((payroll, index) => (
                        <tr key={payroll.person?.personId || index}>
                           <th scope="row" className="text-center">{payroll.person?.employeeId || ''}</th>
                           <td className="text-center">{payroll.person?.fullName || ''}</td>
                           {findDate.map(({ day, date }, dayIndex) => {
                              const payrollDate = payroll && payroll[day]?.dateList;
                              return day && payrollDate && moment(payrollDate).format('MM/DD/YYYY') === date ? (
                                 <EditableCell
                                    key={`${payroll.person?.personId}-${dayIndex}`}
                                    payroll={payroll}
                                    day={day}
                                    translate={translate}
                                    applyValue={applyValue}
                                    triggerUpdate={triggerUpdate}
                                 />
                              ) : (
                                 <td key={`${payroll.person?.personId}-${dayIndex}`} className="text-center"></td>
                              );
                           })}
                           <td className="text-center">{TotalSumAttendanceByWeek(payroll, findDate)}</td>
                        </tr>
                     ))}
                     <tr className="tableRow text-center">
                        <td colSpan={1}></td>
                        <td key="total-label" className="table-content text-uppercase text-center">
                           <Translate id={'com.tempedge.msg.label.total'} />
                        </td>
                        {findDate &&
                          findDate.map(({ day }, index) => (
                              <td key={`total-${index}`} className="table-content">
                                 {TotalSumByWeekDay(attendanceWeekList,day)}
                              </td>
                           ))}
                        <td>{TotalAttendanceWeek(attendanceWeekList, findDate)}</td>
                     </tr>
                  </tbody>
               </table>
            </Col>
         </Row>
      </>
   );
};
