import { useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import DropdownList from '../../../components/common/Dropdown/DropdownList';
import GreenCommonBtn from '../../../components/common/Buttons/GreenCommonBtn';
import { isValidNumberWithMax, onlySingleNumbers } from '../../../utils/ReGexs';
import { addCustomPayrollForAllEmployees } from '../../../Redux/actions/customPayrollActions';
import { Translate } from 'react-localize-redux';
import { Col, Row } from 'react-bootstrap';

const ApplyToAll = ({ type, headerCustomPayroll, weekActive }) => {
   const dispatch = useDispatch();
   const [amount, setAmount] = useState('');
   const [hours, setHours] = useState('');
   const [selectedCode, setSelectedCode] = useState(null);
   const [error, setError] = useState({ hours: false, amount: false });

   const config = useMemo(
      () => ({
         titleToAll: type === 'pay' ? 'com.tempedge.msg.label.paycode' : 'com.tempedge.msg.label.billingcode',
         titleHours: type === 'pay' ? 'com.tempedge.msg.label.hourspay' : 'com.tempedge.msg.label.hoursbill',
         titleAmount: type === 'pay' ? 'com.tempedge.msg.label.amount' : 'com.tempedge.msg.label.amountbill',
         sectionCode: type === 'pay' ? 'payCode' : 'billingCode',
         sectionAmount: type === 'pay' ? 'amountPay' : 'amountBill',
         sectionHours: type === 'pay' ? 'hoursPay' : 'hoursBill',
      }),
      [type],
   );

   const handleBlur = (field, value) => {
      setError((prevErrors) => ({
         ...prevErrors,
         [field]: value ? (field === 'hours' ? !isValidNumberWithMax(value, 3) : !isValidNumberWithMax(value, 4)) : false,
      }));
   };

   const handleUpdateAll = () => {
      if (!selectedCode) return;

      const baseEntry = {
         [config.sectionCode]: {
            customPayrollTypeId: selectedCode.customPayrollTypeId,
            reportTitle: selectedCode.title,
         },
         _idHeader: selectedCode._idHeader,
      };

      const entry = {
         ...baseEntry,
         [config.sectionAmount]: amount ? parseFloat(amount) : '',
         [config.sectionHours]: hours ? parseFloat(hours) : '',
         entryType: type,
         dateList: weekActive?.end,
      };

      dispatch(addCustomPayrollForAllEmployees([entry]));
   };

   return (
      <Row className="time-sheet__body">
         <Col sm={12} md={4} lg={3}>
            <label className="text-left label-p">
               <Translate id={config.titleToAll} />
            </label>
            <DropdownList
               data={headerCustomPayroll}
               onChange={(value) => setSelectedCode(value)}
               textField="title"
               valueField="_idHeader"
               placeholder={<Translate id="com.tempedge.msg.label.select" />}
            />
         </Col>
         <Col sm={12} md={4} lg={3}>
            <label className="text-left label-p">
               <Translate id={config.titleHours} />
            </label>
            <input
               type="text"
               value={hours}
               onChange={(e) => {
                  const value = e.target.value;
                  if (onlySingleNumbers.test(value)) {
                     setHours(value);
                  }
               }}
               onBlur={() => handleBlur('hours', hours)}
               className={`form-control tempEdge-input-box mt-1 text-center ${error.hours ? 'has-error' : ''}`}
            />
         </Col>
         <Col sm={12} md={4} lg={3}>
            <label className="text-left label-p">
               <Translate id={config.titleAmount} />
            </label>
            <input
               type="text"
               value={amount}
               onChange={(e) => {
                  const value = e.target.value;
                  if (onlySingleNumbers.test(value)) {
                     setAmount(value);
                  }
               }}
               onBlur={() => handleBlur('amount', amount)}
               className={`form-control tempEdge-input-box mt-1 text-center ${error.amount ? 'has-error' : ''}`}
            />
         </Col>
         <Col sm={12} md={4} lg={3}>
            <label className="text-left label-p">
               <Translate id={'com.tempedge.msg.label.update'} />
            </label>
            <GreenCommonBtn
               tag="com.tempedge.msg.label.update"
               onClick={handleUpdateAll}
               class="blue-common-btn register-save-btn-100"
               disabled={!selectedCode || error.amount || error.hours}
            />
         </Col>
      </Row>
   );
};

export default ApplyToAll;
