import { useEffect, useState } from 'react';
import { Translate, withLocalize } from 'react-localize-redux';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form, FieldArray } from 'formik';
import { Row, Col } from 'react-bootstrap';
import Modal from '../../../Modals/Modal/Modal';
import ConfirmModal from '../../../components/common/Modal/ModalConfirm';
import { addEmployeeHistory, resetEntireStateHistory } from '../../../Redux/actions/payrollPersonHistoryActions';
import ActiveLanguageAddTranslation from '../../../components/common/ActiveLanguageAddTranslation/ActiveLanguageAddTranslation';
import ContainerBlue from '../../../components/common/Container/ContainerBlue';
import Loader from '../../../components/common/Loader/Loader';
import OutcomeBar from '../../../components/common/OutcomeBar';
import AccountEmployeeSchema from './AccountEmployeeValidation';
import { generateUniqueId } from '../../../utils/PayrollUtils';
import EmployeeTable from '../../../components/common/EmployeeTable/EmployeeTable';
import addIcon from '../../../components/common/ClientCode/assets/plus.png';
import deleteIcon from '../../../components/common/ClientCode/assets/delete.png';
import CancelBtn from '../../../components/common/Buttons/CancelBtn';
import SaveBtn from '../../../components/common/Buttons/SaveBtn';
import DropdownList from '../../../components/common/Dropdown/DropdownList';
import { onlyNumbers, onlyNumbersandDecimals, onlyAlphanumeric } from '../../../utils/ReGexs';
import ObjectiveRange from '../../../components/common/Range/Range';
import UploadFile from '../../../components/common/UploadFile/UploadFile';
import DownloadFileModern from '../../../components/common/downloadFile/DownloadFileIcon';
import { useAccountApi } from '../../../Hooks/Account/useAccountApi';
import EmployeeSearch from './ AccountEmployeeSearch';

const isRowFilled = (account) => {
  return account.bankName || account.routeAndTransitNumber || account.accountNumber || account.accountType || account.amount;
};

const AccountEmployee = ({ activeLanguage, history, addTranslationForLanguage }) => {
  const [prevActiveLanguage, setPrevActiveLanguage] = useState(activeLanguage);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [data, setData] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [employeeId, setEmployeeId] = useState(0);
  const [employeeName, setEmployeeName] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [maxAllowed, setMaxAllowed] = useState(null);
  const [modal, setModal] = useState({
    show: false,
    title: '',
    content: null,
    buttons: null,
    size: 'modal-lg',
  });
  const [showConfirm, setShowConfirm] = useState(false);
  const [pendingValues, setPendingValues] = useState(null);
  const [showAccountForm, setShowAccountForm] = useState(false);
  const [deletedAccounts, setDeletedAccounts] = useState([]);
  
  const accountTypes = [
    { value: 'savings', label: 'Savings' },
    { value: 'checking', label: 'Checking' },
  ];
  
  const clearMessage = () => {
    setMessage(null);
  };
  
  useEffect(() => {
    if (prevActiveLanguage !== activeLanguage) {
      history.push(`/employee/account/${activeLanguage.code}`);
      ActiveLanguageAddTranslation(activeLanguage, addTranslationForLanguage);
      setPrevActiveLanguage(activeLanguage);
    }
  }, [activeLanguage, history, addTranslationForLanguage, prevActiveLanguage]);
  
  useEffect(() => {
    dispatch(resetEntireStateHistory());
  }, [dispatch]);
  
  useEffect(() => {
    return () => {
      dispatch(resetEntireStateHistory());
      setData([]);
    };
  }, [dispatch]);
  
  const employees = useSelector(
    (state) => state?.payrollPersonHistoryReducer?.employeesInList
  );
  
  const { loading: accountLoading, message: accountMessage, setMessage: setAccountMessage, findEmployee: findEmployeeApi, deleteAccount, createOrUpdateAccount } = useAccountApi();
  
  const findEmployee = async () => {
    setAccountMessage(null);
    try {
      setLoading(true);
      const res = await findEmployeeApi(employeeId);
      if (res.status === 200 && res.code === 'TE00') {
        setData(res.result);
        if (res.result.maxAccountAllowed) {
          setMaxAllowed(res.result.maxAccountAllowed);
        }
        setShowAccountForm(true);
        if (!res.result.accountDTOList || res.result.accountDTOList.length === 0) {
          setAccounts([]);
        } else {
          const filteredAccounts = res.result.accountDTOList.filter(
            (account) => !account.deleted && !deletedAccounts.includes(account.accountId)
          );
          setAccounts(filteredAccounts);
        }
        const selectedEmployee = employees.find(
          (employee) => employee.personId === employeeId
        );
        if (selectedEmployee) {
          setEmployeeName(`${selectedEmployee.firstName} ${selectedEmployee.lastName}`);
        }
      } else {
        setShowAccountForm(true);
      }
    } catch (error) {
      setAccountMessage(error);
      setShowAccountForm(true);
    } finally {
      setLoading(false);
    }
  };
  
  const handleSubmitAccounts = async (values) => {
    setSubmitted(true);
    setLoading(true);
    const originalAccounts = [...values.accounts];
    let accountsToSave = values.accounts;
    
    // Procesa las cuentas marcadas para eliminación
    if (deletedAccounts.length > 0) {
      try {
        const deletionPromises = deletedAccounts.map((accountId) =>
          deleteAccount(accountId)
        );
        const responses = await Promise.all(deletionPromises);
        const allDeleted = responses.every((res) => res.status === 200 && res.code === 'TE00');
        if (allDeleted) {
          const remainingAccounts = values.accounts.filter(
            (account) => !deletedAccounts.includes(account.accountId)
          );
          setAccounts(remainingAccounts);
          setMessage(responses[0]);
          accountsToSave = remainingAccounts;
          if (remainingAccounts.length === 0) {
            setData([]);
            setShowAccountForm(false);
            setEmployeeName('');
            dispatch(resetEntireStateHistory());
            setSubmitted(false); // Reiniciamos para que no se pinten errores
            setLoading(false);
            return;
          }
        } else {
          const failedResponse = responses.find((res) => !(res.status === 200 && res.code === 'TE00'));
          setMessage(failedResponse);
          setAccounts(originalAccounts);
          setLoading(false);
          return;
        }
      } catch (error) {
        setMessage(new Error(error));
        setAccounts(originalAccounts);
        setLoading(false);
        return;
      }
    }
    // Validación: la suma de los porcentajes debe ser 100
    const totalPercentage = accountsToSave.reduce((sum, account) => sum + Number(account.percentage || 0), 0);
    if (totalPercentage !== 100) {
      setMessage({
        data: {
          status: 400,
          message: "com.tempedge.error.account.percentagenotreached"
        }
      });
      setLoading(false);
      return;
    }
  
    // VALIDACIÓN DE ARCHIVO PERMITIDO, PERO SIN ABORTAR EL GUARDADO:
    const allowedExtensions = ['doc', 'docx', 'pdf'];
    let filesWarning = false;
    accountsToSave = accountsToSave.map(account => {
      if (account.uploadedFileName) {
        const extension = account.uploadedFileName.split('.').pop().toLowerCase();
        if (!allowedExtensions.includes(extension)) {
          filesWarning = true;
          return {
            ...account,
            uploadedFile: undefined,
            uploadedFileName: undefined,
            fileWarning: true
          };
        }
      }
      return account;
    });
  
    // Envía la actualización o creación para cada cuenta
    const savePromises = accountsToSave.map(async (account) => {
      const res = await createOrUpdateAccount(employeeId, account);
      if (!(res.status === 200 && (res.code === 'TE00' || res.code === 'TE-E02'))) {
        account.saveError = true;
      } else {
        account.saveError = false;
        account.accountId = res.result.accountId;
      }
      return res;
    });
    
    const saveResponses = await Promise.all(savePromises);
    const allSaved = saveResponses.every(
      (res) => res.status === 200 && (res.code === 'TE00' || res.code === 'TE-E02')
    );
    
    if (allSaved) {
      // Si se detectó algún archivo con formato incorrecto, se muestra el warning
      if (filesWarning) {
        setMessage({
          data: {
            status: 200,
            code: 'TE-E08',
            message: "com.tempedge.warn.accountfileerror"
          }
        });
      } else {
        setMessage(saveResponses[0]);
      }
      setAccounts([
        {
          bankName: '',
          routeAndTransitNumber: '',
          accountNumber: '',
          accountType: '',
          amount: '',
          percentage: 0,
          cancelOldAccount: false,
        },
      ]);
      setData([]);
      setShowAccountForm(false);
      dispatch(resetEntireStateHistory());
      setSubmitted(false);
    } else {
      // Si falla el guardado de alguna cuenta, se toma el mensaje del backend del primer error y se mantiene las cuentas para corregirlas.
      const errorResponse = saveResponses.find(
        (res) => !(res.status === 200 && (res.code === 'TE00' || res.code === 'TE-E02'))
      );
      setMessage(errorResponse);
      setAccounts(accountsToSave);
    }
    setLoading(false);
  };
  const handleLocalRemove = (index, accountId, remove) => {
    if (accountId) {
      setDeletedAccounts([...deletedAccounts, accountId]);
    }
    remove(index);
    if (accounts.length === 1) {
      setShowAccountForm(true);
    }
  };
  
  const selectRow = (event) => {
    const personId = parseInt(event[0]);
    setEmployeeId(personId);
    const selectedEmployee = {
      id: generateUniqueId(),
      personId: event[1],
      firstName: event[5],
      lastName: event[4],
      gender: event[7],
      phone: event[8],
      hiredate: event[9],
    };
    dispatch(addEmployeeHistory(selectedEmployee));
    setEmployeeName(`${selectedEmployee.firstName} ${selectedEmployee.lastName}`);
    clearMessage();
  };
  
  const handleRangeChange = (index, newVal, values, setFieldValue) => {
    const totalExcludingCurrent = values.accounts.reduce(
      (acc, acct, i) => (i === index ? acc : acc + (Number(acct.percentage) || 0)),
      0
    );
    const allowedMax = 100 - totalExcludingCurrent;
    if (newVal > allowedMax) newVal = allowedMax;
    setFieldValue(`accounts.${index}.percentage`, newVal);
  };
  
  if (accountLoading || loading) return <Loader />;
  
  return (
    <ContainerBlue
      title={!showAccountForm ? 'com.tempedge.msg.title.employeeaccount' : null}
      label={showAccountForm && employeeName ? employeeName : ''}
    >
      {(message || accountMessage) && <OutcomeBar response={message || accountMessage} />}
      {data.length <= 0 && !showAccountForm && (
        <EmployeeSearch 
          setModal={setModal}
          findEmployee={findEmployee}
          employees={employees}
          selectRow={selectRow}
        />
      )}
      {employees && employees.length > 0 && (
        <div>
          {data.length <= 0 && !showAccountForm && <EmployeeTable employees={employees} />}
          {showAccountForm && (
            <Formik
              initialValues={{ accounts }}
              validationSchema={AccountEmployeeSchema}
              onSubmit={(values) => {
                setSubmitted(true);
                setPendingValues(values);
                setShowConfirm(true);
              }}
              enableReinitialize
            >
              {({ values, errors, touched, handleBlur, handleChange, setFieldValue }) => (
                <Form className="mx-auto">
                  <div className="p-3">
                    <FieldArray
                      name="accounts"
                      render={({ push, remove }) => (
                        <div>
                          {values.accounts.length > 0 ? (
                            values.accounts.map((account, index) => (
                              <div
                                key={index}
                                className={submitted &&
                                  (account.saveError ||
                                    (maxAllowed !== null && index >= maxAllowed && isRowFilled(account)))
                                    ? "has-error"
                                    : ""
                                }
                              >
                                <div className="green-text font-weight-bold text-uppercase mb-3">
                                  <Translate id="com.tempedge.msg.label.account" /> #{index + 1}
                                </div>
                                <Row className="form-group">
                                  <Col xs={12} sm={12} md={12} lg={12}>
                                    <Row>
                                      <Col xs={12} sm={12} md={4} lg={4}>
                                        <label className="control-label">
                                          <Translate id="com.tempedge.msg.label.bankname" />
                                        </label>
                                        <input
                                          type="text"
                                          name={`accounts.${index}.bankName`}
                                          value={account.bankName}
                                          placeholder="Institution Name"
                                          required={submitted}
                                          className={`form-control tempEdge-input-box ${
                                            ((touched.accounts?.[index]?.bankName || submitted) && errors.accounts?.[index]?.bankName)
                                              ? "has-error"
                                              : ""
                                          }`}
                                          maxLength="50"
                                          onChange={(e) => {
                                            e.target.value = onlyAlphanumeric(e.target.value);
                                            handleChange(e);
                                          }}
                                          onBlur={handleBlur}
                                          style={{ width: '100%' }}
                                        />
                                      </Col>
                                      <Col xs={12} sm={12} md={3} lg={3}>
                                        <label className="control-label">
                                          <Translate id="com.tempedge.msg.label.routetransit" />
                                        </label>
                                        <input
                                          type="text"
                                          name={`accounts.${index}.routeAndTransitNumber`}
                                          value={account.routeAndTransitNumber}
                                          placeholder="Route and Transit Number"
                                          required={submitted}
                                          className={`form-control tempEdge-input-box ${
                                            ((touched.accounts?.[index]?.routeAndTransitNumber || submitted) &&
                                              errors.accounts?.[index]?.routeAndTransitNumber)
                                              ? "has-error"
                                              : ""
                                          }`}
                                          maxLength="256"
                                          onChange={(e) => {
                                            e.target.value = onlyNumbers(e.target.value);
                                            handleChange(e);
                                          }}
                                          onBlur={handleBlur}
                                          style={{ width: '100%' }}
                                        />
                                      </Col>
                                      <Col xs={12} sm={12} md={4} lg={4}>
                                        <label className="control-label">
                                          <Translate id="com.tempedge.msg.label.accountnumber" />
                                        </label>
                                        <input
                                          type="text"
                                          name={`accounts.${index}.accountNumber`}
                                          value={account.accountNumber}
                                          placeholder="Customer Account"
                                          required={submitted}
                                          className={`form-control tempEdge-input-box ${
                                            ((touched.accounts?.[index]?.accountNumber || submitted) &&
                                              errors.accounts?.[index]?.accountNumber)
                                              ? "has-error"
                                              : ""
                                          }`}
                                          maxLength="50"
                                          onChange={(e) => {
                                            e.target.value = onlyNumbers(e.target.value);
                                            handleChange(e);
                                          }}
                                          onBlur={handleBlur}
                                          style={{ width: '100%' }}
                                        />
                                      </Col>
                                      <Col xs={12} sm={12} md={1} lg={1} className="d-flex align-items-end justify-content-center" style={{ paddingLeft: '10px' }}>
                                        <span
                                          onClick={() => handleLocalRemove(index, account.accountId, remove)}
                                          title="Remove account"
                                          style={{ cursor: 'pointer', padding: '5px' }}
                                        >
                                          <img className="delete-icon" src={deleteIcon} alt="deleteIcon" />
                                        </span>
                                      </Col>
                                    </Row>
                                    <Row className="mt-2">
                                      <Col xs={12} sm={4}>
                                        <label className="control-label">
                                          <Translate id="com.tempedge.msg.label.accounttype" />
                                        </label>
                                        <DropdownList
                                          data={accountTypes}
                                          textField="label"
                                          valueField="value"
                                          placeholder="Select"
                                          required={submitted}
                                          isInvalid={
                                            (errors.accounts &&
                                              errors.accounts[index] &&
                                              errors.accounts[index].accountType &&
                                              touched.accounts &&
                                              touched.accounts[index] &&
                                              touched.accounts[index].accountType) ||
                                            (index === values.accounts.length - 1 && account.saveError)
                                          }
                                          maxLength="50"
                                          value={account.accountType ? { label: account.accountType, value: account.accountType } : null}
                                          onChange={(value) => {
                                            setFieldValue(`accounts.${index}.accountType`, value.value);
                                          }}
                                        />
                                      </Col>
                                      <Col xs={12} sm={12} md={3} lg={3}>
                                        <label className="control-label">
                                          <Translate id="com.tempedge.msg.label.amount" />
                                        </label>
                                        <input
                                          type="text"
                                          name={`accounts.${index}.amount`}
                                          value={account.amount}
                                          placeholder="Amount"
                                          required={submitted}
                                          className={`form-control tempEdge-input-box ${
                                            ((touched.accounts?.[index]?.amount || submitted) &&
                                              errors.accounts?.[index]?.amount)
                                              ? "has-error"
                                              : ""
                                          }`}
                                          maxLength="256"
                                          onChange={(e) => {
                                            e.target.value = e.target.value.replace(onlyNumbersandDecimals, '');
                                            handleChange(e);
                                          }}
                                          onBlur={handleBlur}
                                          style={{ width: '100%' }}
                                        />
                                      </Col>
                                      <Col xs={12} sm={12} md={4} lg={4}>
                                        <div className="d-flex align-items-center">
                                          <label className="control-label ml-10 pt-2">
                                            <Translate id="com.tempedge.msg.label.percentage" />
                                          </label>
                                          <span style={{ marginLeft: '10px', paddingTop:'5px' }}>
                                            {account.percentage}%
                                          </span>
                                        </div>
                                        <ObjectiveRange
                                          name={`accounts.${index}.percentage`}
                                          value={account.percentage}
                                          min={0}
                                          max={100}
                                          onChange={(e) =>
                                            handleRangeChange(index, Number(e.target.value), values, setFieldValue)
                                          }
                                          className="w-100"
                                          styleRange={{ paddingRight: '40px', paddingTop:'10px' }}
                                        />
                                      </Col>
                                    </Row>
                                    <Row className="align-items-start">
                                      <Col xs={12} md={7} className="align-self-start" style={{ marginTop: '40px' }}>
                                        <label className="d-flex align-items-center" style={{ cursor: 'pointer' }}>
                                          <input
                                            type="checkbox"
                                            name={`accounts.${index}.cancelOldAccount`}
                                            checked={account.cancelOldAccount}
                                            onChange={(e) =>
                                              setFieldValue(`accounts.${index}.cancelOldAccount`, e.target.checked)
                                            }
                                            style={{ marginRight: '8px', marginBottom: '20px' }}
                                          />
                                          <Translate id="com.tempedge.msg.label.canceloldaccount" />
                                        </label>
                                      </Col>
                                      <Col xs={12} md={4} className="d-flex flex-column align-items-center" style={{ paddingRight: '16px', marginBottom: '18px' }}>
                                        <div className="d-flex justify-content-end align-items-center w-100" style={{ marginBottom: '5px' }}>
                                          <span
                                            style={{
                                              fontWeight: 'bold',
                                              marginRight: '10px',
                                              whiteSpace: 'nowrap',
                                              paddingTop: '20px'
                                            }}
                                          >
                                            Amount paycheck ($):
                                          </span>
                                          <UploadFile
                                            accept=".doc,.docx,.pdf"
                                            onFileSelectSuccess={(file) => {
                                              setFieldValue(`accounts.${index}.uploadedFile`, file);
                                              setFieldValue(`accounts.${index}.uploadedFileName`, file.name);
                                            }}
                                            onFileSelectError={(error) => console.error(error)}
                                            style={{ width: '90px' }}
                                          />
                                        </div>
                                        <Row style={{ marginLeft: 'auto', marginRight: '18px', marginTop: '5px' }}>
                                          {account.fileEntities &&
                                            account.fileEntities.map((fileEntity, fileIndex) => (
                                              <Col key={fileIndex} xs={12} sm={12} md={8} lg={8} className="d-flex align-items-center justify-content-between" style={{ gap: '1rem', marginBottom: '10px' }}>
                                                <div>{fileEntity.fileName}</div>
                                                <DownloadFileModern
                                                  file={fileEntity.file}
                                                  fileName={fileEntity.fileName}
                                                  label="Download"
                                                />
                                              </Col>
                                            ))}
                                        </Row>
                                        {account.uploadedFileName && (
                                          <div className="text-center" style={{ paddingLeft: '150px' }}>
                                            {account.uploadedFileName}
                                          </div>
                                        )}
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                                <hr />
                              </div>
                            ))
                          ) : (
                            <div className="mt-8 ml-5">
                              <h2>
                                <Translate id="com.tempedge.msg.label.noaccountsfound" />
                              </h2>
                            </div>
                          )}
                          <Row className="form-group">
                            <Col xs={12} sm={12} md={12} lg={12} className="list-item text-center">
                              <span
                                className="center-block pull-right add-fieldArray-btn"
                                onClick={() => {
                                  if (maxAllowed !== null && values.accounts.length >= maxAllowed) return;
                                  push({
                                    bankName: '',
                                    routeAndTransitNumber: '',
                                    accountNumber: '',
                                    accountType: '',
                                    amount: '',
                                    percentage: values.accounts.length === 0 ? 100 : 0,
                                    cancelOldAccount: false,
                                  });
                                }}
                                style={{
                                  cursor:
                                    maxAllowed !== null && values.accounts.length >= maxAllowed
                                      ? 'not-allowed'
                                      : 'pointer',
                                }}
                              >
                                <img
                                  style={{
                                    width: '42px',
                                    paddingTop: '12px',
                                    marginRight: '20px',
                                    filter:
                                      maxAllowed !== null && values.accounts.length >= maxAllowed
                                        ? 'grayscale(100%)'
                                        : 'none',
                                    opacity:
                                      maxAllowed !== null && values.accounts.length >= maxAllowed
                                        ? 0.5
                                        : 1,
                                  }}
                                  src={addIcon}
                                  alt="addIcon"
                                />
                              </span>
                            </Col>
                          </Row>
                        </div>
                      )}
                    />
                  </div>
                  <Row className="mt-4">
                    <Col xs={12} sm={6} md={6} lg={6} className="text-right">
                      <CancelBtn
                        onCancel={() => {
                          dispatch(resetEntireStateHistory());
                          setData([]);
                        }}
                      />
                    </Col>
                    <Col xs={12} sm={6} md={6} lg={6} className="text-left">
                      <SaveBtn type="submit" />
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          )}
        </div>
      )}
  
      <Modal
        open={modal.show}
        content={modal.content}
        onClose={() => setModal({ show: false, content: null, size: null })}
        modalSize={modal.size ?? 'md'}
      />
  
      <ConfirmModal
        show={showConfirm}
        title="com.tempedge.msg.label.confirmmsg"
        description="com.tempedge.msg.label.confirmsavingaccount"
        onCancel={() => setShowConfirm(false)}
        onSave={() => {
          setShowConfirm(false);
          handleSubmitAccounts(pendingValues);
        }}
      />
    </ContainerBlue>
  );
};
  
export default withLocalize(AccountEmployee);
