
import { limitString } from '../../../../utils/ReGexs';
import downIcon from '../../../../assets/icons/down.png';
import editIcon from '../../../../assets/icons/edit.png';
import deleteIcon from '../../../../assets/icons/delete.png';
import { Translate } from 'react-localize-redux';

const PositionList = ({ positionList, editFromPosList, removeFromPosList }) => {
  return positionList?.map((position, index) => (
    <div id={`positions-${index}`} key={`positions-${index}`}>
      <div className="btn-dept">
        <a
          className="up-down-arrow pull-left"
          data-toggle="collapse"
          href={`#positions${index}`}
          role="button"
          aria-expanded="false"
          aria-controls={`positions${index}`}
        >
          <img
            src={downIcon}
            style={{
              width: 14,
              height: 11,
              display: 'inline',
              marginLeft: 19,
            }}
            alt="downIcon"
          />
        </a>
        <span>
          {position?.name &&
            limitString({
              value: position?.name,
              limit: 9,
              replace: '...',
            })}
        </span>
        <span className="pull-right ">
          <img
            src={editIcon}
            className="client-dpt-btn-edit-delete mr-4"
            onClick={() => editFromPosList(index)}
            alt="editIcon"
          />

          <img
            src={deleteIcon}
            className="client-dpt-btn-edit-delete mr-2"
            onClick={() => removeFromPosList(index, position.positionId ?? null)}
            alt="deleteIcon"
          />
        </span>
      </div>

      <div className="collapse multi-collapse show" id={`positions-${index}`}>
        <div className="card card-body card-department-list">
          <table className="table table-borderless">
            <thead>
              <tr>
                <th className="w-50 ">
                  <label className="tempedge-control-label" >
                    <Translate  id="com.tempedge.msg.label.payRate" />
                  </label>
                </th>
                <td className="w-50">{position.pay !== undefined ? `$ ${position.pay}` : ''}</td>
              </tr>
              <tr>
                <th className="w-50">
                  <label className="tempedge-control-label">
                    <Translate  id="com.tempedge.msg.label.markup" />
                  </label>
                </th>
                <td className="w-50">{position.markUp !== undefined ? `${position.markUp} %` : ''}</td>
              </tr>
              <tr>
                <th className="w-50">
                  <label className="tempedge-control-label">
                    <Translate  id="com.tempedge.msg.label.OtMarkup" />
                  </label>
                </th>
                <td className="w-50">{position.otMarkUp !== undefined ? `${position.otMarkUp} %` : ''}</td>
              </tr>
              <tr>
                <th className="w-50">
                  <label className="tempedge-control-label">
                    <Translate  id="com.tempedge.msg.label.employeeContact" />
                  </label>
                </th>
                <td className="w-50">{position.contactName !== undefined ? position.contactName : ''}</td>
              </tr>
              <tr>
                <th className="w-50">
                  <label className="tempedge-control-label">
                    <Translate  id="com.tempedge.msg.label.contactPhone" />
                  </label>
                </th>
                <td className="w-50">{position.contactPhone !== undefined ? position.contactPhone : ''}</td>
              </tr>
            </thead>
          </table>
        </div>
      </div>
    </div>
  ));
};

export default PositionList