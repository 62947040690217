import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { changeWeekTimeEntryValue } from '../../../Redux/actions/timeEntryActions';
import { roundToTwoDecimals } from '../../../utils/helpers/TotalHoursFormat';
import '../../../assets/styles/components/table-inputs.css';

const EditableCell = ({ key, payroll, day }) => {
   const [totalOtHour, setTotalOtHour] = useState('');
   const [totalRegHour, setTotalRegHour] = useState('');
   const [payrollDisable, setPayrollDisable] = useState(true);

   useEffect(() => {
      const roundedTotalRegHour = roundToTwoDecimals(payroll[day]?.totalRegHour);
      const roundedTotalOtHour = roundToTwoDecimals(payroll[day]?.totalOtHour);
      if (payroll[day]?.totalRegHour === 0) {
         setTotalRegHour('');
      } else {
         setTotalRegHour(roundedTotalRegHour);
      }

      if (payroll[day]?.totalOtHour === 0) {
         setTotalOtHour('');
      } else {
         setTotalOtHour(roundedTotalOtHour);
      }

      if (payroll[day]?.lockPayroll) {
         setPayrollDisable(true);
      } else {
         setPayrollDisable(false);
      }
   }, [payroll, day]);

   const dispatch = useDispatch();

   const validateInput = (value) => {
      const regularExpression = /^-?\d{0,3}(\.\d{0,2})?$/;
      return regularExpression.test(value);
   };

   const handleValueChange = (key, value) => {
      if (validateInput(value)) {
         const data = {
            payrollId: payroll[day].payrollId,
            personId: payroll.person?.personId,
            key,
            value: value || 0,
            day,
         };
         const actionToDispatch = changeWeekTimeEntryValue(data);
         dispatch(actionToDispatch);
      }
   };

   return (
      <>
         {payroll[day] !== null ? (
            <td
               key={key}
               data-title={day}
               data-type="currency"
               data-initial={day.charAt(0).toUpperCase()} // Añade la inicial del día
            >
               <div className="form-group-payroll">           
                     <span>RG</span>
                     <input
                        type="text"
                        className="form-field-payroll text-center"
                        placeholder="0.00"
                        value={totalRegHour}
                        onChange={(event) => {
                           const inputValue = event.target.value;
                           // Regular expression to match the desired format
                           const regex = /^-?\d{0,3}(\.\d{0,2})?$/;
                           if (regex.test(inputValue) || inputValue === '') {
                              setTotalRegHour(inputValue);
                           }
                        }}
                        onBlur={() => handleValueChange('totalRegHour', totalRegHour)}
                        disabled={payrollDisable}
                     /> 
                     <input
                        type="text"
                        className="form-field-payroll text-center"
                        placeholder="0.00"
                        value={totalOtHour}
                        onChange={(event) => {
                           const inputValue = event.target.value;
                           // Regular expression to match the desired format
                           const regex = /^-?\d{0,3}(\.\d{0,2})?$/;
                           if (regex.test(inputValue) || inputValue === '') {
                              setTotalOtHour(inputValue);
                           }
                        }}
                        onBlur={() => handleValueChange('totalOtHour', totalOtHour)}
                        disabled={payrollDisable}
                     />
                     <span>OT</span>                  
               </div>
            </td>
         ) : (
            <td
               key={key}
               data-title={day}
               data-type="currency"
               className='day-blanck'
               data-initial={day.charAt(0).toUpperCase()} // Añade la inicial del día
            >
               <p className='subtitle-payroll'>{''}</p>
            </td>
         )}
      </>
   );
};

export default EditableCell;
