import {
   TotalOtHourWeek,
   TotalRegHourWeek,
   TotalHourByWeekDay,
   TotalAttendanceWeek,
   TotalSumAttendanceByWeek,
} from '../../utils/helpers/TotalHoursFormat';
import moment from 'moment';

export const ExportExcel = (findDateArray, employeesTimeEntry, translate) => {
    
   const weekResults = Object.values(employeesTimeEntry).filter(
      (value) => value != null && (value.totalRegHours > 0 || value.totalRegHours < 0),
   );

   // Mapear los días de la semana del nuevo formato
   const days = findDateArray.map((dateObj) => ({
      day: moment().day(dateObj.day).format('ddd').toLowerCase(),
      date: dateObj.date,
   }));

   const data = weekResults.map((employee) => {
      const employeeData = {
         [translate('com.tempedge.msg.label.name')]: `${employee.person?.firstName} ${employee.person?.lastName}`,
         [translate('com.tempedge.msg.label.deptpos')]:
            employee.department !== null ? `${employee.department?.name} ${employee.position?.name}` : employee.position?.name,
      };

      // Asignar los datos para cada día de la semana
      days.forEach(({ day, date }) => {
         const dayTotal = moment(day).format('dddd').toLowerCase();
         employeeData[`${translate(`com.tempedge.msg.label.${dayTotal}`)}-${date}`] =
            employee[day] !== null && (employee[day]?.totalRegHour || employee[day]?.totalOtHour)
               ? `RG ${employee[day]?.totalRegHour.toFixed(2)} - ${employee[day]?.totalOtHour.toFixed(2)} OT`
               : null;
      });

      employeeData[`${translate(`com.tempedge.msg.label.reghours`)}`] = String(`${employee.totalRegHours.toFixed(2)}`);
      employeeData[`${translate(`com.tempedge.msg.label.othours`)}`] = String(`${employee.totalOTHours.toFixed(2)}`);
      employeeData[`${translate(`com.tempedge.msg.label.attendance`)}`] = String(`${TotalSumAttendanceByWeek(employee, findDateArray)}`);

      return employeeData;
   });

   // Crear totales de horas por día
   const totals = {
      [translate('com.tempedge.msg.label.name')]: '',
      [translate('com.tempedge.msg.label.deptpos')]: 'Total',
   };

   days.forEach(({ day, date }) => {
    const dayTotal = moment(day).format('dddd').toLowerCase();
      totals[`${translate(`com.tempedge.msg.label.${dayTotal}`)}-${date}`] = `${TotalHourByWeekDay(weekResults, day)}`;
   });

   totals[`${translate(`com.tempedge.msg.label.reghours`)}`] = `${TotalRegHourWeek(weekResults)}`;
   totals[`${translate(`com.tempedge.msg.label.othours`)}`] = `${TotalOtHourWeek(weekResults)}`;
   totals[`${translate(`com.tempedge.msg.label.attendance`)}`] = `${TotalAttendanceWeek(weekResults, findDateArray)}`;

   return { data, totals };
};
