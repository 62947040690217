import React, { useEffect, useMemo, useRef, useState,useCallback } from "react";
import { withLocalize } from "react-localize-redux";
import ContainerBlue from "../../../components/common/Container/ContainerBlue";
import DropdownList from "../../../components/common/Dropdown/DropdownList";
import Switch from "../../../components/common/Switch/Switch";
import { Tab, Tabs } from "react-bootstrap";
import BlueCommonBtn from "../../../components/common/Buttons/BlueCommonBtn";
import Datepicker from "../../../components/common/Datepicker/Datepicker";
import payroll from "../../../assets/images/reports/payroll.png";
import invoice from "../../../assets/images/reports/invoice.png";
import attendance from "../../../assets/images/reports/attendance.png";
import { Formik } from "formik";
import Loader from "../../../components/common/Loader/Loader";
import moment from "moment";
import OutcomeBar from "../../../components/common/OutcomeBar";
import InfoModal from "../../../components/common/Modal/InfoModal";
import Modal from "../../../Modals/Modal/Modal";
import DepartmentTables from "../../../components/common/DepartmentTables/DepartmentTables";
import { Schema, initialValues } from "./Schema";
import TempEdgeApi from "../../../services/TempEdgeApi";
import { shallowEqual, useSelector } from "react-redux";
import {
  REACT_APP_URL_FIND_INVOICE_REPORT,
  REACT_APP_URL_FIND_PAYROLL_REPORT,
  REACT_APP_URL_FIND_ATTENDANCE_REPORT,
  REACT_APP_URL_PAYROLL_FIND_WEEK_ACTIVE,
} from "../../../components/common/Routes/URLs";
import { normalizeDate, normalizeDateSendReports } from "../../../utils/ReGexs";
import { DATE_FORMAT_API, DATE_FORMAT_API_GENERATE } from "../../../components/common/Constants/Constants";
import useFetchOfficeList from "../../../Hooks/fetch/useFetchOfficeList";

function Report({ translate }) {
  const today = moment();
  const [loading, setLoading] = useState(false);
  const [clientSelect, setClientSelect] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [positions, setPositions] = useState([]);
  const [message, setMessage] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState(null);
  const [weekDates, setWeekDates] = useState({
    thisWeekInit: today.clone().startOf("isoWeek").format(DATE_FORMAT_API),
    thisWeekEnd: today.clone().endOf("isoWeek").format(DATE_FORMAT_API),
    lastWeekInit: today
    .clone()
    .subtract(1, "week")
    .startOf("isoWeek")
    .format(DATE_FORMAT_API),
    lastWeekEnd: today
    .clone()
    .subtract(1, "week")
    .endOf("isoWeek")
    .format(DATE_FORMAT_API),
  });

  const formikRef = useRef();

  const { loading: loadingOffices, offices, error } = useFetchOfficeList();

  const role = useSelector((state) => {
    return state.tempEdge.login !== "" &&
      state.tempEdge.login.portalUserList.length > 0
      ? state.tempEdge.login?.portalUserList[0]?.user?.roles[0]?.description
      : null;
  }, shallowEqual);

  const btns = useMemo(() => {
    return (
      <div className="row d-flex justify-content-between align-items-center">
        <div className="col-md-4 btn-sm ml-auto">
          <BlueCommonBtn
            tag="com.tempedge.msg.label.download"
            onClick={() => {
              if (formikRef.current) {
                formikRef.current.handleSubmit();
              }
            }}
          />
        </div>
      </div>
    );
  }, []);

  const weekActiveChange = useCallback(async (client, date) => {
    const { data: weekActive } = await TempEdgeApi.post({
      url: REACT_APP_URL_PAYROLL_FIND_WEEK_ACTIVE,
      payload: {
        orgId: JSON.parse(localStorage.getItem("agency"))?.organizationEntity
          ?.orgId,
        clientId: client,
        dateList: moment(date).format(DATE_FORMAT_API_GENERATE),
      },
    });
    const {
      status = "",
      result: { startDate = "", endDate = "" },
    } = weekActive;

    const { data: lastWeekActive } = await TempEdgeApi.post({
      url: REACT_APP_URL_PAYROLL_FIND_WEEK_ACTIVE,
      payload: {
        orgId: JSON.parse(localStorage.getItem("agency"))?.organizationEntity
          ?.orgId,
        clientId: client,
        dateList: moment(weekActive.result.startDate)
          .subtract(1, "d")
          .format(DATE_FORMAT_API_GENERATE),
      },
    });
    const {
      status: lastStatus = "",
      result: { startDate: lastStartDate = "", endDate: lastEndDate = "" },
    } = lastWeekActive;

    if (lastStatus && status === 200) {
      setWeekDates({
        thisWeekInit: startDate,
        thisWeekEnd: endDate,
        lastWeekInit: lastStartDate,
        lastWeekEnd: lastEndDate,
      });
    }
  }, []);

  const clientsListRedux = useSelector((state) => {
    return state.tempEdge.clientList;
  });

  const clientsList = useMemo(() => {
    return clientsListRedux;
  }, [clientsListRedux]);

  useEffect(() => {
    if (
      clientsListRedux.length === 1 &&
      formikRef.current &&
      !formikRef.current.values.company
    ) {
      const selectedClient = clientsListRedux[0];
      formikRef.current.setValues({
        ...formikRef.current.values,
        company: selectedClient,
        weekDay: "LASTWEEK",
        startDate: moment(weekDates.lastWeekInit,DATE_FORMAT_API),
        endDate: moment(weekDates.lastWeekEnd,DATE_FORMAT_API)
      });
      setDepartments(selectedClient.departments);
      weekActiveChange(selectedClient.clientId, today.format(DATE_FORMAT_API));
    }
  }, [clientsListRedux, weekActiveChange, today, weekDates]);

  const handleSubmit = async (values) => {
    try {
      setLoading(true);

      const reportType = values.reportType;
      let reportUrl = "";

      switch (reportType) {
        case "payroll":
          reportUrl = REACT_APP_URL_FIND_PAYROLL_REPORT;
          break;
        case "attendance":
          reportUrl = REACT_APP_URL_FIND_ATTENDANCE_REPORT;
          break;
        case "invoice":
          reportUrl = REACT_APP_URL_FIND_INVOICE_REPORT;
          break;
        default:
          break;
      }

      if (reportUrl !== "") {
        const request = buildReportRequest(values);
        const response = await sendReportRequest(reportUrl, request);

        if (response.status === 200 && (response.code === "TE00" || response.code === "TE-E08")) {
          const name = `${
            values.company.clientName
          }_${reportType.toUpperCase()}`;
          downloadFile(response.result, name);
          setData(values);
        } else {
          setData(values);
        }
        setMessage(response);
      }
    } catch (e) {
      setMessage(e);
    } finally {
      setLoading(false);
    }
  };

  const buildReportRequest = (values) => {
    let startDateWeek = moment(values.startDate,DATE_FORMAT_API).format(
     DATE_FORMAT_API
    );
    let endDateWeek = moment(values.endDate,DATE_FORMAT_API).format(DATE_FORMAT_API);

    if (values.weekDay !== "CUSTOM") {
      startDateWeek =
        values.weekDay === "THISWEEK"
          ? weekDates.thisWeekInit
          : weekDates.lastWeekInit;
      endDateWeek =
        values.weekDay === "THISWEEK"
          ? weekDates.thisWeekEnd
          : weekDates.lastWeekEnd;
    }

    return {
      orgId: JSON.parse(localStorage.getItem("agency"))?.organizationEntity
        ?.orgId,
      startDate: normalizeDateSendReports(startDateWeek),
      endDate: normalizeDateSendReports(endDateWeek),
      clientId: values.company.clientId,
      separatedByDay: values.filter.separatedByDay,
      reportByDept: values.filter.reportByDept,
      reportByPosition: values.filter.reportByPosition,
      showMarkup: values.filter.showMarkup,
      departmentId : values.department?.departmentId,
      positionId : values.position?.positionId,
      officeId: values.office?.officeId,
    };
  };

  const sendReportRequest = async (url, request) => {
    try {
      const { data: response } = await TempEdgeApi.post({
        url,
        payload: request,
      });
      return response;
    } catch (e) {
      return e;
    }
  };

  const downloadFile = (base64, name) => {
    if (typeof base64 === "string") {
      const base64URL = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${base64}`;
      const link = document.createElement("a");
      link.setAttribute("href", base64URL);
      link.setAttribute(
        "download",
        `${name}_${moment(today).format("YYYY-MM-DD-HH-mm")}.xlsx`
      );
      link.click();
    }
  }

  const onChangeClientDropdown = (client, formik) => {
    formik.setFieldValue("department", null);
    formik.setFieldValue("position", null);                        
    formik.setFieldValue("office", null); // Resetear el valor de la oficina
    setClientSelect(client);
    weekActiveChange(
      client.clientId,
      moment().format(DATE_FORMAT_API)
    );
    formik.setFieldValue("company", client);
    formik.setFieldValue("weekDay", "LASTWEEK");
    formik.setFieldValue("startDate", weekDates.lastWeekInit);
    formik.setFieldValue("endDate", weekDates.lastWeekEnd);
    setMessage(null);
    const departmentsTemp = client.departments;
    departmentsTemp.unshift({ departmentId: null, name: "All" });
    if (departmentsTemp?.length > 0) {
      if (departmentsTemp[1]?.name === "All")
        departmentsTemp?.shift({ departmentId: null, name: "All" });
    }
    setDepartments(departmentsTemp);
  
    setPositions([]);
  };
  

  const onChangeDepartmentDropdown = (department, formik) => {
    formik.setFieldValue("department", department);
    setMessage(null);
    setPositions(department.positions);
    formik.setFieldValue("position", '');
  }

  const reportTypes = useMemo(() => {
    if (role === null) return [];

    if (role === "CLIENT") {
      return [
        { key: "payroll", title: translate("com.tempedge.msg.label.payroll") },
        { key: "invoice", title: translate("com.tempedge.msg.label.invoice") },
      ];
    }

    if (role === "CLIENT_PAYROLL") {
      return [
        { key: "payroll", title: translate("com.tempedge.msg.label.payroll") },
      ];
    }

    return [
      { key: "payroll", title: translate("com.tempedge.msg.label.payroll") },
      {
        key: "attendance",
        title: translate("com.tempedge.msg.label.attendance"),
      },
      { key: "invoice", title: translate("com.tempedge.msg.label.invoice") },
    ];
  }, [role, translate]);

  const weekdayOptions = clientSelect
    ? [
        {
          label: `FROM ${normalizeDate(
            weekDates.lastWeekInit
          )} TO ${normalizeDate(weekDates.lastWeekEnd)}`,
          value: "LASTWEEK",
        },
        {
          label: `FROM ${normalizeDate(
            weekDates.thisWeekInit
          )} TO ${normalizeDate(weekDates.thisWeekEnd)}`,
          value: "THISWEEK",
        },
        { label: translate("com.tempedge.msg.label.custom"), value: "CUSTOM" },
      ]
    : [];

  if (loading || loadingOffices) return <Loader />;

  return (
    <React.Fragment>
      <ContainerBlue title="com.tempedge.msg.menu.generatereport" btns={btns}>
        {message && <OutcomeBar response={message} />}
        <Formik
          innerRef={formikRef}
          enableReinitialize={true}
          initialValues={data || initialValues}
          validationSchema={Schema}
          onSubmit={(values) => handleSubmit(values)}
        >
          {(formik) => (
            <div className="row p-2">
              <div className="col-12 col-md-3 filters">
                <div className="green-text font-weight-bold text-uppercase mb-4">
                  {translate("com.tempedge.msg.label.filters")}
                </div>
                <div className="form-group">
                  <label className="text-left label-p">
                    {translate("com.tempedge.msg.label.company")}
                  </label>
                  {clientsList.length === 1 && (
                    <InfoModal
                      value={clientsList[0].clientName}
                      isModal={true}
                      isInvalid={
                        formik.errors.company && formik.touched.company
                      }
                      onClick={() => {
                        setShowModal(true);
                      }}
                    />
                  )}
                  {clientsList.length > 1 && (
                    <DropdownList
                      data={clientsList}
                      name={"company"}
                      textField="clientName"
                      valueField="clientId"
                      value={formik.values.company}
                      onChange={(client) => onChangeClientDropdown(client, formik)}
                      isInvalid={
                        formik.errors.company && formik.touched.company
                      }
                    />
                  )}
                </div>
                <div className="form-group">
                  <label className="text-left label-p">
                    {translate("com.tempedge.msg.label.orgdepartment")}
                  </label>
                  <DropdownList
                    data={departments}
                    textField="name"
                    valueField="departmentId"
                    value={formik.values.department}
                    onChange={(department) => onChangeDepartmentDropdown(department, formik)}
                  />
                </div>
                <div className="form-group">
                  <label className="text-left label-p">
                    {translate("com.tempedge.msg.label.position")}
                  </label>
                  <DropdownList
                    data={positions}
                    textField="name"
                    valueField="positionId"
                    value={formik.values.position}
                    onChange={(position) => {
                      formik.setFieldValue("position", position);
                      setMessage(null);
                    }}
                  />
                </div>
                <div className="form-group">
                  <label className="text-left label-p">
                    {translate("com.tempedge.msg.label.shift")}
                  </label>
                  <DropdownList
                    data={[
                      {
                        shiftName: translate("com.tempedge.msg.label.1st"),
                        shiftId: 1,
                      },
                      {
                        shiftName: translate("com.tempedge.msg.label.2nd"),
                        shiftId: 2,
                      },
                      {
                        shiftName: translate("com.tempedge.msg.label.3rd"),
                        shiftId: 3,
                      },
                    ]}
                    textField="shiftName"
                    valueField="shiftId"
                    value={formik.values.shift}
                    onChange={(shift) => {
                      formik.setFieldValue("shift", shift);
                      setMessage(null);
                    }}
                  />
                </div>
                <div className="form-group">
                  <label className="text-left label-p">
                    {translate("com.tempedge.msg.label.reporttype")}
                  </label>
                  <DropdownList
                    data={reportTypes}
                    textField="title"
                    valueField="key"
                    value={{ key: formik.values.reportType }}
                    onChange={({ key }) => {
                      formik.setFieldValue("reportType", key);
                      setMessage(null);
                    }}
                  />
                </div>
                <div className="form-group">
                  <label className="text-left label-p">
                    {translate("com.tempedge.msg.label.selectweek")}
                  </label>
                  <DropdownList
                    data={weekdayOptions}
                    textField="label"
                    valueField="value"
                    value={{ value: formik.values.weekDay }}
                    onChange={({ value }) => {
                      formik.setFieldValue("weekDay", value);
                    }}
                  />
                </div>
                {formik.values.weekDay === "CUSTOM" && (
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <label className="text-left label-p">
                        {translate("com.tempedge.msg.label.from")}
                      </label>
                      <Datepicker
                        customClass="form-control tempEdge-input-box"
                        selectedValue={formik.values.startDate}
                        onChange={(date) => {
                          formik.setFieldValue(
                            "startDate",
                            moment(date,DATE_FORMAT_API)
                          );
                          setMessage(null);
                        }}
                      />
                    </div>
                    <div className="col-12 col-md-6">
                      <label className="text-left label-p">
                        {translate("com.tempedge.msg.label.to")}
                      </label>
                      <Datepicker
                        customClass="form-control tempEdge-input-box"
                        selectedValue={formik.values.endDate}
                        onChange={(date) => {
                          formik.setFieldValue(
                            "endDate",
                            moment(date,DATE_FORMAT_API)
                          );
                          setMessage(null);
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="col-12 col-md-6 review">
                <div className="green-text font-weight-bold text-uppercase mb-4">
                  {translate("com.tempedge.msg.label.preview")}
                </div>
                <Tabs
                  activeKey={formik.values.reportType}
                  onSelect={(key) => {
                    formik.setFieldValue("reportType", key);
                    setMessage(null);
                  }}
                  transition={false}
                  id="report-type-tab"
                >
                  {reportTypes.find((item) => item.key === "payroll") && (
                    <Tab
                      eventKey="payroll"
                      title={translate("com.tempedge.msg.label.payroll")}
                    >
                      <div className="p-4">
                        <img
                          className="w-100"
                          src={payroll}
                          alt={translate("com.tempedge.msg.label.payroll")}
                        />
                      </div>
                    </Tab>
                  )}
                  {reportTypes.find((item) => item.key === "attendance") && (
                    <Tab
                      eventKey="attendance"
                      title={translate("com.tempedge.msg.label.attendance")}
                      alt={"attendance"}
                    >
                      <div className="p-4">
                        <img
                          className="w-100"
                          src={attendance}
                          alt={translate("com.tempedge.msg.label.attendance")}
                        />
                      </div>
                    </Tab>
                  )}
                  {reportTypes.find((item) => item.key === "invoice") && (
                    <Tab
                      eventKey="invoice"
                      title={translate("com.tempedge.msg.label.invoice")}
                      alt={"invoice"}
                    >
                      <div className="p-4">
                        <img
                          className="w-100"
                          src={invoice}
                          alt={translate("com.tempedge.msg.label.invoice")}
                        />
                      </div>
                    </Tab>
                  )}
                </Tabs>
              </div>
              <div className="col-13 col-md-3 options">
                <div className="green-text font-weight-bold text-uppercase mb-4">
                  {translate("com.tempedge.msg.label.separatedby")}
                </div>
                <div className="form-group">
                  <Switch
                    name="separatedByDept"
                    size={"md"}
                    align={"between"}
                    checked={formik.values.filter.reportByDept}
                    onChange={(value) => {
                      formik.setFieldValue("filter", {
                        ...formik.values.filter,
                        reportByDept: value,
                        reportByPosition: false,
                      });
                      setMessage(null);
                    }}
                  >
                    {translate("com.tempedge.msg.label.orgdepartment")}
                  </Switch>
                </div>
                {(formik.values.reportType === "invoice" ||
                  formik.values.reportType === "payroll") &&
                  formik.values.filter.reportByDept && (
                    <div className="form-group">
                      <Switch
                        name="separatedByPosition"
                        size={"md"}
                        align={"between"}
                        checked={formik.values.filter.reportByPosition}
                        onChange={(value) => {
                          formik.setFieldValue("filter", {
                            ...formik.values.filter,
                            reportByPosition: value,
                          });
                          setMessage(null);
                        }}
                      >
                        {translate("com.tempedge.msg.label.position")}
                      </Switch>
                    </div>
                  )}
                {formik.values.reportType === "attendance" && (
                  <>
                    <div className="green-text font-weight-bold text-uppercase mb-4">
                      {translate("com.tempedge.msg.label.options")}
                    </div>
                    <div className="form-group">
                      <label className="text-left label-p">
                        {translate("com.tempedge.msg.label.filterby")} -{" "}
                        {translate("com.tempedge.msg.label.office")}
                      </label>
                      <DropdownList
                        data={offices}
                        textField="name"
                        valueField="officeId"
                        value={formik.values.office}
                        onChange={(office) => {
                          formik.setFieldValue("office", office);
                          setMessage(null);
                        }}
                      />
                    </div>
                  </>
                )}
                {formik.values.reportType !== "attendance" && (
                  <div className="form-group">
                    <Switch
                      name="separatedByDay"
                      size={"md"}
                      align={"between"}
                      checked={formik.values.filter.separatedByDay}
                      onChange={(value) => {
                        formik.setFieldValue("filter", {
                          ...formik.values.filter,
                          separatedByDay: value,
                        });
                        setMessage(null);
                      }}
                    >
                      {translate("com.tempedge.msg.label.day")}
                    </Switch>
                  </div>
                )}
                {formik.values.reportType === "invoice" && (
                  <div>
                    <div className="green-text font-weight-bold text-uppercase mb-4">
                      {translate("com.tempedge.msg.label.options")}
                    </div>
                    <div className="form-group">
                      <Switch
                        name="showMarkup"
                        size={"md"}
                        align={"between"}
                        checked={formik.values.filter.showMarkup}
                        onChange={(value) => {
                          formik.setFieldValue("filter", {
                            ...formik.values.filter,
                            showMarkup: value,
                          });
                          setMessage(null);
                        }}
                      >
                        {translate("com.tempedge.msg.label.showmarkup")}
                      </Switch>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </Formik>
        <Modal
          open={showModal}
          content={<DepartmentTables clientSelected={clientsList[0]} />}
          onClose={() => setShowModal((showModal) => !showModal)}
          modalSize={"modal-sm"}
        />
      </ContainerBlue>
    </React.Fragment>
  );
}

export default withLocalize(Report);
