import { normalizeDateSendReports } from '../../utils/ReGexs';

const cleanValue = (value) => {
   return value != null && value !== '' ? value : null;
};

export const organizeData = (dataList, weekActive) => {
   return dataList
      .filter((item) => {
         const fieldsToCheck = ['hoursPay', 'amountPay', 'hoursBill', 'amountBill'];

         // Verifico si todos los campos están nulos o vacíos
         const allFieldsNull = fieldsToCheck.every((field) => {
            const value = cleanValue(item[field]);
            return value === null;
         });

         // También verificar que personId no sea nulo
         const personId = cleanValue(item.personId) ?? cleanValue(item.person?.personId);

         // Excluir elementos donde todos los campos están nulos o personId es nulo
         return !allFieldsNull && personId != null;
      })
      .map((item) => {
         const organizedItem = {
            billRate: cleanValue(item.billRate),
            payRate: cleanValue(item.payRate),
            otBillRate: cleanValue(item.otBillRate),
            dateList: normalizeDateSendReports(weekActive?.end),
            person: {
               personId: cleanValue(item.personId) ?? cleanValue(item.person?.personId),
            },
            department:
               cleanValue(item?.department?.departmentId) != null ? { departmentId: cleanValue(item.department.departmentId) } : null,
            position: cleanValue(item?.position?.positionId) != null ? { positionId: cleanValue(item.position.positionId) } : null,
            hoursPay: cleanValue(item.hoursPay),
            amountPay: cleanValue(item.amountPay),
            hoursBill: cleanValue(item.hoursBill),
            amountBill: cleanValue(item.amountBill),
            comments: cleanValue(item.comments),
         };

         if (cleanValue(item.customPayrollId) != null) {
            organizedItem.customPayrollId = cleanValue(item.customPayrollId);
         }

         if (
            cleanValue(item.payCode?.customPayrollTypeId) != null &&
            (cleanValue(item.hoursPay) != null || cleanValue(item.amountPay) != null)
         ) {
            organizedItem.payCode = {
               customPayrollTypeId: cleanValue(item.payCode.customPayrollTypeId),
            };
         }

         if (
            cleanValue(item.billingCode?.customPayrollTypeId) != null &&
            (cleanValue(item.hoursBill) != null || cleanValue(item.amountBill) != null)
         ) {
            organizedItem.billingCode = {
               customPayrollTypeId: cleanValue(item.billingCode.customPayrollTypeId),
            };
         }

         return organizedItem;
      });
};
