import moment from "moment";
import { withLocalize, Translate } from 'react-localize-redux';

const SetTableHeadingResult = ({ findDate, translate }) => {
  const generateHeaders = () => {
    return findDate?.map((date, index) => {
      const day = moment().day(date.day).format("dddd").toLowerCase();
      const translatedDay = translate(`com.tempedge.msg.label.${day}`); // Usar translate como función aquí

      return (
        <th
          scope="col"
          key={index}
          className="text-center day-header table-header-mid-first"
          data-initial={translatedDay.charAt(0).toUpperCase()} // Obtener la inicial correctamente
          onClick={(e) => e.currentTarget.classList.toggle("show-full")}
        >
          <span>{translatedDay}</span>
          <br />
          <span>{date.date}</span>
        </th>
      );
    });
  };

  return (
    <tr className="responsive-table">
      <th scope="col" className="text-center table-header-left-first">
        <Translate id="com.tempedge.msg.label.name" />
      </th>
      <th scope="col" className="text-center table-header-mid-first">
        <Translate id="com.tempedge.msg.label.deptpos" />
      </th>
      {generateHeaders()}
      <th scope="col" className="text-center table-header-mid-first">
        <Translate id="com.tempedge.msg.label.reghours" />
      </th>
      <th scope="col" className="text-center table-header-mid-first">
        <Translate id="com.tempedge.msg.label.othours" />
      </th>
      <th scope="col" className="text-center table-header-mid-first">
        <Translate id="com.tempedge.msg.label.attendance" />
      </th>
    </tr>
  );
};

export default withLocalize(SetTableHeadingResult);