import 'babel-polyfill';
import { BrowserRouter as Router } from 'react-router-dom';
import en from './translations/en.tempedge.json';
import es from './translations/es.tempedge.json';
import './assets/styles/Vars.css';
import TempEdge from './TempEdge';
import TranslatorProvider from './components/common/LocalizationContext/TranslatorProvider';

const translations = { en, es };

const App = () => (
  <TranslatorProvider translations={translations}>
    <Router>
      <TempEdge />
    </Router>
  </TranslatorProvider>
);

export default App;
