import moment from 'moment';
import { useEffect, useState } from 'react';
import { Translate } from 'react-localize-redux';
import { useDispatch } from 'react-redux';
import { moveEmployeeToAbsentWeekTable, totalEmployeeWeekTableExcel } from '../../../Redux/actions/timeEntryActions';
import {
   TotalHourByWeekDay,
   TotalOtHourWeek,
   TotalRegHourWeek,
   roundToTwoDecimals,
   totalHoursByConfig,
} from '../../../utils/helpers/TotalHoursFormat';
import { ExportExcel } from '../PayrollToExcel';
import SetTableHeading from './SetTableHeading';
import { formatUsNumber } from '../../../utils/ReGexs';
import useHeaderDate from './useHeaderDate';
import { Col, Row } from 'react-bootstrap';

const PayrollWeekResult = ({ employeesTimeEntry, dateStart, translate, clientReportConf }) => {
   const dispatch = useDispatch();
   const [findDate, setFindDate] = useState([]);

   const findDateArray = useHeaderDate(dateStart) ?? [];

   useEffect(() => {
      setFindDate(findDateArray);
   }, [findDateArray]);

   useEffect(() => {
      const { data, totals } = ExportExcel(findDate, employeesTimeEntry, translate);
      // aquí le paso la data de payroll  a un arreglo en el store para consumirla solo en el excel de semana del timeentry falta cambiar nombre porque no corresponde y validar que no existan valores en 0 se envia null o vacio
      dispatch(moveEmployeeToAbsentWeekTable(data));
      dispatch(totalEmployeeWeekTableExcel(totals));
   }, [translate, employeesTimeEntry, dispatch]);

   // utilizo object.values para mirar la data con horas totales mayor a 0 para iterar un nuevo arreglo con empleados que trabajaron
   const weekResults = Object.values(employeesTimeEntry).filter(
      (value) => value != null && (value?.totalRegHours !== 0 || value?.totalOTHours !== 0),
   );

   return (
      weekResults.length > 0 && (
         <>
            <div className="form-group col-12 col-lg-3">
               <p>
                  <Translate id={'com.tempedge.msg.label.listsize'} />
                  <span className="badge">{weekResults.length}</span>
               </p>
            </div>
            <Row>
               <Col sm={12} md={12} className="form-check ">
                  <table className="responsive-table">
                     <thead>
                        <SetTableHeading findDate={findDate} />
                     </thead>
                     <tbody>
                        <>
                           {weekResults.map((e, index) => {
                              return (
                                 <tr key={index + 1}>
                                    <th scope="row">{`${e?.person?.fullName}`}</th>
                                    <td data-title={`Deparment-Position`}>
                                       {e.department !== null ? `${e.department?.name}-${e.position?.name}` : e.position?.name}
                                    </td>
                                    {findDate.map((currentDay, index) => {
                                       const payrollDay = e[currentDay.day];
                                       if (
                                          payrollDay &&
                                          payrollDay.dateList &&
                                          moment(payrollDay.dateList).format('MM/DD/YYYY') === currentDay.date &&
                                          (payrollDay.totalRegHour !== null || payrollDay.totalOtHour !== null)
                                       ) {
                                          return (
                                             <td key={index + 1} data-title={currentDay.day}
                                             data-type="currency"
                                             className="day-column"
                                             data-initial={currentDay.day.charAt(0).toUpperCase()}>
                                                {payrollDay.totalRegHour !== null || payrollDay.totalOtHour !== null
                                                   ? `RG: ${roundToTwoDecimals(payrollDay?.totalRegHour) ?? '0.00'} - ${roundToTwoDecimals(payrollDay?.totalOtHour) ?? '0.00'
                                                   } : OT`
                                                   : null}
                                             </td>
                                          );
                                       } else {
                                          return   <td key={index + 1} data-title={currentDay.day}
                                          data-type="currency"
                                          className="day-column"
                                          data-initial={currentDay.day.charAt(0).toUpperCase()}></td>;
                                       }
                                    })}
                                    <td data-title={`Reg Hours`}>
                                       {e.totalRegHours ? totalHoursByConfig(e.totalRegHours, clientReportConf) : '0.00'}
                                    </td>
                                    <td data-title={`Ot Hours`}>{e.totalOTHours ? formatUsNumber(e.totalOTHours) : '0.00'}</td>
                                 </tr>
                              );
                           })}
                           <tr className="time-sheet-row-responsive">
                              <th scope="row" colSpan={2} className=" text-uppercase text-right  custom-totals">
                                 <Translate id={'com.tempedge.msg.label.total'} />
                              </th>
                              {findDate.map((currentDay, index) => {
                                 return (
                                    <td key={index + 1} className="">
                                       {TotalHourByWeekDay(employeesTimeEntry, currentDay.day)}
                                    </td>
                                 );
                              })}
                              <td>{TotalRegHourWeek(employeesTimeEntry)}</td>
                              <td>{TotalOtHourWeek(employeesTimeEntry)}</td>
                              
                           </tr>
                        </>
                     </tbody>
                  </table>
               </Col>
            </Row>
         </>
      )
   );
};

export default PayrollWeekResult;
