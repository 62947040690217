import { Translate, withLocalize } from 'react-localize-redux';

const GreenCommonBtn = (props) => {
  return (
    <div>
      <button
        type={props.type || "button"}
        className={`btn btn-green btn-block-green ${props.class}`}
        disabled={props.disabled}
        onClick={props.onClick}
      >
        <Translate id={props.tag} />
      </button>
    </div>
  );
};

export default withLocalize(GreenCommonBtn);
