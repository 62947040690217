import { cloneDeep } from "lodash";
import moment from "moment";
import { Translate } from "react-localize-redux";
import { useDispatch } from "react-redux";
import { cancelPayRateValidation } from "../../../Redux/actions/payrollValidationActions";
import BlueCommonBtn from "../../../components/common/Buttons/BlueCommonBtn";
import GrayCommonBtn from "../../../components/common/Buttons/GrayCommonBtn";
import Container from "../../../components/common/Container/Container";
import useHeaderDate from "../PayrollByWeek/useHeaderDate";
import EditableCell from "./EditableCell";
import SetTableHeadingPayrate from "./SetTableHeadingPayrate";

const PayrollEditPayRate = ({
  payrollWeekValidation: originalPayrollWeekValidation,
  dateStart,
  id,
  setModal,
  editPayrate
}) => {
  const findDate  = useHeaderDate(dateStart);
  const dispatch = useDispatch(); 
  const deepCopy=cloneDeep(originalPayrollWeekValidation);

  const weekResults = Object.values(originalPayrollWeekValidation).filter(
    (value) =>
      value != null &&
      (value?.totalRegHours !== 0 || value?.totalOTHours !== 0) &&
      value.id === id
  );
    
  const cancelBtnClickHandler = () => {
    dispatch(cancelPayRateValidation(deepCopy));
    setModal({ show: false });
  };

  const btn = (
    <div>
      <div className="d-flex justify-content-center align-items-center">
        <div className="col-md-4">
          <GrayCommonBtn
            tag="com.tempedge.msg.label.cancel"
            onClick={() => {
              cancelBtnClickHandler();
            }}
          />
        </div>
        <div className="col-md-4">
          <BlueCommonBtn
            tag="com.tempedge.msg.label.save"
            onClick={() => {
              setModal({ show: false });
            }}
          />
        </div>
      </div>
    </div>
  );

  return (
    <Container
    label={weekResults[0] ? `${weekResults[0].person.firstName} ${weekResults[0].person.lastName}  EE# ${weekResults[0].person.employeeId}` : ''}
      btns={btn}
    >
      <div className="row ">
        <div className="mr-auto px-4 green-text">
          <p className="font-weight-bold text-uppercase mt-2">
            {<Translate id="com.tempedge.msg.label.payRate" />}
            {` $`}
          </p>
        </div>
      </div>
      <table className="table table-striped table-input-group mt-2 mb-5">
        <thead><SetTableHeadingPayrate findDate={findDate} /></thead>
        <tbody>
          <>
            {weekResults?.map((payroll, index) => {
              return (
                <tr key={index + 1}>
                  {findDate.map(({day,date}, index) => {
                    const payrollDate =
                      payroll[day]?.dateList;
                    return moment(payrollDate).format("MM/DD/YYYY") ===
                      date ? (
                      <EditableCell
                        key={index + 1}
                        payroll={payroll}
                        day={day}
                        editPayrate={editPayrate}
                      />
                    ) : (
                      <td key={index + 1}></td>
                    );
                  })}
                </tr>
              );
            })}
          </>
        </tbody>
      </table>
    </Container>
  );
};

export default PayrollEditPayRate;
