import { useState } from 'react';
import TempEdgeApi from '../services/TempEdgeApi';
import {
   REACT_APP_URL_TAXES_EMPLOYEE_FIND_BY_REGION,
   REACT_APP_URL_TAXES_EMPLOYEE_FIND_BY_PERSON_ID,
} from '../components/common/Routes/URLs';

export const useStateTaxes = ({ region, country, personId, isEdit, agency, translate }) => {
   const [stateTaxes, setStateTaxes] = useState([]);
   const [taxValuesLocal, setTaxValuesLocal] = useState({});
   const [apiDropdownOptions, setApiDropdownOptions] = useState({});

   const fetchRegionTaxes = async () => {
      if (!region || !country) {
         setStateTaxes([]);
         setTaxValuesLocal({});
         return null;
      }

      try {
         const { data: regionsTaxes } = await TempEdgeApi.post({
            url: REACT_APP_URL_TAXES_EMPLOYEE_FIND_BY_REGION,
            payload: {
               orgId: agency.organizationEntity.orgId,
               regionId: region,
               countryId: country,
            },
         });

         if (regionsTaxes.status === 200) {
            const sortedTaxes = [...regionsTaxes.result].sort((a, b) => a.taxParamsId - b.taxParamsId);
            setStateTaxes(sortedTaxes);
            return sortedTaxes;
         } else if (regionsTaxes.status !== 200) {
            setStateTaxes([]);
            return null;
         }
         return null;
      } catch (error) {
         setStateTaxes([]);
         return null;
      }
   };

   const fetchPersonTaxes = async () => {
      try {
         const { data: personTaxes } = await TempEdgeApi.post({
            url: REACT_APP_URL_TAXES_EMPLOYEE_FIND_BY_PERSON_ID,
            payload: {
               orgId: agency.organizationEntity.orgId,
               id: personId,
            },
         });

         if (personTaxes.status === 200 && personTaxes.result?.taxParams) {
            const validTaxParams = personTaxes.result.taxParams.filter((tax) =>
               stateTaxes.some((stateTax) => stateTax.taxParamsId === tax.taxParamsId),
            );

            const taxValuesMap = validTaxParams.reduce((acc, tax) => {
               acc[tax.taxParamsId] = tax.value;
               return acc;
            }, {});

            return taxValuesMap;
         }
         return null;
      } catch (error) {
         return null;
      }
   };

   const fetchApiOptions = async (tax) => {
      try {
         const apiConfig = JSON.parse(tax.rules);
         const { api: endpoint, body: bodyStr, value: valueField = 'description', key: keyField = 'id' } = apiConfig;

         if (!endpoint || !bodyStr) return;

         const bodyParams = bodyStr
            .replace(/[{}]/g, '')
            .split(',')
            .map((param) => param.trim());

         const payload = bodyParams.reduce((acc, param) => {
            if (param === 'regionId') acc[param] = region;
            if (param === 'countryId') acc[param] = country;
            return acc;
         }, {});

         const { data: response } = await TempEdgeApi.post({
            url: endpoint,
            payload,
         });

         if (response.status === 200) {
            const formattedOptions = response.result.map((item) => ({
               value: item[keyField],
               label: translate(item[valueField]),
            }));

            setApiDropdownOptions((prev) => ({
               ...prev,
               [tax.taxParamsId]: formattedOptions,
            }));
         }
      } catch (error) {}
   };

   return {
      stateTaxes,
      taxValuesLocal,
      apiDropdownOptions,
      setTaxValuesLocal,
      fetchRegionTaxes,
      fetchPersonTaxes,
      fetchApiOptions,
   };
};
