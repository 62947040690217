import { Component } from 'react';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import VideosSection from './sections/VideosSection';
import ActiveLanguageAddTranslation from '../../components/common/ActiveLanguageAddTranslation/ActiveLanguageAddTranslation';

class Tutorial extends Component {
  
  constructor(props) {
    super(props);
    this.addTranslationsForActiveLanguage();
  }

  addTranslationsForActiveLanguage = async () => {
    await ActiveLanguageAddTranslation(this.props.activeLanguage, this.props.addTranslationForLanguage);
  };

  async componentDidUpdate(prevProps) {
    const hasActiveLanguageChanged = prevProps.activeLanguage !== this.props.activeLanguage;
    
    if (hasActiveLanguageChanged) {
      await this.props.push(`/tutorials/${this.props.activeLanguage.code}`);
      this.addTranslationsForActiveLanguage();
    }
  }

  render() {
    return <VideosSection />;
  }
}

export default withLocalize(connect(null, { push })(Tutorial));
