import { BigNumber } from 'bignumber.js';
import { Translate } from 'react-localize-redux';
import { REACT_APP_URL_PAYROLL_FIND_WEEK_ACTIVE } from '../components/common/Routes/URLs';
import TempEdgeApi from '../services/TempEdgeApi';
import { store } from '../store/store.js';
import moment from 'moment';

export const formatToDollars = (amount) => {
  if (isNaN(amount)) return '';
  return '$' + parseFloat(amount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
};

export const formatUsNumber = (amount) => {
  if (isNaN(amount)) return '';
  if (parseFloat(amount) === 0) return '0.00';
  return parseFloat(amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};

export const onlyLetters = (string) => {
  if(!string) return '';
  return string.replace(/[^a-zA-ZÀ-ú ]+/g,'');
}

export const onlyAlphanumeric = (string) => {
  if (!string) return '';
  return string.replace(/[^a-zA-ZÀ-ú0-9 ]+/g, '');
};

export const onlyDigitsAndDot = (string) => {
  if (!string) return '';
  return string.replace(/[^\d .]+/g, '');
};

export const onlyNumbers = (string) => {
   if(!string) return string;
   return string.replace(/[^\d]/g, '');
}

export const GenericSSNFormat = (string) => {
  if (!string) return string;
  return string.replace(/[^\d.,-]/g, '');
}

// Helper function to check if a value is a valid number

export const isValidNumberWithMax = (value, maxDigits = 3) => {
  if (value === null || value === undefined) {
    return false;
  }
  const numRegex = new RegExp(`^-?\\d{1,${maxDigits}}(\\.\\d{1,2})?$`);
  return numRegex.test(value.toString());
};

export const normalizeSSN = (input, pattern) => {

  if (!pattern) {
    return input.replace(/[^\d-.,]/g, '');
  }


  let formattedValue = input.replace(/\D/g, '');
  const match = pattern.match(/\d+/g);

  if (match) {
    let index = 0;
    formattedValue = match.reduce((result, length) => {
      const partLength = parseInt(length, 10);
      const part = formattedValue.slice(index, index + partLength);
      if (part) {
        result.push(part);
        index += partLength;
      }
      return result;
    }, []).join('-');
  }

  return formattedValue;
};

export const normalizePhone = (string) => {
   if(!string) return string;
   const onlyNums = string.replace(/[^\d]/g, '');
   if(onlyNums.length <= 3)
      return  onlyNums;
   if (onlyNums.length <= 6)
      return "(" + onlyNums.slice(0, 3) + ') ' + onlyNums.slice(3);
   return "(" + onlyNums.slice(0, 3) + ') ' + onlyNums.slice(3, 6) + '-' + onlyNums.slice(6, 10);
}

export const normalizeClientCode = (string) => {
   if(string.length > 30)
      string = string.substr(0,30);

   string = string.replace(/\s/g, '');
   string = string.replace(/[^a-zA-Z0-9]/g, "");

   return string;
}

export const normalizeZipCode = (string) => {
  if (!string) return string;

  // Use regex to filter out non-numeric characters
  const numericValue = string.replace(/\D/g, '');

  if (numericValue.length > 5) {
    // Insert a hyphen after the first 5 characters
    return `${numericValue.slice(0, 5)}-${numericValue.slice(5, 9)}`;
  }

  return numericValue;
};

export const normalizeAddress = (string) => {
  if (!string) return string;
  return string.replace(/[^#.a-zA-ZÀ-ú,\d /-]+/g, '');
}

export const limitString = ({value, limit, replace}) => {
   if(value.length > limit)
      return value.substr(0, limit) + replace;
   return value;
}

// constructor fullName para create user se puede eliminar despues
export const fullName = (firstName, middleName, lastName) => {
   let fullName='';
   const name = '';
 
   if (middleName === null || middleName === '') {
     fullName = name.concat(firstName, ' ', lastName);
   } else {
     fullName = name.concat(firstName, ' ', middleName, ' ', lastName);
   }
   return fullName;
 }
 
export const normalizeExtention = (value, previousValue) => {
   if(!value)
       return value;
   const onlyNums = value.replace(/[^\d]/g, '');
   
   return onlyNums.slice(0,4);
}

export const longCurrencyFormat = (value) => {
   if (!value) {
     return value;
   }
 
   const onlyNums = value.replace(/[^\d]/g, '');
   if (onlyNums.length <= 3) {
     return onlyNums;
   }
 
   return `${onlyNums.slice(0, 3)}.${onlyNums.slice(3, 5)}`;
 };
 
 export const shortCurrencyFormat = (value) => {
   if (!value) {
     return value;
   }
 
   const onlyNums = value.replace(/[^\d]/g, '');
   if (onlyNums.length <= 2) {
     return onlyNums;
   }
 
   return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 4)}`;
 };

 export const normalizeNumber = (value, previousValue) => {
   if(!value)
       return value;

   return value.replace(/[^\d]/g, '');
}

export const normalizeWord = (value, previousValue) => {
   if(!value) return '';
   return value.replace(/[^a-zA-ZÀ-ú]+/g,'');
}



// This is the config for bignumber.js library
BigNumber.config({
  ROUNDING_MODE: BigNumber.ROUND_HALF_EVEN
});


/**
 * Format long currency to this format xxx.xx
 * @param {String|Number} longCurrency long currency
 */
export const setLongCurrencyFormat = (longCurrency) => {
  const longCurrencyRegExp = /^(?!0\.000)-{0,1}\d{1,3}(\.\d{1,2})?$/

  if (typeof longCurrency === 'string' || typeof longCurrency === 'number') {
    const longCurrencyFixed = BigNumber(longCurrency).toFixed(2);

    if (longCurrencyRegExp.test(longCurrencyFixed)) {
      return longCurrencyFixed;
    }
  }
  return null;
};

export function convertToMarkup(number) {
  return 1 + number / 100;
}

export const GetObjectUtil = (obj, data) => {

	// Validate if obj and data exist, and data must be a string
	if (obj && typeof data === 'string') {

		// Expressions using  RegExp
		data = data.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
		data = data.replace(/^\./, '');           // strip a leading dot
		const a = data.split('.');                  // Separates each string
		a.forEach((el, i) => {
			const k = a[i];
			if (k in obj) {
				obj = obj[k];
			} else {
				return null;
			}
		});
		return obj;
	}
}

export const MapWeekDays = (number) => {
   let day;
   switch (number) {
     case 1:
       day = <Translate id="com.tempedge.msg.label.monday" />;
       break;
     case 2:
       day = <Translate id="com.tempedge.msg.label.tuesday" />;
       break;
     case 3:
       day = <Translate id="com.tempedge.msg.label.wednesday" />;
       break;
     case 4:
       day = <Translate id="com.tempedge.msg.label.thursday" />;
       break;
     case 5:
       day = <Translate id="com.tempedge.msg.label.friday" />;
       break;
     case 6:
       day = <Translate id="com.tempedge.msg.label.saturday" />;
       break;
     case 7:
       day = <Translate id="com.tempedge.msg.label.sunday" />;
       break;
     default:
       break;
   }
 
   return day;
 }

 /**
 * Change index of every array element by the key specified
 * @param {Array.Object} array list of objects
 * @param {String} key the key to be indexed
 */
export const keyBy = (array, key) => array.reduce((accumulator, element) => {
   accumulator[element[key]] = element;
   return accumulator;
 });
 
 export const mapList = (list, returnField, findField, value) => {
 
   if (list === null || list === undefined) 
     return [];
 
   for (let index = 0; index < list.length; index++) {
     if (list[index][findField] === value) {
       return list[index][returnField];
     }
   }
 
   return [];
 
 };
 
export const inputTypeHours=(value)=>{
  const numberList=(/^([-?0-999]{1,3}(\.[0-9]{1,2})?)$/.test(value))
  return numberList
}

export const normalizeValuesNumbers = (string) => {
  if(!string) return string;
  const onlyNums = string.replace(/[^\d]/g, '');
  if (onlyNums.length <= 3) {
     return onlyNums;
  }
  if (onlyNums.length <= 4) {
     return onlyNums.slice(0, 3) + '.' + onlyNums.slice(3)
  }
  return onlyNums.slice(0, 3) + '.' + onlyNums.slice(3, 5)
}
 

export const normalizeDate = (value) => {
  const date = new Date(value);
  date.setUTCHours(0, 0, 0, 0); 
  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
  const day = String(date.getUTCDate()).padStart(2, '0');
  const year = date.getUTCFullYear();
  return `${month}-${day}-${year}`;
};


// aqui se cambia la data que enviara al backend
export const normalizeDateSend = (date) => {
  const dateObj = new Date(date);
  dateObj.setUTCHours(0, 0, 0, 0); 
  const year = dateObj.getUTCFullYear();
  const month = String(dateObj.getUTCMonth() + 1).padStart(2, '0');
  const day = String(dateObj.getUTCDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};
export const normalizeDateSendReports = (date) => {
  const dateObj = new Date(date);
  dateObj.setUTCHours(0, 0, 0, 0); 
  const year = dateObj.getUTCFullYear();
  const month = String(dateObj.getUTCMonth() + 1).padStart(2, '0');
  const day = String(dateObj.getUTCDate()).padStart(2, '0');
  return `${month}/${day}/${year}`;
};

export   const downloadFile = (base64, name) => {
  if (typeof base64 === "string") {
    const base64URL = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${base64}`;
    const link = document.createElement("a");
    link.setAttribute("href", base64URL);
    link.setAttribute(
      "download",
      `${name}_${moment().format("YYYY-MM-DD-HH-mm")}.xlsx`
    );
    link.click();
  }
};

export async function getWeekActiveDates(client, date) {
  const orgId = JSON.parse(localStorage.getItem("agency"))?.organizationEntity?.orgId;
  const payload = {
    orgId,
    clientId: client,
    dateList: date,
  };

  try {
    const { data: weekActive } = await TempEdgeApi.post({
      url: REACT_APP_URL_PAYROLL_FIND_WEEK_ACTIVE,
      payload,
    });

    const { status = "", result: { startDate = "", endDate = "" } } = weekActive;

    if (status === 200) {
      return { startDate, endDate };
    } else {
      throw new Error("Error in weekActive status");
    }
  } catch (error) {
    throw new Error("Error in getWeekActiveDates");
  }
}

export  const statusUser=(data) =>{
  switch (data) {
    case "A":
      return <Translate id="com.tempedge.msg.label.active" />;
    case "P":
      return <Translate id="com.tempedge.msg.label.pending" />;
    case "D":
      return <Translate id="com.tempedge.msg.label.deleted" />;
    case "R":
      return <Translate id="com.tempedge.msg.label.rejected" />;
    default:
      return "N/A";
  }
}

export const validateTaxAllowance = (field, type) => {
  if (field === null) {
    return true;
  }
  field = field === undefined ? 0 : field;

  const login = store.getState();
  let data = 0;

  if (type === 'juniorAllowances') {
    data = login?.tempEdge?.login?.parameterEntityList?.taxAllowanceJunior;
  }

  if (type === 'seniorAllowances') {
    data = login?.tempEdge?.login?.parameterEntityList?.taxAllowanceSenior;
  }

  return (field / data) % 1 === 0;
};

export const validateEmail = (email) => {
  if (!email) return false;
  
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i;

  return emailRegex.test(email);
};

export const  handleMultipleEmailValidation = (emails) => {
  const emailList = emails.split(',').map(email => email.trim());

  for (const email of emailList) {
    if (!validateEmail(email)) {
      return false;
    }
  }
  return true;
};

export const getAlphabeticSequence = (num) => {
  const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let result = '';
  while (num >= 0) {
    result = alphabet[num % 26] + result;
    num = Math.floor(num / 26) - 1;
  }
  return result;
}
export const onlyNumbersandDecimals=/[^-?\d.]+/g
export const allowedCharacters =/[^a-zA-ZÀ-ú 0-9 ._,-/_$!@#]+/g

export const onlySingleNumbers=/^-?\d*\.?\d*$/