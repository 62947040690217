import { countryStateTypes } from "../types/countryStateTypes";

const initialState = {
  selectedCountryId: null, // ID del país seleccionado
};

export const countryStateReducer = (state = initialState, action) => {
  switch (action.type) {
    case countryStateTypes.CHANGE_SELECTED_COUNTRY:
      return {
        ...state,
        selectedCountryId: action.payload,
      };
    default:
      return state;
  }
};
