import '../../../assets/styles/components/tablepayroll.css';
import '../../../assets/styles/components/table-inputs.css';
import { Suspense, useState } from 'react';
import EditableCell from './EditableCell';
import { Translate } from 'react-localize-redux';
import { TotalOtHourWeek, TotalRegHourWeek, TotalHourByWeekDay, totalHoursByConfig } from '../../../utils/helpers/TotalHoursFormat';
import SetTableHeading from './SetTableHeading';
import useHeaderDate from './useHeaderDate';
import commentIcon from '../../../assets/static/actionIcons/comment.png';
import CommentModal from '../../../components/common/CommentModal/CommentModal';
import Modal from 'react-bootstrap/Modal';
import { formatUsNumber } from '../../../utils/ReGexs';
import { Row, Col } from 'react-bootstrap';

const PayrollByWeek = ({ employeesTimeEntry, dateStart, clientReportConf }) => {
   const [componentToRender, SetComponentToRender] = useState(null);
   const [showModal, setShowModal] = useState(false);
   const [selectedEmployeeIndex, setSelectedEmployeeIndex] = useState(null);

   const findDate = useHeaderDate(dateStart);
   const commetModal = true;

   const modalComment = (payroll) => {
      import('../../../components/common/Comments/Comments').then(({ default: Comments }) => {
         SetComponentToRender(<CommentModal payroll={payroll} setShowModal={setShowModal} />);
         setShowModal(true);
      });
   };

   const handleCloseModal = () => {
      setShowModal(false);
   };

   const totalHoursTimeEntry = parseFloat(TotalRegHourWeek(employeesTimeEntry)) + parseFloat(TotalOtHourWeek(employeesTimeEntry));

   return (
      <>
         <Row>
            {/* Encabezados y sumarios de horas */}
            <Col sm={12} className="">
               <Row>
                  <Col sm={6}>
                     <div>
                        <Translate id="com.tempedge.msg.label.listsize" />
                        <span className="badge">{employeesTimeEntry.length}</span>
                     </div>
                  </Col>

                  {/* Resumen de horas */}
                  <Col sm={6} className="ml-auto">
                     <Row>
                        {['reghours', 'othours', 'total'].map((label, idx) => (
                           <Col sm={4} key={idx} className="text-right">
                              <div>
                                 <Translate id={`com.tempedge.msg.label.${label}`} />:
                              </div>
                              <div>
                                 {formatUsNumber(
                                    label === 'reghours'
                                       ? TotalRegHourWeek(employeesTimeEntry)
                                       : label === 'othours'
                                         ? TotalOtHourWeek(employeesTimeEntry)
                                         : totalHoursTimeEntry,
                                 )}
                              </div>
                           </Col>
                        ))}
                     </Row>
                     <Row>
                        <Col className="offset-sm-8">
                           <hr className="time-entry__body w-100" />
                        </Col>
                     </Row>
                  </Col>
               </Row>
            </Col>

            {/* Tabla de empleados */}
            <Col sm={12} md={12} className="form-check">
               <table className="responsive-table">
                  <thead>
                     <SetTableHeading findDate={findDate} commetModal={commetModal} />
                  </thead>
                  <tbody>
                     {employeesTimeEntry.map((payroll, index) => {
                        const rowId = `${payroll.person.personId}-${index}`;
                        return (
                           <tr
                              key={rowId}
                              className={selectedEmployeeIndex === rowId ? 'time-sheet-row--selected' : 'time-sheet-row'}
                              onClick={() => setSelectedEmployeeIndex(rowId)}
                           >
                              <th scope="row">{payroll?.person?.fullName}</th>
                              <td data-title={`Deparment-Position`}>
                                 <p className="subtitle-payroll">
                                    {payroll.department ? `${payroll.department.name} - ${payroll.position.name}` : payroll.position?.name}
                                 </p>
                              </td>
                              {findDate.map(({ day, date }, idx) => (
                                 <EditableCell key={idx} payroll={payroll} day={day} date={date} />
                              ))}
                              <td data-title={`Reg Hours`}>
                                 <p className="subtitle-payroll">{totalHoursByConfig(payroll.totalRegHours, clientReportConf) || '0.00'}</p>
                              </td>
                              <td data-title={`Ot Hours`}>
                                 <p className="subtitle-payroll">{formatUsNumber(payroll.totalOTHours) || '0.00'}</p>
                              </td>
                              <td data-title={`Options`} >
                                 <img
                                    className="actions-cell__icon"
                                    onClick={() => modalComment(payroll)}
                                    src={commentIcon}
                                    alt="Comments icon"
                                 />
                              </td>
                              <div  className='day-column'></div>
                           </tr>
                        );
                     })}
                     <tr className="time-sheet-row-responsive">
                        <th scope="row" colSpan={2} className="text-uppercase text-right  custom-totals ">
                           <Translate id="com.tempedge.msg.label.total" />
                        </th>
                        {findDate.map(({ day }, idx) => (
                           <td key={idx}>{TotalHourByWeekDay(employeesTimeEntry, day)}</td>
                        ))}
                        <td>{TotalRegHourWeek(employeesTimeEntry)}</td>
                        <td>{TotalOtHourWeek(employeesTimeEntry)}</td>
                        <td ></td>
                     </tr>
                  </tbody>
               </table>
            </Col>
         </Row>

         <Modal show={showModal} onHide={handleCloseModal}>
            <Modal.Header closeButton />
            <Suspense fallback={<div>Loading...</div>}>{componentToRender}</Suspense>
         </Modal>
      </>
   );
};

export default PayrollByWeek;
