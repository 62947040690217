import editIcon from '../../../assets/static/actionIcons/edit.png';
import deleteIcon from '../../../assets/icons/delete.png';
import addIcon from '../../../assets/icons/plus.png';

const RowActions = ({ onEdit, onDelete, onAdd }) => {
   return (
      <div className=" mx-auto text-center ">
         <button className="btn text-center mx-auto" type="button" onClick={onEdit}>
            <img className="actions-cell__icon" src={editIcon} alt="Edit icon" />
         </button>
         <button type="button" onClick={onDelete} className="btn text-center mx-auto">
            <img className="icon-width" src={deleteIcon} alt="Delete icon" />
         </button>
         <button type="button" onClick={onAdd} className="btn text-center mx-auto">
            <img style={{ width: '28px' }} src={addIcon} alt="Add icon" />
         </button>
      </div>
   );
};

export default RowActions;
