import moment from 'moment';
import { useEffect } from 'react';
import { Translate } from 'react-localize-redux';
import { useDispatch } from 'react-redux';
import { moveEmployeeToAbsentWeekTable, totalEmployeeWeekTableExcel } from '../../../../Redux/actions/timeEntryActions';
import {
   TotalHourByWeekDay,
   TotalAttendanceWeek,
   totalHoursByConfig,
   TotalSumAttendanceByWeek,
   TotalRegHourWeeklyPayroll,
   TotalOtHourWeeklyPayroll,
} from '../../../../utils/helpers/TotalHoursFormat';
import useHeaderDate from '../../PayrollByWeek/useHeaderDate';
import { ExportExcel } from '../../PayrollToExcel';
import { formatUsNumber } from '../../../../utils/ReGexs';
import SetTableHeadingResult from './SetTableHeadingResult';


const PayrollResultValidation = ({ payrollWeekValidation, dateStart, translate, clientReportConf }) => {
   const dispatch = useDispatch();
   const findDate = useHeaderDate(dateStart);

   useEffect(() => {
      const { data, totals } = ExportExcel(findDate, payrollWeekValidation, translate);
      // aquí le paso la data de payroll  a un arreglo en el store para consumirla solo en el excel de semana del timeentry falta cambiar nombre porque no corresponde y validar que no existan valores en 0 se envia null o vacio
      dispatch(moveEmployeeToAbsentWeekTable(data));
      dispatch(totalEmployeeWeekTableExcel(totals));
   }, [findDate, translate, payrollWeekValidation, dispatch]);

   // utilizo object.values para mirar la data con horas totales mayor a 0 para iterar un nuevo arreglo con empleados que trabajaron
   const weekResults = Object.values(payrollWeekValidation).filter(
      (value) => value != null && (value?.employeeTotalRegHours !== 0 || value?.employeeTotalOTHours !== 0),
   );

   return (
      <>
         <h3>
            <Translate id={'com.tempedge.msg.menu.payrollvalidation'} />
         </h3>
         <p style={{ textAlign: 'left' }}>
            <Translate id={'com.tempedge.msg.label.listsize'} /> <span className="badge">{weekResults.length}</span>{' '}
         </p>
         <div className="row">
            <div className="form-check col-12 col-lg-12">
               <table className="table table-striped table-input-group">
                  <thead>{<SetTableHeadingResult findDate={findDate}/>}</thead>
                  <tbody>
                     <>
                        {weekResults?.map((e, index) => {
                           return (
                              <tr key={index + 1}>
                                 <td>{`${e.person?.fullName}`}</td>
                                 <td>{e.department !== null ? `${e.department?.name} - ${e.position?.name}` : e.position?.name}</td>
                                 {findDate.map((currentDay, index) => {
                                    const payrollDay = e[currentDay.day];
                                    if (
                                       payrollDay &&
                                       payrollDay.dateList &&
                                       moment(payrollDay.dateList).format('MM/DD/YYYY') === currentDay.date &&
                                       (payrollDay.TotalRegHour !== null || payrollDay.TotalOTHour !== null)
                                    ) {
                                       return (
                                        <td key={index + 1} data-title={currentDay.day}
                                        data-type="currency"
                                        className="day-column"
                                        data-initial={currentDay.day.charAt(0).toUpperCase()}>
                                             {`RG: ${formatUsNumber(payrollDay.totalRegHour) ?? '0.00'} - ${
                                                formatUsNumber(payrollDay.totalOtHour) ?? '0.00'
                                             } : OT`}
                                          </td>
                                       );
                                    } else {
                                       return <td key={index + 1} data-title={currentDay.day}
                                       data-type="currency"
                                       className="day-column text-center"
                                       data-initial={currentDay.day.charAt(0).toUpperCase()}></td>;;
                                    }
                                 })}
                                 <td data-title={`Reg Hours`} className="text-center">
                                    {e.employeeTotalRegHours ? totalHoursByConfig(e.employeeTotalRegHours, clientReportConf) : '0.00'}
                                 </td>
                                 <td data-title={`Ot Hours`} className="text-center">{e.employeeTotalOTHours ? e.employeeTotalOTHours?.toFixed(2) : '0.00'}</td>
                                 <td className="text-center">{TotalSumAttendanceByWeek(e, findDate)}</td>
                              </tr>
                           );
                        })}
                        <tr className="time-sheet-row-responsive">
                           <td colSpan="2" className="table-content text-uppercase text-right">
                              <Translate id={'com.tempedge.msg.label.total'} />
                           </td>
                           {findDate.map((currentDay, index) => {
                              return (
                                 <td key={index + 1} className="table-content text-center">
                                    {TotalHourByWeekDay(payrollWeekValidation, currentDay.day)}
                                 </td>
                              );
                           })}
                           <td className="table-content text-center">{TotalRegHourWeeklyPayroll(payrollWeekValidation)}</td>
                           <td className="table-content text-center">{TotalOtHourWeeklyPayroll(payrollWeekValidation)}</td>
                           <td className="table-content text-center">{TotalAttendanceWeek(payrollWeekValidation, findDate)}</td>
                        </tr>
                     </>
                  </tbody>
               </table>
            </div>
         </div>
      </>
   );
};

export default PayrollResultValidation;
