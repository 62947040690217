import { withLocalize } from 'react-localize-redux';
import moment from 'moment';

const SetTableHeadingAbsentValidation = ({ findDate, translate }) => {

  const generateHeaders = (findDate) => {
    return findDate?.map((date, index) => {
      const day = moment().day(date.day).format('dddd').toLowerCase();
      const translatedDay = translate(`com.tempedge.msg.label.${day}`); // Usa la función de traducción directamente

      return (
        <th
          scope="col"
          key={index}
          className="text-center day-header table-header-mid-first"
          data-initial={translatedDay?.charAt(0)?.toUpperCase()} // Aquí usamos la primera letra del texto traducido
          onClick={(e) => e.currentTarget?.classList.toggle('show-full')}
        >
          <span>{translatedDay}</span> {/* Traducción directa del día */}
          <br />
          <span>{date.date}</span>
        </th>
      );
    });
  };

  return (
    <tr className="responsive-table">
      <th scope="col" className="text-center table-header-left-first">
        {translate('com.tempedge.msg.label.name')}
      </th>
      <th scope="col" className="text-center table-header-mid-first">
        {translate('com.tempedge.msg.label.deptpos')}
      </th>
      {generateHeaders(findDate)}
      <th scope="col" className="text-center table-header-right-first">
        {translate('com.tempedge.msg.label.backtopayroll')}
      </th>
    </tr>
  );
};

export default withLocalize(SetTableHeadingAbsentValidation);