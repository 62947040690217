import { Nav } from 'react-bootstrap';
import './TutorialsTopTabs.css';

const TutorialsTopTabs = ({ tabs, activeTab, onSelectTab }) => {
    return (
        <div className="tutorials-top-nav">
            <Nav variant="pills" className="tutorials-top-nav-items text-center" onSelect={onSelectTab}>
                {Object.keys(tabs).map((key) => (
                    <Nav.Item key={key}>
                        <Nav.Link eventKey={key} active={activeTab === key} className="tutorials-top-nav-link">
                            {tabs[key].title}
                        </Nav.Link>
                    </Nav.Item>
                ))}
            </Nav>
        </div>
    );
};

export default TutorialsTopTabs;
