import { useState, useEffect, useCallback } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Translate } from 'react-localize-redux';
import ContainerBlue from '../../../components/common/Container/ContainerBlue';
import TutorialsSidebarTabs from '../components/TutorialsSidebarTabs';
import TutorialsTopTabs from '../components/TutorialsTopTabs';
import TutorialsCardVideo from '../components/TutorialsCardVideo';
import PaginatorButtons from '../../../components/common/Paginator/ PaginatorButtons';
import videoData from '../data/videoData.json';
import './VideosSection.css';
import stepIconImage from '../img/tempedge_c.png';

const TutorialsSection = () => {
  const [selectedLanguage, setSelectedLanguage] = useState('en');
  const [selectedTab, setSelectedTab] = useState('employee');
  const [currentPage, setCurrentPage] = useState(0);
  const [currentVideos, setCurrentVideos] = useState([]);

  const videosPerPage = 4;

  const loadVideos = useCallback(() => {
    const videos = videoData[selectedLanguage][selectedTab] || [];
    const startIndex = currentPage * videosPerPage;
    const endIndex = startIndex + videosPerPage;
    const videosToDisplay = videos.slice(startIndex, endIndex);
    setCurrentVideos(videosToDisplay);
  }, [selectedLanguage, selectedTab, currentPage]);

  useEffect(() => {
    loadVideos();
  }, [loadVideos]);

  const handleLanguageChange = (languageKey) => {
    setSelectedLanguage(languageKey);
    setCurrentPage(0);
    loadVideos();
  };

  const handleTabChange = (tabKey) => {
    setSelectedTab(tabKey);
    setCurrentPage(0);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const totalVideos = videoData[selectedLanguage][selectedTab]?.length || 0;
  const totalPages = Math.ceil(totalVideos / videosPerPage);

  useEffect(() => {
    if (currentPage >= totalPages && totalPages > 0) {
      setCurrentPage(totalPages - 1);
    }
  }, [totalPages, currentPage]);

  return (
    <ContainerBlue title="com.tempedge.msg.title.tutorials">
      <Row className="justify-content-start">
        <Col lg={3} md={4} sm={12} className="mb-4 align-self-start">
          <TutorialsSidebarTabs
            tabs={{
              employee: <Translate id="com.tempedge.msg.title.employee" />,
              payroll: <Translate id="com.tempedge.msg.title.payroll" />,
              company: <Translate id="com.tempedge.msg.title.company" />,
              user: <Translate id="com.tempedge.msg.title.user" />,
              tempedge: 'TempEdge',
            }}
            activeTab={selectedTab}
            onSelectTab={handleTabChange}
          />
        </Col>

        <Col lg={9} md={8} sm={12} className="justify-content-start">
          <Row className="justify-content-start">
            <Col sm={12}>
              <TutorialsTopTabs
                tabs={{
                  en: { title: <Translate id="com.tempedge.msg.title.english" /> },
                  es: { title: <Translate id="com.tempedge.msg.title.spanish" /> },
                }}
                activeTab={selectedLanguage}
                onSelectTab={handleLanguageChange}
              />
            </Col>
          </Row>

          <Row className="justify-content-start">
            <Col sm={12}>
              <div className="tutorials-video-list">
                {currentVideos.map((video, index) => (
                  <Row key={index} className="tutorials-video-item align-items-center">
                    <Col xs="auto" className="step-indicator">
                      <div className="step-icon-container">
                        <img src={stepIconImage} alt="Step Icon" className="step-icon-img" />
                      </div>
                    </Col>
                    <Col xs={12} md={5} className="text-left custom-padding-left">
                      {video.title}
                    </Col>
                    <Col xs={12} md={5} className="d-flex justify-content-center align-items-center">
                      <TutorialsCardVideo url={`https://www.youtube.com/watch?v=${video.id}`} />
                    </Col>
                  </Row>
                ))}
              </div>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col sm="auto" className="custom-paginator">
              {totalPages > 1 && (
                <PaginatorButtons
                  changePage={handlePageChange}
                  totalPages={totalPages}
                  currentPage={currentPage}
                />
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </ContainerBlue>
  );
};

export default TutorialsSection;
