import CellPayrollValidation from './CellPayrollValidation';
import edit from '../../../../assets/icons/edit.png';
import { totalHoursByConfig } from '../../../../utils/helpers/TotalHoursFormat';

const PayrollRow = ({ payroll, index, findDate, clientReportConf, highlightRow, selectedEmployeeIndex, handleOpenPayRate }) => {
   const rowId = `${payroll.person.personId}-${index}`;

   return (
      <tr
         className={selectedEmployeeIndex === rowId ? 'time-sheet-row--selected' : 'time-sheet-row'}
         onClick={() => highlightRow(payroll, index)}
      >
         <th scope="row">{payroll?.person?.fullName}</th>
         <td data-title={`Deparment-Position`}>
            <p className="subtitle-payroll">
               {payroll.department ? `${payroll.department.name} - ${payroll.position.name}` : payroll.position?.name}
            </p>
         </td>
         {findDate.map(({ day, date }, idx) => (
            <CellPayrollValidation key={idx} payroll={payroll} day={day}/>
         ))}
         <td data-title={`Reg Hours`}>
            <p className="subtitle-payroll">
               {payroll.employeeTotalRegHours ? totalHoursByConfig(payroll.employeeTotalRegHours, clientReportConf) : 0}
            </p>
         </td>
         <td data-title={`Ot Hours`}>
            <p className="subtitle-payroll">{payroll.employeeTotalOTHours.toFixed(2) || 0}</p>
         </td>
         <td data-title={`Options`}>
            <button
               type="button"
               className="btn"
               onClick={() => {
                  handleOpenPayRate(payroll.id);
               }}
            >
               <img className="icon_edit" src={edit} alt="edit icon" />
            </button>
         </td>
      </tr>
   );
};

export default PayrollRow;
