import { useState } from "react";
import TempEdgeApi from "../services/TempEdgeApi";
import { REACT_APP_URL_GET_ACTIVE_WEEK } from "../components/common/Routes/URLs";


export const useActiveWeek = () => {
   const [weekActive, setWeekActive] = useState({ start: new Date(), end: new Date() });
   const [loading, setLoading] = useState(false);
   const [message, setMessage] = useState(null);
   const [errors, setErrors] = useState({});

   const selectCompany = async (value) => {
      setMessage(null);
      setLoading(true);
      try {
         const { data: res } = await TempEdgeApi.post({
            url: REACT_APP_URL_GET_ACTIVE_WEEK,
            payload: { clientId: value.clientId },
         });
         if (res.status === 200) {
            setWeekActive({ start: res.result.startDate, end: res.result.endDate });
            setErrors((prevErrors) => ({ ...prevErrors, company: null }));
         }
      } catch (error) {
         setMessage(new Error(error));
      } finally {
         setLoading(false);
      }
   };

   return { weekActive, loading, message, selectCompany, errors, setErrors };
};
