import React from 'react';
import HeaderSectionBackground from '../components/BackgroundSlider';
import TextWithLogo from '../../../components/common/TempEdgeLogoLabel/TempEdgeLogoLabel';
import image1 from "../assets/hero_image.png";
import image2 from "../assets/C1.jpg";
import image3 from "../assets/C2.jpg";
import image4 from "../assets/culture_8.jpg";

export const HeaderSection = () => {
    return (
        <>
            <div style={{ position: 'relative' }}>
                <HeaderSectionBackground images={[image1, image2, image3, image4]} />
                <div className="bg-overlay-gredient" id="home">
                    <HeaderSectionContent />
                </div>
            </div>
        </>
    );
};

const HeaderSectionContent = () => {
    return (
        <>
            <section className="section-global">
                <div className="container">
                    <div className="row gy-5">
                        <div className="col-lg-6 align-self-center">
                            <div className="align-self-center">
                                <p className="large-text">Welcome to <TextWithLogo fontSize="70px" color="white" /></p>
                                <p className='txt-1 mt-16 me-lg-5' style={{ color: 'white' }}>A payroll management tool designed specifically for businesses that engage temporary employees regularly.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default HeaderSection;
