import { useEffect, useState } from 'react';
import { initialValuesPosition, positionSchema } from '../validationSchemaClient';
import { Formik } from 'formik';
import { Col, Row, Container } from 'react-bootstrap';
import { Translate } from 'react-localize-redux';
import OutcomeBar from '../../../../components/common/OutcomeBar';
import TranslatedLabel from '../../../../components/common/TranslatedLabel/TranslatedLabel';
import { allowedCharacters } from '../../../../utils/ReGexs';
import PositionList from '../PositionList/PositionList';
import PositionForm from '../PositionList/PositionForm';
import GrayCommonBtn from '../../../../components/common/Buttons/GrayCommonBtn';
import { generateUniqueId } from '../../../../utils/PayrollUtils';
import { isValidTime } from '../../../../utils/DateUtils';
import GreenCommonBtn from '../../../../components/common/Buttons/GreenCommonBtn';
import BlueCommonBtn from '../../../../components/common/Buttons/BlueCommonBtn';
import { calculateBillRates } from './EditDepartmentandPositions';

const NewDepartmentandPositions = ({ onCloseModal, addDepartment,values }) => {
   const [message, setMessage] = useState('');
   const [positionList, setPositionList] = useState([]);
   const [errorDepartmentName, setErrorDepartmentName] = useState(false);
   const [formPosition, setFormPosition] = useState(initialValuesPosition);
   const [departmentname, setDepartmentname] = useState('');
   const [editingIndex, setEditingIndex] = useState(null);

   const resetComponentPositions = () => {
      setFormPosition(initialValuesPosition);
      setPositionList([]);
      setMessage('');
      setErrorDepartmentName(false);
   };

   useEffect(() => {
      resetComponentPositions();
   }, []);

   const editFromPosList = (index) => {
      const position = positionList[index];
      setEditingIndex(position._id);

      setFormPosition({
         _id: position._id,
         bill: position.bill,
         contactName: position.contactName,
         contactPhone: position.contactPhone,
         description: position.description,
         markUp: position.markUp,
         name: position.name,
         otMarkUp: position.otMarkUp,
         pay: position.pay,
         timeIn: position.timeIn,
         timeOut: position.timeOut,
      });
   };


   const updatePosition = (index, updatedPosition) => {
      setPositionList((prevList) => prevList.map((item, i) => (i === index ? updatedPosition : item)));
   };

   // Funcion para agregar una nueva posicion
   const handleSubmit = async (values, { resetForm, setFieldError }) => {
      const { billRate, otBillRate } = calculateBillRates(values);
      const newPosition = {
         ...values,
         _id: generateUniqueId(),
         bill: otBillRate?.toFixed(2),
         otBillRate: billRate?.toFixed(2),
         timeIn: isValidTime(values.timeIn),
         timeOut: isValidTime(values.timeOut),
      };

      const isDuplicate = positionList?.some((position, index) => {
         const isSameName = position.name?.trim().toLowerCase() === newPosition.name?.trim().toLowerCase();
         return isSameName && position._id !== newPosition._id;
      });
      if (isDuplicate) {
         setFieldError('name', 'The position already exists.');
      } else {
         const index = positionList.findIndex((position) => position._id === editingIndex);

         if (index !== -1) {
            updatePosition(editingIndex, newPosition);
            setEditingIndex(null);
         } else {
            setPositionList((prevList) => [...prevList, newPosition]);
            setEditingIndex(null);
         }
         setFormPosition(initialValuesPosition);
         resetForm();
         setMessage('');
      }
   };

   // Funcion para actualizar agregar un departamento
   const handleAddDepartment = () => {
      if (departmentname === '') {
         setErrorDepartmentName(true);
         return;
      }
      if (positionList.length === 0) {
         return setMessage('com.tempedge.error.client.clientincompleted');
      }
      const isDuplicate = values.departments?.some((dept) => dept.name?.trim().toLowerCase() === departmentname?.trim().toLowerCase());
      if (isDuplicate) {
         setMessage('com.tempedge.error.deptposalreadyexists');
         setErrorDepartmentName(true);
         return;
      }
      addDepartment({
         _id: generateUniqueId(),
         name: departmentname,
         orgId: JSON.parse(localStorage.getItem('agency'))?.organizationEntity.orgId,
         positions: positionList,
      });
   };

   return (
      <Formik initialValues={formPosition} enableReinitialize={true} onSubmit={handleSubmit} validationSchema={positionSchema}>
         {({ handleSubmit }) => (
            <Container className="sign-up-wrapper">
               <Row>
                  <h2 className="mx-auto text-center">
                     <Translate id="com.tempedge.msg.label.addDept" />
                  </h2>
               </Row>
               <Row className="row-agency-name">
                  <Col sm={12} md={12} lg={12}>
                     {message && <OutcomeBar personMessage={message} />}
                  </Col>

                  <Col sm={12} md={4} lg={4}>
                     <TranslatedLabel id="com.tempedge.msg.label.deptName" />
                     <input
                        id={`departmentname`}
                        name={`departmentname`}
                        type="text"
                        maxLength={90}
                        value={departmentname}
                        className={`tempEdge-input-box form-control ${errorDepartmentName ? 'has-error' : ''}`}
                        onChange={(e) => {
                           setDepartmentname(e.target.value.replace(allowedCharacters, '')?.toUpperCase());
                           setErrorDepartmentName(false);
                        }}
                     />
                  </Col>
                  <div className="form-horizontal center-block panel-body mt-3">
                     <Row className="new-client-form">
                        <Col sm={12} md={8} lg={8} className="client-col">
                           <div className="">
                              <div className="new-client-header">
                                 <h2>
                                    <Translate id="com.tempedge.msg.label.deptinfo" />
                                 </h2>
                              </div>
                              <div className="new-clients-contents">
                                 <PositionForm />
                              </div>
                           </div>
                        </Col>
                        <Col sm={12} md={4} lg={4} className="dept-col">
                           <div className="department-list">
                              <div className="department-list-header">
                                 <h2>
                                    <Translate id="com.tempedge.msg.label.positionlist" />
                                 </h2>
                              </div>
                              <div className="position-list-contents">
                                 <div>
                                    <div style={{ height: 10 }}></div>
                                    {positionList.length > 0 && (
                                       <PositionList
                                          positionList={positionList}
                                          removeFromPosList={(index) =>
                                             setPositionList((prevList) => prevList.filter((_, i) => i !== index))
                                          }
                                          editFromPosList={editFromPosList}
                                       />
                                    )}
                                 </div>
                              </div>
                           </div>
                        </Col>
                     </Row>
                  </div>
               </Row>
               <Row>
                  <Col sm={12} md={4} lg={4}>
                     <GrayCommonBtn tag={'com.tempedge.msg.label.cancel'} onClick={() => onCloseModal()} />
                  </Col>
                  <Col sm={12} md={4} lg={4} className='text-center mx-auto'>
                     <GreenCommonBtn type="submit" onClick={handleSubmit} tag="com.tempedge.msg.label.addPos" />
                  </Col>
                  <Col sm={12} md={4} lg={4}>
                     <BlueCommonBtn tag="com.tempedge.msg.label.addDept" type={'button'} onClick={handleAddDepartment} />
                  </Col>
               </Row>
            </Container>
         )}
      </Formik>
   );
};

export default NewDepartmentandPositions;
