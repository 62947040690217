import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { changeValuesValidation } from '../../../../Redux/actions/payrollValidationActions';
import '../../../../assets/styles/components/table-inputs.css';

const CellAbsentPayrollValidation = ({ payroll, day }) => {
   const [totalOtHour, setTotalOtHour] = useState('');
   const [totalRegHour, setTotalRegHour] = useState('');
   const [payrollDisable, setPayrollDisable] = useState(true);
   const dispatch = useDispatch();

   const changeValidation = (values, key) => {
      const data = {
         payrollId: payroll[day]?.payrollId,
         id: payroll.id,
         key,
         value: values ?? 0,
         day,
      };

      const regularExpression = /^-?\d{0,3}(\.\d{0,2})?$/;
      if (regularExpression.test(values)) {
         dispatch(changeValuesValidation(data));
      }
   };

   const changeDisable = () => {
      if (payroll[day]?.lockPayroll) {
         setPayrollDisable(true);
      } else {
         setPayrollDisable(false);
      }
   };

   const cellClass = payroll[day]?.attendance !== null ? "red-background" : "";
  const borderClass = payroll[day]?.attendance !== null ? "red-border" : "";



   if (payroll[day] !== null && payroll[day].totalRegHour === 0 && payroll[day].totalOtHour === 0) {
      return (
         <td
            data-title={day}
            data-type="currency"
            data-initial={day.charAt(0).toUpperCase()} // Añade la inicial del día
            className={cellClass}
         >
            <div className={`form-group-payroll ${borderClass}`}>
               <span>RG</span>
               <input
                  onClick={() => changeDisable()}
                  style={{ fontSize: '11px' }}
                  type="text"
                  step="0.01"
                  className="form-field-payroll text-center"
                  placeholder="0.00"
                  aria-label="totalRegHour"
                  aria-describedby="basic-addon1"
                  value={totalRegHour}
                  onBlur={() => changeValidation(totalRegHour, 'totalRegHour')}
                  onChange={(event) => {
                     const inputValue = event.target.value;
                     // Regular expression to match the desired format
                     const regex = /^-?\d{0,3}(\.\d{0,2})?$/;
                     if (regex.test(inputValue) || inputValue === '') {
                        setTotalRegHour(inputValue);
                     }
                  }}
                  readOnly={payrollDisable}
               />
               <input
                  onClick={() => changeDisable()}
                  style={{ fontSize: '11px' }}
                  type="text"
                  step="0.01"
                  className="form-field-payroll text-center"
                  placeholder="0.00"
                  aria-label="totalOtHour"
                  aria-describedby="basic-addon2"
                  value={totalOtHour}
                  onBlur={() => changeValidation(totalOtHour, 'totalOtHour')}
                  onChange={(event) => {
                     const inputValue = event.target.value;
                     // Regular expression to match the desired format
                     const regex = /^-?\d{0,3}(\.\d{0,2})?$/;
                     if (regex.test(inputValue) || inputValue === '') {
                        setTotalOtHour(inputValue);
                     }
                  }}
                  readOnly={payrollDisable}
               />

               <span>OT</span>
            </div>
         </td>
      );
   } else {
      return (
         <td
            data-title={day}
            data-type="currency"
            data-initial={day.charAt(0).toUpperCase()} // Añade la inicial del día
         >
            <p className="subtitle-payroll">{''}</p>
         </td>
      );
   }
};

export default CellAbsentPayrollValidation;
