// useFetchFindCustomPayrollCode.js
import { useState, useCallback } from 'react';
import TempEdgeApi from '../services/TempEdgeApi';
import { REACT_APP_URL_FIND_PAYROLLCODE } from '../components/common/Routes/URLs';

export const useFetchFindCustomPayrollCode = () => {
   const [dataGlobal, setDataGlobal] = useState([]);
   const [loading, setLoading] = useState(false);
   const [message, setMessage] = useState(null);

   const getCustomCode = useCallback(async () => {
      setLoading(true);
      try {
         const { data: res } = await TempEdgeApi.post({
            url: REACT_APP_URL_FIND_PAYROLLCODE,
            payload: {
               parameterKey: 'com.tempedge.global.param.custompayrolltype',
            },
         });
         if (res.status === 200 && res?.result?.data?.content?.length > 0) {
            setDataGlobal(res.result?.data?.content);
         }
      } catch (error) {
         setMessage(error);
      } finally {
         setLoading(false);
      }
   }, []);

   return { dataGlobal, loading, message, getCustomCode };
};