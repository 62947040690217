import moment from "moment";
import { withLocalize } from "react-localize-redux";

const SetTableHeadingAttendance = ({ findDate, translate }) => {
  
  return (
    <tr className="responsive-table">
      <th scope="col" className="text-center table-header-left-first">
        {translate('com.tempedge.msg.label.name')}
      </th>
      <th scope="col" className="text-center table-header-mid-first">
        {translate('com.tempedge.msg.label.deptpos')}
      </th>
      {findDate?.map((date, index) => {
        const day = moment().day(date.day).format('dddd').toLowerCase();
        const translatedDay = translate(`com.tempedge.msg.label.${day}`); // Usa la función de traducción directamente

        return (
          <th
            scope="col"
            key={index}
            className="text-center table-header-mid-first"
            data-initial={translatedDay?.charAt(0)?.toUpperCase()} // Aquí usamos la primera letra del texto traducido
            onClick={(e) => e.currentTarget.classList.toggle('show-full')}
          >
            <span>{translate(`com.tempedge.msg.label.${day}`)}</span>
            <br />
            <span>{date.date || ''}</span>
          </th>
        );
      })}
      <th scope="col" className="text-center table-header-mid-first">
        {translate('com.tempedge.msg.label.attendance')}
      </th>
    </tr>
  );
};

export default withLocalize(SetTableHeadingAttendance);
