import { Switch, Route } from 'react-router-dom';

// public components

import CreateOrUpdateUser from "../../../Pages/User/CreateOrUpdateUser";
import Error from '../../../Pages/Error/Error';
import ForgotPassword from '../../../Pages/ForgotPassword/ForgotPassword';
import PrivateRoutes from '../PrivateRoutes/PrivateRoutes';
import SaveNewPassword from '../../../Pages/ForgotPassword/SaveNewPassword';
import Home from '../../../Pages/Home/Home';
import Login from '../../../Pages/Login/Login';
import FormCandidate from '../../../Pages/NewCandidate/FormCandidate';
import Tutorial from '../../../Pages/Tutorials/Tutorial';

function Routes(props){

      return (
            <Switch>
                {/*public components*/}
                <Route exact path="/tutorials/:lang" component={Tutorial} />
                <Route exact path="/auth/:lang" component={Home} />
                <Route exact path="/candidate/registration/:id/:lang" component={FormCandidate} />
                <Route exact path="/signin/:lang" component={Login} />
                <Route exact path="/register/:lang" component={CreateOrUpdateUser} />
                <Route exact path="/resetPassword/:lang" component={ForgotPassword} />
                <Route exact path="/saveNewPassword/:id/:lang" component={SaveNewPassword} />
                <Route exact path="/pending/user/:lang" component={Error} />
                <Route exact path="/pending/agency/:lang" component={Error} />
                <Route exact path="/denied/user/:lang" component={Error} />
                <Route exact path="/denied/agency/:lang" component={Error} />
                <Route exact path="/error/:lang" component={Error} />
                <Route exact path="/pending/user/:lang" component={Error} />                
                <PrivateRoutes/>
            </Switch>
        );

}


export default Routes;
