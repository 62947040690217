import React from  'react';
import { Route, Switch } from 'react-router-dom';

// Hoc to protect components 
import requireAuth from '../../../Hoc/Authentication/requireAuth';

// private components

import FaceMashDesktop from '../../../Pages/FaceMash/FaceMashDesktop';
import EmployeeList from '../../../Pages/Employee/EmployeeList/EmployeeList';
import ViewEmployee from '../../../Pages/Employee/ViewEmployee/ViewEmployee';
import ClientList from '../../../Pages/Client/ClientList/ClientList';
import UploadClient from '../../../Pages/Client/UploadCode/Form'
import ViewClient from '../../../Pages/Client/ViewClient/ViewClient';
import Dashboard from '../../../Pages/Dashboard/Dashboard';
import CreateNewAgency from '../../../Pages/Login/CreateNewAgency/CreateNewAgency';
import NewInternalPayroll from '../../../Pages/InternalPayroll/NewInternalPayroll/NewInternalPayroll';
import OrgList from '../../../Pages/Organization/OrgSelect/OrgSelectList';
import ChangePassword from '../../../Pages/ChangePassword/ChangePassword';
import ProfilePage from '../../../Pages/Profile';
import UpdloadEmployeeList from '../../../Pages/Employee/UploadEmployeeList/UpdloadEmployeeList';
import DailyList from '../../../Pages/DailyList/DailyList';
import PendingUserList from '../../../Pages/ApprovalUser/PendingUserList/PendingUserList';
import TimeOutModal from '../TimeOutModal/TimeOutModal';
import ReportUpload from '../../../Pages/Reports/Upload';
import CreateOrUpdateEmployee from "../../../Pages/Employee/CreateOrUpdateEmployee";
import DownloadReports from "../../../Pages/Reports/Generate";
import TimeEntry from '../../../Pages/Payroll/TimeEntry/TimeEntry';
import ConfigReport from '../../../Pages/Reports/Configuration/ConfigReport';
import Adjustments from "../../../Pages/Adjustment";
import PayrollValidation from '../../../Pages/Payroll/PayrollValidation/PayrollValidation';
import UserView from '../../../Pages/User/UserView';
import CreateOrUpdateUser from "../../../Pages/User/CreateOrUpdateUser";
import PendingUser from "../../../Pages/ApprovalUser/ViewUser/PendingUser";
import ListUsers from '../../../Pages/User/ListUsers';
import InternalPersonList from '../../../Pages/InternalEmployees/InternalPersonList';
import CustomPayroll from '../../../Pages/CustomPayroll/Custompayroll';
import GlobalParameters from '../../../Pages/GlobalParameters';
import TimeOff from '../../../Pages/TimeOff';
import CustomReport from '../../../Pages/Customreport/index';
import PayrollByPerson from '../../../Pages/Payroll/PayrollHistory/PayrollByPerson';
import MyHistoryPayroll from '../../../Pages/Payroll/PayrollHistory/MyHistoryPayroll';
import CreateCandidate from '../../../Pages/NewCandidate/CreateCandidate';
import Attendance from '../../../Pages/Payroll/Attendance/Attendance';
import AccountEmployee from "../../../Pages/Employee/Account/AccountEmployee"

import CreateOrUpdateClient from '../../../Pages/Client/CreateOrUpdateClient/CreateOrUpdateClient';
// import CreateNewClient from '../../../Pages/Client/CreateNewClient/CreateNewClient';


function PrivateRoutes(props) {

  return (
    <>
      <TimeOutModal/>
    <Switch> 
      <Route exact path="/user/changePass/:lang" component={requireAuth(ChangePassword)} />
      <Route exact path="/pendinguser/pending/:lang" component={PendingUserList} />
      <Route exact path="/pendinguser/view/:lang" component={PendingUser} />
      <Route exact path="/user/view/:lang" component={UserView} />
      <Route exact path="/user/list/:lang" component={requireAuth(ListUsers)} />
      <Route exact path="/user/update/:lang" component= {CreateOrUpdateUser} />
      <Route exact path="/employee/internal/list/:lang" component={requireAuth(InternalPersonList)} />
      <Route exact path="/profile/:lang" component={requireAuth(ProfilePage)} />
      <Route exact path="/uploadClientCodes/:lang" component={requireAuth(UploadClient)} />
      {/* <Route exact path="/client/new/:lang" component={requireAuth( CreateNewClient)} /> */}
      {/* <Route exact path="/client/update/:lang" component={requireAuth(CreateNewClient)} /> */}
      <Route exact path="/client/new/:lang" component={requireAuth(CreateOrUpdateClient)} />
      <Route exact path="/client/update/:lang" component={requireAuth(CreateOrUpdateClient)} />
      <Route exact path="/snapshot-desktop/:lang" component={requireAuth(FaceMashDesktop)} />
      <Route exact path="/employee/uploadlist/:lang" component={requireAuth(UpdloadEmployeeList )} />
      <Route exact path="/employee/list/:lang" component={requireAuth(EmployeeList)} />
      <Route exact path="/employee/new/:lang" component={requireAuth(CreateOrUpdateEmployee)} />
      <Route exact path="/employee/update/:lang" component={requireAuth(CreateOrUpdateEmployee)} />
      <Route exact path="/client/list/:lang" component={requireAuth(ClientList)} />
      <Route exact path="/client/view/:lang" component={requireAuth(ViewClient)} />
      <Route exact path="/dashboard/:lang" component={requireAuth(Dashboard)} />
      <Route exact path="/registerAgency/:lang" component={requireAuth(CreateNewAgency)} />
      <Route exact path="/organization-select/:lang" component={requireAuth(OrgList)} />
      <Route exact path="/payroll/history/:lang" component={requireAuth(PayrollByPerson)} /> 
      <Route exact path="/intpayroll/history/:lang" component={requireAuth(MyHistoryPayroll)} />
      <Route exact path="/payroll/dailylist/:lang" component={requireAuth(DailyList)} />
      <Route exact path="/employee/view/:lang" component={requireAuth(ViewEmployee)} />
      <Route exact path="/payroll/timeentry/:lang" component={requireAuth(TimeEntry)} />
      <Route exact path="/payroll/payrollvalidation/:lang" component={requireAuth(PayrollValidation)} />
      <Route exact path="/report/upload/:lang" component={requireAuth(ReportUpload)} />
      <Route exact path="/report/new/:lang" component={requireAuth(ConfigReport)} />
      <Route exact path="/report/generate/:lang" component={requireAuth(DownloadReports)} />
      <Route exact path="/adjustment/new/:lang" component={requireAuth(Adjustments)} />
      <Route exact path="/customPayroll/:lang" component={requireAuth(CustomPayroll)} />
      <Route exact path="/globalparams/:lang" component={requireAuth(GlobalParameters)} />
      <Route exact path="/timeoff/:lang" component={requireAuth(TimeOff)} />
      <Route exact path="/customreport/:lang" component={requireAuth(CustomReport)} />
      <Route exact path="/intpayroll/new/:lang" component={requireAuth(NewInternalPayroll)} />
      <Route exact path="/candidate/new/:lang" component={requireAuth(CreateCandidate )} />
      <Route exact path="/attendance/:lang" component={requireAuth(Attendance)} />
      <Route exact path="/employee/account/:lang" component={requireAuth(AccountEmployee)} />
    </Switch>
    </>
  );
}

export default React.memo(PrivateRoutes, (prevProps,nextProps) => {
   let prevLocation = prevProps.location.pathname;
   let nextLocation = nextProps.location.pathname;
   prevLocation = prevLocation.substring(0,prevLocation.lastIndexOf('/'));
   nextLocation = nextLocation.substring(0,nextLocation.lastIndexOf('/'));
   return prevLocation === nextLocation;
});
