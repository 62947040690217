import * as Yup from 'yup';
import { generateUniqueId } from '../../../utils/PayrollUtils';

// Expresión regular para validar números con hasta dos decimales
const numberFormatDecimals = /^(-?\d{1,5}(\.\d{1,2})?)$/;
// Expresión regular para validar porcentajes con hasta dos decimales
const numberFormatPorcentage = /^(?!0\.00)-{0,1}\d{1,2}(\.\d{1,2})?%?$/;
// Expresión regular para validar correos electrónicos
const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i;

export const positionSchema = Yup.object().shape({
   bill: Yup.string(),
   contactName: Yup.string(),
   contactPhone: Yup.string(),
   description: Yup.string().required('Description is required'),
   markUp: Yup.string().matches(numberFormatPorcentage, {
      message: 'Formato inválido.',
      excludeEmptyString: true,
   }).required('Markup is required'),
   name: Yup.string().required('Position Name is required'),
   otMarkUp: Yup.string().matches(numberFormatPorcentage, {
      message: 'Formato inválido.',
      excludeEmptyString: true,
   }).required('OT Markup is required'),
   pay: Yup.string().matches(numberFormatDecimals, {
      message: 'Formato inválido.',
      excludeEmptyString: true,
   }).required('Pay Rate is required'),
});

export const validationSchemaClient = (page) => {
   switch (page) {
      case 1:
         return Yup.object().shape({
            company: Yup.string().required('Company is required'),
            salesman: Yup.string().required('Salesman is required'),
            payrollCycle: Yup.string().required('Payroll cycle is required'),
            workCompCode: Yup.string()
               .matches(numberFormatDecimals, {
                  message: 'Formato inválido.',
                  excludeEmptyString: true, // Excluye las cadenas vacías de la validación
               })
               .required('Work Comp Code is required'),
            workCompRate: Yup.string()
               .matches(numberFormatDecimals, {
                  message: 'Formato inválido.',
                  excludeEmptyString: true,
               })
               .required('Work Comp Rate is required'),
            companyInitials: Yup.string().required('Company Initials are required'),
            attnTo: Yup.string().required('Attn To is required'),
            email: Yup.string()
               .matches(emailRegex, {
                  message: 'Formato inválido. Debe ser una dirección de correo electrónico válida.',
                  excludeEmptyString: true,
               })
               .required('Email is required'),
            markupClient: Yup.string()
               .matches(numberFormatPorcentage, {
                  message: 'Formato inválido.',
                  excludeEmptyString: true,
               })
               .required('Markup Client is required'),
            otMarkupClient: Yup.string()
               .matches(numberFormatPorcentage, {
                  message: 'Formato inválido.',
                  excludeEmptyString: true,
               })
               .required('OT Markup Client is required'),
            workRegion: Yup.string().required('Work State is required'),
         });
      case 2:
         return Yup.object().shape({
            clientcountry: Yup.number().required('Client Country is required'),
            clientaddress: Yup.string().required('Client Address is required'),
            clientcity: Yup.string().required('Client City is required'),
            clientstate: Yup.string().required('Client State is required'),
            clientzipcode: Yup.string().required('Client Zipcode is required'),
         });
      case 3:
         return Yup.object()
            .shape({
               clientlastName: Yup.string().required('Client Last Name is required'),
               clientfirstName: Yup.string().required('Client First Name is required'),
               clientcontactphone: Yup.string(),
               clientcontactcellphone: Yup.string(),
            })
            .test('atLeastOne', 'Either Client Contact Phone or Client Contact Cellphone is required', function (value) {
               const { clientcontactphone, clientcontactcellphone } = value;

               if (!clientcontactphone && !clientcontactcellphone) {
                  return (
                     this.createError({
                        message: 'Either Client Contact Phone or Client Contact Cellphone is required',
                        path: 'clientcontactphone',
                     }) &&
                     this.createError({
                        message: 'Either Client Contact Phone or Client Contact Cellphone is required',
                        path: 'clientcontactcellphone',
                     })
                  );
               }

               return true;
            });
      case 4:
         return Yup.object().shape({
            fileUpdate: Yup.array(),
            fileUpdateDelete: Yup.array(),
         });
      default:
         return Yup.object().shape({});
   }
};

export const initialValues = {
   /* ClientfirstPage */
   company: '',
   salesman: '',
   payrollCycle: '',
   workCompCode: '',
   workCompRate: '',
   worksate: '',
   companyInitials: '',
   attnTo: '',
   email: '',
   markupClient: '',
   otMarkupClient: '',
   /* ClientSecondsPage */
   clientcountry: 234,
   clientaddress: '',
   clientcity: '',
   clientstate: '',
   clientzipcode: '',
   /* ClientThirdPage */
   clientlastName: '',
   clientfirstName: '',
   clientcontactphone: '',
   clientcontactcellphone: '',
   /* ClientFourthPage */
   fileUpdate: [],
   fileUpdateDelete: [],
   /* departments and positions */
   departments: [],
};

export const buildClientRequest = (values, orgId, additionalFields = {}) => {
   return {
      orgId,
      address: values.clientaddress?.toUpperCase(),
      address2: values.clientaddress2 ? values.clientaddress2.toUpperCase() : undefined,
      attn: values.attnTo,
      city: values.clientcity,
      clientInitials: values.companyInitials,
      clientName: values.company,
      commonMarkup: values.markupClient,
      commonOtMarkup: values.otMarkupClient,
      country: values.clientcountry,
      email: values.email?.toUpperCase(),
      notes: values.comments ? values.comments.toUpperCase() : undefined,
      payrollSchedule: values.payrollCycle,
      phone: values.clientcontactphone,
      region: values.clientstate,
      wcCode: values.workCompCode,
      wcRate: values.workCompRate,
      workRegion: values.workRegion,
      zipcode: values.clientzipcode,
      contact: {
         cellPhone: values.clientcontactcellphone,
         firstName: values.clientfirstName?.toUpperCase(),
         lastName: values.clientlastName?.toUpperCase(),
         phone: values.clientcontactphone,
         personType: {
            personTypeId: 2,
         },
      },
      departments: values.departments,
      clientSellers: [
         {
            person: {
               personId: values.salesman,
            },
         },
      ],
      ...additionalFields,
   };
};

export const initialValuesPosition = {
   bill: 0,
   contactName: '',
   contactPhone: '',
   description: '',
   markUp: '',
   name: '',
   otMarkUp: '',
   pay: '',
   timeIn: '',
   timeOut: '',
   otBillRate: 0,
   id: generateUniqueId(),
};