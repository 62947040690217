import React from 'react';
import { Provider } from 'react-redux';
import { store } from '../../../store/store';
import { LocalizationProvider } from '../LocalizationContext/LocalizationContext';

const TranslatorProvider= ({ children, translations }) => {
  return (
    <Provider store={store}>
      <LocalizationProvider translations={translations}>
        {children}
      </LocalizationProvider>
    </Provider>
  );
};

export default TranslatorProvider;
