import React from 'react';
import ReactDOM from 'react-dom';
import '../../assets/styles/components/Modals.css';

class Modal extends React.Component {
  render() {
    // eslint-disable-next-line prefer-const
    let { modalSize, height } = this.props;
    modalSize = modalSize || 'modal-lg';
    // eslint-disable-next-line prefer-const
    let ModalComponent = this.props.open ? (
      <div onClick={() => this.props.onClose()} 
      className="facemash-modal modal "
      >
        <div onClick={(e) => e.stopPropagation()} className={`modal-dialog modal-dialog-centered ${modalSize}`}>
          <div className="modal-content mx-auto" data-backdrop="static" 
            data-keyboard="false">
            <div className="modal-header">
              <h2 className="modal-title text-center">{this.props.title}</h2>
              <button 
              type="button" 
              data-backdrop="static" 
              data-keyboard="false"
              onClick={() => this.props.onClose()} 
              className="close" 
              data-dismiss="modal" 
              aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            {/* eslint-disable-next-line object-shorthand */}
            <div className="modal-body" style={{ height, overflowY: 'scroll' }}>
              {this.props.content}
            </div>
            <div className="modal-footer">{this.props.buttons}</div>
          </div>
        </div>
      </div>
    ) : null;

    return ReactDOM.createPortal(ModalComponent, document.querySelector('#modal'));
  }
}

export default Modal;
