import { useEffect, useMemo, useRef, useState } from 'react';
import { Formik } from 'formik';
import moment from 'moment';
import { Tab, Tabs } from 'react-bootstrap';
import { Translate, withLocalize } from 'react-localize-redux';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Stepper from 'react-stepper-horizontal';
import { clearTempedgeStoreProp, updateMessage } from '../../../Redux/actions/tempEdgeActions';
import '../../../assets/styles/components/Tabs.css';
import { DATE_FORMAT, DEFAULT_COUNTRY, MAIN_OFFICE_NAME, fileType } from '../../../components/common/Constants/Constants';
import Loader from '../../../components/common/Loader/Loader';
import OutcomeBar from '../../../components/common/OutcomeBar';
import {
   REACT_APP_URL_CLIENT_CODE_DELETE,
   REACT_APP_URL_COMMONS_FILE_DELETE,
   REACT_APP_URL_COMMONS_FILE_UPDATE,
   REACT_APP_URL_COUNTRY_LIST_ALL,
   REACT_APP_URL_FIND_PAYROLLCODE,
   REACT_APP_URL_OFFICE_FIND_ALL,
   REACT_APP_URL_ORG_DEPARTMENT_FIND_ALL,
   REACT_APP_URL_PERSON_INTERNAL_VIEW,
   REACT_APP_URL_PERSON_SAVE,
   REACT_APP_URL_PERSON_SKILL_LIST,
   REACT_APP_URL_PERSON_UPDATE,
   REACT_APP_URL_PERSON_VIEW,
   REACT_APP_URL_PERSON_VALIDATE,
   REACT_APP_URL_TAXES_EMPLOYEE_SAVE,
} from '../../../components/common/Routes/URLs';
import TempEdgeApi from '../../../services/TempEdgeApi';
import ClientCode from './ClientCode';
import Contact from './Contact';
import { CreateOrUpdateEmployeeSchema, initialValues } from './CreateUpdateEmployeeSchema';
import FileUpdate from './FileUpdate';
import Information from './Information';
import Misc from './Misc';
import Skills from './Skills';
import Modal from '../../../Modals/Modal/Modal';
import ValidatePerson from './ValidatePerson';
import StateTaxes from './StateTaxes';

function FormEmployee(props) {
   const { promiseInProgress } = usePromiseTracker();
   const [showModal, setShowModal] = useState(false);
   const [step, setStep] = useState(1);
   const [offices, setOffices] = useState([]);
   const [skills, setSkills] = useState([]);
   const [departments, setDepartments] = useState([]);
   const [internalDep, setInternalDep] = useState('');
   const [data, setData] = useState(null);
   const [countries, setCountries] = useState([]);
   const [message, setMessage] = useState(null);
   const [customMessage, setCustomMessage] = useState(null);
   const [maritalStatusFind, setMaritalStatusFind] = useState([]);
   const history = useHistory();
   const [findMaritalStatus, setFindMaritalStatus] = useState([]);
   const [formatSSN, setFormatSSN] = useState('');
   const [dataSave, setDataSave] = useState([]);
   const [valuesSavePerson, setValuesSavePerson] = useState(null);
   const [taxValues, setTaxValues] = useState({});
   const dispatch = useDispatch();

   const personId = useSelector((state) => {
      return state.tempEdge.personId;
   }, shallowEqual);

   const parametersFilterFind = useSelector((state) => {
      return state.tempEdge.parameters;
   }, shallowEqual);

   const messageUpdate = useSelector((state) => {
      return state.tempEdge.message;
   }, shallowEqual);

   const clientsListRedux = useSelector((state) => {
      return state.tempEdge.clientList;
   });

   const clients = useMemo(() => clientsListRedux, [clientsListRedux]);

   const formikRef = useRef();

   useEffect(() => {
      let isMounted = true;
      async function fetch() {
         try {
            const { data: offices } = await TempEdgeApi.post({
               url: REACT_APP_URL_OFFICE_FIND_ALL,
               payload: { page: 0, size: 50, filterBy: {} },
            });
            if (offices.status === 200 && isMounted) setOffices(offices.result.data.content);
            const { data: skills } = await TempEdgeApi.post({
               url: REACT_APP_URL_PERSON_SKILL_LIST,
            });
            if (skills.status === 200 && isMounted) setSkills(skills.result);
            const { data: countries } = await TempEdgeApi.get({
               url: REACT_APP_URL_COUNTRY_LIST_ALL,
            });
            if (countries.status === 200 && isMounted) {
               setCountries(countries.result);
               const country = countries.result.filter((country) => {
                  return country.countryId === DEFAULT_COUNTRY;
               })[0];
               setFormatSSN(country.idFormat);
            }
            const { data: departments } = await TempEdgeApi.post({
               url: REACT_APP_URL_ORG_DEPARTMENT_FIND_ALL,
               payload: {
                  page: 0,
                  size: 200,
                  filterBy: {},
               },
            });
            if (departments.status === 200 && isMounted)
               setDepartments(departments.result.data.content.filter((department) => department.status === 'A'));
            setInternalDep(departments.result.data.content.filter((department) => department.name === 'INTERNAL'));
         } catch (error) {
            setMessage(error);
         }
      }

      trackPromise(fetch());
      return () => {
         isMounted = false;
         setCustomMessage(null);
      };
   }, []);

   useEffect(() => {
      async function fetchMarital() {
         const { data: res } = await TempEdgeApi.post({
            url: REACT_APP_URL_FIND_PAYROLLCODE,
            payload: { parameterKey: 'com.tempedge.global.param.maritalstatus' },
         });
         if (res?.status === 200) {
            const newMarital = res.result?.data?.content.map((item) => {
               const shortName = props.translate(item.description);
               return {
                  label: <Translate id={item.description} />,
                  value: item.code,
                  status: item.status,
                  shortName,
               };
            });
            setMaritalStatusFind(newMarital);
         }
      }
      fetchMarital();
   }, [props]);

   useEffect(() => {
      let isMounted = true;
      async function fetch() {
         const path = window.location.pathname.split('/');
         if (personId && path[2] === 'update') {
            try {
               const personTypes = parametersFilterFind || { personType: '1' };
               const { data: response } = await TempEdgeApi.post({
                  url: personTypes.personType === '3' ? REACT_APP_URL_PERSON_INTERNAL_VIEW : REACT_APP_URL_PERSON_VIEW,
                  payload: {
                     id: personId,
                     filterBy: {
                        ...personTypes,
                     },
                  },
               });
               if (response.status === 200 && isMounted) {
                  const employee = response.result;
                  let clientCodes = [];
                  let fileUpdate = [];
                  if (employee.personCodeEntityList && Array.isArray(employee.personCodeEntityList)) {
                     clientCodes = employee.personCodeEntityList.map((item) => {
                        return {
                           clientPersonCodeId: item.clientPersonCodeId,
                           client: item.client.clientId,
                           code: item.clientCode,
                        };
                     });
                  }
                  if (employee.fileEntities && Array.isArray(employee.fileEntities)) {
                     fileUpdate = employee.fileEntities.map((item, i) => {
                        return {
                           key: i,
                           type: item?.type,
                           fileNames: item?.fileName,
                           file: item?.file,
                        };
                     });
                  }
                  let departmentsList = [];
                  const { data: departments } = await TempEdgeApi.post({
                     url: REACT_APP_URL_ORG_DEPARTMENT_FIND_ALL,
                     payload: {
                        page: 0,
                        size: 200,
                        filterBy: { orgDepartmentCode: employee.empDepartment },
                     },
                  });
                  if (departments.status === 200 && isMounted) {
                     departmentsList = departments.result.data.content.filter((department) => department.status === 'A');
                     setInternalDep(departments.result.data.content.filter((department) => department.name === 'INTERNAL'));
                  }
                  const department = departmentsList.find((department) => {
                     return department.orgDepartmentCode === employee.empDepartment;
                  });
                  const data = {
                     edit: true,
                     information: {
                        temporalInfo: employee.temporalInfo === null ? false : employee.temporalInfo,
                        departmentName: department ? department.name : '',
                        department: department ? department.orgDepartmentCode : '',
                        office: employee.office,
                        firstName: employee.firstName ?? '',
                        middleName: employee.middleName ?? '',
                        lastName: employee.lastName ?? '',
                        ssn: employee.identification ?? '',
                        employeeId: employee.employeeId ?? '',
                        hireDate: employee.hireDate ? moment(employee.hireDate, DATE_FORMAT) : moment().format(DATE_FORMAT),
                        birthDay: employee.birthDay
                           ? moment(employee.birthDay, DATE_FORMAT)
                           : moment().subtract(18, 'years').format(DATE_FORMAT),
                        gender: employee.gender === 'M' ? 0 : employee.gender === 'F' ? 1 : 2,
                     },
                     contact: {
                        phone: employee.phone ?? '',
                        email: employee.email ?? '',
                        country: employee.country,
                        address: employee.address,
                        address2: employee.address2,
                        city: employee.city ?? '',
                        region: employee.region,
                        zipCode: employee.zipcode ?? '',
                     },
                     skills: employee.skills.map((skill) => skill.skillId),
                     clientCodes,
                     clientsCodesDeleted: [],
                     misc: {
                        jobLocation: employee.taxRegion,
                        maritalStatus: employee.maritalStatus,
                        juniorAllowances: employee.taxAllowanceJunior === null ? null : employee.taxAllowanceJunior,
                        seniorAllowances: employee.taxAllowanceSenior === null ? null : employee.taxAllowanceSenior,
                        drugTest: employee.drugTest ? 1 : 0,
                        drugTestDate: employee.drugTestDate ? moment(employee.drugTestDate, DATE_FORMAT) : moment().format(DATE_FORMAT),
                        idExpiration: employee.idExpiration ? moment(employee.idExpiration, DATE_FORMAT) : null,
                        multipleJobs: employee.multipleJobs ?? 0,
                        backgroundTest: employee.backgroundtest ? 1 : 0,
                        backgroundTestDate: employee.backgroundTest
                           ? moment(employee.backgroundTestDate, DATE_FORMAT)
                           : moment().format(DATE_FORMAT),
                        documents: employee.documents ?? '',
                        resume: employee.resume ?? '',
                        createUser: employee.createUser ?? false,
                        createUserEdit: employee.createUser ?? false,
                     },
                     fileUpdate,
                     fileUpdateDeleted: [],
                  };
                  setData(data);
                  setMessage(messageUpdate);
               }
            } catch (error) {
               setMessage(error);
            }
         }
      }
      trackPromise(fetch());
      return () => {
         isMounted = false;
      };
   }, [personId, messageUpdate, parametersFilterFind]);

   function loadTemporalInfo(checked) {
      if (!formikRef.current) return;
      if (offices.length > 0 && departments.length > 0) {
         let defaultDepartment = {};
         departments.map((department) => {
            if (department.default) {
               defaultDepartment = department;
            }
            return department;
         });
         const [mainOffice] = offices.filter((office) => office.name.toUpperCase().trim() === MAIN_OFFICE_NAME);
         if (checked && mainOffice) {
            formikRef.current.setFieldValue('information', {
               ...formikRef.current.values.information,
               department: defaultDepartment.orgDepartmentCode,
               departmentName: defaultDepartment.name,
            });
            formikRef.current.setFieldValue('contact', {
               ...formikRef.current.values.contact,
               phone: mainOffice.phone ?? '',
               address: mainOffice.address ?? '',
               address2: mainOffice.address2 ?? '',
               zipCode: mainOffice.zipcode ?? '',
               city: mainOffice.city ?? '',
               country: mainOffice.country ?? '',
               region: mainOffice.region ?? '',
            });
            formikRef.current.setFieldValue('misc', {
               ...formikRef.current.values.misc,
               maritalStatus: 1,
               jobLocation: mainOffice.region ?? '',
               idExpiration: moment().add(8, 'd').format(DATE_FORMAT) ?? '',
            });
         } else {
            formikRef.current.setFieldValue('information', {
               ...formikRef.current.values.information,
               department: '',
               departmentName: '',
               hireDate: moment().format(DATE_FORMAT),
               birthDay: moment().subtract(18, 'years').format(DATE_FORMAT),
            });
            formikRef.current.setFieldValue('contact', {
               ...formikRef.current.values.contact,
               phone: '',
               address: '',
               address2: '',
               zipCode: '',
               city: '',
               country: '',
               region: '',
            });
            formikRef.current.setFieldValue('misc', {
               ...formikRef.current.values.misc,
               maritalStatus: '',
               jobLocation: '',
               idExpiration: moment().add(8, 'd').format(DATE_FORMAT) ?? '',
               multipleJobs: 1,
            });
         }
         formikRef.current.setTouched('information', formikRef.current.values.information);
      }
   }

   const messageWarning = (message) => {
      return {
         status: 200,
         code: 'TE-E08',
         message,
      };
   };

   const handleTaxesSave = async (personId, regionId) => {
      if (Object.keys(taxValues).length === 0) {
         return { success: true };
      }

      const agency = JSON.parse(localStorage.getItem('agency'));
      const taxPayload = {
         orgId: agency.organizationEntity.orgId,
         personId,
         regionId,
         personTaxParams: Object.entries(taxValues).map(([taxParamsId, value]) => ({
            taxParamsId: parseInt(taxParamsId),
            value,
         })),
      };

      try {
         const { data: taxResponse } = await TempEdgeApi.post({
            url: REACT_APP_URL_TAXES_EMPLOYEE_SAVE,
            payload: taxPayload,
         });

         if (taxResponse.status !== 200 || taxResponse.code !== 'TE00') {
            return {
               success: false,
               error: messageWarning('com.tempedge.warn.taxerror'),
            };
         }

         return { success: true };
      } catch (error) {
         return {
            success: false,
            error: messageWarning('com.tempedge.warn.taxerror'),
         };
      }
   };

   async function handleSubmit(values) {
      setCustomMessage(null);
      if (values.edit && values.information.temporalInfo) return;

      try {
         const agency = JSON.parse(localStorage.getItem('agency'));
         const personCodeEntityList = [];
         for (const value of values.clientCodes) {
            if (value.client && value.code)
               personCodeEntityList.push({
                  clientPersonCodeId: value.clientPersonCodeId,
                  clientCode: value.code,
                  client: {
                     clientId: value.client,
                  },
               });
         }
         const dataSave = {
            orgId: agency.organizationEntity.orgId,
            personId,
            temporalInfo: values.information.temporalInfo,
            skills: values.skills.map((skill) => ({ skillId: skill })),
            address: values.contact?.address?.trim(),
            address2: values.contact?.address2?.trim(),
            backgroundtest: values.misc.backgroundTest === 1,
            backgroundTestDate: values.misc.backgroundTest === 1 ? moment(values.misc.backgroundTestDate).format(DATE_FORMAT) : null,
            birthDay: moment(values.information.birthDay).format(DATE_FORMAT),
            cellPhone: values.contact.phone,
            city: values.contact?.city?.trim(),
            country: values.contact.country,
            drugTest: values.misc.drugTest === 1,
            drugTestDate: values.misc.drugTest === 1 ? moment(values.misc.drugTestDate).format(DATE_FORMAT) : null,
            idExpiration: moment(values.misc.idExpiration).format(DATE_FORMAT),
            email: values.contact.email,
            empDepartment: values.information.department || values.information.departmentName,
            employeeId: values.information.employeeId,
            firstName: values.information?.firstName?.trim(),
            gender: values.information.gender === 0 ? 'M' : values.information.gender === 1 ? 'F' : null,
            hireDate: moment(values.information.hireDate).format(DATE_FORMAT),
            identification: values.information.ssn,
            lastName: values.information?.lastName?.trim(),
            maritalStatus: values.misc.maritalStatus,
            middleName: values.information?.middleName?.trim(),
            phone: values.contact.phone,
            region: values.contact.region,
            taxRegion: values.misc.jobLocation,
            taxAllowanceJunior: values.misc.juniorAllowances,
            taxAllowanceSenior: values.misc.seniorAllowances,
            multipleJobs: values.misc.multipleJobs,
            usrCreatedBy: agency.portalUserConfId,
            zipcode: values.contact.zipCode,
            docExt: null,
            resumeExt: null,
            createUser: !values.edit ? values.misc.createUser : !values.misc.createUserEdit ? values.misc.createUser : false,
            personType: {
               personTypeId: 1,
            },
            office: {
               officeId: values.information.office.officeId,
            },
            personCodeEntityList,
         };
         if (!values.edit) {
            const validationResponse = await TempEdgeApi.post({
               url: REACT_APP_URL_PERSON_VALIDATE,
               payload: { ...dataSave, personId: null },
            });
            if (validationResponse.data.status === 409) {
               setValuesSavePerson(values);
               setDataSave(validationResponse.data);
               setShowModal(true);
            } else {
               await savePerson(values);
            }
         } else {
            const { data } = await TempEdgeApi.post({
               url: REACT_APP_URL_PERSON_UPDATE,
               payload: dataSave,
            });
            if (data.status === 200 && data.code === 'TE00') {
               const person = data?.result?.personId;
           
               let clientPersonCodeList = [];
               
               try {
                  // Handle client codes deletion
                  if (person && values.clientsCodesDeleted.length > 0) {
                     clientPersonCodeList = values.clientsCodesDeleted.map((item) => {
                        return {
                           clientPersonCodeId: item.clientPersonCodeId,
                           clientCode: item.code,
                           client: {
                              clientId: item.client,
                           },
                           person: {
                              personId: person,
                           },
                        };
                     });
                     await TempEdgeApi.post({
                        url: REACT_APP_URL_CLIENT_CODE_DELETE,
                        payload: {
                           clientPersonCodeList,
                        },
                     });
                  }

                  // Handle file updates and deletions
                  if (person && values.fileUpdateDeleted.length > 0) {
                     for (let index = 0; index < values.fileUpdateDeleted.length; index++) {
                        const datas = {
                           moduleId: person,
                           module: 'com.tempedge.msg.menu.employee',
                           type: values?.fileUpdateDeleted[index]?.type,
                        };
                        if (typeof values.fileUpdateDeleted[index].file === 'string') {
                           const { data } = await TempEdgeApi.post({
                              url: REACT_APP_URL_COMMONS_FILE_DELETE,
                              payload: datas,
                           });
                           if (data.status !== 200 && data.code !== 'TE00') {
                             
                              setMessage(data);
                              values.fileUpdate = values.fileUpdateDeleted;
                              setData(values);
                              break;
                           }
                        }
                     }
                  }
                  if (person && values.fileUpdate.length > 0) {
                     for (let index = 0; index < values.fileUpdate.length; index++) {
                        const datasFile = {
                           orgId: agency.organizationEntity.orgId,
                           moduleId: person,
                           module: 'com.tempedge.msg.menu.employee',
                           extension: values.fileUpdate[index].fileNames?.split('.').pop(),
                           type: values?.fileUpdate[index]?.type,
                        };
                        if (typeof values.fileUpdate[index].file !== 'string') {
                           const requests = new FormData();
                           const stringDatas = JSON.stringify(datasFile);
                           const blobs = new Blob([stringDatas], {
                              type: 'application/json',
                           });
                           requests.append('data', blobs);
                           requests.append('file', values.fileUpdate[index].file);
                           const { data } = await TempEdgeApi.postMultipart({
                              url: REACT_APP_URL_COMMONS_FILE_UPDATE,
                              payload: requests,
                           });
                           if (data.status !== 200 && data.code !== 'TE00') {
                              
                              setMessage(data);
                              setData(values);
                              break;
                           }
                        }
                     }
                  }

                  // Handle tax saving
                  const taxResult = await handleTaxesSave(person, values.contact.region);
                  if (!taxResult.success) {
                     throw new Error('Tax save failed');
                  }

                  // If we reach here, everything succeeded
                  if (parametersFilterFind && parametersFilterFind.personType === '3') {
                     history.push('/employee/internal/list/en');
                  } else {
                     history.push('/employee/list/en');
                  }
                  dispatch(updateMessage(null));
                  dispatch(clearTempedgeStoreProp('parameters'));

               } catch (error) {
                  const errorMessage = error.message === 'Tax save failed' 
                     ? 'com.tempedge.warn.taxerror' 
                     : 'com.tempedge.warn.fileerror';
                  setMessage(messageWarning(errorMessage));
                  setData(values);
                  if (error.message === 'Tax save failed') {
                     setTaxValues((prev) => ({ ...prev, ...taxValues }));
                  }
               }
            } else {
               setMessage(data);
               setData(values);
            }
         }
      } catch (error) {
         setMessage(error);
         setData(values);
      } finally {
         setStep(1);
      }
   }

   const savePerson = async (values) => {
      const employeeData = valuesSavePerson || values;
      setShowModal(false);
      const agency = JSON.parse(localStorage.getItem('agency'));
      const personCodeEntityList = [];
      for (const value of values.clientCodes) {
         if (value.client && value.code)
            personCodeEntityList.push({
               clientPersonCodeId: value.clientPersonCodeId,
               clientCode: value.code,
               client: {
                  clientId: value.client,
               },
            });
      }
      const dataSave = {
         orgId: agency.organizationEntity.orgId,
         personId,
         temporalInfo: employeeData.information.temporalInfo,
         skills: employeeData.skills.map((skill) => ({ skillId: skill })),
         address: employeeData.contact?.address?.trim(),
         address2: employeeData.contact?.address2?.trim(),
         backgroundtest: employeeData.misc.backgroundTest === 1,
         backgroundTestDate:
            employeeData.misc.backgroundTest === 1 ? moment(employeeData.misc.backgroundTestDate).format(DATE_FORMAT) : null,
         birthDay: moment(employeeData.information.birthDay).format(DATE_FORMAT),
         cellPhone: employeeData.contact.phone,
         city: employeeData.contact?.city?.trim(),
         country: employeeData.contact.country,
         drugTest: employeeData.misc.drugTest === 1,
         drugTestDate: employeeData.misc.drugTest === 1 ? moment(employeeData.misc.drugTestDate).format(DATE_FORMAT) : null,
         idExpiration: moment(employeeData.misc.idExpiration).format(DATE_FORMAT),
         email: employeeData.contact.email,
         empDepartment: employeeData.information.department || employeeData.information.departmentName,
         employeeId: employeeData.information.employeeId,
         firstName: employeeData.information?.firstName?.trim(),
         gender: employeeData.information.gender === 0 ? 'M' : employeeData.information.gender === 1 ? 'F' : null,
         hireDate: moment(employeeData.information.hireDate).format(DATE_FORMAT),
         identification: employeeData.information.ssn,
         lastName: employeeData.information?.lastName?.trim(),
         maritalStatus: employeeData.misc.maritalStatus,
         middleName: employeeData.information?.middleName?.trim(),
         phone: employeeData.contact.phone,
         region: employeeData.contact.region,
         taxRegion: employeeData.misc.jobLocation,
         taxAllowanceJunior: employeeData.misc.juniorAllowances,
         taxAllowanceSenior: employeeData.misc.seniorAllowances,
         multipleJobs: employeeData.misc.multipleJobs,
         usrCreatedBy: agency.portalUserConfId,
         zipcode: employeeData.contact.zipCode,
         docExt: null,
         resumeExt: null,
         createUser: !employeeData.edit
            ? employeeData.misc.createUser
            : !employeeData.misc.createUserEdit
              ? employeeData.misc.createUser
              : false,
         personType: {
            personTypeId: 1,
         },
         office: {
            officeId: employeeData.information.office.officeId,
         },
         personCodeEntityList,
      };
      try {
         let fileorcodefix = 0;
         let taxError = 0;
         const { data } = await TempEdgeApi.post({
            url: REACT_APP_URL_PERSON_SAVE,
            payload: { ...dataSave, personId: null },
         });
         if (data.status !== 200 && data.code !== 'TE00') {
            setData(employeeData);
            setMessage(data);
         } else {
            const person = data?.result?.personId;
            if (employeeData?.fileUpdate?.length > 0) {
               for (let index = 0; index < employeeData.fileUpdate.length; index++) {
                  const datas = {
                     orgId: agency.organizationEntity.orgId,
                     moduleId: person,
                     module: 'com.tempedge.msg.menu.employee',
                     extension: employeeData?.fileUpdate[index]?.fileNames?.split('.').pop(),
                     type: employeeData?.fileUpdate[index]?.type,
                  };
                  const requests = new FormData();
                  const stringDatas = JSON.stringify(datas);
                  const blobs = new Blob([stringDatas], { type: 'application/json' });
                  requests.append('data', blobs);
                  requests.append('file', employeeData.fileUpdate[index].file);
                  const { data } = await TempEdgeApi.postMultipart({
                     url: REACT_APP_URL_COMMONS_FILE_UPDATE,
                     payload: requests,
                  });
                  if (data.status !== 200 && data.code !== 'TE00') {
                     fileorcodefix++;
                     break;
                  }
                  continue;
               }
            }

            const taxResult = await handleTaxesSave(person, values.contact.region);
            if (!taxResult.success) {
               taxError++;
               setMessage(taxResult.error);
            }

            if (fileorcodefix > 0 || taxError > 0) {
               setData(null);
               const errorMessage = fileorcodefix > 0 ? 'com.tempedge.warn.fileerror' : 'com.tempedge.warn.taxerror';
               setMessage(messageWarning(errorMessage));
            } else {
               if (agency?.organizationEntity?.autoEmployeeId) {
                  setCustomMessage(`EMPLOYEE ID : ${data?.result?.employeeId}`);
               }
               setMessage(data);
               setData(null);
               setValuesSavePerson(null);
            }
         }
      } catch (error) {
         setMessage(error);
         setData(employeeData);
      } finally {
         setStep(1);
      }
   };

   const handleCancel = () => {
      if (valuesSavePerson) {
         formikRef.current.setValues(valuesSavePerson);
      }
      setShowModal(false);
   };

   const handleModalSave = () => {
      savePerson(formikRef.current.values); // true indica saltarse la validación
   };

   const handleTaxValueChange = (taxParamsId, value) => {
      setTaxValues((prev) => ({
         ...prev,
         [taxParamsId]: value,
      }));
   };

   if (promiseInProgress) return <Loader />;
   let maritalStatus = true;
   return (
      <>
         <Stepper
            steps={[{ title: '' }, { title: '' }, { title: '' }, { title: '' }, { title: '' }, { title: '' }]}
            activeStep={step - 1}
            activeColor="#eb8d34"
            completeColor="#8cb544"
            defaultBarColor="#eb8d34"
            completeBarColor="#8cb544"
            barStyle="solid"
            circleFontSize={16}
         />
         <div
            style={{
               padding: '3rem',
               width: '90%',
               border: 'dotted 1px #888888',
               borderRadius: '1.6rem',
               backgroundColor: '#ffff',
               margin: 'auto',
               marginBottom: '1.6rem',
            }}
         >
            {message && <OutcomeBar response={message} customMessage={customMessage} />}
            <Formik
               innerRef={formikRef}
               enableReinitialize={true}
               initialValues={data || initialValues}
               validationSchema={CreateOrUpdateEmployeeSchema}
               onSubmit={async (values) => {
                  maritalStatus = maritalStatusFind.find((item) => {
                     return item.value === values?.misc?.maritalStatus?.toString();
                  });
                  if (maritalStatus.status === 'A') {
                     await trackPromise(handleSubmit(values));
                  }
               }}
            >
               {(formik) => {
                  return (
                     <form onSubmit={formik.handleSubmit}>
                        <Tabs id="controlled-tab-example" activeKey={step} onSelect={(step) => setStep(+step)} className="mb-3">
                           <Tab
                              eventKey="1"
                              title={props.translate('com.tempedge.msg.label.information')}
                              tabClassName={`${
                                 (formik.errors.information && formik.touched.information) ||
                                 (formik.values.edit && formik.values.information.temporalInfo)
                                    ? 'nav-error'
                                    : ''
                              }`}
                           >
                              <Information offices={offices} formik={formik} loadTemporalInfo={loadTemporalInfo} formatSSN={formatSSN} />
                           </Tab>
                           <Tab
                              eventKey="2"
                              title={props.translate('com.tempedge.msg.label.contact')}
                              tabClassName={`${
                                 (formik.errors.contact && formik.touched.contact) ||
                                 (formik.errors?.contact?.email && formik.touched?.contact?.email) ||
                                 formik?.errors?.email
                                    ? 'nav-error'
                                    : ''
                              }`}
                           >
                              <Contact countries={countries} formik={formik} internalDep={internalDep} setFormatSSN={setFormatSSN} />
                           </Tab>
                           <Tab
                              eventKey="3"
                              title={props.translate('com.tempedge.msg.label.taxes')}
                              tabClassName={`${Object.keys(formik.errors).some((error) => error.startsWith('tax_')) ? 'nav-error' : ''}`}
                           >
                              <StateTaxes
                                 formik={formik}                                
                                 onTaxValueChange={handleTaxValueChange}
                                 personId={personId}
                                 isEdit={formik.values.edit}
                                 setTaxValues={setTaxValues}
                                 taxValues={taxValues}
                              />
                           </Tab>
                           <Tab
                              eventKey="4"
                              title={props.translate('com.tempedge.msg.label.skills')}
                              tabClassName={`${formik.errors.skills && formik.touched.skills ? 'nav-error' : ''}`}
                           >
                              <Skills skills={skills} formik={formik} />
                           </Tab>
                           <Tab eventKey="5" title={props.translate('com.tempedge.msg.label.clientcode')}>
                              <ClientCode companies={clients} formik={formik} />
                           </Tab>
                           <Tab
                              eventKey="6"
                              title={props.translate('com.tempedge.msg.label.files')}
                              tabClassName={`${formik.errors.fileUpdate && formik.touched.fileUpdate ? 'nav-error' : ''}`}
                           >
                              <FileUpdate typeFile={fileType} formik={formik} />
                           </Tab>
                           <Tab
                              eventKey="7"
                              title={props.translate('com.tempedge.msg.label.misc')}
                              tabClassName={`${(formik.errors.misc && formik.touched.misc) || !maritalStatus ? 'nav-error' : ''}`}
                           >
                              <Misc
                                 countries={countries}
                                 formik={formik}
                                 setFieldValue={formik?.setFieldValue}
                                 maritalStatusFind={maritalStatusFind}
                                 setFieldError={formik.setFieldError}
                                 findMaritalStatus={findMaritalStatus}
                                 setFindMaritalStatus={setFindMaritalStatus}
                              />
                           </Tab>
                        </Tabs>
                        <div className="prev-next-btns-agency row mt-5" style={{ width: '-webkit-fill-available' }}>
                           <div className="col-md-5 offset-md-1">
                              <button
                                 type="button"
                                 className="btn btn-default btn-block register-save-btn previous"
                                 onClick={() => {
                                    if (formik.values.edit) {
                                       window.history.back();
                                       formik.resetForm();
                                       setData(null);
                                       setMessage(null);
                                       setTaxValues({});
                                    } else {
                                       formik.resetForm();
                                       setData(null);
                                       setStep(1);
                                       setMessage(null);
                                       setTaxValues({});
                                    }
                                 }}
                              >
                                 <Translate id="com.tempedge.msg.label.cancel" />
                              </button>
                           </div>
                           <div className="col-md-5">
                              <button type="submit" className="btn btn-primary btn-block register-save-btn next">
                                 <Translate id="com.tempedge.msg.label.save" />
                              </button>
                           </div>
                        </div>
                     </form>
                  );
               }}
            </Formik>
         </div>
         <Modal
            content={
               <ValidatePerson
                  dataSave={dataSave}
                  onCancel={handleCancel}
                  onSave={handleModalSave} // Aquii se utiliza la funcio para guardar la info
               />
            }
            open={showModal}
            onClose={() => setShowModal(false)}
         />
      </>
   );
}

export default withLocalize(FormEmployee);
