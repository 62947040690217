import { useEffect, useState } from 'react';
import { Translate } from 'react-localize-redux';
import SetTableHeadingAbsent from './SetTableHeadingAbsent';
import useHeaderDate from './useHeaderDate';

const AbsentPayrollForWeek = ({ employeesTimeEntry, dateStart }) => {
   const [findDate, setFindDate] = useState([]);

   const findDateArray = useHeaderDate(dateStart) ?? [];

   useEffect(() => {
      setFindDate(findDateArray);
   }, [findDateArray]);

   const absent = [];
   employeesTimeEntry.map((payroll) => {
      for (const [key, value] of Object.entries(payroll)) {
         if (key !== null) {
            if (value !== null) {
               if (value.totalRegHour === 0 && value.totalOtHour === 0) {
                  absent.push(payroll);
               }
            }
         }
      }
      return payroll;
   });

   if (absent.length > 0) {
      const newAbsent = absent.filter((value, index) => {
         return absent.indexOf(value) === index;
      });

      return (
         <div className='time-entry__body'>
            <div className="form-group col-12 col-lg-6">
               <p>
                  <Translate id={'com.tempedge.msg.label.peoplewithouthours'} />
                  <span className="badge">{newAbsent.length}</span>
               </p>
            </div>
            <div className="row">
               <div className="form-check col-12 col-lg-12">
                  <table className="responsive-table">
                     <thead>{<SetTableHeadingAbsent findDate={findDate}/>}</thead>
                     <tbody>
                        <>
                           {newAbsent.map((e, index) => {
                              return (
                                 <tr key={index + 1}>
                                    <th scope="row">{`${e?.person?.fullName}`}</th>
                                    <td data-title={`Deparment-Position`}>
                                       {e?.department !== null ? `${e?.department?.name} ${e?.position?.name}` : e?.position?.name}
                                    </td>
                                    {findDate.map((currentDay, index) => {
                                       const payrollDay = e[currentDay.day];
                                       return payrollDay && payrollDay?.totalRegHour === 0 && payrollDay.totalOtHour === 0 ? (
                                          <td
                                             key={index + 1}
                                             data-title={currentDay?.day}
                                             data-type="currency"
                                             className="day-column"
                                             data-initial={currentDay?.day.charAt(0).toUpperCase()}
                                          >
                                             <Translate id={'com.tempedge.msg.label.absent'} />
                                          </td>
                                       ) : (
                                          <td
                                             key={index + 1}
                                             data-title={currentDay?.day}
                                             data-type="currency"
                                             className="day-column"
                                             data-initial={currentDay?.day.charAt(0).toUpperCase()}
                                          ></td>
                                       );
                                    })}
                                 </tr>
                              );
                           })}
                        </>
                     </tbody>
                  </table>
               </div>
            </div>
         </div>
      );
   } else {
      return null;
   }
};

export default AbsentPayrollForWeek;
