import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Translate, withLocalize } from 'react-localize-redux';
import { addAbsentValidation } from '../../../../Redux/actions/payrollValidationActions';
import SetTableHeading from '../../PayrollByWeek/SetTableHeading';
import useHeaderDate from '../../PayrollByWeek/useHeaderDate';
import usePayrollTotals from './usePayrollTotals';
import PayrollRow from './PayrollRow';
import PayrollFooter from './PayrollFooter';
import { Col, Row } from 'react-bootstrap';
import Modal from '../../../../Modals/Modal/Modal';
import PayrollEditPayRate from '../../PayRollEditPayRate/PayrollEditPayRate';

const PayrollByWeekValidation = ({ payrollWeekValidation, dateStart, clientReportConf }) => {
   const dispatch = useDispatch();
   const findDate = useHeaderDate(dateStart);
   const [weekResults, setWeekResults] = useState([]);
   const [selectedEmployeeIndex, setSelectedEmployeeIndex] = useState(null);
   const totals = usePayrollTotals(payrollWeekValidation);

   useEffect(() => {
      const weekResul = Object.values(payrollWeekValidation).filter(
         (value) => value !== null && (value.employeeTotalRegHours !== 0 || value.employeeTotalOTHours !== 0),
      );
      setWeekResults(weekResul);

      const absent = payrollWeekValidation.filter((payroll) =>
         Object.values(payroll).some((value) => value?.totalRegHour === 0 && value?.totalOtHour === 0),
      );
      dispatch(addAbsentValidation(absent));
   }, [payrollWeekValidation, dispatch]);

   const highlightRow = (payroll, index) => {
      const rowId = `${payroll.person.personId}-${index}`;
      setSelectedEmployeeIndex(rowId);
   };

   const handleOpenPayRate =(payroll)=>{
    setModal({
      show: true,
      content: (
         <PayrollEditPayRate
            payrollWeekValidation={payrollWeekValidation}
            dateStart={dateStart}
            id={payroll}
            setModal={setModal}
         />
      ),
      size: 'modal-lg',
   });
   }

   const [modal, setModal] = useState({
      show: false,
      content: null,
      size: null,
   });


   return (
      <>
         <div className="row">
            <div className="col-4">
               <h2 className="section-title">
                  <Translate id={'com.tempedge.msg.label.hoursvalidation'} />
               </h2>
               <div className="badge-container">
                  <Translate id={'com.tempedge.msg.label.listsize'} />
                  <span className="badge">{weekResults.length}</span>
               </div>
            </div>
            <div className="col-8">
               <div className="row">
                  {/* Totals displayed here */}
                  {[
                     { label: 'reghours', value: totals.totalRegHours },
                     { label: 'othours', value: totals.totalOTHours },
                     { label: 'totalhours', value: totals.totalRegHours + totals.totalOTHours },
                     { label: 'billamount', value: totals.totalBillAmount },
                     { label: 'otbillamount', value: totals.totalOTBillAmount },
                     { label: 'totalamount', value: totals.totalAmount },
                  ].map(({ label, value }, idx) => (
                     <div key={idx} className="col-2">
                        <div>
                           <Translate id={`com.tempedge.msg.label.${label}`} />:
                        </div>
                        <div>{value && value.toFixed(2)}</div>
                     </div>
                  ))}
               </div>
            </div>
         </div>

         <Row>
            <Col sm={12} md={12} className="form-check">
               <table className="responsive-table">
                  <thead>
                     <SetTableHeading findDate={findDate} editPayrate={true} />
                  </thead>
                  <tbody>
                     {weekResults.map((payroll, index) => (
                        <PayrollRow
                           key={index}
                           payroll={payroll}
                           index={index}
                           findDate={findDate}
                           clientReportConf={clientReportConf}
                           highlightRow={highlightRow}
                           selectedEmployeeIndex={selectedEmployeeIndex}
                           handleOpenPayRate={handleOpenPayRate}
                        />
                     ))}
                     <PayrollFooter payrollWeekValidation={payrollWeekValidation} findDate={findDate} />
                  </tbody>
               </table>
            </Col>
         </Row>

         <Modal
            open={modal.show}
            content={modal.content}
            modalSize={modal.size ?? 'modal-sm'}
            onClose={() => setModal({ show: false, content: null, size: null })}
         />
      </>
   );
};

export default withLocalize(PayrollByWeekValidation);
