import { useEffect, useState } from 'react';
import { Translate, withLocalize } from 'react-localize-redux';
import DropdownList from '../../../components/common/Dropdown/DropdownList';
import { normalizeAddress, normalizePhone, normalizeZipCode, onlyLetters, validateEmail } from '../../../utils/ReGexs';

function Contact(props) {
   const [regionList, setRegionList] = useState([]);

   const initRegionList = props.countries.filter((country) => {
      return country.countryId === props.formik.values.contact.country;
   })[0];

   useEffect(() => {
      const country = props.countries.filter((country) => {
         return country.countryId === props.formik.values.contact.country;
      })[0];
      if (country) {
         setRegionList(country.regionEntityList);
      } else {
         setRegionList([]);
      }
   }, [props.countries, props.formik.values.contact.country]);

   return (
      <div className="tab">
         <div className="form-group row">
            <div className="col-10 col-md-5 col-lg-4">
               <label className="control-label">
                  <Translate id="com.tempedge.msg.label.phone" />
               </label>
               <input
                  className={`form-control tempEdge-input-box 
                    ${
                       props.formik.errors.contact &&
                       props.formik.touched.contact &&
                       props.formik.errors.contact.phone &&
                       props.formik.touched.contact.phone
                          ? 'has-error'
                          : ''
                    }`}
                  name={'phone'}
                  placeholder={props.translate('com.tempedge.msg.label.phone')}
                  type={'text'}
                  value={props.formik.values.contact.phone || ''}
                  onChange={(event) => {
                     event.target.value = normalizePhone(event.target.value);
                     props.formik.setFieldValue('contact.phone', event.target.value);
                  }}
               />
            </div>
            <div className="col-10 col-md-8">
               <label className="control-label">
                  <Translate id="com.tempedge.msg.label.email" />
               </label>
               <input
                  className={`form-control tempEdge-input-box 
                    ${
                       (props.formik.errors.contact &&
                          props.formik.touched.contact &&
                          props.formik.errors.contact.email &&
                          props.formik.touched.contact.email) ||
                       props.formik.errors?.contact?.email
                          ? 'has-error'
                          : ''
                    }`}
                  name={'email'}
                  type={'email'}
                  placeholder={props.translate('com.tempedge.msg.label.email')}
                  value={props.formik.values.contact.email || ''}
                  maxLength={120}
                  onChange={(event) => {
                     event.target.value = event.target.value.toUpperCase();
                     props.formik.setFieldValue('contact.email', event.target.value);
                  }}
                  onBlur={(event) => {
                     if (event.target.value.trim() !== '') {
                        if (!validateEmail(event.target.value)) {
                           props.formik.setFieldError('contact.email', 'required');
                        }
                     }
                  }}
               />
            </div>
         </div>
         <div className="form-group row">
            <div className="col-10 col-md-5 col-lg-4">
               <label className="control-label">
                  <Translate id="com.tempedge.msg.label.country" />
               </label>
               <DropdownList
                  name={'country'}
                  data={props.countries}
                  textField={'name'}
                  valueField={'countryId'}
                  value={props.formik.values.contact.country ? { countryId: props.formik.values.contact.country } : null}
                  isInvalid={
                     props.formik.errors.contact &&
                     props.formik.touched.contact &&
                     props.formik.errors.contact.country &&
                     props.formik.touched.contact.country
                  }
                  onChange={({ countryId }) => {
                     const country = props.countries.find((country) => country.countryId === countryId);
                     setRegionList(country.regionEntityList);
                     props.formik.setFieldValue('contact.country', countryId);
                     setRegionList(country.regionEntityList);
                     props.setFormatSSN(country.idFormat);
                  }}
               />
            </div>
         </div>
         <div className="form-group row">
            <div className="col-10 col-md-8">
               <label className="control-label">
                  <Translate id="com.tempedge.msg.label.address" />
               </label>
               <input
                  className={`form-control tempEdge-input-box 
                           ${
                              props.formik.errors.contact &&
                              props.formik.touched.contact &&
                              props.formik.errors.contact.address &&
                              props.formik.touched.contact.address
                                 ? 'has-error'
                                 : ''
                           }`}
                  type={'text'}
                  name={'address'}
                  placeholder={props.translate('com.tempedge.msg.label.address')}
                  value={props.formik.values.contact.address || ''}
                  maxLength={200}
                  onChange={(event) => {
                     event.target.value = normalizeAddress(event.target.value);
                     props.formik.setFieldValue('contact.address', event.target.value);
                  }}
               />
            </div>
            <div className="col-10 col-md-5 col-lg-4">
               <label className="control-label">
                  <Translate id="com.tempedge.msg.label.agencyaddress2" />
               </label>
               <input
                  className={'form-control tempEdge-input-box'}
                  name={'address2'}
                  type={'text'}
                  placeholder={props.translate('com.tempedge.msg.label.agencyaddress2')}
                  value={props.formik.values.contact.address2 || ''}
                  maxLength={50}
                  onChange={(event) => {
                     if (event.target.value.length > 50) {
                        event.target.value = event.target.value.slice(0, 50);
                     }
                     event.target.value = normalizeAddress(event.target.value);
                     props.formik.setFieldValue('contact.address2', event.target.value);
                  }}
               />
            </div>
         </div>
         <div className="form-group row">
            <div className="col-10 col-md-5 col-lg-4">
               <label className="control-label">
                  <Translate id="com.tempedge.msg.label.city" />
               </label>
               <input
                  className={`form-control tempEdge-input-box 
                       ${
                          props.formik.errors.contact &&
                          props.formik.touched.contact &&
                          props.formik.errors.contact.city &&
                          props.formik.touched.contact.city
                             ? 'has-error'
                             : ''
                       }`}
                  name="city"
                  type="text"
                  value={props.formik.values.contact.city}
                  onChange={(event) => {
                     event.target.value = onlyLetters(event.target.value).toUpperCase();
                     props.formik.setFieldValue('contact.city', event.target.value);
                  }}
                  placeholder={props.translate('com.tempedge.msg.label.city')}
               />
            </div>
            <div className="col-10 col-md-5 col-lg-4">
               <label className="control-label">
                  <Translate id="com.tempedge.msg.label.state" />
               </label>
               <DropdownList
                  data={regionList?.length === 0 ? initRegionList?.regionEntityList : regionList}
                  textField={'name'}
                  valueField={'regionId'}
                  value={props.formik.values.contact.region ? { regionId: props.formik.values.contact.region } : null}
                  isInvalid={
                     props.formik.errors.contact &&
                     props.formik.touched.contact &&
                     props.formik.errors.contact.region &&
                     props.formik.touched.contact.region
                  }
                  onChange={({ regionId }) => {
                     props.formik.setFieldValue('contact.region', regionId);
                  }}
               />
            </div>
            <div className="col10 col-md-5 col-lg-4">
               <label className="control-label">
                  <Translate id="com.tempedge.msg.label.agencyzipcode" />
               </label>
               <input
                  className={`form-control tempEdge-input-box 
                       ${
                          props.formik.errors.contact &&
                          props.formik.touched.contact &&
                          props.formik.errors.contact.zipCode &&
                          props.formik.touched.contact.zipCode
                             ? 'has-error'
                             : ''
                       }`}
                  name={'zip'}
                  type={'text'}
                  placeholder={props.translate('com.tempedge.msg.label.agencyzipcode')}
                  value={props.formik.values.contact.zipCode || ''}
                  onChange={(event) => {
                     event.target.value = normalizeZipCode(event.target.value);
                     props.formik.setFieldValue('contact.zipCode', event.target.value);
                  }}
               />
            </div>
         </div>
      </div>
   );
}

export default withLocalize(Contact);
