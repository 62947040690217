import { Form, Formik } from "formik";
import React, { useState } from "react"; 
import { Translate, withLocalize } from "react-localize-redux";
import { useHistory } from "react-router-dom";
import ContainerBlue from "../../../components/common/Container/ContainerBlue";
import Datepicker from "../../../components/common/Datepicker/Datepicker";
import DropdownList from "../../../components/common/Dropdown/DropdownList";
import OutcomeBar from "../../../components/common/OutcomeBar";
import { validateEmail } from "../../../utils/ReGexs";
import { onlyLetters, userLetters } from "../../../utils/stringConversion";
import { Schema } from "./Schema";
import eye_open from '../../Login/assets/eye_open.png';
import eye_close from '../../Login/assets/eye_close.png';

function WizardFirstPage({
  translate,
  next,
  changeFormikValues,
  data,
  roles,
  message,
  resetFormikValues
}) {
  const history = useHistory();
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  
  return (
    <ContainerBlue
      title={
        data?.edit
        ? "com.tempedge.msg.title.updateaccount"
          : "com.tempedge.msg.title.newaccount"
      }
      width={1280}
    >
      {message && <OutcomeBar response={message} />}
      <Formik
        enableReinitialize
        initialValues={data}
        validationSchema={Schema}
        onSubmit={(values) => {
          changeFormikValues(values);
          next();
        }}
      >
        {({ errors, touched, values, setFieldValue, resetForm,setFieldError }) => (
          <Form>
            <div className="form-group row">
              <div className="col-md-4">
                <label className="control-label">
                  {translate("com.tempedge.msg.label.firstname")}
                </label>
                <input
                  className={`form-control tempEdge-input-box 
                            ${
                              errors.account &&
                              touched.account &&
                              errors.account.firstName &&
                              touched.account.firstName
                                ? "has-error"
                                : ""
                            }`}
                  name="firstName"
                  type="text"
                  value={values.account.firstName || ""}
                  placeholder={translate("com.tempedge.msg.label.firstname")}
                  onChange={(event) => {
                    event.target.value = onlyLetters(
                      event.target.value
                    ).toUpperCase();
                    setFieldValue("account.firstName", event.target.value);
                  }}
                />
              </div>
              <div className="col-md-4">
                <label className="control-label">
                  {translate("com.tempedge.msg.label.middlename")}
                </label>
                <input
                  className={`form-control tempEdge-input-box 
                            ${
                              errors.account &&
                              touched.account &&
                              errors.account.middleName &&
                              touched.account.middleName
                                ? "has-error"
                                : ""
                            }`}
                  name="middlename"
                  type="text"
                  placeholder={translate("com.tempedge.msg.label.middlename")}
                  value={values.account.middleName || ""}
                  onChange={(event) => {
                    event.target.value = onlyLetters(
                      event.target.value
                    ).toUpperCase();
                    setFieldValue("account.middleName", event.target.value);
                  }}
                />
              </div>
              <div className="col-md-4">
                <label className="control-label">
                  {translate("com.tempedge.msg.label.lastname")}
                </label>
                <input
                  className={`form-control tempEdge-input-box 
                            ${
                              errors.account &&
                              touched.account &&
                              errors.account.lastName &&
                              touched.account.lastName
                                ? "has-error"
                                : ""
                            }`}
                  name="lastname"
                  type="text"
                  value={values.account.lastName || ""}
                  placeholder={translate("com.tempedge.msg.label.lastname")}
                  onChange={(event) => {
                    event.target.value = onlyLetters(
                      event.target.value
                    ).toUpperCase();
                    setFieldValue("account.lastName", event.target.value);
                  }}
                />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-md-4">
                <label className="control-label">
                  {translate("com.tempedge.msg.label.gender")}
                </label>
                <DropdownList
                  data={[
                    {
                      value: 0,
                      label: translate("com.tempedge.msg.label.gender.male"),
                    },
                    {
                      value: 1,
                      label: translate("com.tempedge.msg.label.gender.female"),
                    },
                    {
                      value: 2,
                      label: translate("com.tempedge.msg.label.select"),
                    },
                  ]}
                  valueField="value"
                  textField="label"
                  value={
                    values.account.gender !== ""
                      ? { value: values.account.gender }
                      : null
                  }
                  onChange={(gender) => {
                    setFieldValue("account.gender", gender.value);
                  }}
                  isInvalid={
                    errors.account &&
                    touched.account &&
                    errors.account.gender &&
                    touched.account.gender
                  }
                  name={"gender"}
                />
              </div>
              <div className="col-md-4">
                <label className="control-label">
                  {translate("com.tempedge.msg.label.email")}
                </label>
                <input
                  className={`form-control tempEdge-input-box 
                                ${
                                  errors.account &&                                  
                                  errors.account.email                                  
                                    ? "has-error"
                                    : ""
                                }`}
                  name="email"
                  type="email"
                  value={values.account.email || ""}
                  placeholder={translate("com.tempedge.msg.label.email")}
                  onChange={(event) => {
                    event.target.value = event.target.value.toUpperCase();
                    setFieldValue("account.email", event.target.value);
                  }}
                  onBlur={(event) => {
                    if (event.target.value.trim() !== "") {
                      if (!validateEmail(event.target.value)) {
                        setFieldError("account.email", 'required');
                      }
                    }
                  }}
                />
              </div>
              <div className="col-md-4">
                <label className="control-label">
                  {translate("com.tempedge.msg.label.birthday")}
                </label>
                <Datepicker
                  customClass={`form-control tempEdge-input-box
                                     ${
                                       errors.account &&
                                       touched.account &&
                                       errors.account.birthDay &&
                                       touched.account.birthDay
                                         ? "has-error"
                                         : ""
                                     }`}
                  selectedValue={values.account.birthDay}
                  onChange={(date) => {
                    setFieldValue("account.birthDay", date);
                  }}
                />
              </div>
            </div>
            {/* this is client or offices information */}
            {!data.edit && <div className="form-group row ">
              <div className="col-md-4">
                <label className="control-label top-label-agency-form">
                  {translate("com.tempedge.msg.label.role")}
                </label>
                <DropdownList
                  data={roles}
                  valueField="roleId"
                  textField="name"
                  value={values.user.role}
                  onChange={(role) => {
                    setFieldValue("user.role", role);
                  }}
                  isInvalid={
                    errors.user &&
                    touched.user &&
                    errors.user.role &&
                    touched.user.role
                  }
                  name={"role"}
                />
              </div>              
              <div className="col-md-4 ">
                <label className="control-label">
                  {translate("com.tempedge.msg.label.organization")}
                </label>
                <input
                  className={`form-control tempEdge-input-box 
                             ${
                               errors.user &&
                               touched.user &&
                               errors.user.orgName &&
                               touched.user.orgName
                                 ? "has-error"
                                 : ""
                             }`}
                  name="orgName"
                  type="text"
                  placeholder={translate("com.tempedge.msg.label.organization")}
                  value={values.user.orgName}
                  onChange={(event) => {
                    event.target.value = onlyLetters(
                      event.target.value
                    ).toUpperCase();
                    setFieldValue("user.orgName", event.target.value);
                  }}
                />
              </div>
              <div className="col-md-4 ">
                {values.user.role &&
                  values.user.role.name &&
                  values.user.role.name.indexOf("CLIENT") >= 0 && (
                    <>
                      <label className="control-label">
                        {translate("com.tempedge.msg.label.client")}
                      </label>
                      <input
                        className={`form-control tempEdge-input-box 
                                     ${
                                       errors.user &&
                                       touched.user &&
                                       errors.user.agencyclient &&
                                       touched.user.agencyclient
                                         ? "has-error"
                                         : ""
                                     }`}
                        name="agencyclient"
                        type="text"
                        placeholder={translate("com.tempedge.msg.label.client")}
                        value={values.user.agencyclient || ""}
                        onChange={(event) => {
                          event.target.value = onlyLetters(
                            event.target.value
                          ).toUpperCase();
                          setFieldValue(
                            "user.agencyclient",
                            event.target.value
                          );
                        }}
                      />
                    </>
                  )}
                {values.user.role &&
                  values.user.role.name &&
                  values.user.role.name.indexOf("CLIENT") < 0 && (
                    <>
                      <label className="control-label">
                        {translate("com.tempedge.msg.label.office")}
                      </label>
                      <input
                        className={`form-control tempEdge-input-box 
                                       ${
                                         errors.user &&
                                         touched.user &&
                                         errors.user.agencyoffice &&
                                         touched.user.agencyoffice
                                           ? "has-error"
                                           : ""
                                       }`}
                        name="office"
                        type="text"
                        value={values.user.agencyoffice || ""}
                        placeholder={translate("com.tempedge.msg.label.office")}
                        onChange={(event) => {
                          event.target.value = onlyLetters(
                            event.target.value
                          ).toUpperCase();
                          setFieldValue(
                            "user.agencyoffice",
                            event.target.value
                          );
                        }}
                      />
                    </>
                  )}
              </div>
            </div>}
            {/* this is username and password */}
            <div className="form-group row">
              <div className="col-md-4">
                <label className="control-label">
                  {translate("com.tempedge.msg.label.username")}
                </label>
                <input
                  className={`form-control tempEdge-input-box 
                                ${errors.account &&
                      touched.account &&
                      errors.account.username &&
                      touched.account.username
                      ? "has-error"
                      : ""
                    }`}
                  name="email"
                  type="text"
                  maxLength={"30"}
                  autoComplete="new-username"
                  disabled={values.edit}
                  placeholder={translate("com.tempedge.msg.label.username")}
                  value={values.account.username}
                  onChange={(event) => {
                    event.target.value = userLetters(
                      event.target.value
                    ).toUpperCase();
                    setFieldValue("account.username", event.target.value);
                  }}
                />
              </div>
              <div className="col-md-4" style={{ position: "relative" }}>
                <label className="control-label">
                  {translate("com.tempedge.msg.label.password")}
                </label>
                <input
                  className={`form-control tempEdge-input-box ${errors.account &&
                      touched.account &&
                      errors.account.password &&
                      touched.account.password
                      ? "has-error"
                      : ""
                    }`}
                  name="password"
                  type={showNewPassword ? "text" : "password"}
                  pattern="^(?=.*\d)(?=.*[\u0021-\u002b\u003c-\u0040])(?=.*[A-Z])(?=.*[a-z])\S{10,16}$"
                  autoComplete="new-password"
                  disabled={values.edit}
                  value={values.account.password}
                  placeholder={translate("com.tempedge.msg.label.password")}
                  onChange={(event) => {
                    setFieldValue("account.password", event.target.value);
                  }}
                />
                <img
                  src={showNewPassword ? eye_open : eye_close}
                  className="password-icon"
                  onClick={() => setShowNewPassword(!showNewPassword)}
                  alt="Toggle Password Visibility"
                  style={{ marginRight: '10px' }}
                />
              </div>

              <div className="col-md-4" style={{ position: "relative" }}>
                <label className="control-label">
                  {translate("com.tempedge.msg.label.confirmpassword")}
                </label>
                <input
                  className={`form-control tempEdge-input-box ${errors.account &&
                      touched.account &&
                      errors.account.passwordConfirmation &&
                      touched.account.passwordConfirmation
                      ? "has-error"
                      : ""
                    }`}
                  name="passwordConfirmation"
                  type={showConfirmPassword ? "text" : "password"}
                  disabled={values.edit}
                  pattern="^(?=.*\d)(?=.*[\u0021-\u002b\u003c-\u0040])(?=.*[A-Z])(?=.*[a-z])\S{10,16}$"
                  value={values.account.passwordConfirmation}
                  placeholder={translate("com.tempedge.msg.label.confirmpassword")}
                  onChange={(event) => {
                    setFieldValue("account.passwordConfirmation", event.target.value);
                  }}
                />
                <img
                  src={showConfirmPassword ? eye_open : eye_close}
                  className="password-icon"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  alt="Toggle Password Visibility"
                  style={{ marginRight: '10px' }}
                />
              </div>
            </div>
            {/* bottons */}
            <div
              className="row py-4 mt-5"
              style={{ justifyContent: "space-evenly" }}
            >
              <div className="col-md-5">
                <button
                  type="button"
                  className="btn btn-default btn-block register-save-btn previous"
                  onClick={() => {
                    if (values.edit) {
                      resetFormikValues();
                      history.push(`/user/list/en`);
                    } else {
                      resetForm();
                      resetFormikValues();
                    }
                  }}
                >
                  <Translate id="com.tempedge.msg.label.cancel" />
                </button>
              </div>
              <div className="col-md-5">
                <button
                  type="submit"
                  className="btn btn-primary btn-block register-save-btn next"
                >
                  <Translate id="com.tempedge.msg.label.next" />
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </ContainerBlue>
  );
}

export default withLocalize(WizardFirstPage);
