import moment from 'moment';
import { useState } from 'react';
import { Translate } from 'react-localize-redux';
import Modal from '../../../Modals/Modal/Modal';
import { TotalHourByWeekDay, TotalOtHourWeek, TotalRegHourWeek } from '../../../utils/helpers/TotalHoursFormat';
import edit from '../../../assets/icons/edit.png';
import PayrollEditPayRate from '../PayRollEditPayRate/PayrollEditPayRate';
import useHeaderDate from '../PayrollByWeek/useHeaderDate';
import TableHeading from '../PayrollValidation/PayrollByWeekValidation/TableHeading';
import '../../../assets/styles/components/tablepayroll.css';
import '../../../assets/styles/components/table-inputs.css';


const PayrollHistoryTablet = ({ payrollWeekDtos = [], dateStart }) => {
   const [modal, setModal] = useState({
      show: false,
      content: null,
      size: null,
   });
   const findDate = useHeaderDate(payrollWeekDtos[0].startDate || dateStart);
   const editPayRate = true;
   return (
      <>
         <div className="row">
            <div className="form-check col-12 col-lg-12">
               <table className="table table-striped table-input-group">
                  <thead>{<TableHeading findDate={findDate} editPayrate={editPayRate} />}</thead>
                  <tbody>
                     {payrollWeekDtos?.map((e, index) => {
                        return (
                           <tr key={index + 1}>
                              <td>{e.department !== null ? `${e.department?.name} - ${e.position?.name}` : e.position?.name}</td>
                              {findDate.map((currentDay, index) => {
                                 const payrollDay = e[currentDay.day];

                                 if (
                                    payrollDay &&
                                    payrollDay.dateList &&
                                    moment(payrollDay.dateList).format('MM/DD/YYYY') === currentDay.date &&
                                    (payrollDay.totalRegHour !== null || payrollDay.totalOtHour !== null)
                                 ) {
                                    return (
                                       <td key={index + 1} className="text-center" style={{ fontSize: '12px' }}>
                                          {payrollDay.totalRegHour !== null || payrollDay.totalOtHour !== null
                                             ? `RG: ${payrollDay.totalRegHour?.toFixed(2) ?? '0.00'} - ${
                                                  payrollDay.totalOtHour?.toFixed(2) ?? '0.00'
                                               } : OT`
                                             : null}
                                       </td>
                                    );
                                 } else {
                                    return <td key={index + 1}></td>;
                                 }
                              })}
                              <td className="text-center">{e.totalRegHours ? e.totalRegHours.toFixed(2) : 0}</td>
                              <td className="text-center">{e.totalOTHours ? e.totalOTHours.toFixed(2) : 0}</td>
                              <td className="text-center">
                                 <button
                                    type="button"
                                    className="btn"
                                    onClick={() => {
                                       setModal({
                                          show: true,
                                          content: (
                                             <PayrollEditPayRate
                                                payrollWeekValidation={payrollWeekDtos}
                                                dateStart={payrollWeekDtos[0].startDate}
                                                id={e?.id}
                                                setModal={setModal}
                                                editPayrate={true}
                                             />
                                          ),
                                          size: 'modal-lg',
                                       });
                                    }}
                                 >
                                    <img className="icon_edit" src={edit} alt="edit icon" />
                                 </button>
                              </td>
                           </tr>
                        );
                     })}
                     <tr className="tableRow text-center">
                        <td className="table-content text-uppercase text-right">
                           <Translate id={'com.tempedge.msg.label.total'} />
                        </td>
                        {findDate.map((currentDay, index) => {
                           return (
                              <td key={index + 1} className="table-content">
                                 {TotalHourByWeekDay(payrollWeekDtos, currentDay.day)}
                              </td>
                           );
                        })}

                        <td className="table-content">{TotalRegHourWeek(payrollWeekDtos)}</td>
                        <td className="table-content">{TotalOtHourWeek(payrollWeekDtos)}</td>
                        <td className="table-content"></td>
                     </tr>
                  </tbody>
               </table>
            </div>
         </div>
         <Modal
            open={modal.show}
            content={modal.content}
            onClose={() => setModal({ show: false, content: null, size: null })}
            modalSize={modal.size ?? 'modal-sm'}
         />
      </>
   );
};

export default PayrollHistoryTablet;
