import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateCustomPayrollEntry } from '../../../Redux/actions/customPayrollActions';
import { Translate } from 'react-localize-redux';
import Tooltip_ from '../../../components/common/Tooltip';
import { isValidNumberWithMax, onlySingleNumbers } from '../../../utils/ReGexs';

const CellTable = ({ payroll, label, weekActive, setOpenInfo, errorsCustom, entryType, customPayrollList }) => {
   const dispatch = useDispatch();
   const [amount, setAmount] = useState('');
   const [hours, setHours] = useState('');
   const [relatedEntriesFind, setRelatedEntriesFind] = useState([]);
   const [error, setError] = useState({ hours: false, amount: false });

   const sectionAmount = entryType === 'pay' ? 'amountPay' : 'amountBill';
   const sectionHours = entryType === 'pay' ? 'hoursPay' : 'hoursBill';
   const sectionCode = entryType === 'pay' ? 'payCode' : 'billingCode';
   const sectionRate = entryType === 'pay' ? 'payRate' : 'billRate';

   const initialValuesEntry = {
      _idHeader: label._idHeader,
      id: payroll.id,
      personId: payroll.person?.personId || payroll.personId,
      employeeId: payroll.employeeId,
      [sectionCode]: {
         customPayrollTypeId: label.customPayrollTypeId,
         reportTitle: label.title,
      },
      person: {
         personId: payroll.person?.personId || payroll.personId,
      },
      sepCheck: false,
      sepInvoice: false,
      dateList: weekActive?.end,
      [sectionRate]: entryType === 'pay' ? payroll?.rate : payroll?.bill,
      entryType,
      department: payroll.department,
      position: payroll.position,
      payRate: payroll.rate,
      billRate: payroll.bill,
   };

   // Validar si existe un error en la entrada
   useEffect(() => {
      if (errorsCustom.length > 0) {
         const entryError = errorsCustom.find((entry) => entry.id === payroll.id && entry._idHeader === label._idHeader);
         if (entryError) {
            if (entryError.type === entryType) {
               setError((prevState) => ({ ...prevState, [entryError.hours]: true, [entryError.amount]: true }));
            }
         } else {
            setError({ hours: false, amount: false });
         }
      } else {
         setError({ hours: false, amount: false });
      }
   }, [errorsCustom, customPayrollList]);

   const handleClick = () => {
      setOpenInfo({ employee: payroll.person || payroll, label, relatedEntriesFind });
   };

   useEffect(() => {
      // Filtrar las entradas relacionadas con la persona, tipo de custom payroll y el header de la tabla
      const relatedEntries = customPayrollList.filter(
         (item) =>
            item.id === payroll.id &&
            item[sectionCode]?.customPayrollTypeId === label.customPayrollTypeId &&
            item._idHeader === label._idHeader,
      );

      setRelatedEntriesFind(relatedEntries);

      const amountEntry = relatedEntries.find((entry) => entry[sectionAmount] != null && entry[sectionAmount] !== '');
      if (amountEntry) {
         const amountValue = parseFloat(amountEntry[sectionAmount]);
         setAmount(!isNaN(amountValue) ? amountValue.toFixed(2) : '');
      } else {
         setAmount('');
      }

      const hoursEntry = relatedEntries.find((entry) => entry[sectionHours] != null && entry[sectionHours] !== '');
      if (hoursEntry) {
         const hoursValue = parseFloat(hoursEntry[sectionHours]);
         setHours(!isNaN(hoursValue) ? hoursValue.toFixed(2) : '');
      } else {
         setHours('');
      }
   }, [customPayrollList, payroll.person?.personId, label.customPayrollTypeId, label._idHeader, payroll.id]);

   const handleBlur = (field, value) => {
      const isValid = (val) => val === 0 || val === '' || (field === 'hours' ? isValidNumberWithMax(val, 3) : isValidNumberWithMax(val, 4));
      const hasError = !isValid(value);

      setError((prevState) => ({ ...prevState, [field]: hasError }));
      if (hasError) return;

      const section = field === 'hours' ? sectionHours : sectionAmount;

      const updatedEntry = {
         ...initialValuesEntry,
         [section]: parseFloat(value) || value,
      };

      dispatch(updateCustomPayrollEntry(updatedEntry));
   };

   return (
      <td className="text-center mx-auto">
         <div className="form-group-payroll">
            <span>RH</span>
            <input
               type="text"
               placeholder="0.0"
               value={hours}
               onChange={(e) => {
                  const value = e.target.value;
                  if (onlySingleNumbers.test(value)) {
                     setHours(value);
                  }
               }}
               onBlur={(e) => handleBlur('hours', e.target.value)}
               className={`form-field-payroll text-center mx-auto ${error.hours ? 'has-error' : ''}`}
            />
            <input
               type="text"
               placeholder="0.0"
               value={amount}
               onChange={(e) => {
                  const value = e.target.value;
                  if (onlySingleNumbers.test(value)) {
                     setAmount(value);
                  }
               }}
               onBlur={(e) => handleBlur('amount', e.target.value)}
               className={`form-field-payroll text-center mx-auto ${error.amount ? 'has-error' : ''}`}
            />
            <span>AMT</span>
            <div onClick={handleClick} className="mt-1 mx-auto text-center ml-1">
               <Tooltip_
                  translate={<Translate id="com.tempedge.msg.tooltip.custompayroll" />}
                  iconWidth={'20px'}
                  iconHeight={'20px'}
                  iconFloat={'right'}
               />
            </div>
         </div>
      </td>
   );
};

export default CellTable;
