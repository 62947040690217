import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { changeHoursPayrollValidation, changeHoursPayrollValidationLocked } from '../../../../Redux/actions/payrollValidationActions';
import { roundToTwoDecimals } from '../../../../utils/helpers/TotalHoursFormat';
import '../../../../assets/styles/components/table-inputs.css';

const CellPayrollValidation = ({ payroll, day }) => {
   const [totalOtHour, setTotalOtHour] = useState('');
   const [totalRegHour, setTotalRegHour] = useState('');
   const [payrollDisable, setPayrollDisable] = useState(true);
   const [lockPayroll, SetLockPayroll] = useState(false);

   useEffect(() => {
      const roundedTotalRegHour = roundToTwoDecimals(payroll[day]?.totalRegHour);
      const roundedTotalOtHour = roundToTwoDecimals(payroll[day]?.totalOtHour);
      setTotalOtHour(roundedTotalOtHour);
      setTotalRegHour(roundedTotalRegHour);
      Object.entries(payroll).forEach(([key, value]) => {
         if (key !== null) {
            if (value?.lockPayroll) {
               SetLockPayroll(true);
            }
         }
      });
   }, [payroll, day]);

   const dispatch = useDispatch();
   const changeTimeEntry = (values, key) => {
      setPayrollDisable(false);
      const data = {
         payrollId: payroll[day].payrollId,
         id: payroll.id,
         key,
         value: values || 0,
         day,
      };
      const regularExpression = /^-?\d{0,3}(\.\d{0,2})?$/;
      if (regularExpression.test(values)) {
         if (lockPayroll) {
            dispatch(changeHoursPayrollValidationLocked(data));
         } else {
            dispatch(changeHoursPayrollValidation(data));
         }
      }
   };

   const changeDisable = () => {
      if (payroll[day]?.lockPayroll) {
         setPayrollDisable(true);
      } else {
         setPayrollDisable(false);
      }
   };

   if (payroll[day] !== null && (payroll[day].totalRegHour !== 0 || payroll[day].totalOtHour !== 0)) {
      return lockPayroll && payroll[day]?.lockPayroll ? (
         <td
            data-title={day}
            data-type="currency"
            data-initial={day.charAt(0).toUpperCase()} // Añade la inicial del día
         >
            {`RG ${payroll[day].totalRegHour.toFixed(2)} - ${payroll[day].totalOtHour.toFixed(2)} OT`}
         </td>
      ) : (
         <td
            data-title={day}
            data-type="currency"
            data-initial={day.charAt(0).toUpperCase()} // Añade la inicial del día
         >
            <div className="form-group-payroll">
               <span>RG</span>
               <input
                  onClick={() => changeDisable()}
                  type="text"
                  step="0.01"
                  className="form-field-payroll text-center"
                  placeholder="0.00"
                  aria-label="totalRegHour"
                  aria-describedby="basic-addon1"
                  value={totalRegHour}
                  onBlur={() => changeTimeEntry(totalRegHour, 'totalRegHour')}
                  onChange={(event) => {
                     const inputValue = event.target.value;
                     // Regular expression to match the desired format
                     const regex = /^-?\d{0,3}(\.\d{0,2})?$/;
                     if (regex.test(inputValue) || inputValue === '') {
                        setTotalRegHour(inputValue);
                     }
                  }}
                  readOnly={payrollDisable}
               />
               <input
                  onClick={() => changeDisable()}
                  type="number"
                  step="0.01"
                  className="form-field-payroll text-center"
                  placeholder="0.00"
                  aria-label="totalOtHour"
                  aria-describedby="basic-addon2"
                  value={totalOtHour}
                  onBlur={() => changeTimeEntry(totalOtHour, 'totalOtHour')}
                  onChange={(event) => {
                     const inputValue = event.target.value;
                     // Regular expression to match the desired format
                     const regex = /^-?\d{0,3}(\.\d{0,2})?$/;
                     if (regex.test(inputValue) || inputValue === '') {
                        setTotalOtHour(inputValue);
                     }
                  }}
                  readOnly={payrollDisable}
               />
               <span>OT</span>
            </div>
         </td>
      );
   } else {
      return (
         <td
            data-title={day}
            data-type="currency"
            data-initial={day.charAt(0).toUpperCase()} // Añade la inicial del día
         >
            <p className="subtitle-payroll">{''}</p>
         </td>
      );
   }
};

export default CellPayrollValidation;
