// Definimos un array con los días de la semana empezando por Domingo
const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

const payrollCycle = daysOfWeek.map((day, index) => {
   // Calculamos el día siguiente de manera circular
   const nextDay = daysOfWeek[(index - 1 + daysOfWeek.length) % daysOfWeek.length];

   return {
      payrollCycle: `${day} - ${nextDay}`,
      payrollId: index,
   };
});

export default payrollCycle;
