import React from "react";
import { FiDownload } from "react-icons/fi";
import "./DownloadFileIcon.css";

const DownloadFile = ({ file, fileName, label = "com.tempedge.msg.label.download" }) => {
  const handleDownload = (fileData) => {
    let mimeType = "application/octet-stream";
    if (fileName) {
      const lowerName = fileName.toLowerCase();
      if (lowerName.includes(".pdf")) {
        mimeType = "application/pdf";
      } else if (lowerName.includes(".doc") || lowerName.includes(".docx")) {
        mimeType = "application/msword";
      }
    }
    const linkSource = `data:${mimeType};base64,${fileData}`;
    const link = document.createElement("a");
    link.href = linkSource;
    link.download = fileName;
    link.target = "_blank";
    link.click();
  };

  return (
    <div className="download-file">
      <button type="button" onClick={() => handleDownload(file)} className="download-btn">
        <FiDownload />
      </button>
    </div>
  );
};

export default DownloadFile;
