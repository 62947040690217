import { Translate } from 'react-localize-redux';
import SetTableHeadingAbsent from '../../PayrollByWeek/SetTableHeadingAbsent';
import useHeaderDate from '../../PayrollByWeek/useHeaderDate';
import { Col, Row } from 'react-bootstrap';

const AbsentValidation = ({ payrollWeekValidation, dateStart }) => {
   const findDate = useHeaderDate(dateStart);

   const absent = [];
   payrollWeekValidation.map((payroll) => {
      for (const [key, value] of Object.entries(payroll)) {
         if (key !== null) {
            if (value !== null) {
               if (value.totalRegHour === 0 && value.totalOtHour === 0) {
                  absent.push(payroll);
               }
            }
         }
      }
      return payroll;
   });

   if (absent.length > 0) {
      const newAbsent = absent.filter((value, index) => {
         return absent.indexOf(value) === index;
      });

      return (
         <div className="time-entry__body">
            <div className="form-group col-12 col-lg-6">
               <p style={{ textAlign: 'left' }}>
                  <Translate id={'com.tempedge.msg.label.peoplewithouthours'} /> <span className="badge">{newAbsent.length}</span>{' '}
               </p>
               <p style={{ color: 'red' }}>
                  <Translate id={'com.tempedge.msg.label.notetransportationcost'} />
               </p>
            </div>
            <Row>
                <Col sm={12} md={12} className="form-check">
                  <table className="responsive-table">
                     <thead>
                        <SetTableHeadingAbsent findDate={findDate} />
                     </thead>
                     <tbody>
                        <>
                           {newAbsent?.map((e, index) => {
                              return (
                                 <tr className={'time-sheet-row'} key={index + 1}>
                                    <th scope="row">{`${e?.person?.fullName}`}</th>
                                    <td data-title={`Deparment-Position`}>
                                       <p className="subtitle-payroll">
                                          {e?.department !== null ? `${e?.department?.name} - ${e?.position?.name}` : e?.position?.name}
                                       </p>
                                    </td>
                                    {findDate.map((currentDay, index) => {
                                       return e[currentDay.day]?.totalRegHour === 0 && e[currentDay.day]?.totalOtHour === 0 ? (
                                          <td
                                             key={index + 1}
                                             className={`text-center ${e[currentDay.day]?.attendance !== null && e[currentDay.day]?.attendance === true ? 'red-background' : ''}`}
                                             data-title={currentDay.day}
                                             data-type="currency"
                                             data-initial={currentDay.day.charAt(0).toUpperCase()} // Añade la inicial del día
                                          >
                                             <Translate id={'com.tempedge.msg.label.absent'} />
                                          </td>
                                       ) : (
                                          <td
                                             data-title={currentDay.day}
                                             data-type="currency"
                                             data-initial={currentDay.day.charAt(0).toUpperCase()} // Añade la inicial del día
                                          ></td>
                                       );
                                    })}
                                 </tr>
                              );
                           })}
                        </>
                     </tbody>
                  </table>
               </Col>
            </Row>
         </div>
      );
   } else {
      return null;
   }
};

export default AbsentValidation;
