import moment from "moment";
import { convertToMarkup } from "../ReGexs";

export const roundToTwoDecimals = (num) => {
  const roundedNumber = Math.round((num + Number.EPSILON) * 100) / 100;
  return roundedNumber.toFixed(2);
};

export const TotalHoursByDay = (timeIn, timeOut, lunch) => {
  let tin = moment(timeIn, "hh:mm A").format("HH:mm");
  let tout = moment(timeOut, "hh:mm A").format("HH:mm");
  let start = moment.duration(tin).asMinutes();
  let end = moment.duration(tout).asMinutes();
  let middle = lunch;
  let num = 0;
  let totalHour = 0;
  if (isNaN(middle) && start < end) {
    num = end - start;
    totalHour = num / 60;
  }
  if (isNaN(middle) && start > end) {
    end += 1440;
    num = start - end;
    totalHour = num / 60;
  }
  if (start < end) {
    num = end - start - middle;
    let atnight = num / 60;
    totalHour = atnight;
  }
  if (start > end) {
    end += 1440;
    num = end - start - middle;
    let atnight = num / 60;
    totalHour = atnight;
  }
  if (isNaN(middle) && start === end) {
    totalHour = 1440;
  }
  if (start === end) {
    num = 24 * 60 - middle;
    let atnight = num / 60;
    totalHour = atnight;
  }
  let totalRegHour = totalHour.toFixed(2);
  if (isNaN(totalRegHour)) return 0;
  return totalRegHour;
};

export const TotalHourByWeekDay = (employeesTimeEntry, day) => {
  let totalReg = null;
  let totalOt = null;
  employeesTimeEntry.map((e) => {
    if (e[day] !== null) {
      totalReg += e[day]?.totalRegHour;
      totalOt += e[day]?.totalOtHour;
      return { totalReg, totalOt };
    }
    return { totalReg, totalOt };
  });
  if (isNaN(totalReg) || isNaN(totalOt)) {
    return 0.0;
  }
  if (totalReg !== null && totalOt !== null) {
    return roundToTwoDecimals(totalReg) + " - " + roundToTwoDecimals(totalOt);
  } else {
    return "";
  }
};

export const TotalSumAttendanceByWeek = (payroll, findDate) => {
  let totalAttendance = 0;
  Object.keys(findDate).forEach((currentDay) => {
    if (payroll[findDate[currentDay].day]?.attendance) {
      totalAttendance += 1;
    }
  });
  if (isNaN(totalAttendance)) return 0;
  return totalAttendance;
};

export const TotalSumByWeekDay = (employees, day) => {
  let totalAttendance = 0;
  employees?.forEach((e) => {
    if (e[day] !== null) {
      if (e[day]?.attendance) {
        totalAttendance = totalAttendance + 1;
      }
    }
  });
  if (isNaN(totalAttendance)) return 0;
  return totalAttendance;
};

export const TotalAttendanceWeek = (employees, findDate) => {
  let totalAttendance = 0;
  employees.forEach((emp) => {
    findDate.forEach((day) => {
      if (emp[day]?.attendance) {
        totalAttendance += 1;
      }
    });
  });
  if (isNaN(totalAttendance)) return 0;
  return totalAttendance;
};

export const calculateWeeklyTotals = (employees) => {
  let totalRegHours = 0;
  let totalOTHours = 0;
  let totalBillAmount = 0;
  let totalOTBillAmount = 0;
  let totalAmount = 0;

  const data = employees.map((employee) => {
    const {
      employeeTotalRegHours,
      employeeTotalOTHours,
      employeeTotalBillAmount,
      employeeTotalOTBillAmount,
    } = employee;

    totalRegHours += employeeTotalRegHours || 0;
    totalOTHours += employeeTotalOTHours || 0;
    totalBillAmount += employeeTotalBillAmount || 0;
    totalOTBillAmount += employeeTotalOTBillAmount || 0;

    totalAmount = totalBillAmount + totalOTBillAmount;

    return {
      ...employee,
      totalRegHours,
      totalOTHours,
      totalBillAmount,
      totalOTBillAmount,
      totalAmount,
    };
  });

  return {
    data,
    totalRegHours,
    totalOTHours,
    totalBillAmount,
    totalOTBillAmount,
    totalAmount,
  };
};

export const TotalRegHourWeek = (employees) => {
  let total = 0;
  employees?.map((e) => {
    if (e.totalRegHours) {
      return (total += e.totalRegHours);
    }
    return total;
  });
  if (isNaN(total) || total === 0 || Object.is(total, -0)) return '0.00'

  return total.toFixed(2);
};

export const TotalOtHourWeek = (employees) => {
  let total = 0;
  employees?.map((e) => {
    if (e.totalOTHours) {
      return (total += e.totalOTHours);
    }
    return total;
  });
  if (isNaN(total) || total === 0 || Object.is(total, -0)) return '0.00'
  return total.toFixed(2);
};

export const TotalRegHourWeeklyPayroll = (employees) => {
  let total = 0;
  employees?.map((e) => {
    if (e.employeeTotalRegHours) {
      return (total += e.employeeTotalRegHours);
    }
    return total;
  });
  if (isNaN(total) || total === 0) return '0.00'
  return roundToTwoDecimals(total);
};

export const TotalOtHourWeeklyPayroll = (employees) => {
  let total = 0;
  employees?.map((e) => {
    if (e.employeeTotalOTHours) {
      return (total += e.employeeTotalOTHours);
    }
    return total;
  });
  if (isNaN(total) || total === 0) return parseFloat(0.00);
  return roundToTwoDecimals(total);
};

export const calculationHourWeek = (payrollWeekValidation, dateStart) => {
  const startDayIndex = moment(dateStart).day();
  let updatedEmployeeData = payrollWeekValidation.map((employee) => {
    let currentDayIndex = startDayIndex;
    let totalRegularHours = 0;
    let totalOvertimeHours = 0;
    let totalTransportationCost = 0;

    for (let dayCount = 0; dayCount < 7; dayCount++) {
      let weekDayName = moment().day(currentDayIndex).format("ddd").toLowerCase();
      if (!employee[weekDayName]?.lockPayroll) {
        if (employee[weekDayName]) {
          if (!employee[weekDayName].totalRegHour) {
            employee[weekDayName].totalRegHour = 0;
          }
          if (employee[weekDayName].totalOtHour) {
            employee[weekDayName].totalRegHour += employee[weekDayName].totalOtHour;
            employee[weekDayName].totalOtHour = 0;
          }
          employee[weekDayName].timeIn = null;
          employee[weekDayName].timeOut = null;
          employee[weekDayName].lunch = 0;
          totalRegularHours += employee[weekDayName].totalRegHour;
          if (totalRegularHours > 40) {
            let extraRegularHours = 40 - totalRegularHours;
            let extraOvertimeHours = totalRegularHours - 40;
            employee[weekDayName].totalRegHour=employee[weekDayName].totalRegHour + extraRegularHours;
            employee[weekDayName].totalOtHour = extraOvertimeHours;
            totalRegularHours = 40;
            totalOvertimeHours += extraOvertimeHours;
          }
          if (
            employee[weekDayName].totalOtHour === 0 &&
            employee[weekDayName].totalRegHour === 0
          ) {
            employee[weekDayName].timeIn = null;
            employee[weekDayName].timeOut = null;
            employee[weekDayName].lunch = 0;
          }
          totalTransportationCost += employee[weekDayName].transportationCost;
        }
      } else {
        if (employee[weekDayName]) {
          if (employee[weekDayName].totalRegHour) {
            totalRegularHours += employee[weekDayName].totalRegHour;
          }
          if (employee[weekDayName].totalOtHour) {
            totalOvertimeHours += employee[weekDayName].totalOtHour;
          }
          if (employee[weekDayName].transportationCost) {
            totalTransportationCost += employee[weekDayName].transportationCost;
          }
        }
      }
      currentDayIndex++;
      currentDayIndex = currentDayIndex % 7;
    }
    return {
      ...employee,
      totalRegHours: parseFloat(totalRegularHours),
      totalOTHours: parseFloat(totalOvertimeHours),
      totalTrans: parseFloat(totalTransportationCost),
    };
  });

  return updatedEmployeeData;
};

export const calculateWeeklyPayrollTotals = (
  payrollWeekValidation,
  dateStart
) => {
  let numDay = moment(dateStart).day();
  let newEmployeeDetails = payrollWeekValidation.map((employee) => {
    let j = numDay;
    let employeeTotalRegHours = 0;
    let employeeTotalOTHours = 0;
    let employeeTotalBillAmount = 0;
    let employeeTotalOTBillAmount = 0;

    for (let i = 0; i < 7; i++) {
      let weekDay = moment(dateStart).day(j).format("ddd").toLowerCase();
      if (employee[weekDay]) {
        let { totalRegHour, totalOtHour, position, payRate, client } =
          employee[weekDay];

        //calcular la suma de dias para tener el total de semana
        employeeTotalRegHours += totalRegHour || 0;
        // calculo la suma de dias el total de horas extras
        employeeTotalOTHours += totalOtHour || 0;
        // aqui busco la position , si no tiene al client para calcular el bill amount de la semana  que es la suma de los dias de esa operacion
        let markup = position
          ? convertToMarkup(position.markUp) * payRate
          : convertToMarkup(client.commonMarkup) * payRate;
        employeeTotalBillAmount += markup.toFixed(2) * (totalRegHour || 0);
        // al igual que markup buscamos el otmarkup para tener el total de la semana
        let otMarkup = position
          ? convertToMarkup(position.otMarkUp) * payRate * 1.5
          : convertToMarkup(client.commonOtMarkup) * payRate * 1.5;
        employeeTotalOTBillAmount += otMarkup.toFixed(2) * (totalOtHour || 0);
      }
      j = (j + 1) % 7;
    }
    // este return es por cada fila o cada payroll , y esta operacion al ser un mapeo  , la hace por cada fila o cada objeto del arreglo que recibo
    return {
      ...employee,
      employeeTotalRegHours,
      employeeTotalOTHours,
      employeeTotalOTBillAmount,
      employeeTotalBillAmount,
    };
  });
  // retorno el calculo de todo el payroll
  return newEmployeeDetails;
};

export const totalHoursByConfig = (hours, clientReportConf) => {
  let formatHours = 40;
  if (clientReportConf === 3) {
    if (hours.toFixed(2) >= 39.99 && hours.toFixed(2) <= 40.01) {
      return formatHours?.toFixed(2);
    }
    return roundToTwoDecimals(hours);
  }
  return roundToTwoDecimals(hours);
};
