import React from 'react';
import { Link } from 'react-router-dom';
import { withLocalize, Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import HamburgerButton from '../HamburgerButton/HamburgerButton';
import tempEdgeLogo from '../../../assets/static/tempEge-logo-sm.png';
import { NavBarOption } from './NavBarOption';
import { navigateTo } from '../../../utils/NavigateTo';
import LanguageSelector from '../LanguageSelector/LanguageSelector';

class NavBar extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      logo: tempEdgeLogo
    }
  }

  changeActiveLang = (language) => {
    this.props.setActiveLanguage(language);
  }


  render() {
    let profile = JSON.parse(localStorage.getItem('agency'));
    let { languages, activeLanguage, currentPath, lang } = this.props;
    const isLanding = currentPath === "/" || currentPath === "/auth/en" || currentPath === "/auth/es";
    let path = window.location.pathname;
    let hamburgerBtn = "";
    let logo = "";

    const navBarOptions = [
      {
        optionId: 1,
        optionName: "com.tempedge.msg.label.home",
        optionPath: `/auth`
      },
      {
        optionId: 2,
        optionName: "com.tempedge.msg.title.tutorials", 
        optionPath: "/tutorials"
      }
      //uncomment these lines to enable create user in menu outside of tempedge and enable the route en the file Route.js
      /*{
        optionId: 1,
        optionName: "com.tempedge.msg.label.newuser",
        optionPath: "/register",
      },
      {
        optionId: 2,
        optionName: "com.tempedge.msg.label.newagency",
        optionPath: "/registerAgency"
      }
       */
    ];

    if (!path.includes("organization-select")) {
      logo = <Link to={`/dashboard/${activeLanguage.code}`}><img className="company-logo" src={profile?.organizationEntity?.logo ? `data:/png;base64,${profile?.organizationEntity?.logo}` : this.state.logo} alt="Company Logo" /></Link>;
    } else {
      logo = <img className="company-logo" src={profile?.organizationEntity?.logo ? `data:/png;base64,${profile?.organizationEntity?.logo}` : this.state.logo} alt="Company Logo" />;

    }

    if (typeof this.props.portalUserList !== 'undefined' && this.props.portalUserList[0].status === "A") {
      hamburgerBtn = <HamburgerButton toggleNav={this.props.toggleNav} />;
    }

    return (
      <nav className="navbar navbar-expand-sm">
        <div className="row" style={{ padding: 0, margin: 0, width: "100%" }}>
          <div className="col-lg-1">{hamburgerBtn}</div>
          <div className="col-lg-2 col-xs-6 col-sm-6">
            <div className="logo-container-navbar d-flex align-items-center w-80" style={{ minHeight: '3.5rem' }}>
              {logo}
            </div>
          </div>
          <div className="col-lg-8 col-xs-12 col-sm-12 menu-list">
            <div className="navbar-collapse collapse" id="navbarsExample03">
              <ul className="nav navbar-nav menu-ul mr-auto">
                {
                  lang && navBarOptions.map((navBarItem) => (
                    <NavBarOption
                      key={navBarItem.optionId}
                      activePage={currentPath}
                      path={`${navBarItem.optionPath}/${activeLanguage.code}`}
                      label={navBarItem.optionName}
                    />
                  ))
                }
              </ul>
              {!isLanding && (
                <div className="col-lg-3 language-container-lg mt-3">
                  <LanguageSelector
                    languages={languages}
                    changeActiveLang={this.changeActiveLang}
                  />
                </div>
              )}
              {(typeof this.props.portalUserList !== 'undefined' && this.props.portalUserList[0].status === "A") || currentPath.includes('/signin') ? "" : (
                <div style={{ paddingTop: '8px', paddingRight: '25px' }}>
                  <div className='btn btn-primary btn-block register-save-btn btn-landing' onClick={() => navigateTo('/signin/en')}>
                      <Translate id="com.tempedge.msg.label.sign_in" />
                    </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </nav>
    )
  }
}

//Current REDUX state
let mapStateToProps = (state) => {
  return ({
    lang: state?.tempEdge?.lang,
    currentPath: state.router.location.pathname,
    portalUserList: (typeof state.tempEdge.login !== 'undefined' || typeof state.tempEdge.login.portalUserList !== 'undefined') ? state.tempEdge.login.portalUserList : {}
  });
}

export default withLocalize(connect(mapStateToProps)(NavBar));
