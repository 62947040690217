import React, { createContext, useContext, useState } from 'react';

const LocalizationContext = createContext();

export const LocalizationProvider = ({ children, translations }) => {
  const [locale, setLocale] = useState('en');

  // Función de traducción: retorna la traducción de la clave o la clave misma si no existe.
  const t = (key) => {
    return (translations[locale] && translations[locale][key]) || key;
  };

  return (
    <LocalizationContext.Provider value={{ t, locale, setLocale }}>
      {children}
    </LocalizationContext.Provider>
  );
};

export const useLocalization = () => useContext(LocalizationContext);