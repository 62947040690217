import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import ThreeStateSwitch from "../../../components/common/ThreeStateSwitch/ThreeStateSwitch";
import { updateAttendanceList } from "../../../Redux/actions/attendanceActions";
import '../../../assets/styles/components/table-inputs.css';

const naValue = 'N/A';

const EditableCell = ({ payroll, day, translate, applyValue, triggerUpdate }) => {
   const [attendanceValue, setAttendanceValue] = useState(payroll[day]?.attendance);
   const dispatch = useDispatch();
   const isActiveWeek = useSelector((store) => store.attendance.activeWeek);
   const applyValueRef = useRef(applyValue);
   applyValueRef.current = applyValue;

   useEffect(() => {
      if (triggerUpdate) {  // Solo ejecuta el cambio si `triggerUpdate` es verdadero
         const newValue = applyValueRef.current === naValue ? null : applyValueRef.current;
         setAttendanceValue(newValue); // Aplica el valor a la celda
         dispatch(updateAttendanceList({
            payrollId: payroll[day].payrollId,
            id: payroll.id,
            key: "attendance",
            value: newValue,
            day,
         }));
      }
   }, [triggerUpdate]); // Solo se ejecuta cuando `triggerUpdate` cambia

   const updateAttendanceDay = (value) => {
      setAttendanceValue(value);
      dispatch(updateAttendanceList({
         payrollId: payroll[day].payrollId,
         id: payroll.id,
         key: "attendance",
         value,
         day,
      }));
   };

   if (payroll[day] && payroll[day] !== null && (payroll[day]?.lockPayroll === null || !payroll[day]?.lockPayroll)) {
      return (
         <td 
         data-title={day}
         data-type="currency"
         data-initial={day.charAt(0).toUpperCase()} // Añade la inicial del día 
         >
            <ThreeStateSwitch
               name="Switch"
               size="md"
               currentValue={attendanceValue}
               key={payroll.person?.personId}
               onClick={(value) => updateAttendanceDay(value)}
            />
         </td>
      );
   } else {
      return (
         <td 
         data-title={day}
               data-type="currency"
               className='day-blanck'
               data-initial={day.charAt(0).toUpperCase()} // Añade la inicial del día
         >
            {attendanceValue === null
               ? translate('com.tempedge.msg.label.na')  // Muestra 'N/A' si el valor es `null`
               : !isActiveWeek && attendanceValue === naValue
                  ? '-'
                  : attendanceValue === true
                     ? translate('com.tempedge.msg.label.yes')
                     : translate('com.tempedge.msg.label.no')
            }
         </td>
      );
   }
};

export default EditableCell;
