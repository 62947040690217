import { regionStateTypes } from "../types/regionStateTypes";

const initialState = {
  regions: [], // Lista de regiones basada en el país seleccionado
};

export const regionStateReducer = (state = initialState, action) => {
  switch (action.type) {
    case regionStateTypes.SET_REGIONS_BY_COUNTRY:
      return {
        ...state,
        regions: action.payload,
      };
    case regionStateTypes.RESET_REGION_STATE:
      return initialState;
    default:
      return state;
  }
};
