import React from 'react';
import { Translate } from 'react-localize-redux';
import './PaginatorButtons.css';

const  PaginatorButtons = ({ changePage, totalPages, currentPage }) => {
  const handlePageChange = (page, direction) => {
    let newPage = page;
    if (direction === 'next') {
      newPage = page + 1;
    } else if (direction === 'prev') {
      newPage = page - 1;
    }

    if (newPage < 1) {
      newPage = 1;
    } else if (newPage > totalPages) {
      newPage = totalPages;
    }

    changePage(newPage - 1); // Convertimos de 1-based a 0-based index
  };

  const renderPaginationButtons = (totalPages, displayedPage) => {
    let totalButtons = 0;
    let paginationButtons = [];
    let startPage = 1;

    if (totalPages && totalPages > 0) {
      if (totalPages <= 5) {
        totalButtons = totalPages + 1;
      } else if (totalPages >= 6) {
        if (displayedPage - 2 <= 2) {
          startPage = 1;
        } else if (displayedPage + 2 < totalPages) {
          startPage = displayedPage - 2;
        } else if (displayedPage + 2 >= totalPages) {
          startPage = totalPages - 4;
        } else {
          startPage = 1;
        }
        totalButtons = startPage + 5;
      }
    }

    // Solo mostrar los botones "Prev" y "Next" si hay más de 5 páginas
    if (totalPages > 5) {
      // Botón "Prev", siempre gris, deshabilitado si es la primera página
      paginationButtons.push(
        <button
          key="prevBtn"
          type="submit"
          className={`btn paginator-button paginator-button-prev ${displayedPage <= 1 ? 'paginator-button-disabled' : ''}`}
          onClick={() => handlePageChange(displayedPage, 'prev')}
          disabled={displayedPage <= 1} // Deshabilitar si estamos en la primera página
        >
          <Translate id="com.tempedge.msg.label.previous" />
        </button>
      );
    }

    if (totalButtons > 0) {
      for (let i = startPage; i < totalButtons; i++) {
        paginationButtons.push(
          <button
            key={i}
            type="submit"
            className={`btn paginator-page-button ${i === displayedPage ? 'paginator-page-button-active' : ''}`}
            onClick={() => handlePageChange(i, null)}>
            {i}
          </button>
        );
      }
    }

    // Solo mostrar el botón "Next" si hay más de 5 páginas
    if (totalPages > 5) {
      paginationButtons.push(
        <button
          key="nextBtn"
          type="submit"
          className={`btn paginator-button paginator-button-next ${displayedPage >= totalPages ? 'paginator-button-disabled' : ''}`}
          onClick={() => handlePageChange(displayedPage, 'next')}
          disabled={displayedPage >= totalPages} // Deshabilitar si estamos en la última página
        >
          <Translate id="com.tempedge.msg.label.next" />
        </button>
      );
    }

    return paginationButtons;
  };

  return (
    <div className="text-center">
      {renderPaginationButtons(totalPages, currentPage + 1)} {/* Convertimos de 0-based a 1-based index */}
    </div>
  );
};

export default  PaginatorButtons;
