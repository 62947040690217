import {customPayrollTypes} from "../types/customPayrollTypes";

export const addEmployee = (payload) => ({
   type: customPayrollTypes.ADD_EMPLOYEE_IN_CUSTOMPAYROLL,
   payload
});

export const addCustomPayroll = (payload) => ({
   type: customPayrollTypes.ADD_CUSTOM_PAYROLL,
   payload
});
export const addSameDateCustomPayroll = (payload) => ({
   type: customPayrollTypes.ADD_SAME_DATA_CUSTOM_PAYROLL,
   payload
});

export const removeEmployee = ({_id,label,rate}) => ({
  type: customPayrollTypes.REMOVE_EMPLOYEE_IN_CUSTOMPAYROLL,
  payload: {_id,label,rate}
});

export const resetEntireState = () => ({
   type: customPayrollTypes.RESET_ENTIRE_STATE,
});

export const resetEntireStateCustom = () => ({
   type: customPayrollTypes.RESET_ENTIRE_STATE,
});

export const changeEmployeeValue = (payload) => ({
    type: customPayrollTypes.CHANGE_EMPLOYEE_VALUE,
    payload
});

export const removeValuesCustomPayroll = (payload) => ({
    type: customPayrollTypes.REMOVE_VALUE_CUSTOM_PAYROLL,
    payload
});

export const deleteValuesCustomPayroll = (payload) => ({
    type: customPayrollTypes.DELETE_VALUE_CUSTOM_PAYROLL,
    payload
});
export const deleteCustomPayroll = (payload) => ({
  type: customPayrollTypes.DELETE_CUSTOM_PAYROLL,
  payload 
});

export const addCustomPayrollForAllEmployees = (customPayroll) => ({
   type: customPayrollTypes.ADD_CUSTOM_PAYROLL_FOR_ALL_EMPLOYEES,
   payload: customPayroll,
 });

 export const updateCustomPayrollEntry = (entry) => ({
   type: customPayrollTypes.UPDATE_CUSTOM_PAYROLL_ENTRY,
   payload: entry,
 });

 export const updateCustomPayrollHoursEntry = (payload) => ({
   type: customPayrollTypes.UPDATE_CUSTOM_PAYROLL_HOURS_ENTRY,
   payload,
 });
 
 export const updateCustomPayrollAmountEntry = (payload) => ({
   type: customPayrollTypes.UPDATE_CUSTOM_PAYROLL_AMOUNT_ENTRY,
   payload,
 });

 export const addCustomPayrollList = (customPayrollList) => ({
   type: customPayrollTypes.ADD_CUSTOM_PAYROLL_LIST,
   payload: customPayrollList,
 });

 export const deleteHeaderAndRelatedPayrollEntries = (_idHeader) => ({
    type: customPayrollTypes.DELETE_HEADER_AND_RELATED_PAYROLL_ENTRIES,
    payload: { _idHeader },
  });