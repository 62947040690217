import { Modal, Row, Col } from "react-bootstrap";
import ContainerBlue from "../Container/ContainerBlue";
import { withLocalize } from "react-localize-redux";
import { Translate } from "react-localize-redux";

const ConfirmModal = ({ show, onCancel, onSave, title, description, translate }) => {
  const buttons = (
    <Row>
      <Col xs={6} md={6} lg={6}>
        <button
          className="btn btn-default btn-block previous"
          onClick={onCancel}
          type="button"
        >
          {translate("com.tempedge.msg.label.cancel")}
        </button>
      </Col>
      <Col xs={6} md={6} lg={6}>
        <button
          className="btn btn-primary btn-block"
          onClick={onSave}
          type="button"
        >
          {translate("com.tempedge.msg.label.save")}
        </button>
      </Col>
    </Row>
  );

  return (
    <Modal show={show} onHide={onCancel} backdrop="static" keyboard={false} centered>
      <ContainerBlue
        title={title}
        btns={buttons}
      >
        <p className="mt-2">
          <Translate id={description} />
        </p>
      </ContainerBlue>
    </Modal>
  );
};

export default withLocalize(ConfirmModal);
