import { useDispatch, useSelector } from 'react-redux';
import { Translate } from 'react-localize-redux';
import { IconClose } from '../../../components/common/Icons/Icon_svg';
import { addEmployee, deleteHeaderAndRelatedPayrollEntries, removeEmployee } from '../../../Redux/actions/customPayrollActions';
import { useState } from 'react';
import CustomPayrollForm from '../CustomPayrollForm';
import Modal from '../../../Modals/Modal/Modal';
import { titleDeptPos } from '../../../components/common/Constants/Constants';
import CellTable from './CellTable';
import { generateNumericId } from '../../../utils/PayrollUtils';
import RowActions from './RowActions';

const TableCustomPayroll = ({ headerCustomPayroll, setHeaderCustomPayroll, weekActive, entryType, errorsCustom }) => {
   const dispatch = useDispatch();
   const [modal, setModal] = useState({
      show: false,
      content: null,
      size: null,
   });

   const employees = useSelector((state) => state.customPayrollReducer.employeesInCustomPayroll);
   const customPayrollEntityList = useSelector((state) => state.customPayrollReducer.payrollEntityList);

   const handleDeleteEmployee = (employee) => {
      const labelKey = entryType === 'pay' ? 'labelPay' : 'labelBill';
      dispatch(removeEmployee({ _id: employee.id, label: labelKey, rate: employee.rate }));
   };

   const handleDeleteHeader = (_idHeader) => {
      dispatch(deleteHeaderAndRelatedPayrollEntries(_idHeader));
      setHeaderCustomPayroll((prev) => prev.filter((header) => header._idHeader !== _idHeader));
   };

   // Calculate totals per header
   const totalsPerHeader = headerCustomPayroll.map((label) => {
      let totalAmount = 0;
      let totalHours = 0;
      customPayrollEntityList.forEach((entry) => {
         const codeKey = entryType === 'pay' ? 'payCode' : 'billingCode';
         const amountKey = entryType === 'pay' ? 'amountPay' : 'amountBill';
         const hoursKey = entryType === 'pay' ? 'hoursPay' : 'hoursBill';

         if (entry[codeKey]?.customPayrollTypeId === label.customPayrollTypeId && entry._idHeader === label._idHeader) {
            totalAmount += parseFloat(entry[amountKey]) || 0;
            totalHours += parseFloat(entry[hoursKey]) || 0;
         }
      });

      return {
         labelId: label._idHeader,
         totalAmount,
         totalHours,
      };
   });

   const setOpenInfo = ({ employee, label, relatedEntriesFind }) => {
      setModal({
         show: true,
         content: (
            <CustomPayrollForm
               person={employee}
               closeModal={() => setModal({ show: false, content: null })}
               weekActive={weekActive}
               dataGlobal={[label]}
               customPayrollEntityList={relatedEntriesFind}
               label={label}
               type={entryType}
            />
         ),
      });
   };

   return (
      <>
         <table className="responsive-table mt-4">
            <thead>
               <tr>
                  <th scope="col" className="text-center table-header-left-first">
                     <Translate id="com.tempedge.msg.label.name" />
                  </th>
                  <th scope="col" className="text-center table-header-mid-first">
                     {titleDeptPos}
                  </th>
                  {headerCustomPayroll?.map((label, index) => (
                     <th
                        scope="col"
                        key={index}
                        className="text-center day-header table-header-mid-first"
                        data-initial={label?.title.charAt(0).toUpperCase()}
                     >
                        <span
                           className="center-block float-right cursor-pointer mr-3"
                           onClick={() => {
                              if (headerCustomPayroll.length > 1) {
                                 handleDeleteHeader(label._idHeader);
                              }
                           }}
                        >
                           <IconClose icons={18} />
                        </span>
                        <span>{label?.title}</span>
                     </th>
                  ))}
                  <th scope="col" className="text-center table-header-right-first">
                     <Translate id="com.tempedge.msg.label.options" />
                  </th>
               </tr>
            </thead>
            <tbody>
               {employees.map((payroll, index) => {
                  const labelKey = entryType === 'pay' ? 'labelPay' : 'labelBill';
                  const deleteKey = entryType === 'pay' ? 'deletePay' : 'deleteBill';
                  const rowId = `${payroll.id}-${index}`;

                  return (
                     payroll[labelKey] &&
                     !payroll[deleteKey] && (
                        <tr key={rowId} className="time-sheet-row">
                           <th scope="row" className="name-table">
                              {payroll.fullName || `${payroll.lastName} ${payroll.firstName}`}
                           </th>
                           <td data-title={`${titleDeptPos}`}>
                              {payroll.department ? `${payroll.department.name} - ${payroll.position.name}` : payroll.position?.name}
                           </td>
                           {headerCustomPayroll?.map((label, idx) => (
                              <CellTable
                                 key={idx}
                                 payroll={payroll}
                                 label={label}
                                 weekActive={weekActive}
                                 setOpenInfo={setOpenInfo}
                                 entryType={entryType}
                                 customPayrollList={customPayrollEntityList}
                                 errorsCustom={errorsCustom}
                              />
                           ))}
                           <td>
                              <RowActions
                                 onEdit={() => {
                                    setModal({
                                       show: true,
                                       content: (
                                          <CustomPayrollForm
                                             person={payroll}
                                             closeModal={() => setModal({ show: false, content: null })}
                                             weekActive={weekActive}
                                             dataGlobal={headerCustomPayroll}
                                             customPayrollEntityList={customPayrollEntityList}
                                             type={entryType}
                                             label={null}
                                          />
                                       ),
                                    });
                                 }}
                                 onDelete={() => handleDeleteEmployee(payroll)}
                                 onAdd={() => {
                                    let newIdEmployee;
                                    do {
                                       newIdEmployee = generateNumericId();
                                    } while (employees.some((employee) => employee.id === newIdEmployee));

                                    dispatch(
                                       addEmployee({
                                          id: newIdEmployee,
                                          personId: payroll.personId,
                                          employeeId: payroll.employeeId,
                                          firstName: payroll.firstName,
                                          lastName: payroll.lastName,
                                          labelPay: true,
                                          labelBill: true,
                                          deleteBill: false,
                                          deletePay: false,
                                          isNewEmployee: true,
                                       }),
                                    );
                                 }}
                              />
                           </td>
                        </tr>
                     )
                  );
               })}
               {/* Totals Row */}
               <tr className="time-sheet-row-responsive">
                  <th scope="row" colSpan={2} className="text-uppercase text-right custom-totals">
                     <Translate id="com.tempedge.msg.label.total" />
                  </th>
                  {headerCustomPayroll?.map((label, index) => {
                     const totals = totalsPerHeader.find((t) => t.labelId === label._idHeader);
                     const totalHours = totals ? totals.totalHours.toFixed(2) : '0.00';
                     const totalAmount = totals ? totals.totalAmount.toFixed(2) : '0.00';

                     return (
                        <td key={index} className="text-center">
                           <p className="custom-totals">{`RH : ${totalHours} - ${totalAmount} : AMT`}</p>
                        </td>
                     );
                  })}
                  <td></td>
               </tr>
            </tbody>
         </table>
         <Modal
            open={modal.show}
            content={modal.content}
            onClose={() => setModal({ show: false, content: null, size: null })}
            modalSize={modal.size ?? 'modal-lg'}
         />
      </>
   );
};

export default TableCustomPayroll;
