import { useDispatch } from 'react-redux';
import { setActiveLanguage } from 'react-localize-redux'; // acción del traductor viejo
import { useLocalization } from '../components/common/LocalizationContext/LocalizationContext';

export const useTranslation = () => {
  const dispatch = useDispatch();
  const { t, locale, setLocale } = useLocalization();

  const changeLanguage = (language) => {
    // Actualizamos el nuevo traductor
    setLocale(language);
    // Sincronizamos el traductor viejo disparando su acción
    dispatch(setActiveLanguage(language));
  };

  const translate = (key, options = {}) => {
    return t(key);
  };

  const getCurrentLanguage = () => locale;
  const getAvailableLanguages = () => ['en', 'es'];
  const formatDate = (date) => new Date(date).toLocaleDateString(locale);

  return {
    translate,
    changeLanguage,
    getCurrentLanguage,
    getAvailableLanguages,
    formatDate,
  };
};
