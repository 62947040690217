import { useEffect } from 'react';
import { Translate, withLocalize } from 'react-localize-redux';
import DropdownList from '../../../components/common/Dropdown/DropdownList';
import moment from 'moment';
import Datepicker from '../../../components/common/Datepicker/Datepicker';
import { Col } from 'react-bootstrap';
import { useStateTaxes } from '../../../Hooks/useStateTaxes';

const StateTaxes = ({ formik, onTaxValueChange, personId, isEdit, translate, setTaxValues, taxValues = [] }) => {
   const { region, country } = formik.values.contact || {};
   const agency = JSON.parse(localStorage.getItem('agency'));

   const { stateTaxes, taxValuesLocal, apiDropdownOptions, setTaxValuesLocal, fetchRegionTaxes, fetchPersonTaxes, fetchApiOptions } =
      useStateTaxes({ region, country, personId, isEdit, agency, translate });

   useEffect(() => {
      let isSubscribed = true;

      const initRegionTaxes = async () => {
         await fetchRegionTaxes();
         if (!isSubscribed) return;

         setTaxValuesLocal({});
         setTaxValues({});
      };

      initRegionTaxes();

      return () => {
         isSubscribed = false;
      };
   }, [region, country]);

   useEffect(() => {
      if (stateTaxes?.length > 0 || Object.keys(taxValues || {}).length > 0) {
         let isSubscribed = true;

         const initPersonTaxes = async () => {
            if (!isEdit) {
               const emptyTaxValues = {};
               if (stateTaxes?.length > 0) {
                  stateTaxes.forEach((tax) => {
                     emptyTaxValues[tax.taxParamsId] = '';
                     onTaxValueChange(tax.taxParamsId, '');
                  });
               }
               setTaxValues(emptyTaxValues);
            } else if (personId) {
               const taxValuesMap = await fetchPersonTaxes();
               if (!isSubscribed || !taxValuesMap) return;

               const completeValuesMap = { ...taxValuesMap };
               stateTaxes.forEach((tax) => {
                  if (!(tax.taxParamsId in completeValuesMap)) {
                     completeValuesMap[tax.taxParamsId] = '';
                  }
               });

               setTaxValuesLocal(completeValuesMap);
               setTaxValues(completeValuesMap);

               stateTaxes.forEach((tax) => {
                  const value = completeValuesMap[tax.taxParamsId] || '';
                  onTaxValueChange(parseInt(tax.taxParamsId), value);
               });
            }
         };

         initPersonTaxes();

         return () => {
            isSubscribed = false;
         };
      }
   }, [stateTaxes, isEdit, personId]);

   useEffect(() => {
      if (stateTaxes.length > 0) {
         stateTaxes.forEach((tax) => {
            if (tax.fieldType === 'api') {
               fetchApiOptions(tax);
            }
         });
      }
   }, [stateTaxes]);

   const formatCurrency = (value) => {
      if (!value) return '';

      // Remove all characters except digits, dots and the first $
      let cleanValue = value.replace(/[^\d.$]/g, '');

      // Ensure it starts with $
      if (!cleanValue.startsWith('$')) {
         cleanValue = '$' + cleanValue;
      }

      // Remove any extra $ symbols after the first one
      cleanValue = '$' + cleanValue.substring(1).replace(/\$/g, '');

      // Handle the decimal part
      const parts = cleanValue.split('.');
      let integerPart = parts[0].replace(/\$/g, '');
      let decimalPart = parts[1] || '';

      // Remove leading zeros from integer part
      integerPart = integerPart.replace(/^0+/, '') || '0';

      // Format integer part with commas
      integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

      // Handle decimal part - limit to 2 digits
      if (decimalPart.length > 2) {
         decimalPart = decimalPart.substring(0, 2);
      }

      // Combine parts
      let result = '$' + integerPart;
      if (decimalPart) {
         result += '.' + decimalPart;
      }

      return result;
   };

   const handleValueChange = (tax, value) => {
      let finalValue = value;
      let displayValue = value;

      switch (tax.fieldType.toLowerCase()) {
         case 'number':
            finalValue = value ? value.replace(/[^\d]/g, '') : '';
            displayValue = finalValue;
            break;
         case 'currency':
            if (tax.rules) {
               const regex = new RegExp(tax.rules);
               if (value && !regex.test(value)) {
                  return; // No permite el valor si no cumple con la regla
               }
            }
            displayValue = value || '';
            if (value && !value.startsWith('$')) {
               displayValue = '$' + value;
            }
            finalValue = value;
            break;
         case 'text':
            if (tax.rules) {
               const regex = new RegExp(tax.rules);
               if (value === '' || regex.test(value)) {
                  finalValue = value;
                  displayValue = value;
               } else {
                  return;
               }
            }
            break;
         case 'boolean':
            finalValue = value?.value || value || '';
            displayValue = finalValue;
            break;
         case 'api':
            finalValue = value?.value || value || '';
            displayValue = finalValue;
            break;
         default:
            finalValue = value?.value || value || '';
            displayValue = finalValue;
      }

      setTaxValuesLocal((prev) => ({
         ...prev,
         [tax.taxParamsId]: displayValue,
      }));

      if (tax.required && (finalValue === null || finalValue === undefined || finalValue === '')) {
         formik.setFieldError(`tax_${tax.taxParamsId}`, translate('com.tempedge.msg.label.required'));
      } else if (tax.fieldLength && finalValue?.toString().length > parseInt(tax.fieldLength)) {
         formik.setFieldError(`tax_${tax.taxParamsId}`, translate('com.tempedge.msg.label.maxlength', { length: tax.fieldLength }));
      } else {
         formik.setFieldError(`tax_${tax.taxParamsId}`, undefined);
      }

      if (finalValue !== undefined && finalValue !== null) {
         onTaxValueChange(tax.taxParamsId, finalValue);
         setTaxValues((prev) => ({
            ...prev,
            [tax.taxParamsId]: finalValue,
         }));
      }
   };

   const renderInput = (tax) => {
      const value = taxValuesLocal[tax.taxParamsId];

      switch (tax.fieldType.toLowerCase()) {
         case 'api': {
            const selectedOption = apiDropdownOptions[tax.taxParamsId]?.find((option) => option.value.toString() === value?.toString());

            return (
               <DropdownList
                  data={apiDropdownOptions[tax.taxParamsId] || []}
                  name={`tax_${tax.taxParamsId}`}
                  textField="label"
                  valueField="value"
                  value={selectedOption || null}
                  onChange={(item) => handleValueChange(tax, item)}
                  className="text-start"
                  placeholder={translate('com.tempedge.msg.label.select')}
               />
            );
         }
         case 'boolean':
            return (
               <DropdownList
                  name={`tax_${tax.taxParamsId}`}
                  data={[
                     { value: '1', label: translate('com.tempedge.msg.label.yes') },
                     { value: '0', label: translate('com.tempedge.msg.label.no') },
                  ]}
                  valueField="value"
                  textField="label"
                  value={value ? { value } : null}
                  onChange={(option) => handleValueChange(tax, option)}
                  placeholder={translate('com.tempedge.msg.label.select')}
               />
            );

         case 'date':
            return (
               <Datepicker
                  customClass="form-control tempEdge-input-box"
                  selectedValue={value ? moment(value).toDate() : null}
                  maxDate={moment().toDate()}
                  onChange={(date) => handleValueChange(tax, moment(date).format('MM/DD/YYYY'))}
               />
            );

         case 'currency': {
            return (
               <input
                  className="tempEdge-input-box form-control px-2 text-start"
                  name={`tax_${tax.taxParamsId}`}
                  type="text"
                  value={taxValuesLocal[tax.taxParamsId] || ''}
                  onChange={(e) => {
                     const newValue = e.target.value;
                     const formattedValue = formatCurrency(newValue);

                     // Only update if empty or matches the regex pattern
                     if (!formattedValue || (tax.rules && new RegExp(tax.rules).test(formattedValue))) {
                        handleValueChange(tax, formattedValue);
                     }
                  }}
                  required={tax.required}
                  maxLength={tax.fieldLength}
                  placeholder="$0.00"
                  onBlur={(e) => {
                     const value = e.target.value;
                     if (value) {
                        const formattedValue = formatCurrency(value);
                        // Validate against regex on blur
                        if (!tax.rules || new RegExp(tax.rules).test(formattedValue)) {
                           handleValueChange(tax, formattedValue);
                        } else {
                           // If doesn't match regex, clear the value
                           handleValueChange(tax, '');
                        }
                     }
                  }}
               />
            );
         }
         case 'number':
            return (
               <input
                  className="tempEdge-input-box form-control px-2 text-start"
                  name={`tax_${tax.taxParamsId}`}
                  type="text"
                  value={value || ''}
                  onChange={(e) => {
                     const newValue = e.target.value;
                     if (tax.rules) {
                        const regex = new RegExp(tax.rules);
                        if (newValue === '' || regex.test(newValue)) {
                           handleValueChange(tax, newValue);
                        }
                     } else if (/^\d*$/.test(newValue)) {
                        handleValueChange(tax, newValue);
                     }
                  }}
                  required={tax.required}
                  maxLength={tax.fieldLength}
               />
            );

         case 'text':
            return (
               <input
                  className="tempEdge-input-box form-control px-2 text-start"
                  name={`tax_${tax.taxParamsId}`}
                  type="text"
                  value={value || ''}
                  onChange={(e) => {
                     const newValue = e.target.value;
                     if (tax.rules) {
                        const regex = new RegExp(tax.rules);
                        if (newValue === '' || regex.test(newValue)) {
                           handleValueChange(tax, newValue);
                        }
                     } else {
                        handleValueChange(tax, newValue);
                     }
                  }}
                  required={tax.required}
                  maxLength={tax.fieldLength}
               />
            );
      }
   };

   return (
      <div className="tab">
         <Col sm={12}>
            <label className="control-label justify-content-center mb-5">{stateTaxes[0]?.formName}</label>
         </Col>
         <Col sm={12}>
            {stateTaxes.length > 0 ? (
               <>
                  {stateTaxes.map((tax) => (
                     <div key={tax.taxParamsId} className="form-group row px-5 border-bottom pb-4">
                        <Col sm={12} md={6} lg={7}>
                           <label className="control-label text-start">
                              <Translate id={tax.label} />
                              {tax.required && <span className="text-danger">*</span>}
                           </label>
                        </Col>
                        <Col sm={12} md={6} lg={5} className="text-start">
                           {renderInput(tax)}
                           {formik.errors[`tax_${tax.taxParamsId}`] && formik.touched[`tax_${tax.taxParamsId}`] && (
                              <div className="invalid-feedback d-block">{formik.errors[`tax_${tax.taxParamsId}`]}</div>
                           )}
                        </Col>
                     </div>
                  ))}
                  <Col sm={12} md={12} lg={12} className="text-start px-5">
                     <label className="label-view-data-name-tax">
                        <Translate id="com.tempedge.msg.label.notetax" />
                     </label>
                  </Col>
               </>
            ) : (
               <div className="text-center">
                  <h2>{translate('com.tempedge.msg.label.notaxes')}</h2>
               </div>
            )}
         </Col>
      </div>
   );
};

export default withLocalize(StateTaxes);
