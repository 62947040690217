import { Nav } from 'react-bootstrap';
import './TutorialsSidebarTabs.css';

const TutorialsSidebarTabs = ({ tabs, activeTab, onSelectTab }) => {
    return (
        <div className="tutorials-sidebar-nav">
            <Nav variant="pills" className="flex-column tutorials-nav-items" onSelect={onSelectTab}>
                {Object.entries(tabs).map(([key, label]) => (
                    <Nav.Item key={key}>
                        <Nav.Link 
                            eventKey={key}
                            className="tutorials-nav-link"
                            active={activeTab === key}
                        >
                            {label}
                        </Nav.Link>
                    </Nav.Item>
                ))}
            </Nav>
        </div>
    );
};

export default TutorialsSidebarTabs;
