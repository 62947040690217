import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, reset } from 'redux-form';
import { withLocalize, Translate } from 'react-localize-redux';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import InputBox from '../../components/common/InputBox/InputBox';
import Validate from '../Validations/Validations';
import ActiveLanguageAddTranslation from '../../components/common/ActiveLanguageAddTranslation/ActiveLanguageAddTranslation';
import { tempedgeAPI, clearTempedgeStoreProp } from '../../Redux/actions/tempEdgeActions';
import types from '../../Redux/actions/types';
import OutcomeBar from '../../components/common/OutcomeBar';
import { REACT_APP_URL_CHANGE_PASSWORD, REACT_APP_RECAPTCHA_SITE_KEY, REACT_APP_RECAPTCHA_V2_SITE_KEY } from '../../components/common/Routes/URLs';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import eye_open from '../Login/assets/eye_open.png';
import eye_close from '../Login/assets/eye_close.png';

class ChangePassword extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = { 
      submitted: 0,
      recaptchaTokenV3: '',
      showRecaptchaV2: false,
      recaptchaV2Verified: false,
      showPassword: false,
      showInitialPassword: false,
      showConfirmPassword: false,
    };
    const { activeLanguage } = this.props;
    const { addTranslationForLanguage } = this.props;
    ActiveLanguageAddTranslation(activeLanguage, addTranslationForLanguage);
  }

  componentDidMount() {
    this.resetChangePasswordForm();
    this.executeRecaptchaV3();
    this.setupRecaptchaV2();
  }

  componentDidUpdate(prevProps) {
    const { changePassword, clearTempedgeStoreProp } = this.props;
    const { submitted } = this.state;
    if (changePassword && submitted === 1) {
      this.setState({
        submitted: 0,
      });
      if (changePassword.status === 200) {
        clearTempedgeStoreProp('changePassword');
        if (changePassword.data.status === 200) {
          this.showSuccessResultBar(changePassword.data);
          this.props.reset();  // Restablecer el formulario después del envío exitoso
          this.resetRecaptchaV2(); // Reiniciar reCAPTCHA v2
        } else {
          this.showErrorResultBar(changePassword.data);
        }
      } else {
        this.showErrorResultBar(changePassword.data);
      }
    }

    const hasActiveLanguageChanged = prevProps.activeLanguage !== this.props.activeLanguage;

    if (hasActiveLanguageChanged) {
      this.props.push(`/user/changePass/${this.props.activeLanguage.code}`);
      ActiveLanguageAddTranslation(this.props.activeLanguage, this.props.addTranslationForLanguage);
    }
  }

  executeRecaptchaV3 = async () => {
    const { executeRecaptcha } = this.props;
    if (executeRecaptcha) {
      const recaptchaTokenV3 = await executeRecaptcha('change_password');
      this.setState({ recaptchaTokenV3 });
    }
  };

  setupRecaptchaV2 = () => {
    setTimeout(() => {
      if (REACT_APP_RECAPTCHA_V2_SITE_KEY) {
        this.setState({ showRecaptchaV2: true }, () => { 
          window.grecaptcha.render('recaptcha-v2', {
            sitekey: REACT_APP_RECAPTCHA_V2_SITE_KEY,
            callback: this.handleRecaptchaVerify,
          });
        });
      }
    }, 60000);  // Mostrar reCAPTCHA v2 después de 60 segundos
  };

  handleRecaptchaVerify = (token) => {
    this.setState({ recaptchaTokenV2: token, recaptchaV2Verified: true });
  };

  resetRecaptchaV2 = () => {
    if (this.state.showRecaptchaV2 && window.grecaptcha) {
      window.grecaptcha.reset(); // Reiniciar el reCAPTCHA v2
      this.setState({ recaptchaV2Verified: false }); // Restablecer el estado de verificación
    }
  };

  togglePasswordVisibility = () => {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }));
  };
  
  toggleInitialPasswordVisibility = () => {
    this.setState((prevState) => ({
      showInitialPassword: !prevState.showInitialPassword,
    }));
  };
  
  toggleConfirmPasswordVisibility = () => {
    this.setState((prevState) => ({
      showConfirmPassword: !prevState.showConfirmPassword,
    }));
  };

  onSubmit = async (formValues) => {
    const { recaptchaTokenV3, recaptchaTokenV2, recaptchaV2Verified } = this.state;
    if (this.state.showRecaptchaV2 && !recaptchaV2Verified) {
      return;
    }

    const request = {
      oldPassword: formValues.password,
      newPassword: formValues.confirmpassword,
      recaptchaToken: recaptchaTokenV2 || recaptchaTokenV3,
    };

    this.setState(
      () => ({
        submitted: 1,
      }),
      () => {
        this.props.tempedgeAPI(REACT_APP_URL_CHANGE_PASSWORD, request, types.CHANGE_PASSWORD);
      },
    );
  };

  showResultBar(result) {
    this.setState({
      resultBar: <OutcomeBar response={result} />,
    });
  }

  showSuccessResultBar(result) {
    this.showResultBar(result);
    this.props.reset();  // Restablecer el formulario después del envío exitoso
    this.setState({ showRecaptchaV2: false });  // Quitar reCAPTCHA v2 del DOM
}

  showErrorResultBar(result) {
    this.showResultBar(result);
  }

  resetChangePasswordForm() {
    const { reset, clearTempedgeStoreProp } = this.props;
    reset('ChangePassword'); // Reset form fields all to empty
    clearTempedgeStoreProp('changePassword');
  }

  render() {
    const { resultBar, showRecaptchaV2, recaptchaV2Verified } = this.state;
    const { handleSubmit, invalid, submitting, pristine } = this.props;

    return (
      <GoogleReCaptchaProvider reCaptchaKey={REACT_APP_RECAPTCHA_SITE_KEY}>
        <div className="container login-container">
          <div className="row">
            <div className="col-md-12">
              <div className="login-form">
                <div className="panel panel-default login-form-panel">
                  <div className="panel-heading login-header">
                    <h2 className="text-center">
                      <Translate id="com.tempedge.changepass.title.changepass" />
                    </h2>
                  </div>
                  <form className="panel-body" onSubmit={handleSubmit(this.onSubmit)}>
                    <div className="form-group row">
                      <div className="col-12">{resultBar}</div>
                    </div>
                    <div className="form-group row">
                      <div className="col-12">
                        <p className="text-left label-p">
                          <Translate id="com.tempedge.msg.label.oldpassword" />
                        </p>
                        <Field
                          name="password"
                          component="input"
                          className="form-control tempEdge-input-box"
                          type={this.state.showPassword ? 'text' : 'password'}
                          autoComplete="new-password"
                        />
                        <img
                          src={this.state.showPassword ? eye_open : eye_close}
                          className="password-icon"
                          onClick={this.togglePasswordVisibility}
                          alt="Toggle Password Visibility"
                          style={{ marginRight: '10px' }}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-12">
                        <p className="text-left label-p">
                          <Translate id="com.tempedge.msg.label.newpassword" />
                        </p>
                        <Field
                          name="initialpassword"
                          component="input"
                          className="form-control tempEdge-input-box"
                          type={this.state.showInitialPassword ? 'text' : 'password'}
                          autoComplete="new-password"
                        />
                        <img
                          src={this.state.showInitialPassword ? eye_open : eye_close}
                          className="password-icon"
                          onClick={this.toggleInitialPasswordVisibility}
                          alt="Toggle Password Visibility"
                          style={{ marginRight: '10px' }}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-12">
                        <p className="text-left label-p">
                          <Translate id="com.tempedge.msg.label.confirmpassword" />
                        </p>
                        <Field
                          name="confirmpassword"
                          component="input"
                          className="form-control tempEdge-input-box"
                          type={this.state.showConfirmPassword ? 'text' : 'password'}
                          autoComplete="new-password"
                        />
                        <img
                          src={this.state.showConfirmPassword ? eye_open : eye_close}
                          className="password-icon"
                          onClick={this.toggleConfirmPasswordVisibility}
                          alt="Toggle Password Visibility"
                          style={{ marginRight: '10px' }}
                        />
                      </div>
                    </div>
                    {showRecaptchaV2 && (
                      <div className="captcha-container">
                        <div className="center-block captcha-panel" style={{ width: '304px' }}>
                          <div id="recaptcha-v2" style={{ border: recaptchaV2Verified ? 'none' : '1px solid red' }}></div>
                        </div>
                      </div>
                    )}
                    <div className="form-group">
                      <button 
                        type="submit" 
                        className="btn btn-primary btn-block"
                        disabled={invalid || submitting || pristine}>
                        <Translate id="com.tempedge.msg.label.save" />
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </GoogleReCaptchaProvider>
    );
  }
}

ChangePassword.propTypes = {
  reset: PropTypes.func.isRequired,
  tempedgeAPI: PropTypes.func.isRequired,
  clearTempedgeStoreProp: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    changePassword: state.tempEdge.changePassword ? state.tempEdge.changePassword : null,
  };
};

ChangePassword = reduxForm({
  form: 'changePassword',
  validate: Validate,
})(ChangePassword);

export default withLocalize(connect(mapStateToProps, { tempedgeAPI, push, reset, clearTempedgeStoreProp })(ChangePassword));
