import { calculationHourWeek, calculateWeeklyPayrollTotals, TotalHoursByDay } from '../../utils/helpers/TotalHoursFormat';
import { timeEntryTypes } from '../types/timeEntryTypes';


const initialState = {
  client: '',
  department: '',
  shift: '',
  weekday: '',
  customDate: '',
  resultBar: null,
  positionList: [],
  employeesTimeEntry: [],
  employeesTimeEntryDay:[],
  requestDay:[],
  absentEmployees: [],
  absentEmployeesWeek: [],
  totalWeekExcel: [],
  payrollResponse: {},
  saveTimeDayListResponse: {},
  saveTimeWeekListResponse: {},
  dateStart: "",
  dateEnd: "",
  dayResul: false,
  weekActive:true,
  positionSelectDay:'',
};

export const timeEntryReducer = (state = initialState, action) => {
  
  switch (action.type) {
    case timeEntryTypes.FIND_PAYROLL:
      return {
        ...state,
        payrollResponse: action.payload,
        absentEmployeesValidation: action.payload.data?.result
      }

    case timeEntryTypes.REQUEST_LIST_SAVING:
      return {
        ...state,
        requestDay: [action.payload]
      };

      // con este reducer paso la data por dia
    case timeEntryTypes.ADD_EMPLOYEE_TIME_ENTRY:      
      return {
        ...state,
        employeesTimeEntryDay: state.employeesTimeEntryDay && state.employeesTimeEntryDay.length === 0
          ? [action.payload]
          : [...state.employeesTimeEntryDay, action.payload]
      };


    // aqui guardo la data que viene de el search por semana
    case timeEntryTypes.ADD_EMPLOYEE_WEEK_TIME_ENTRY: {
      const { employees, date1, date2 } = action.payload;
      let weekLocked = false;
      employees.map((payroll) => {
        for (const [key, value] of Object.entries(payroll)) {
          if (key !== null) {
            if (value) {
              if (value.lockPayroll) {
                weekLocked = value.lockPayroll;
              }
            }
          }
        }
        return payroll;
      });
      let { employeesTimeEntry } = state;
      if (weekLocked) {
        employeesTimeEntry = calculateWeeklyPayrollTotals(employees, date1);
      } else {
        employeesTimeEntry = calculationHourWeek(employees, date1);
      }
      return {
        ...state,
        dateStart: date1,
        dateEnd: date2,
        employeesTimeEntry,
      };
    }

    // funcion que permite el cambio de horas a todos los payroll

    case timeEntryTypes.LOAD_VALUES_TO_EMPLOYEE_SAMETIME: {
      const { timeIn, timeOut, lunch, totalRegHour } = action.payload;
      let { employeesTimeEntryDay } = state;
      if (employeesTimeEntryDay) {
        employeesTimeEntryDay = employeesTimeEntryDay.map((employee) => {
          const employeeCopy = { ...employee };
          if (!employeeCopy.lockPayroll) {
            employeeCopy.timeIn = timeIn ;
            employeeCopy.timeOut = timeOut ;
            employeeCopy.lunch = lunch ;
            if (totalRegHour !== undefined) {
              employeeCopy.totalRegHour = parseFloat(totalRegHour) ;
            } else {
              employeeCopy.totalRegHour = 0;
            }
          }
          return employeeCopy;
        });
      }
      return {
        ...state,
        employeesTimeEntryDay
      };
    }

    case timeEntryTypes.LOAD_VALUES_TO_EMPLOYEE_SAMETIME_WEEK: {
      
      let { employeesTimeEntry, dateStart } = state;
      const regHours = parseFloat(action.payload)
      if (employeesTimeEntry) {
        employeesTimeEntry = employeesTimeEntry.map((employee) => {
          const employeeCopy = { ...employee };
          for (const [key, value] of Object.entries(employeeCopy)) {
            if (key !== null) {
              if (value) {
                if (!value.lockPayroll) {
                  if (value.totalRegHour !== undefined) {
                  value.totalRegHour = regHours
                  value.totalOtHour = 0
                  }
                }
              }
            }
          }
          return { ...employeeCopy };
        })
      }
      employeesTimeEntry = calculationHourWeek(employeesTimeEntry, dateStart);
      return {
        ...state,
        employeesTimeEntry
      }
    }

    // este reducer se utiliza para absent byDay
    case timeEntryTypes.MOVE_EMPLOYEE_TO_ABSENTS_TABLE: {
      const exist = state.absentEmployees.find(item => item.payrollId === action.payload.payrollId)
      if (exist) return { ...state }
      let { absentEmployees } = state
      absentEmployees = [...state.absentEmployees, action.payload]
      absentEmployees.map((employee) => {
        employee.timeIn = 0
        employee.timeOut = 0
        employee.lunch = 0
        employee.totalRegHour = 0
        employee.totalOtHour = 0
        return employee
      })
      return {
        ...state,
        employeesTimeEntryDay: state.employeesTimeEntryDay
        .filter((employee) => (employee.payrollId!== action.payload.payrollId)),
        absentEmployees
      };
    }

    case timeEntryTypes.MOVE_EMPLOYEE_TO_TIME_ENTRY_TABLE: {
      return {
        ...state,
        absentEmployees: state.absentEmployees
        .filter((employee) => (employee.payrollId !== action.payload.payrollId)),
        employeesTimeEntryDay: [...state.employeesTimeEntryDay, action.payload]
      };
    }

    // este reducer se utiliza para pasar la data de horas del excel nota:cambiar nombres
    case timeEntryTypes.MOVE_EMPLOYEE_TO_ABSENTS_WEEK_TABLE:
      return {
        ...state,
        absentEmployeesWeek: action.payload
      };


    // este reducer se utiliza para hacer el calculo de las horas por payroll por dia
    case timeEntryTypes.CHANGE_TIME_ENTRY_VALUE: {
      const { payrollId, key, value } = action.payload;
      const { employeesTimeEntryDay } = state;
      const updatedEmployees = employeesTimeEntryDay.map(employee => {
        if (employee.payrollId === payrollId) {
          // Si se está actualizando directamente totalRegHour, actualiza solo ese valor
          if (key === 'totalRegHour') {
            return { ...employee, totalRegHour: value };
          } else {
            // Si se están actualizando otros campos, actualiza el campo y recalcula totalRegHour si es necesario
            const updatedEmployee = { ...employee, [key]: value };
            // Recalcula totalRegHour si se actualizan timeIn, timeOut o lunch
            if (['timeIn', 'timeOut', 'lunch'].includes(key)) {
              // Asegurarse de que todos los valores necesarios estén presentes antes de calcular
              if (updatedEmployee.timeIn && updatedEmployee.timeOut && updatedEmployee.lunch !== undefined) {
                updatedEmployee.totalRegHour = TotalHoursByDay(
                  updatedEmployee.timeIn,
                  updatedEmployee.timeOut,
                  updatedEmployee.lunch,
                );
              }
            }
            return updatedEmployee;
          }
        }
        return employee;
      });
      return {
        ...state,
        employeesTimeEntryDay: updatedEmployees,
      };
    }

    // este reducer se utiliza para cambiar el valor de cada payroll segun el dia, se utiliza el payrollId
    case timeEntryTypes.CHANGE_WEEK_TIME_ENTRY_VALUE: {      
      const { personId,day, key, value, payrollId } = action.payload;
      let { employeesTimeEntry,dateStart } = state;
        if ( employeesTimeEntry) {
          employeesTimeEntry =  employeesTimeEntry.map((employee) => {
            if(employee?.person?.personId === personId
              && employee[day]?.payrollId === payrollId
              && employee[day]) {
            const employeeCopy = {...employee} 
            employeeCopy[day][key]= parseFloat(value)            
            return employeeCopy
          }
          return employee
        })
      }
      return {
        ...state,
        employeesTimeEntry: calculationHourWeek(employeesTimeEntry, dateStart)
      }
    }

    case timeEntryTypes.CHANGE_WEEK_TIME_ENTRY_VALUE_LOCKED: {
      const { payrollId, day, key, value, positionId } = action.payload;
      let { employeesTimeEntry, dateStart } = state;
      if (employeesTimeEntry) {
        employeesTimeEntry = employeesTimeEntry.map((employee) => {
          if (employee?.payrollId === payrollId
            && employee?.position?.positionId === positionId
            && employee[day]) {
            const employeeCopy = { ...employee }
            employeeCopy[day][key] = parseFloat(value)
            return employeeCopy
          }
          return employee
        })
      }
      return {
        ...state,
        employeesTimeEntry: calculateWeeklyPayrollTotals(employeesTimeEntry, dateStart)
      }
    }

    case timeEntryTypes.CHANGE_STATE: {
      return {
        ...state,
        [action.payload.key]: action.payload.value
      };
    }

    case timeEntryTypes.FIND_WEEK_ACTIVE:{
      return{
        ...state,
        weekActive:action.payload
      };
    }

    case timeEntryTypes.RESET_ENTIRE_STATE:
      return initialState;

    case timeEntryTypes.TIMEDAY_LIST_SAVING:
      return {
        ...state,
        saveTimeDayListResponse: action.payload
      };

    case timeEntryTypes.TIMEWEEK_LIST_SAVING:
      return {
        ...state,
        saveTimeWeekListResponse: action.payload
      };

    case timeEntryTypes.TOTALS_WEEK_EXCEL:
      return {
        ...state,
        totalWeekExcel: action.payload
      };

    case timeEntryTypes.SET_FIND_BY_WEEK:
      return {
        ...state,
        isFindByWeek: action.payload
      };

    case timeEntryTypes.CLEAR_PROP: {
      return {
        ...state,
        employeesTimeEntry: [],
        employeesTimeEntryDay:[],
        absentEmployees:[],
        dayResul:false,
        weekDay:'',
      };
    }       
    
    default:
      return state;
  }
};
