import { TotalHourByWeekDay, TotalRegHourWeeklyPayroll, TotalOtHourWeeklyPayroll } from '../../../../utils/helpers/TotalHoursFormat';
import { Translate } from 'react-localize-redux';

const PayrollFooter = ({ payrollWeekValidation, findDate }) => {
  return (
    <tr className="time-sheet-row-responsive">
      <td scope="row" colSpan={2} className="text-uppercase text-right  custom-totals">
        <Translate id={'com.tempedge.msg.label.total'} />
      </td>
      {findDate.map((currentDay, index) => (
        <td key={index} className="table-content">
          {TotalHourByWeekDay(payrollWeekValidation, currentDay.day)}
        </td>
      ))}
      <td className="table-content">{TotalRegHourWeeklyPayroll(payrollWeekValidation)}</td>
      <td className="table-content">{TotalOtHourWeeklyPayroll(payrollWeekValidation)}</td>
      <td> -- </td>
    </tr>
  );
};

export default PayrollFooter;