import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { changeWeekAttendanceValue } from "../../../Redux/actions/payrollValidationActions";
import ThreeStateSwitch from "../../../components/common/ThreeStateSwitch/ThreeStateSwitch";
import { Translate } from "react-localize-redux";

const EditableCell = ({ payroll, day }) => {
  const [attendanceValue, setAttendanceValue] = useState(payroll[day]?.attendance);
  useEffect(() => {
    setAttendanceValue(payroll[day]?.attendance);
  }, [payroll, day]);

  const dispatch = useDispatch();
  const changeTimeEntry = (values, key) => {
    const data = {
      payrollId: payroll[day].payrollId,
      id: payroll.id,
      key,
      value: values,
      day
    };

    dispatch(changeWeekAttendanceValue(data));
  };

  if (
    payroll[day] !== null &&
    (payroll[day]?.totalRegHour !== 0 || payroll[day]?.totalOtHour !== 0) && (payroll[day]?.lockPayroll === null || !payroll[day]?.lockPayroll)
  ) {
    return (
      <td className="editableCell">
        <ThreeStateSwitch
          name={'Switch'}
          size={'md'}
          currentValue={attendanceValue}
          key={payroll.person?.personId}
          onClick={(value) => {
            setAttendanceValue(value);
            changeTimeEntry(value, "attendance")
          }}
          disabled={payroll[day]?.lockPayroll}
        />
      </td>
    );
  } else {
    return (
      <td className="editableCell">
        {
          attendanceValue === null || attendanceValue === undefined ? '' : attendanceValue
            ? <Translate id={'com.tempedge.msg.label.yes'} />
            : <Translate id={'com.tempedge.msg.label.no'} />
        }
      </td>
    )
  }
};

export default EditableCell;
