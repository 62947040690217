import { useEffect, useState } from 'react';
import { allowedCharacters, convertToMarkup } from '../../../../utils/ReGexs';
import { initialValuesPosition, positionSchema } from '../validationSchemaClient';
import { Formik } from 'formik';
import { Col, Container, Row } from 'react-bootstrap';
import { Translate } from 'react-localize-redux';
import TranslatedLabel from '../../../../components/common/TranslatedLabel/TranslatedLabel';
import GrayCommonBtn from '../../../../components/common/Buttons/GrayCommonBtn';
import PositionForm from '../PositionList/PositionForm';
import PositionList from '../PositionList/PositionList';
import OutcomeBar from '../../../../components/common/OutcomeBar';
import { isValidTime } from '../../../../utils/DateUtils';
import GreenCommonBtn from '../../../../components/common/Buttons/GreenCommonBtn';
import { generateUniqueId } from '../../../../utils/PayrollUtils';
import BlueCommonBtn from '../../../../components/common/Buttons/BlueCommonBtn';

export const calculateBillRates = (mark) => {
   let billRate = 0;
   let otBillRate = 0;
   let editmarkup = 0;

   if (mark.otMarkUp) {
      editmarkup = parseFloat(mark.otMarkUp);
      otBillRate = mark.pay * convertToMarkup(editmarkup);
   }

   if (mark.markUp) {
      editmarkup = parseFloat(mark.markUp);
      billRate = mark.pay * convertToMarkup(editmarkup) * 1.5;
   }

   return { billRate, otBillRate };
};

const EditDepartmentandPositions = ({ onCloseModal, updateDepartment, listDepartment, setDeletePositionList, values }) => {
   const [message, setMessage] = useState('');
   const [positionList, setPositionList] = useState([]);
   const [departmentname, setDepartmentname] = useState('');
   const [errorDepartmentName, setErrorDepartmentName] = useState(false);
   const [formPosition, setFormPosition] = useState(initialValuesPosition);
   const [editPositiion, setEditPosition] = useState('');

   useEffect(() => {
      if (listDepartment?.name) {
         setDepartmentname(listDepartment.name);
      }
      setPositionList(listDepartment?.positions || []);
   }, [listDepartment]);

   // button back to the previous page and save the information
   const handleUpdate = () => {
      if (departmentname === '') {
         setErrorDepartmentName(true);
         return;
      }

      const isDuplicate = values.departments?.some((dept) => {
         const isSameName = dept.name?.trim().toLowerCase() === departmentname?.trim().toLowerCase();
         // Excluir el departamento en edición comparando `departmentId`
         return isSameName && dept._id !== listDepartment._id;
      });

      if (isDuplicate) {
         setMessage('com.tempedge.error.deptposalreadyexists');
         setErrorDepartmentName(true);
         return;
      }

      updateDepartment({
         ...listDepartment,
         name: departmentname,
         orgId: JSON.parse(localStorage.getItem('agency'))?.organizationEntity.orgId,
         positions: positionList,
      });

      onCloseModal();
   };

   // add new position or edit position
   const handleSubmit = async (values, { resetForm, setFieldError }) => {
      const { billRate, otBillRate } = calculateBillRates(values);
      const newPosition = {
         ...values,
         bill: otBillRate?.toFixed(2),
         otBillRate: billRate?.toFixed(2),
         timeIn: isValidTime(values.timeIn),
         timeOut: isValidTime(values.timeOut),
         _id: values._id || generateUniqueId(),
      };

      // Handle position addition or edit
      const isDuplicate = positionList?.some((position) => {
         const isSameName = position.name?.trim().toLowerCase() === newPosition.name?.trim().toLowerCase();
         const isDifferentPosition = position._id !== editPositiion;
         return isSameName && isDifferentPosition;
      });

      if (isDuplicate) {
         setFieldError('name', 'The position already exists.');
      } else {
         const index = positionList.findIndex((position) => position._id === editPositiion);

         if (index !== -1) {
            // Edit existing position
            const newListPositions = [...positionList];
            newListPositions[index] = newPosition;
            setPositionList(newListPositions);
            setEditPosition('');
         } else {
            // Add new position
            setPositionList((prevList) => [...prevList, newPosition]);
            setEditPosition('');
         }

         setFormPosition(initialValuesPosition);
         resetForm(); // Reset the form values
         setMessage(''); // Clear the message
      }
   };

   return (
      <Formik
         initialValues={formPosition}
         enableReinitialize={true}
         onSubmit={handleSubmit}
         validationSchema={positionSchema}
         key={formPosition._id || 'new'}
      >
         {({ handleSubmit, resetForm }) => {
            const handleCancel = () => {
               setDepartmentname(listDepartment?.name || '');
               setPositionList(listDepartment?.positions || []);
               setFormPosition(initialValuesPosition);
               setErrorDepartmentName(false);
               resetForm();
            };
            useEffect(() => {
               resetForm({ values: formPosition });
            }, [formPosition, resetForm]);
            return (
               <Container className="sign-up-wrapper">
                  <Row>
                     <h2 className="mx-auto text-center">
                        <Translate id="com.tempedge.msg.label.addDept" />
                     </h2>
                  </Row>
                  <Row className="row-agency-name">
                     <Col sm={12} md={12} lg={12}>
                        {message && <OutcomeBar personMessage={message} />}
                     </Col>

                     <Col sm={12} md={4} lg={4}>
                        <TranslatedLabel id="com.tempedge.msg.label.deptName" />
                        <input
                           id={`departmentname`}
                           name={`departmentname`}
                           type="text"
                           maxLength={90}
                           value={departmentname}
                           className={`tempEdge-input-box form-control ${errorDepartmentName ? 'has-error' : ''}`}
                           onChange={(e) => {
                              setDepartmentname(e.target.value.replace(allowedCharacters, '')?.toUpperCase());
                              setErrorDepartmentName(false);
                           }}
                        />
                     </Col>
                     <div className="form-horizontal center-block panel-body mt-2">
                        <Row className="new-client-form">
                           <Col sm={12} md={8} lg={8} className="client-col">
                              <div className="">
                                 <div className="new-client-header">
                                    <h2>
                                       <Translate id="com.tempedge.msg.label.deptinfo" />
                                    </h2>
                                 </div>
                                 <div className="new-clients-contents">
                                    <PositionForm />
                                 </div>
                              </div>
                           </Col>
                           <Col sm={12} md={4} lg={4} className="dept-col">
                              <div className="department-list">
                                 <div className="department-list-header">
                                    <h2>
                                       <Translate id="com.tempedge.msg.label.positionlist" />
                                    </h2>
                                 </div>

                                 <div className="position-list-contents">
                                    {positionList.length > 0 && (
                                       <PositionList
                                          positionList={positionList}
                                          editFromPosList={(index) => {
                                             const position = positionList[index];
                                             setEditPosition(position._id);
                                             setFormPosition({
                                                bill: position.bill,
                                                contactName: position.contactName,
                                                contactPhone: position.contactPhone,
                                                description: position.description,
                                                markUp: position.markUp,
                                                name: position.name,
                                                otMarkUp: position.otMarkUp,
                                                pay: position.pay,
                                                timeIn: position.timeIn,
                                                timeOut: position.timeOut,
                                                ...(position.positionId && { positionId: position.positionId }),
                                                ...(position._id && { _id: position._id }),
                                             });
                                          }}
                                          removeFromPosList={(index, positionId) => {
                                             setPositionList((prevList) => prevList.filter((_, i) => i !== index));
                                             setDeletePositionList((prevList) => [...prevList, positionId]);
                                          }}
                                       />
                                    )}
                                 </div>
                              </div>
                           </Col>
                        </Row>
                     </div>
                  </Row>
                  <Row>
                     <Col sm={12} md={4} lg={4}>
                        <GrayCommonBtn tag={'com.tempedge.msg.label.cancel'} onClick={handleCancel} />
                     </Col>
                     <Col sm={12} md={4} lg={4} className="text-center mx-auto">
                        <GreenCommonBtn type="submit" onClick={handleSubmit} tag="com.tempedge.msg.label.addPos" />
                     </Col>
                     <Col sm={12} md={4} lg={4}>
                        <BlueCommonBtn type={'button'} tag={'com.tempedge.msg.label.update'} onClick={handleUpdate} />
                     </Col>
                  </Row>
               </Container>
            );
         }}
      </Formik>
   );
};

export default EditDepartmentandPositions;
