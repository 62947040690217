import React from 'react'
import { SectionContainer } from '../components/SectionContainer'
import { TagNameContainer } from '../components/TagNameContainer'
import ServiceCards from '../components/ServicesCard'
import image1 from '../assets/culture_4.jpg';
import image2 from '../assets/culture_6.jpg';
import image3 from '../assets/culture_7.jpg';
import TextWithLogo from '../../../components/common/TempEdgeLogoLabel/TempEdgeLogoLabel';

export const WhatWeOfferSection = () => {
    return (
        <>
            <SectionContainer shadow={''}>
                <>
                    <TagNameContainer tagName={'What We Offer'} />
                    <div className="row" style={{ display: 'flex', alignContent: 'space-evenly', flexDirection: 'row', justifyContent: 'center' }}>
                        {
                            services.map((service, i) =>
                                <div className="col-lg-4 col-xl-3 col-md-6 col-sm-6 gx-5 gy-4" key={i}>
                                    <ServiceCards data={service} src={service.icoUrl} />
                                </div>
                            )
                        }
                    </div>
                </>
            </SectionContainer>
        </>
    )
}

const services = [
    {
        name: 'Grow Your Busisness',
        info: 'Our web application is user-friendly, enabling you to administer your payroll with speed and precision, thereby saving valuable time and minimizing errors.',
        link: '/services',
        icoUrl: image1
    },
    {
        name: 'Integration',
        info: <>To further enhance your experience, <TextWithLogo fontSize="inherit" color="inherit" /> boasts an extensive online support section, complete with tutorials and solutions to common challenges.</>,
        link: '/services',
        icoUrl: image2
    },
    {
        name: 'Fulltime Support',
        info: 'Moreover, our dedicated technical support team is always at your disposal, ready to assist with any queries or issues our customers may have.',
        link: '/services',
        icoUrl: image3
    }
]
