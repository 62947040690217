import { withLocalize } from 'react-localize-redux';
import Switch from '../components/common/Switch/Switch';
import { Col, Row } from 'react-bootstrap';

const TitleMenusDataTables = ({ translate, title, sameData, setSameData, employees, isSame = true, page = 1 }) => {
   return (
      <Row className="mt-2">
         <Col sm={12} md={3} lg={3}>
            <p className="green-text font-weight-bold text-uppercase tempedge-control-label">
               {translate(`${title}`) +
                  ' ' +
                  (page === 1
                     ? translate(`com.tempedge.msg.label.paytoemployee`)
                     : page === 2
                       ? translate(`com.tempedge.msg.label.billtoclient`)
                       : '')}
            </p>
            <p className="tempedge-control-label-semibold">
               {translate('com.tempedge.msg.label.listsize')}:<span className="badge">{employees && employees.length}</span>
            </p>
         </Col>

         <Col sm={12} md={4} lg={7}>
         
         </Col>

         {isSame && (
            <Col sm={12} md={2} lg={2}>
               <Row className="ml-4">
               <div className="form-group ml-auto mr-4" >
                  <p className="tempedge-control-label-semibold">{translate('com.tempedge.msg.label.samehour')}</p>
                  <p>
                     <Switch
                        name="week"
                        checked={sameData}
                        onChange={(value) => {
                           setSameData(value);
                        }}
                     />
                  </p>
               </div>
               </Row>
            </Col>
         )}
      </Row>
   );
};

export default withLocalize(TitleMenusDataTables);
