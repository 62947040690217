import moment from "moment";
import { useEffect, useState } from "react";

const useHeaderDate = (dateStart= new Date()) => {
	const [findDate, setFindDate] = useState([]);
	
	useEffect(() => {
	  const dates = [];
	  for (let i = 0; i < 7; i++) {
		const date = moment(dateStart).add(i, 'd');
		dates.push({
		  day: date.format('ddd').toLowerCase(),
		  date: date.format('MM/DD/YYYY'),
		});
	  }
	  setFindDate(dates);
	}, [dateStart]);
	
	return findDate;
  };

  export default useHeaderDate;