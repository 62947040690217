import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';
import { Translate, withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import moment from 'moment';
import types from '../../Redux/actions/types';
import { clearTempedgeStoreProp, tempedgeAPI, getList } from '../../Redux/actions/tempEdgeActions';
import ActiveLanguageAddTranslation from '../../components/common/ActiveLanguageAddTranslation/ActiveLanguageAddTranslation';
import Container from '../../components/common/Container/Container';
import GrayCommonBtn from '../../components/common/Buttons/GrayCommonBtn';
import BlueCommonBtn from '../../components/common/Buttons/BlueCommonBtn';
import Loader from '../../components/common/Loader/Loader';
import { DangerButton, SuccessButton } from '../../components/common/Buttons/Button';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import OutcomeBar from '../../components/common/OutcomeBar';
import {
 REACT_APP_URL_COUNTRY_LIST_ALL,
 REACT_APP_URL_ORG_DEPARTMENT_FIND_ALL,
 REACT_APP_URL_USER_CHANGE_STATUS,
 REACT_APP_URL_USER_ID,
 REACT_APP_URL_USER_UNLOCK,
} from '../../components/common/Routes/URLs';
import { limitString, normalizeDate } from '../../utils/ReGexs';
import { Row, Col } from 'react-bootstrap'

const icons = '24px';
class ViewUSer extends Component {
 constructor(props) {
  super(props);
  this.state = {
   tabId: 0,
   orgId: JSON.parse(localStorage.getItem('agency'))?.organizationEntity?.orgId,
   userSelected: props.username,
   resultBar: '',
   selectedEmployee: [],
   loading: false,
  };
  this.addTranslationsForActiveLanguage();
 }

 componentDidMount = async () => {
  this.setState({
   loading: true,
  });
  await this.fetchData();
  this.setState({
   loading: false,
  });
 };

 componentDidUpdate(prevProps) {
  if (prevProps.activeLanguage !== this.props.activeLanguage) {
   this.props.push(`/user/view/${this.props.activeLanguage.code}`);
   this.addTranslationsForActiveLanguage();
  }
 }

 addTranslationsForActiveLanguage = async () => {
  await ActiveLanguageAddTranslation(this.props.activeLanguage, this.props.addTranslationForLanguage);
 };

 async fetchData() {
  this.setState({
   loading: true,
  });
  const { orgId, userSelected } = this.state;
  await this.props.tempedgeAPI(
   REACT_APP_URL_ORG_DEPARTMENT_FIND_ALL,
   { orgId, page: 0, size: 200, filterBy: {} },
   types.GET_ORG_DEPARTMENT_LIST,
  );
  await this.props.getList(REACT_APP_URL_COUNTRY_LIST_ALL, types.GET_COUNTRY_REGION_LIST);
  const payload = {
   orgId: orgId,
   filterBy: { username: userSelected },
  };

  const response = await this.props.tempedgeAPI(REACT_APP_URL_USER_ID, payload, types.VIEW_USER_ID);

  if (response?.data?.result) {
   this.setState({
    selectedEmployee: response.data.result,
   });
   this.setState({
    loading: false,
    tabId: 0,
   });
  } else {
   this.setState({
    loading: false,
   });
   this.props.history.push(`/user/list/${this.props.activeLanguage.code}`);
  }
  setTimeout(() => this.setState({ resultBar: '' }), 6000);
 }

 showResultBar(data) {
  this.setState({
   resultBar: <OutcomeBar response={data} />,
  });
 }

 showSuccessResultBar(data) {
  this.showResultBar(data);
 }
 showErrorResultBar(data) {
  this.showResultBar(data);
 }

 statusUser(data) {
  switch (data.status) {
   case 'A':
    return <Translate id="com.tempedge.msg.label.active" />;
   case 'P':
    return <Translate id="com.tempedge.msg.label.pending" />;
   case 'D':
    return <Translate id="com.tempedge.msg.label.deleted" />;
   case 'R':
    return <Translate id="com.tempedge.msg.label.rejected" />;
   default:
    return 'N/A';
  }
 }

 editAction() {
  this.props.history.push({
   pathname: `/user/update/${this.props.activeLanguage.code}`,
  });
 }

 //Cancel actions
 cancelAction() {
  this.props.clearTempedgeStoreProp('viewUserId');
  this.props.history.push(`/user/list/${this.props.activeLanguage.code}`);
 }

 //change status of user
 changeStatusUser = async (id) => {
  const payload = {
   orgId: this.state.orgId,
   id: id,
  };
  const response = await this.props.tempedgeAPI(
   REACT_APP_URL_USER_CHANGE_STATUS,
   payload,
   types.VIEW_USER_CHANGE_STATUS,
  );
  if ([200, 201, 203, 204].indexOf(response?.status) > -1) {
   if (response.data.status === 200) {
    this.showSuccessResultBar(response.data);
    this.fetchData();
   } else {
    this.showErrorResultBar(response.data);
   }
  }
 };

 changeUnLocked = async () => {
  const payload = {
   orgId: this.state.orgId,
   id: this.state.selectedEmployee[0]?.portalUserConfId,
  };
  const response = await this.props.tempedgeAPI(REACT_APP_URL_USER_UNLOCK, payload, types.VIEW_USER_CHANGE_STATUS);
  if ([200, 201, 203, 204].indexOf(response?.status) > -1) {
   if (response.data.status === 200) {
    this.showSuccessResultBar(response.data);
    this.fetchData();
   } else {
    this.showErrorResultBar(response.data);
   }
  }
 };

 render() {
  if (this.state.loading) return <Loader />;
  const { selectedEmployee, tabId, resultBar } = this.state;
  const hireDate = selectedEmployee[0]?.user?.lastLogin
   ? moment(selectedEmployee[0]?.user?.lastLogin).format('DD-MM-YYYY hh:mm:ss')
   : '';
  const fullName = `${selectedEmployee[0]?.user?.firstName} ${
   selectedEmployee[0]?.user?.middleName ? selectedEmployee[0]?.user?.middleName : ''
  } ${selectedEmployee[0]?.user?.lastName}`;
  const lookedDates = selectedEmployee[0]?.user?.lockDate
   ? moment(selectedEmployee[0]?.user?.lockDate).format('DD-MM-YYYY hh:mm:ss')
   : '';
   let btns = (
    <Row className="justify-content-center">
      <Col md={6} className="text-end mb-2">
        <GrayCommonBtn
          tag={'com.tempedge.msg.label.cancel'}
          onClick={() => this.cancelAction()}
          style={{ width: '4.5rem' }} 
        />
      </Col>
      <Col md={6} className="text-start mb-2">
        <BlueCommonBtn
          tag={'com.tempedge.msg.label.edit'}
          onClick={() => this.editAction()}
          style={{ width: '4.5rem' }} 
        />
      </Col>
    </Row>
  );

  return (
    <Container title="com.tempedge.employee.title.viewuser" btns={btns}>
      <Row style={{ width: '100%', margin: 0 }}>
        <Col>
          {resultBar}
        </Col>
      </Row>
    <Row className="justify-content-between align-items-center" style={{ width: '100%' }}>
              <Col
                  xs={12}
                  sm={3}
                  md={3}
                  lg={3}
                  className={`p-3 ${!selectedEmployee[0]?.user?.isLocked ? 'formPanel-EmployeeData' : 'formPanel-UserDenied'
                      }`}
                  style={{width: '100%', flexDirection: 'column', justifyContent: 'center',   marginLeft: '70px' }}
              >
                <div>
                    <Row>
                        <Col xs={12}>
                            <p className="label-view-data-name text-center">{selectedEmployee[0]?.user?.username.toUpperCase()}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={3} className="text-right">
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip id="popover-contained" style={{ fontSize: '15px' }}>
                                        <Translate id="com.tempedge.msg.label.name" />
                                    </Tooltip>
                                }
                            >
                                <svg
                                    width={icons}
                                    height={icons}
                                    viewBox="0 0 16 16"
                                    className="bi bi-person-circle"
                                    fill="currentColor"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path d="M13.468 12.37C12.758 11.226 11.195 10 8 10s-4.757 1.225-5.468 2.37A6.987 6.987 0 0 0 8 15a6.987 6.987 0 0 0 5.468-2.63z" />
                                    <path fillRule="evenodd" d="M8 9a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                                    <path fillRule="evenodd" d="M8 1a7 7 0 1 0 0 14A7 7 0 0 0 8 1zM0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8z" />
                                </svg>
                            </OverlayTrigger>
                        </Col>
                        <Col xs={1} />
                        <Col xs={7}>
                            <label className="label-view-data-info">{fullName.toUpperCase()}</label>
                        </Col>
                        <Col xs={3} className="text-right">
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip id="popover-contained" style={{ fontSize: '15px' }}>
                                        <Translate id="com.tempedge.msg.label.locked" />
                                    </Tooltip>
                                }
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="26"
                                    height="26"
                                    fill="currentColor"
                                    className="bi bi-bag-check"
                                    viewBox="0 0 16 16"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M10.854 8.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L7.5 10.793l2.646-2.647a.5.5 0 0 1 .708 0z"
                                    />
                                    <path d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1zm3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4h-3.5zM2 5h12v9a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V5z" />
                                </svg>
                            </OverlayTrigger>
                        </Col>
                        <Col xs={1} />
                        <Col xs={6}>
                            <label className="label-view-data-info">
                                {!selectedEmployee[0]?.user?.isLocked ? (
                                    <Translate id="com.tempedge.msg.label.active" />
                                ) : (
                                    <Translate id="com.tempedge.msg.label.locked" />
                                )}
                            </label>
                        </Col>
                        <Col xs={1} />
                        <Col xs={3} className="text-right">
                            <OverlayTrigger
                                overlay={
                                    <Tooltip id="popover-contained" style={{ fontSize: '15px' }}>
                                        <Translate id="com.tempedge.msg.label.lastLogin" />
                                    </Tooltip>
                                }
                            >
                                <svg
                                    width={icons}
                                    height={icons}
                                    viewBox="0 0 16 16"
                                    className="bi bi-calendar2-event"
                                    fill="currentColor"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM2 2a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H2z"
                                    />
                                    <path d="M2.5 4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H3a.5.5 0 0 1-.5-.5V4zM11 7.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1z" />
                                </svg>
                            </OverlayTrigger>
                        </Col>
                        <Col xs={6}>
                            <label className="label-view-data-info">{hireDate ? hireDate : 'N/A'}</label>
                        </Col>
                    </Row>
                </div>
            </Col>
            <Col xs={12} sm={8} md={8} lg={8} className="formPanel-EmployeeInfo p-3" style={{ width: '100%', marginRight:'30px'}}>
                <div ref="viewEmployee">
                    <div className="center-block">
                        <ul className="nav nav-panel">
                            <li className="nav-item first-panel" onClick={() => this.setState({ tabId: 0 })}>
                                <a className={tabId === 0 ? 'nav-link active' : 'nav-link'} data-toggle="tab" href="#tab0">
                                    <Translate id="com.tempedge.msg.label.information" />
                                </a>
                            </li>
                            {selectedEmployee?.map((org, key) => {
                                return (
                                    <li
                                        key={key}
                                        className="nav-item panel"
                                        onClick={() => this.setState({ tabId: org.portalUserConfId })}
                                    >
                                        <a
                                            className={tabId === org.portalUserConfId ? 'nav-link active' : 'nav-link'}
                                            style={org.status !== 'A' ? { border: 'dotted 1px red' } : null}
                                            data-toggle="tab"
                                            href={`#tab${key + 1}`}
                                        >
                                            {org?.organizationEntity ? org?.organizationEntity?.organizationName : org?.officeName}
                                        </a>
                                    </li>
                                );
                            })}
                        </ul>
                        <div className="form-group" style={{ marginLeft: '1px' }}>
                            <div className="tab-content formPanel-EmployeeInfo">
                                <div className={tabId === 0 ? 'tab-pane fade show active' : 'tab-pane fade'} id="tab0" role="tabpanel">
                                    <div
                                        className={
                                            selectedEmployee[0]?.user?.isLocked
                                                ? 'tab-content formPanelBodyDenied formPanel-EmployeeInfo'
                                                : 'tab-content formPanelBody formPanel-EmployeeInfo'
                                        }
                                    >
                                        <div className="form-group row">
                                            <Col xs={10} md={5} lg={4} className="form-group">
                                                <label className="control-label">
                                                    <Translate id="com.tempedge.msg.label.locked" />
                                                </label>
                                                <label className="label-view-data">
                                                    {!selectedEmployee[0]?.user?.isLocked ? (
                                                        <Translate id="com.tempedge.msg.label.active" />
                                                    ) : (
                                                        <Translate id="com.tempedge.msg.label.locked" />
                                                    )}
                                                </label>
                                            </Col>
                                            <Col xs={10} md={5} lg={4}>
                                                <label className="control-label">
                                                    <Translate id="com.tempedge.msg.label.authAttempts" />
                                                </label>
                                                <label className="label-view-data">
                                                    {selectedEmployee[0]?.user?.authAttempts ? selectedEmployee[0]?.user?.authAttempts : 0}
                                                </label>
                                            </Col>
                                            <Col xs={10} md={5} lg={4}>
                                                <label className="control-label">
                                                    <Translate id="com.tempedge.msg.label.lockDate" />
                                                </label>
                                                <label className="label-view-data">{lookedDates}</label>
                                            </Col>
                                        </div>
    
                                        <div className="form-group row">
                                            <Col xs={10} md={5} lg={4}>
                                                <label className="control-label">
                                                    <Translate id="com.tempedge.msg.label.email" />
                                                </label>
                                                <label className="label-view-data">
                                                    {selectedEmployee[0]?.user?.email
                                                        ? limitString({
                                                              value: selectedEmployee[0]?.user?.email,
                                                              limit: 20,
                                                              replace: '...',
                                                          })
                                                        : ''}
                                                </label>
                                            </Col>
                                            <Col xs={10} md={5} lg={4} className="offset-md-4">
                                                <label className="control-label">
                                                    <Translate id="com.tempedge.msg.label.datecreate" />
                                                </label>
                                                <label className="label-view-data">
                                                    {selectedEmployee[0]?.dtCreate && normalizeDate(selectedEmployee[0]?.dtCreate)}
                                                </label>
                                            </Col>
                                        </div>
                                        <hr />
                                        <br />
                                        <div className="form-group row">
                                            <Col xs={10} md={5} lg={4} className="ml-auto">
                                                {!selectedEmployee[0]?.user?.isLocked ? (
                                                    <>
                                                        <label className="control-label">
                                                            <Translate id="com.tempedge.msg.label.locked" />
                                                        </label>
                                                        <DangerButton onClick={() => this.changeUnLocked()}>
                                                            <Translate id="com.tempedge.msg.label.locked" />
                                                        </DangerButton>
                                                    </>
                                                ) : (
                                                    <>
                                                        <label className="control-label">
                                                            <Translate id="com.tempedge.msg.label.unlock" />
                                                        </label>
                                                        <SuccessButton onClick={() => this.changeUnLocked()}>
                                                            <Translate id="com.tempedge.msg.label.unlock" />
                                                        </SuccessButton>
                                                    </>
                                                )}
                                            </Col>
                                        </div>
                                    </div>
                                </div>
                                {selectedEmployee.map((data, key) => {
                                    if (data.portalUserConfId === tabId) {
                                        return (
                                            <div
                                                className={tabId === data.portalUserConfId ? 'tab-pane fade show active' : 'tab-pane fade'}
                                                id={`tab${key + 1}`}
                                                role="tabpanel"
                                                key={key}
                                            >
                                                <div
                                                    className={
                                                        data.status !== 'A'
                                                            ? 'tab-content formPanelBodyDenied formPanel-EmployeeInfo'
                                                            : 'tab-content formPanelBody formPanel-EmployeeInfo'
                                                    }
                                                >
                                                    <div className="form-group row">
                                                        <Col xs={10} md={5} lg={4}>
                                                            <label className="control-label">
                                                                <Translate id="com.tempedge.msg.label.officename" />
                                                            </label>
                                                            <label className="label-view-data">{data.officeName}</label>
                                                        </Col>
                                                        <Col xs={10} md={5} lg={4}>
                                                            <label className="control-label">
                                                                <Translate id="com.tempedge.msg.label.datecreate" />
                                                            </label>
                                                            <label className="label-view-data">
                                                                {data.dtCreate && normalizeDate(data.dtCreate)}
                                                            </label>
                                                        </Col>
                                                        <Col xs={10} md={5} lg={4}>
                                                            <label className="control-label">
                                                                <Translate id="com.tempedge.msg.label.modifieddate" />
                                                            </label>
                                                            <label className="label-view-data">{data.dtModified}</label>
                                                        </Col>
                                                    </div>
                                                    <div className="form-group row">
                                                        <Col xs={10} md={5} lg={4}>
                                                            <label className="control-label">
                                                                <Translate id="com.tempedge.msg.label.approvedby" />
                                                            </label>
                                                            <label className="label-view-data">
                                                                {data?.approvedby?.toUpperCase()}
                                                            </label>
                                                        </Col>
                                                        <Col xs={10} md={5} lg={4}>
                                                            <label className="control-label">
                                                                <Translate id="com.tempedge.msg.label.approveddate" />
                                                            </label>
                                                            <label className="label-view-data">{data.approvedDate}</label>
                                                        </Col>
                                                        <Col xs={10} md={5} lg={4}>
                                                            <label className="control-label">
                                                                <Translate id="com.tempedge.msg.label.identification" />
                                                            </label>
                                                            <label className="label-view-data">
                                                                {data.personIdentification ? parseInt(data.personIdentification) : null}
                                                            </label>
                                                        </Col>
                                                    </div>
                                                    <div className="form-group row">
                                                        <Col xs={10} md={5} lg={4}>
                                                            <label className="control-label">
                                                                <Translate id="com.tempedge.msg.label.status" />
                                                            </label>
                                                            <label className="label-view-data">
                                                                {data.status && this.statusUser(data)}
                                                            </label>
                                                        </Col>
                                                        <Col xs={10} md={5} lg={4}>
                                                            <label className="control-label">
                                                                <Translate id="com.tempedge.msg.label.role" />
                                                            </label>
                                                            {data?.user?.roles?.map((item, key) => (
                                                                <li key={key}>{item.name}</li>
                                                            ))}
                                                        </Col>
                                                    </div>
                                                    <hr />
                                                    <br />
                                                    <div className="form-group row justify-content-end">
                                                        <Col xs={10} md={5} lg={4}>
                                                            {data.status === 'A' ? (
                                                                <>
                                                                    <label className="control-label">
                                                                        <Translate id="com.tempedge.msg.label.deleteuser" />
                                                                    </label>
                                                                    <DangerButton onClick={() => this.changeStatusUser(data?.portalUserConfId)}>
                                                                        <Translate id="com.tempedge.msg.label.delete" />
                                                                    </DangerButton>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <label className="control-label">
                                                                        <Translate id="com.tempedge.msg.label.activeuser" />
                                                                    </label>
                                                                    <SuccessButton onClick={() => this.changeStatusUser(data?.portalUserConfId)}>
                                                                        <Translate id="com.tempedge.msg.label.active" />
                                                                    </SuccessButton>
                                                                </>
                                                            )}
                                                        </Col>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    }
                                    return null;
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </Col>
        </Row>
    </Container>
  );
 }
}

ViewUSer.propTypes = {
 clearTempedgeStoreProp: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
 return {
  username: state.tempEdge.username,
  personId: state.tempEdge.personId,
  countryRegionList: state.tempEdge.country_region_list ? state.tempEdge.country_region_list : [],
  orgDepartmentList: state.tempEdge.orgDepartmentList?.data?.result?.data?.content
   ? state.tempEdge.orgDepartmentList.data.result.data.content
   : [],
 };
};

export default withLocalize(
 connect(mapStateToProps, {
  push,
  clearTempedgeStoreProp,
  tempedgeAPI,
  getList,
 })(ViewUSer),
);
