import moment from 'moment';
import { Translate } from 'react-localize-redux';
import { TotalSumByWeekDay, TotalAttendanceWeek, TotalSumAttendanceByWeek } from '../../../utils/helpers/TotalHoursFormat';
import useHeaderDate from '../PayrollByWeek/useHeaderDate';
import EditableCell from './EditableCell';
import SetTableHeadingAttendance from './SetTableHeadingAttendance';
import { DATE_FORMAT } from '../../../components/common/Constants/Constants';
import { Col, Row } from 'react-bootstrap';

const PayrollByWeekAttendance = ({ payrollWeekValidation, dateStart }) => {

   const findDate = useHeaderDate(dateStart);

   const weekResults = Object.values(payrollWeekValidation).filter(
      (value) => value != null && (value?.totalRegHours !== 0 || value?.totalOTHours !== 0),
   );

   return (
      <>
         <div className="form-group col-12 col-lg-3">
            <h2>
               <Translate id={'com.tempedge.msg.label.attendancevalidation'} />
            </h2>
         </div>
         <div className="form-group col-12 col-lg-3">
            <p style={{ textAlign: 'left' }}>
               <Translate id={'com.tempedge.msg.label.listsize'} /> <span className="badge">{weekResults.length}</span>{' '}
            </p>
         </div>
         <Row>
            <Col sm={12} md={12} className="form-check">
               <table className="responsive-table">
                  <thead>{<SetTableHeadingAttendance findDate={findDate} />}</thead>
                  <tbody>
                     <>
                        {weekResults?.map((payroll, index) => {
                           return (
                              <tr key={index + 1}
                              className={'time-sheet-row'}
                              >
                                <th scope="row">{`${payroll.person?.fullName}`}</th>
                                <td data-title={`Deparment-Position`}>
                                <p className="subtitle-payroll">
                                    {payroll.department !== null
                                       ? `${payroll.department?.name} ${payroll.position?.name}`
                                       : payroll.position?.name}
                                        </p>
                                 </td>
                                 {findDate.map((currentDay, index) => {
                                    const payrollDate = payroll[currentDay.day]?.dateList;
                                    return moment(payrollDate).format(DATE_FORMAT) === currentDay.date ? (
                                       <EditableCell payroll={payroll} day={currentDay.day} />
                                    ) : (
                                       <td key={index + 1}></td>
                                    );
                                 })}
                                 <td className="text-center">{TotalSumAttendanceByWeek(payroll, findDate)}</td>
                              </tr>
                           );
                        })}
                        <tr className="tableRow text-center">
                           <td colSpan="2" className="table-content text-uppercase text-right">
                              <Translate id={'com.tempedge.msg.label.total'} />
                           </td>
                           {Object.keys(findDate).map((currentDay, index) => {
                              return (
                                 <td key={index + 1} className="table-content">
                                    {TotalSumByWeekDay(payrollWeekValidation, findDate[currentDay].day)}
                                 </td>
                              );
                           })}
                           <td className="table-content">{TotalAttendanceWeek(payrollWeekValidation, findDate)}</td>
                        </tr>
                     </>
                  </tbody>
               </table>
            </Col>
         </Row>
      </>
   );
};

export default PayrollByWeekAttendance;
