import { Translate } from "react-localize-redux";
import SetTableHeadingAbsent from "../PayrollByWeek/SetTableHeadingAbsent";
import useHeaderDate from "../PayrollByWeek/useHeaderDate";
import { Row } from "react-bootstrap";

const AbsentPayrollForWeekAttendance = ({ absentValidation, dateStart }) => {

  const findDate  = useHeaderDate(dateStart);

  if (absentValidation?.length > 0) {
    const newAbsent = absentValidation.filter((value, index) => {
      return absentValidation.indexOf(value) === index
    })

    return (
      <>
        <div className="form-group col-12 col-lg-6">
          <p style={{ textAlign: "left" }}><Translate id={'com.tempedge.msg.label.peoplewithouthours'} /> <span className="badge">{newAbsent.length}</span> </p>
          <p style={{ color: 'red' }}><Translate id={"com.tempedge.msg.label.notetransportationcost"} /></p>
        </div>
        <Row>
          <div className="form-check col-12 col-lg-12">
            <table className="table table-striped table-input-group">
              <thead>{<SetTableHeadingAbsent findDate={findDate} />}</thead>
              <tbody>
                <>
                  {newAbsent?.map((e, index) => {
                    return (
                      <tr key={index + 1}>
                        <td>{`${e?.person?.fullName}`}</td>
                        <td>{e?.department !== null ? `${e?.department?.name} - ${e?.position?.name}` : e?.position?.name}</td>
                        {findDate.map(({day,date}, index) => {
                          const cellClass = e[day]?.attendance !== null ? "red-background" : "";
                          const borderClass = e[day]?.attendance !== null ? "red-border" : "";
                          return e[day]?.totalRegHour === 0 && e[day]?.totalOtHour === 0 ?
                            (
                              <td key={index + 1} className={`text-center  ${cellClass} ${borderClass}` }><Translate id={'com.tempedge.msg.label.absent'} /></td>
                            ) : (
                              <td key={index + 1}></td>
                            )
                        })}
                      </tr>
                    )
                  })}
                </>
              </tbody>
            </table>
          </div>
        </Row>      
      </>
    );
  } else {
    return (
      null
    )
  }

}

export default AbsentPayrollForWeekAttendance




