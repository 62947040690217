import { useState } from 'react'; 
import TempEdgeApi from '../../services/TempEdgeApi';
import {
  REACT_APP_URL_ACCOUNT_FIND_PERSON,
  REACT_APP_URL_ACCOUNT_CREATE,
  REACT_APP_URL_ACCOUNT_DELETE,
  REACT_APP_URL_COMMONS_FILE_UPDATE,
  REACT_APP_URL_COMMONS_FILE_DELETE,
} from '../../components/common/Routes/URLs';

export const useAccountApi = () => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  // Extrae el orgId de localStorage
  const getOrgId = () =>
    JSON.parse(localStorage.getItem('agency'))?.organizationEntity?.orgId;

  // Busca empleado y sus cuentas (filtrando las eliminadas)
  const findEmployee = async (employeeId) => {
    setMessage(null);
    setLoading(true);
    try {
      const orgId = getOrgId();
      const { data: res } = await TempEdgeApi.post({
        url: REACT_APP_URL_ACCOUNT_FIND_PERSON,
        payload: {
          parameterKey: 'com.tempedge.msg.menu.employee',
          id: employeeId,
          orgId,
        },
      });
      if (res.status === 200 && res.code === 'TE00') {
        return {
          ...res,
          result: {
            ...res.result,
            accountDTOList: (res.result.accountDTOList || [])
              .filter(account => !account.deleted)
              .map((account) => ({
                accountId: account.accountId ?? null,
                bankName: account.bankName || '',
                routeAndTransitNumber: account.aba || '',
                accountNumber: account.accountNumber || '',
                accountType: account.accountType || '',
                amount: account.amount || '',
                percentage: account.percentage || 0,
                cancelOldAccount: account.cancelOldAccount || false,
                fileEntities: account.file ? [account.file] : [],
              })),
            maxAccountAllowed: res.result.maxAccountAllowed,
          },
        };
      }
      return res;
    } catch (error) {
      setMessage(error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  // Borra cuenta (y archivo asociado)
  const deleteAccount = async (accountId) => {
    setLoading(true);
    try {
      const orgId = getOrgId();
      const deletePayload = { orgId, id: accountId };
      const { data: res } = await TempEdgeApi.post({
        url: REACT_APP_URL_ACCOUNT_DELETE,
        payload: deletePayload,
      });
      if (res.status === 200 && res.code === 'TE00') {
        // Borra el archivo asociado
        await TempEdgeApi.post({
          url: REACT_APP_URL_COMMONS_FILE_DELETE,
          payload: {
            moduleId: accountId,
            module: 'com.tempedge.msg.menu.account',
          },
        });
      }
      return res;
    } catch (error) {
      setMessage(error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  // Crea o actualiza cuenta (se envía el accountId para actualizar)
  const createOrUpdateAccount = async (employeeId, account) => {
    setLoading(true);
    try {
      const orgId = getOrgId();
      const request = {
        module: 'com.tempedge.msg.menu.employee',
        moduleId: employeeId,
        orgId,
        accountDTO: {
          accountId: account.accountId,
          bankName: account.bankName,
          aba: account.routeAndTransitNumber,
          accountNumber: account.accountNumber,
          accountType: account.accountType,
          amount: account.amount,
          percentage: account.percentage,
          cancelOldAccount: account.cancelOldAccount,
        },
      };
      const { data: res } = await TempEdgeApi.post({
        url: REACT_APP_URL_ACCOUNT_CREATE,
        payload: request,
      });

      // Si se subió un archivo
      if (res.status === 200 && res.code === 'TE00' && account.uploadedFile) {
        const acceptedExtensions = ['doc', 'docx', 'pdf'];
        const fileExtension = account.uploadedFile.name.split('.').pop().toLowerCase();

        // Verifica la extensión
        if (!acceptedExtensions.includes(fileExtension)) {
          account.fileSaveError = true;
        } else {
          // Elimina la extensión del nombre para evitar doble .pdf
          const baseName = account.uploadedFile.name.replace(/\.[^/.]+$/, '');
          
          const formData = new FormData();
          const fileData = {
            orgId,
            moduleId: res.result.accountId,
            module: 'com.tempedge.msg.menu.account',
            extension: fileExtension,
            type: baseName, // Solo el nombre sin extensión
          };

          const blobData = new Blob([JSON.stringify(fileData)], {
            type: 'application/json',
          });
          formData.append('data', blobData);
          formData.append('file', account.uploadedFile);

          const { data: fileRes } = await TempEdgeApi.postMultipart({
            url: REACT_APP_URL_COMMONS_FILE_UPDATE,
            payload: formData,
          });

          if (fileRes.status === 200 && fileRes.code === 'TE00') {
            account.fileEntities = fileRes.result ? [fileRes.result] : [];
            account.fileSaveError = false;
          } else {
            account.fileSaveError = true;
          }
        }
      }
      return res;
    } catch (error) {
      setMessage(error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    message,
    setMessage,
    findEmployee,
    deleteAccount,
    createOrUpdateAccount,
  };
};
