import React from 'react'
import { SectionContainer } from '../components/SectionContainer'
import { TagNameContainer } from '../components/TagNameContainer'
import { ImageCard } from '../components/ImageCard'
import TextWithLogo from '../../../components/common/TempEdgeLogoLabel/TempEdgeLogoLabel';

export const WhatWeDoSection = () => {
    return (
        <>
            <SectionContainer shadow={'bg-shade-1'}>
                <div>
                <TagNameContainer tagName={'What We Do'} />
                <div className="row mb-40 gy-4">
                    <ImageCard imgSrc={require('../assets/about1.png')} alt={'About1'} />
                    <div className="col-lg-6 align-self-center card-container">
                        <h2 className='mb-16 medium-text'>Effortless Payroll Management</h2>
                            <p className='txt-1'>
                                At <TextWithLogo fontSize="inherit" color="inherit" />, we understand the complexities involved in managing payroll for temporary staff. Our platform is engineered to streamline this process.
                                <br /><br />
                                You can effortlessly input and maintain records of your temporary workers, including details such as hours worked, wages, and deductions.
                            </p>
                    </div>
                    <div className="row pt-5 gy-4">
                        <div className="col-lg-6 align-self-center card-container">
                            <h2 className='mb-16 medium-text'>Automated Reporting and Invoicing</h2>
                            <p className='txt-1'>Our sophisticated system autonomously generates reports and invoices, ensuring you maintain a comprehensive overview of your company's financial health while preventing expensive mistakes. </p>
                        </div>
                        <ImageCard imgSrc={require(`../assets/culture_5.png`)} alt={'About2'} />
                    </div>
                </div>
                </div>
            </SectionContainer>
        </>
    )
}
