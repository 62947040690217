import moment from 'moment';
import { Translate, withLocalize } from 'react-localize-redux';

export const AttendanceTableHeader = ({ findDate,translate }) => {

    const generateHeaders = (findDate) => {
       return findDate?.map((date, index) => {
          const day = moment().day(date.day).format('dddd').toLowerCase();
          const translatedDay = translate(`com.tempedge.msg.label.${day}`);  // Usa la función de traducción directamente
          return (
             <th
                scope="col"
                key={index}
                className="text-center day-header table-header-mid-first"
                data-initial={translatedDay.charAt(0).toUpperCase()}  // Aquí usamos la primera letra del texto traducido
                onClick={(e) => e.currentTarget.classList.toggle('show-full')}
             >
                <span>
                   <Translate id={`com.tempedge.msg.label.${day}`} />
                </span>
                <br />
                <span>{date.date}</span>
             </th>
          );
       });
    };
   return (
      <tr className="responsive-table">
         <th scope="col" className="text-center table-header-left-first">
            <Translate id={'com.tempedge.msg.label.ee'} />
         </th>
         <th scope="col" className="text-center table-header-mid-first">
            <Translate id={'com.tempedge.msg.label.name'} />
         </th>
         {generateHeaders(findDate)}
         <th scope="col" className="text-center table-header-right-first">
            <Translate id={'com.tempedge.msg.label.attendance'} />
         </th>
      </tr>
   );
};

export default withLocalize(AttendanceTableHeader);
