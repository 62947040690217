import * as Yup from 'yup';

const AccountEmployeeSchema = Yup.object().shape({
  accounts: Yup.array().of(
    Yup.object().shape({
      bankName: Yup.string().required('Institution Name is required'),
      routeAndTransitNumber: Yup.string().required('Route & Transit is required'),
      accountNumber: Yup.string().required('Customer Account is required'),
      accountType: Yup.lazy((value) => {
        if (typeof value === 'object' && value !== null) {
          return Yup.object({
            label: Yup.string(),
            value: Yup.string().required('Account Type is required'),
          }).required('Account Type is required');
        }
        return Yup.string().required('Account Type is required');
      }),
      amount: Yup.string().required('Amount is required'),
      percentage: Yup.number()
        .min(0, 'Percentage must be at least 0')
        .max(100, 'Percentage must be at most 100')
        .required('Percentage is required'),
      cancelOldAccount: Yup.boolean().required('This field is required'),
    })
  ),
});

export default AccountEmployeeSchema;
