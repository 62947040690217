import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Rating from 'react-rating';
import { Translate, withLocalize } from 'react-localize-redux';

import ContainerBlue from '../Container/ContainerBlue';
import SaveBtn from '../Buttons/SaveBtn';
import ListComments from './ListComments';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import {  REACT_APP_BASE_URL_TEMPEDGE,REACT_APP_URL_COMMONS_COMMENT_LIST, REACT_APP_URL_COMMONS_COMMENT_SAVE } from '../Routes/URLs';
import Loader from "../Loader/Loader";



const emptyStarScore =
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"  viewBox="0 0 16 15.001">
      <path id="star_2_" data-name="star (2)" d="M2.866,14.85a.531.531,0,0,0,.746.593L8,13.187l4.389,2.256a.531.531,0,0,0,.746-.592l-.83-4.73L15.83,6.765a.556.556,0,0,0-.283-.95l-4.9-.7L8.465.792a.513.513,0,0,0-.927,0L5.354,5.12l-4.9.7a.556.556,0,0,0-.283.95L3.7,10.122l-.83,4.73Zm4.923-2.112L3.448,14.851l.82-4.657a.565.565,0,0,0-.163-.5L.577,6.34l4.888-.617a.525.525,0,0,0,.393-.288L8,1.081l2.254,4.355a.525.525,0,0,0,.393.288l4.9.617-3.6,3.347a.564.564,0,0,0-.163.506l.757,4.657L8.25,12.737a.5.5,0,0,0-.461,0Z" transform="translate(-0.001 -0.499)" fillRule="evenodd"/>
    </svg>
const fullStarScore = 
    <svg width="24" height="24" viewBox="0 0 16 16" className="bi bi-star-fill" fill="var(--tempedge-orange)" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
    </svg>
const emptyStarRate =
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"  viewBox="0 0 16 15.001">
      <path id="star_2_" data-name="star (2)" d="M2.866,14.85a.531.531,0,0,0,.746.593L8,13.187l4.389,2.256a.531.531,0,0,0,.746-.592l-.83-4.73L15.83,6.765a.556.556,0,0,0-.283-.95l-4.9-.7L8.465.792a.513.513,0,0,0-.927,0L5.354,5.12l-4.9.7a.556.556,0,0,0-.283.95L3.7,10.122l-.83,4.73Zm4.923-2.112L3.448,14.851l.82-4.657a.565.565,0,0,0-.163-.5L.577,6.34l4.888-.617a.525.525,0,0,0,.393-.288L8,1.081l2.254,4.355a.525.525,0,0,0,.393.288l4.9.617-3.6,3.347a.564.564,0,0,0-.163.506l.757,4.657L8.25,12.737a.5.5,0,0,0-.461,0Z" transform="translate(-0.001 -0.499)" fillRule="evenodd"/>
    </svg>
const fullStarRate = 
    <svg width="16" height="16" viewBox="0 0 16 16" className="bi bi-star-fill" fill="var(--tempedge-orange)" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
    </svg>

const CHAR_LIMIT = 50;

function Comment(props) {

  const personProps=props?.payroll
  const modalcloses=props?.modalClose
  const location  = useLocation();
  const baseUrlTempEdge = REACT_APP_BASE_URL_TEMPEDGE;
  const orgId = JSON.parse(localStorage.getItem('agency')).organizationEntity.orgId;

  
  let entityId = useSelector((state) => {
    return location.pathname.indexOf('client') !== -1 ?  state.tempEdge.clientId :state.tempEdge.personId;
  }, shallowEqual);

  if(personProps){entityId= personProps?.person?.personId ?personProps?.person?.personId :personProps.personId }
  
  const [listComments, setListComments] =  useState([]);
  const [comment, setComment] = useState('');
  const [raiting, setRaiting] = useState(0);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const handleOnChange = (event) => {
    setComment(event.target.value);
  }

  const name = useMemo(() => {
        if(location.pathname.indexOf('client') === -1)
            return props?.data?.fullName;
        return  props?.data?.clientName;
  }, [location, props?.data]);

  const loadComments = useCallback( async () => {
      let listComments = [];
      const request = {
        orgId,
        filterBy : {
          module : location.pathname.indexOf('client') !== -1 ?  'com.tempedge.msg.menu.client' :'com.tempedge.msg.menu.employee',
          moduleId: `${entityId}`
        }
      }
      let token = localStorage.getItem('access_token');
      const response = await axios({
        url: baseUrlTempEdge + REACT_APP_URL_COMMONS_COMMENT_LIST,
        method: 'post',
        headers: {
          'Content-Type': 'application/json'
        },
        data: request,
        params: {
          access_token: token
        }
      });
      if(response.data.status === 200 && response.data.code === 'TE00') {
          listComments = response.data.result.content;
      }
      setListComments(listComments);
  }, [baseUrlTempEdge, entityId, location,orgId]);

  const save = async () => {
    const request = {
        orgId,
        comment,
        score: raiting,
        module: location.pathname.indexOf('client') !== -1
            ? 'com.tempedge.msg.menu.client'
            : 'com.tempedge.msg.menu.employee',
        moduleId: entityId,
    };
    let token = localStorage.getItem('access_token');

    const response = await axios({
        url: baseUrlTempEdge + REACT_APP_URL_COMMONS_COMMENT_SAVE,
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
        data: request,
        params: {
            access_token: token,
        },
    });

    if (response.status === 200) {
        setComment('');
        setRaiting(0);

        if (personProps) {
            props?.setShowModal(false);
        }
        if (modalcloses) {
            props?.modalClose();
        }

        window.location.reload();
    }
};

  useEffect(() => {
     loadComments(); 
  },[loadComments]);

  const roundNumber = (number) => {
    
    const parteDecimal = number % 1;

    const parteEntera =  number - parteDecimal;

    let aux = Math.ceil(parteDecimal * 10);

    if(aux >= 0 && aux < 3)
      aux = 0;
          
    if(aux >= 3 && aux <= 5)
       aux = 0.5;
       
    if(aux > 5 && aux < 7)
        aux = 0.5;

    if(aux >= 7 )
       aux = 1;    

    return parteEntera + aux;
  }

  const averageScore = useMemo(() => {

    if(listComments === undefined || listComments === null || listComments.length === 0)
      return 0;

    let map = new Map();
    listComments.forEach((comment) => {
      if(map.has(comment.score)){
        map.set(comment.score,map.get(comment.score) + 1);
      }else{
        map.set(comment.score,1);
      }
    });
    let totalWeight = 0;
    let totalScore = 0;
    for(let [score, count] of map){
       const weightMultipliedByNumber = score*count;
       totalWeight += weightMultipliedByNumber;
       totalScore += count;
    }

    const result = (totalWeight / totalScore).toFixed(1);

    return roundNumber(result);

  },[listComments]);

  const comments = useMemo(
     () => <ListComments comments={listComments}/>
  ,[listComments]);
  if (loading) return <Loader />;
  return (
    <React.Fragment>
        <ContainerBlue label={name} style={{margin:0}} btns={comments}>
            <div>
                <div className="col-12">
                  <div className="row container-form">
                <div className="col-12 col-md-7">
                  <label className="control-label"><Translate id="com.tempedge.msg.label.comments"/></label>
                  <textarea name = 'commentBox' className="form-control tempEdge-input-box" type="textarea" rows="2" value={comment} maxLength={CHAR_LIMIT} onChange = {handleOnChange} placeholder="Add your comment here"/>
                  <h6 style={{ paddingTop: 10 }}><p className="text-right">{CHAR_LIMIT - comment.length} <Translate id="com.tempedge.msg.label.charsrem"/> </p></h6>
                  <div className="row container-form">
                    <div className="col-12 col-md-3">
                      <label className="control-label"><Translate id="com.tempedge.msg.label.score"/></label>
                    </div>
                    <div className="col-12 col-md-9" style={{ paddingTop: 5 }}>
                      <Rating
                        emptySymbol={emptyStarRate}
                        fullSymbol={fullStarRate} 
                        initialRating={raiting}
                        fractions={2}
                        onChange={(value) => {setRaiting(value)}}
                      />
                    </div>
                  </div>    
              </div>  
              <div className="col-12 col-md-5 text-center vertical-center">
                <h1 className="employeeScore">{averageScore}</h1>
                  <div className="RatingBarStars">
                    <Rating 
                      emptySymbol={emptyStarScore}
                      fullSymbol={fullStarScore} 
                      initialRating={averageScore}
                      readonly
                    />
                  </div> 
                  <h4 style={{ paddingTop: 20 }}>{listComments.length} <Translate id="com.tempedge.msg.label.totalcomments"/></h4>
                </div>               
              </div>  
                </div>
                <div className="row d-flex justify-content-between align-items-center">
                    <div className="col-md-4 btn-sm ml-auto">
                        <SaveBtn onClick={() => save()} invalid={comment.length === 0 || raiting <= 0}/>
                    </div>
                </div>
            </div>
        </ContainerBlue>
      </React.Fragment>
  )
}

export default withLocalize(Comment);