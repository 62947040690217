import React from 'react'
import BlueCommonBtn from '../../../components/common/Buttons/BlueCommonBtn';
import { navigateTo } from '../../../utils/NavigateTo';
import TextWithLogo from '../../../components/common/TempEdgeLogoLabel/TempEdgeLogoLabel';

export const GetStartedSection = () => {
    const route = '/signin/en';
    return (
        <>
            <div className='get-started-container'>
                <div className="container">
                    <div className="row get-started-elements-container">
                        <div className='label-text-white'>
                            Sign in into <TextWithLogo fontSize="20px" color="white" />
                        </div>
                        <div className="col-md-2">
                            <BlueCommonBtn
                                tag={"com.tempedge.msg.label.sign_in"}
                                onClick={() => navigateTo(route)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
