/* eslint-disable react/no-string-refs */
import { push } from 'connected-react-router';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Translate, withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import Modal from '../../../Modals/Modal/Modal';
import { clearTempedgeStoreProp, getList, tempedgeAPI } from '../../../Redux/actions/tempEdgeActions';
import types from '../../../Redux/actions/types';
import ActiveLanguageAddTranslation from '../../../components/common/ActiveLanguageAddTranslation/ActiveLanguageAddTranslation';
import BlueCommonBtn from '../../../components/common/Buttons/BlueCommonBtn';
import { DangerButton, SuccessButton } from '../../../components/common/Buttons/Button';
import GrayCommonBtn from '../../../components/common/Buttons/GrayCommonBtn';
import Container from '../../../components/common/Container/Container';
import Datepicker from '../../../components/common/Datepicker/Datepicker';
import Loader from '../../../components/common/Loader/Loader';
import OutcomeBar from '../../../components/common/OutcomeBar';
import {
   REACT_APP_URL_COUNTRY_LIST_ALL,
   REACT_APP_URL_FIND_PAYROLLCODE,
   REACT_APP_URL_ORG_DEPARTMENT_FIND_ALL,
   REACT_APP_URL_PERSON_ACTIVATE,
   REACT_APP_URL_PERSON_IDENTIFICATION,
   REACT_APP_URL_PERSON_INTERNAL_VIEW,
   REACT_APP_URL_PERSON_SKILL_LIST,
   REACT_APP_URL_PERSON_TERMINATE,
   REACT_APP_URL_PERSON_VIEW,
   REACT_APP_URL_TAXES_EMPLOYEE_FIND_BY_PERSON_ID,
} from '../../../components/common/Routes/URLs';
import DownloadFile from '../../../components/common/downloadFile/DownloadFile';
import TempEdgeApi from '../../../services/TempEdgeApi';
import SsnIcons from '../../../utils/Icons/SsnIcons';
import { limitString, normalizePhone } from '../../../utils/ReGexs';
import { DATE_FORMAT } from '../../../components/common/Constants/Constants';

const icons = '24px';

class ViewEmployee extends Component {
   _isMounted = false;
   static mapGender(gender) {
      if (gender === 'M') {
         return <Translate id="com.tempedge.msg.label.gender.male" />;
      }
      if (gender === 'F') {
         return <Translate id="com.tempedge.msg.label.gender.female" />;
      }
      return <Translate id="com.tempedge.msg.label.select" />;
   }

   static mapYesNoOptions(item) {
      switch (item) {
         case true:
            return <Translate id="com.tempedge.msg.label.yes" />;
         case false:
            return <Translate id="com.tempedge.msg.label.no" />;
         case '0':
            return <Translate id="com.tempedge.msg.label.yes" />;
         case '1':
            return <Translate id="com.tempedge.msg.label.no" />;
         default:
            return 'N/A';
      }
   }

   static mapTestDates(item, date) {
      if (item === true) {
         return date;
      }
      return 'N/A';
   }

   constructor(props) {
      super(props);
      this.state = {
         tabId: 0,
         orgId: JSON.parse(localStorage.getItem('agency'))?.organizationEntity?.orgId,
         selectedEmployee: props.personId,
         loading: false,
         resultBar: '',
         dateModal: false,
         date: new Date(),
         btnDisabled: true,
         showModal: false,
         toggleModal: false,
         maritalStatusFind: [],
         personIdentification: null,
         stateTaxes: [],
         apiDropdownOptions: {},
      };
      this.addTranslationsForActiveLanguage();
   }

   componentDidMount = async () => {
      this._isMounted = true;
      this.setState({
         loading: true,
      });

      if (this._isMounted) {
         await this.fetchData();
         await this.fetchStateTaxes();
      }

      this.setState({
         loading: false,
      });
   };

   componentWillUnmount() {
      this._isMounted = false;
   }

   componentDidUpdate(prevProps) {
      if (prevProps.activeLanguage !== this.props.activeLanguage) {
         this.props.push(`/employee/view/${this.props.activeLanguage.code}`);
         this.addTranslationsForActiveLanguage();
      }
   }

   addTranslationsForActiveLanguage = async () => {
      await ActiveLanguageAddTranslation(this.props.activeLanguage, this.props.addTranslationForLanguage);
   };

   mapSkills = (skill, selectedSkills) => {
      if (selectedSkills) {
         let existSkill;

         for (let i = 0; i < selectedSkills.length; i++) {
            if (skill.skillId === selectedSkills[i].skillId) {
               existSkill = skill;
               break;
            }
         }

         return (
            <div key={skill.skillId} className={`form-check skill-item ${existSkill ? 'skill-selected' : 'skill-NO-selected'}`}>
               <ul>
                  <li id={`skillId${skill.skillId}`} name={`data-skill-id-${skill.skillId}`} data-skill-id={skill.skillId}>
                     <label htmlFor={`skillId${skill.skillId}`} className="form-check-label">
                        {skill.skill}
                     </label>
                  </li>
               </ul>
            </div>
         );
      }
      return null;
   };

   mapCountry = (userCountry, returnField, findField) => {
      const employeeCountry = this.props.countryRegionList.filter((country) => country.countryId === this.state.selectedEmployee.country);

      if (employeeCountry && this.props.countryRegionList) {
         return this.mapList(this.props.countryRegionList, returnField, findField, userCountry);
      }
   };

   mapRegion = (userState, returnField, findField) => {
      const selectedCountry = this.props.countryRegionList.filter((country) => country.countryId === this.state.selectedEmployee.country);

      if (selectedCountry.length > 0) {
         const regions = selectedCountry[0].regionEntityList;

         if (userState && regions) {
            return this.mapList(regions, returnField, findField, userState);
         }
      }
   };

   /**
    * Esta función busca un valor un arreglo de objetos y retorna los campos enviados por parámetro
    * @param {Array} list lista de datos
    * @param {String} returnField campo a retornar de la lista
    * @param {String} findField campo a buscar de la lista (campo a comparar)
    * @param {*} findValue valor a encontrar (valor de comparación)
    */
   mapList = (list, returnField, findField, findValue) => {
      if (list) {
         for (let index = 0; index < list.length; index++) {
            if (list[index][findField] === findValue) {
               return list[index][returnField];
            }
         }
      }
      return null;
   };

   mapAllowance() {
      const { selectedEmployee } = this.state;

      if (selectedEmployee.taxAllowance !== null) {
         return (
            <>
               <label className="label-view-data">{selectedEmployee.taxAllowance}</label>
            </>
         );
      } else if ((selectedEmployee.taxAllowanceJunior || selectedEmployee.taxAllowanceSenior) !== null) {
         return (
            <>
               <label className="control-label">
                  <Translate id="com.tempedge.msg.label.allowancejr" />
               </label>
               <label className="label-view-data">{this.state.selectedEmployee.taxAllowanceJunior}</label>
               <label className="control-label">
                  <Translate id="com.tempedge.msg.label.allowancesr" />
               </label>
               <label className="label-view-data">{this.state.selectedEmployee.taxAllowanceSenior}</label>
            </>
         );
      } else {
         return <label className="label-view-data">{'0'}</label>;
      }
   }

   async fetchData() {
      const { personId, tempedgeAPI, countryRegionList, parametersFilterFind } = this.props;
      const { orgId } = this.state;
      if (countryRegionList.length === 0) {
         await this.props.getList(REACT_APP_URL_COUNTRY_LIST_ALL, types.GET_COUNTRY_REGION_LIST);
      }
      await this.props.tempedgeAPI(
         REACT_APP_URL_ORG_DEPARTMENT_FIND_ALL,
         { orgId, page: 0, size: 200, filterBy: {} },
         types.GET_ORG_DEPARTMENT_LIST,
      );
      await this.props.tempedgeAPI(REACT_APP_URL_PERSON_SKILL_LIST, { orgId }, types.SKILLS_LIST);

      const payload = {
         orgId: this.state.orgId,
         id: personId,
         filterBy: {
            ...parametersFilterFind,
         },
      };

      const URLView =
         parametersFilterFind && parametersFilterFind.personType === '3' ? REACT_APP_URL_PERSON_INTERNAL_VIEW : REACT_APP_URL_PERSON_VIEW;
      const response = await tempedgeAPI(URLView, payload, types.VIEW_PERSON);

      if (response?.data?.result) {
         this.setState({
            selectedEmployee: response.data.result,
         });
      } else {
         this.cancelAction();
      }

      const { data: res } = await TempEdgeApi.post({
         url: REACT_APP_URL_FIND_PAYROLLCODE,
         payload: { parameterKey: 'com.tempedge.global.param.maritalstatus' },
      });
      if (res.status === 200) {
         const newMarital = res.result.data?.content?.map((item) => {
            return {
               label: item.description,
               value: item.code,
            };
         });
         this.setState({
            maritalStatusFind: newMarital,
         });
      }
   }

   async fetchStateTaxes() {
      try {
         const { data: regionsTaxes } = await TempEdgeApi.post({
            url: REACT_APP_URL_TAXES_EMPLOYEE_FIND_BY_PERSON_ID,
            payload: {
               orgId: this.state.orgId,
               id: this.props.personId,
            },
         });

         if (regionsTaxes.status === 200) {

            const apiTaxes = regionsTaxes.result.taxParams.filter((tax) => tax.fieldType.toLowerCase() === 'api');
            const apiOptionsMap = {};

            for (const tax of apiTaxes) {
               try {
                  const rulesObj = JSON.parse(tax.rules);
                  if (!rulesObj.api) continue; 

                  const payload = {};
                  if (rulesObj.body) {
                     const bodyParams = rulesObj.body
                        .replace(/[{}]/g, '')
                        .split(',')
                        .map((p) => p.trim());
                     bodyParams.forEach((param) => {
                        if (param === 'regionId') payload[param] = this.state.selectedEmployee?.region;
                        if (param === 'countryId') payload[param] = this.state.selectedEmployee?.country;
                     });
                  }

                  const { data: response } = await TempEdgeApi.post({
                     url: rulesObj.api,
                     payload,
                  });

                  if (response.status === 200 && response.result) {
                     const matchingItem = response.result.find((item) => {
                        const itemKeyValue = item[rulesObj.key];
                        const taxValue = tax.value;

                        return String(itemKeyValue) === String(taxValue);
                     });

                     if (matchingItem) {
                        apiOptionsMap[tax.taxParamsId] = matchingItem[rulesObj.value];
                     }

                     this.setState({
                        stateTaxes: regionsTaxes.result,
                        apiDropdownOptions: apiOptionsMap,
                     });
                  }
               } catch (error) {}
            }

            this.setState({
               stateTaxes: regionsTaxes.result,
               apiDropdownOptions: apiOptionsMap,
            });
         }
      } catch (error) {
         console.error('Error fetching state taxes:', error);
         this.setState({ stateTaxes: [] });
      }
   }

   editAction() {
      this.props.history.push({
         pathname: `/employee/update/${this.props.activeLanguage.code}`,
      });
   }

   async findIdentification() {
      const { data: ssn } = await TempEdgeApi.post({
         url: REACT_APP_URL_PERSON_IDENTIFICATION,
         payload: {
            orgId: this.state.orgId,
            id: this.props.personId,
         },
      });
      if (ssn.status === 200) {
         this.setState({
            personIdentification: ssn.result,
         });
      }
   }

   // Cancel actions
   cancelAction() {
      this.props.clearTempedgeStoreProp('saveEmployeeList');
      window.history.back();
   }

   termDateEmployee = async () => {
      this.setState({
         resultBar: null,
         loading: true,
      });
      const payload = {
         orgId: this.state.orgId,
         id: this.state.selectedEmployee.personId,
         date: moment(this.state.date).format('YYYY-MM-DD'),
      };
      const response = await this.props.tempedgeAPI(REACT_APP_URL_PERSON_TERMINATE, payload, types.VIEW_PERSON_CHANGE_STATUS);

      if ([200, 201, 203, 204].indexOf(response?.status) > -1) {
         if (response.data.status === 200) {
            this.showSuccessResultBar(response.data);
            this.fetchData();
            this.setState({ showModal: false, loading: false });
         } else {
            this.showErrorResultBar(response.data);
            this.setState({ loading: false, showModal: false });
         }
      }
      setTimeout(() => {
         this.setState({
            resultBar: null,
         });
      }, 5000);
   };

   activeEmployee = async () => {
      this.setState({ loading: true });
      const payload = {
         orgId: this.state.orgId,
         id: this.state.selectedEmployee.personId,
      };
      const response = await this.props.tempedgeAPI(REACT_APP_URL_PERSON_ACTIVATE, payload, types.VIEW_PERSON_CHANGE_STATUS);

      if ([200, 201, 203, 204].indexOf(response?.status) > -1) {
         if (response.data.status === 200) {
            this.showSuccessResultBar(response.data);
            this.fetchData();
            this.setState({ showModal: false, loading: false });
         } else {
            this.showErrorResultBar(response.data);
            this.setState({ loading: false });
         }
      }
      setTimeout(() => {
         this.setState({
            resultBar: null,
         });
      }, 1000);
   };

   getDate = (date) => {
      if (moment(date).isValid()) {
         this.setState({
            date,
            btnDisabled: false,
         });
      }
   };

   modalContent = () => {
      const { translate } = this.props;
      return (
         <>
            <h3 className="text-center">{translate('com.tempedge.msg.label.pickadate')}</h3>
            <div className="row text-center mt-4">
               <div className="form-group col-12 col-lg-6 mx-auto">
                  <label className="text-left label-p">{translate('com.tempedge.msg.label.date')}</label>
                  <Datepicker
                     customClass="form-control tempEdge-input-box"
                     shouldCloseOnSelect
                     onChange={this.getDate}
                     selectedValue={this.state.date}
                     open
                     maxDate={moment().toDate()}
                     showPopperArrow={false}
                  />
               </div>
            </div>
         </>
      );
   };

   modalButtons() {
      return (
         <div className="text-center col-12">
            <button type="button" className="btn btn-blue w-25" onClick={() => this.termDateEmployee()} disabled={this.state.btnDisabled}>
               {this.props.translate('com.tempedge.msg.label.submit')}
            </button>
         </div>
      );
   }

   showResultBar(data) {
      this.setState({
         resultBar: <OutcomeBar response={data} />,
      });
   }

   showSuccessResultBar(data) {
      this.showResultBar(data);
   }

   showErrorResultBar(data) {
      this.showResultBar(data);
   }

   mapMaritalStatus(id) {
      const itemMaritalFind = id || this.state.selectedEmployee.maritalStatus;
      const marital = this.state.maritalStatusFind.find((item) => {
         return item.value === itemMaritalFind?.toString();
      });
      return marital ? <Translate id={marital?.label} /> : '';
   }

   renderTooltip = (props) => {
      if (props !== undefined && props !== '') {
         return (
            <Tooltip id="popover-contained" style={{ fontSize: '15px' }}>
               {props}
            </Tooltip>
         );
      }
      return <div />;
   };

   handleMouseOut = () => {
      this.setState({ personIdentification: '' });
   };

   renderApiValue = (tax) => {
      const description = this.state.apiDropdownOptions[tax.taxParamsId];
      return description ? <Translate id={description} /> : tax.value;
   };

   render() {
      if (this.state.loading) return <Loader />;

      const { selectedEmployee, tabId, resultBar } = this.state;
      const hireDate = selectedEmployee?.hireDate ? moment(selectedEmployee.hireDate).format(DATE_FORMAT) : '';
      const birthDay = selectedEmployee?.birthDay ? moment(selectedEmployee.birthDay).format(DATE_FORMAT) : '';
      const age = selectedEmployee?.birthDay ? moment().diff(selectedEmployee.birthDay, 'years', false) : 0;
      const backgroundTestDate = selectedEmployee?.backgroundTestDate
         ? moment(selectedEmployee.backgroundTestDate).format(DATE_FORMAT)
         : '';
      const drugTestDate = selectedEmployee?.drugTestDate ? moment(selectedEmployee.drugTestDate).format(DATE_FORMAT) : '';

      let sortedSkillList = [];
      if (typeof this.props.skillsList !== 'undefined' && Array.isArray(this.props.skillsList)) {
         sortedSkillList = this.props.skillsList.sort((a, b) => {
            const x = a.skill;
            const y = b.skill;
            return x < y ? -1 : x > y ? 1 : 0;
         });
      }

      const name = '';
      const fullName = name.concat(
         selectedEmployee?.firstName ? selectedEmployee.firstName : '',
         ' ',
         selectedEmployee?.middleName ? selectedEmployee.middleName : '',
         ' ',
         selectedEmployee?.lastName ? selectedEmployee.lastName : '',
      );

      const btns = (
         <div className="row">
            <div className="col-md-6">
               <GrayCommonBtn tag={'com.tempedge.msg.label.cancel'} onClick={() => this.cancelAction()} />
            </div>
            <div className="col-md-6">
               <BlueCommonBtn tag={'com.tempedge.msg.label.edit'} onClick={() => this.editAction()} />
            </div>
         </div>
      );

      return (
         <Container title="com.tempedge.employee.title.viewemployee" btns={btns}>
            <div className="col-12">{resultBar}</div>
            <div className="row container-content mx-auto mb-5">
               <div
                  className={
                     selectedEmployee.termDate !== null
                        ? 'col-12 sm-auto col-md-4 col-lg-4 formPanel-UserDenied'
                        : 'col-12 sm-auto col-md-4 col-lg-4 formPanel-EmployeeData'
                  }
               >
                  <div className="row mx-auto">
                     <div className="col-12 text-center">
                        <p className="label-view-data-name">{fullName}</p>
                     </div>
                     <div className="row col-12">
                        <div className="col-12 text-center">
                           <div className="row">
                              <div className="col-4 align-middle mb-3">EE#</div>
                              <div className="col-8">
                                 <label className="label-view-data-info text-left p-0 w-100 align-top">
                                    {selectedEmployee && selectedEmployee.employeeId ? selectedEmployee.employeeId : ''}
                                 </label>
                              </div>
                           </div>
                           <div className="row">
                              <div className="col-4 " onMouseOut={this.handleMouseOut}>
                                 <OverlayTrigger
                                    placement="top"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={this.renderTooltip(this.state.personIdentification)}
                                 >
                                    <div onClick={() => this.findIdentification()}>{<SsnIcons />}</div>
                                 </OverlayTrigger>
                              </div>
                              <div className="col-8">
                                 <label className="label-view-data-info text-left p-0 w-100 align-top">
                                    {selectedEmployee && selectedEmployee.identification ? selectedEmployee.identification : ''}
                                 </label>
                              </div>
                           </div>
                           <div className="row">
                              <div className="col-4 align-middle mb-3">
                                 <svg
                                    width={icons}
                                    height={icons}
                                    viewBox="0 0 16 16"
                                    className="bi bi-person-circle"
                                    fill="currentColor"
                                    xmlns="http://www.w3.org/2000/svg"
                                 >
                                    <path d="M13.468 12.37C12.758 11.226 11.195 10 8 10s-4.757 1.225-5.468 2.37A6.987 6.987 0 0 0 8 15a6.987 6.987 0 0 0 5.468-2.63z" />
                                    <path fillRule="evenodd" d="M8 9a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                                    <path fillRule="evenodd" d="M8 1a7 7 0 1 0 0 14A7 7 0 0 0 8 1zM0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8z" />
                                 </svg>
                              </div>
                              <div className="col-8">
                                 <label className="label-view-data-info text-left p-0 w-100 align-top">
                                    {selectedEmployee && this.constructor.mapGender(selectedEmployee.gender)
                                       ? this.constructor.mapGender(selectedEmployee.gender)
                                       : ''}
                                 </label>
                              </div>
                           </div>
                           <div className="row">
                              <div className="col-4 align-middle mb-3">
                                 <svg
                                    width={icons}
                                    height={icons}
                                    viewBox="0 0 16 16"
                                    className="bi bi-calendar2-event"
                                    fill="currentColor"
                                    xmlns="http://www.w3.org/2000/svg"
                                 >
                                    <path
                                       fillRule="evenodd"
                                       d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM2 2a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H2z"
                                    />
                                    <path d="M2.5 4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H3a.5.5 0 0 1-.5-.5V4zM11 7.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1z" />
                                 </svg>
                              </div>
                              <div className="col-8">
                                 <label className="label-view-data-info text-left p-0 w-100 align-top">
                                    {selectedEmployee && age ? age : ''} <Translate id="com.tempedge.msg.label.yearsold" />
                                 </label>
                              </div>
                           </div>
                           <div className="row">
                              <div className="col-4 align-middle mb-3">
                                 <svg
                                    width={icons}
                                    height={icons}
                                    viewBox="0 0 16 16"
                                    className="bi bi-calendar2-event"
                                    fill="currentColor"
                                    xmlns="http://www.w3.org/2000/svg"
                                 >
                                    <path
                                       fillRule="evenodd"
                                       d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM2 2a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H2z"
                                    />
                                    <path d="M2.5 4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H3a.5.5 0 0 1-.5-.5V4zM11 7.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1z" />
                                 </svg>
                              </div>
                              <div className="col-8">
                                 <label className="label-view-data-info text-left p-0 w-100 align-top">
                                    {selectedEmployee && birthDay ? birthDay : ''}
                                 </label>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="col-12 sm-auto col-md-8 col-lg-8 formPanel-EmployeeInfo">
                  <div className="center-block" ref="viewEmployee">
                     <ul className="nav nav-panel">
                        <li className="nav-item first-panel" onClick={() => this.setState({ key: 0 })}>
                           <a className={tabId === 0 ? 'nav-link active' : 'nav-link'} data-toggle="tab" href="#tab1">
                              <Translate id="com.tempedge.msg.label.information" />
                           </a>
                        </li>
                        <li className="nav-item panel" onClick={() => this.setState({ key: 1 })}>
                           <a className={tabId === 1 ? 'nav-link active' : 'nav-link'} data-toggle="tab" href="#tab2">
                              <Translate id="com.tempedge.msg.label.taxes" />
                           </a>
                        </li>
                        <li className="nav-item panel" onClick={() => this.setState({ key: 2 })}>
                           <a className={tabId === 2 ? 'nav-link active' : 'nav-link'} data-toggle="tab" href="#tab3">
                              <Translate id="com.tempedge.msg.label.skills" />
                           </a>
                        </li>
                        <li className="nav-item panel" onClick={() => this.setState({ key: 3 })}>
                           <a className={tabId === 3 ? 'nav-link active' : 'nav-link'} data-toggle="tab" href="#tab4">
                              <Translate id="com.tempedge.msg.label.clientcode" />
                           </a>
                        </li>
                        <li className="nav-item panel" onClick={() => this.setState({ key: 4 })}>
                           <a className={tabId === 4 ? 'nav-link active' : 'nav-link'} data-toggle="tab" href="#tab5">
                              <Translate id="com.tempedge.msg.label.files" />
                           </a>
                        </li>
                        <li className="nav-item last-panel" onClick={() => this.setState({ key: 5 })}>
                           <a className={tabId === 5 ? 'nav-link active' : 'nav-link'} data-toggle="tab" href="#tab6">
                              <Translate id="com.tempedge.msg.label.misc" />
                           </a>
                        </li>
                     </ul>
                     <div className="form-group" style={{ marginLeft: '1px' }}>
                        <div
                           className={
                              selectedEmployee.termDate !== null
                                 ? 'tab-content formPanelBody formPanel-EmployeeInfoDenied'
                                 : 'tab-content formPanelBody formPanel-EmployeeInfo'
                           }
                        >
                           <div className={tabId === 0 ? 'tab-pane fade show active' : 'tab-pane fade'} id="tab1" role="tabpanel">
                              <div className="form-group row">
                                 <div className="col-10 col-md-5 col-lg-4">
                                    <label className="control-label">
                                       <Translate id="com.tempedge.msg.label.office" />
                                    </label>
                                    <label className="label-view-data">
                                       {selectedEmployee && selectedEmployee.office ? selectedEmployee.office.name : ''}
                                    </label>
                                 </div>
                                 <div className="form-group col-10 col-md-5 col-lg-4">
                                    <label className="control-label">
                                       <Translate id="com.tempedge.msg.label.orgdepartment" />
                                    </label>
                                    <label className="label-view-data">
                                       {selectedEmployee && selectedEmployee.empDepartment
                                          ? this.mapList(
                                               this.props.orgDepartmentList,
                                               'name',
                                               'orgDepartmentCode',
                                               selectedEmployee.empDepartment,
                                            )
                                          : ''}
                                    </label>
                                 </div>
                                 <div className="col-10 col-md-5 col-lg-4">
                                    <label className="control-label">
                                       <Translate id="com.tempedge.msg.label.hiredate" />
                                    </label>
                                    <label className="label-view-data">{selectedEmployee && hireDate ? hireDate : ''}</label>
                                 </div>
                              </div>
                              <hr />
                              <br />
                              <div className="form-group row">
                                 <div className="col-10 col-md-5 col-lg-4">
                                    <label className="control-label">
                                       <Translate id="com.tempedge.msg.label.phone" />
                                    </label>
                                    <label className="label-view-data">
                                       {selectedEmployee && selectedEmployee.phone ? normalizePhone(selectedEmployee.phone, 0) : ''}
                                    </label>
                                 </div>
                                 <div className="col-10 col-md-8">
                                    <label className="control-label">
                                       <Translate id="com.tempedge.msg.label.email" />
                                    </label>
                                    <label className="label-view-data">
                                       {selectedEmployee && selectedEmployee.email
                                          ? selectedEmployee.email &&
                                            limitString({
                                               value: selectedEmployee.email,
                                               limit: 33,
                                               replace: '...',
                                            })
                                          : ''}
                                    </label>
                                 </div>
                              </div>
                              <div className="form-group row">
                                 <div className="col-10 col-md-5 col-lg-4">
                                    <label className="control-label">
                                       <Translate id="com.tempedge.msg.label.country" />
                                    </label>
                                    <label className="label-view-data">
                                       {selectedEmployee && selectedEmployee.country
                                          ? this.mapCountry(selectedEmployee.country, 'name', 'countryId')
                                          : ''}
                                    </label>
                                 </div>
                              </div>
                              <div className="form-group row">
                                 <div className="col-10 col-md-8">
                                    <label className="control-label">
                                       <Translate id="com.tempedge.msg.label.address" />
                                    </label>
                                    <label className="label-view-data">
                                       {selectedEmployee && selectedEmployee.address
                                          ? limitString({
                                               value: selectedEmployee.address,
                                               limit: 20,
                                               replace: '...',
                                            })
                                          : ''}
                                    </label>
                                 </div>
                                 <div className="col-10 col-md-5 col-lg-4">
                                    <label className="control-label">
                                       <Translate id="com.tempedge.msg.label.agencyaddress2" />
                                    </label>
                                    <label className="label-view-data">
                                       {selectedEmployee && selectedEmployee.address2
                                          ? limitString({
                                               value: selectedEmployee.address2,
                                               limit: 18,
                                               replace: '...',
                                            })
                                          : ''}
                                    </label>
                                 </div>
                              </div>
                              <div className="form-group row">
                                 <div className="col-10 col-md-5 col-lg-4">
                                    <label className="control-label">
                                       <Translate id="com.tempedge.msg.label.city" />
                                    </label>
                                    <label className="label-view-data">
                                       {selectedEmployee && selectedEmployee.city ? selectedEmployee.city : ''}
                                    </label>
                                 </div>
                                 <div className="col-10 col-md-5 col-lg-4">
                                    <label className="control-label">
                                       <Translate id="com.tempedge.msg.label.state" />
                                    </label>
                                    <label className="label-view-data">
                                       {selectedEmployee && selectedEmployee.country && selectedEmployee.region
                                          ? this.mapRegion(selectedEmployee.region, 'name', 'regionId')
                                          : ''}
                                    </label>
                                 </div>
                                 <div className="col10 col-md-5 col-lg-4">
                                    <label className="control-label">
                                       <Translate id="com.tempedge.msg.label.agencyzipcode" />
                                    </label>
                                    <label className="label-view-data">
                                       {selectedEmployee && selectedEmployee.zipcode ? selectedEmployee.zipcode : ''}
                                    </label>
                                 </div>
                              </div>
                           </div>
                           <div className={tabId === 1 ? 'tab-pane fade show active' : 'tab-pane fade'} id="tab2" role="tabpanel">
                              <div className="col-md-12 text-center">
                                 <label className="label-view-data-name-tax">{this.state?.stateTaxes?.formName}</label>
                              </div>
                              <div className="row">
                                 <div className="col-md-12">
                                    {this.state?.stateTaxes?.taxParams?.length > 0 &&
                                       this.state?.stateTaxes?.taxParams
                                          .sort((a, b) => a.personTaxParamId - b.personTaxParamId)
                                          .map((tax) => (
                                             <div key={tax?.taxParamsId} className="form-group row">
                                                <div className="col-10 col-md-6 col-lg-8">
                                                   <label className="control-label">{tax?.label && <Translate id={tax?.label} />}</label>
                                                </div>
                                                <div className="col-2 col-md-6 col-lg-4">
                                                   {tax?.fieldType === 'api' ? (
                                                      <label className="control-label">
                                                         <Translate id={this.state.apiDropdownOptions[tax.taxParamsId]} />
                                                      </label>
                                                   ) : tax.value === '1' ? (
                                                      <label className="control-label">
                                                         <Translate id="com.tempedge.msg.label.yes" />
                                                      </label>
                                                   ) : tax.value === '2' ? (
                                                      <label className="control-label">
                                                         <Translate id="com.tempedge.msg.label.no" />
                                                      </label>
                                                   ) : (
                                                      <label className="control-label">{this.renderApiValue(tax)}</label>
                                                   )}
                                                </div>
                                             </div>
                                          ))}
                                 </div>
                              </div>
                              {this.state?.stateTaxes?.taxParams?.length > 0 && (
                                 <div className="col-md-12 text-left mt-3">
                                    <label className="label-view-data-name-tax">
                                       <Translate id="com.tempedge.msg.label.notetax" />
                                    </label>
                                 </div>
                              )}
                           </div>
                           <div className={tabId === 2 ? 'tab-pane fade show active' : 'tab-pane fade'} id="tab3" role="tabpanel">
                              <div className="row">
                                 <div className="col-md-6">
                                    {typeof sortedSkillList !== 'undefined'
                                       ? sortedSkillList
                                            .filter((_, index) => index < (this.props.skillsList.length - 1) / 2)
                                            .map((item) => selectedEmployee && this.mapSkills(item, selectedEmployee.skills))
                                       : ''}
                                 </div>
                                 <div className="col-md-6">
                                    {typeof sortedSkillList !== 'undefined'
                                       ? sortedSkillList
                                            .filter((_, index) => index > (this.props.skillsList.length - 1) / 2)
                                            .map((item) => selectedEmployee && this.mapSkills(item, selectedEmployee.skills))
                                       : ''}
                                 </div>
                              </div>
                           </div>
                           <div className={tabId === 3 ? 'tab-pane fade show active' : 'tab-pane fade'} id="tab4" role="tabpanel">
                              <div className="form-group row">
                                 <div className="col-10 col-md-5 col-lg-4">
                                    <label className="control-label">
                                       <Translate id="com.tempedge.msg.label.company" />
                                    </label>
                                 </div>
                                 <div className="form-group col-10 col-md-5 col-lg-4">
                                    <label className="control-label">
                                       <Translate id="com.tempedge.msg.label.clientcode" />
                                    </label>
                                 </div>
                              </div>
                              {selectedEmployee &&
                                 selectedEmployee.personCodeEntityList &&
                                 selectedEmployee.personCodeEntityList.map((item, index) => {
                                    return (
                                       <div key={index} className="form-group row">
                                          <div className="col-10 col-md-5 col-lg-4">{item.client.clientName}</div>
                                          <div className="form-group col-10 col-md-5 col-lg-4">{item.clientCode}</div>
                                       </div>
                                    );
                                 })}
                           </div>
                           <div className={tabId === 4 ? 'tab-pane fade show active' : 'tab-pane fade'} id="tab5" role="tabpanel">
                              {selectedEmployee && selectedEmployee.fileEntities ? (
                                 <>
                                    <div className="form-group row">
                                       <div className="form-group col-10 col-md-5 col-lg-4">
                                          <label className="control-label">
                                             <Translate id="com.tempedge.msg.label.filetype" />
                                          </label>
                                       </div>
                                       <div className="form-group col-10 col-md-5 col-lg-4">
                                          <label className="control-label">
                                             <Translate id="com.tempedge.msg.label.filename" />
                                          </label>
                                       </div>
                                    </div>
                                    {selectedEmployee.fileEntities.map((item, index) => {
                                       return (
                                          <div key={index} className="form-group row">
                                             <div className="col-10 col-md-5 col-lg-4">{item.type}</div>
                                             <div className="form-group col-10 col-md-5 col-lg-4">{item.fileName}</div>
                                             <div className="form-group col-10 col-md-5 col-lg-4">
                                                <DownloadFile file={item.file} fileName={item.fileName} />
                                             </div>
                                          </div>
                                       );
                                    })}
                                 </>
                              ) : null}
                           </div>
                           <div className={tabId === 5 ? 'tab-pane fade show active' : 'tab-pane fade'} id="tab6" role="tabpanel">
                              <div className="row">
                                 <div className="col-md-4 border-panel">
                                    <div className="misc-info">
                                       <label className="control-label">
                                          <Translate id="com.tempedge.msg.label.joblocation" />
                                       </label>
                                       <label className="label-view-data">
                                          {selectedEmployee && selectedEmployee.country && selectedEmployee.taxRegion
                                             ? this.mapRegion(selectedEmployee.taxRegion, 'name', 'regionId')
                                             : ''}
                                       </label>
                                    </div>
                                    <div className="misc-info">
                                       <label className="control-label" style={{ marginBottom: 5 }}>
                                          <Translate id="com.tempedge.msg.label.multiplejobs" />
                                       </label>
                                       <label className="label-view-data">
                                          {selectedEmployee?.multipleJobs > 1 && !!selectedEmployee?.multipleJobs ? (
                                             <Translate id="com.tempedge.msg.label.multiplejobsyes" />
                                          ) : (
                                             <Translate id="com.tempedge.msg.label.multiplejobsno" />
                                          )}
                                       </label>
                                    </div>
                                    <div className="misc-info">
                                       <label className="control-label" style={{ marginBottom: 5 }}>
                                          <Translate id="com.tempedge.msg.label.numberofallowances" />
                                       </label>
                                    </div>
                                    <div className="misc-info">{selectedEmployee ? this.mapAllowance() : '0'}</div>
                                 </div>
                                 <div className="col-md-8">
                                    <div className="row">
                                       <div className="col-md-6">
                                          <div className="misc-tests">
                                             <label className="control-label">
                                                <Translate id="com.tempedge.msg.label.maritalstatus" />
                                             </label>
                                             <label className="label-view-data">{selectedEmployee ? this.mapMaritalStatus() : ''}</label>
                                          </div>
                                       </div>
                                       <div className="col-md-6">
                                          <div className="misc-tests">
                                             <label className="control-label">
                                                <Translate id="com.tempedge.msg.label.idexpiration" />
                                             </label>
                                             <label className="label-view-data">{selectedEmployee.idExpiration}</label>
                                          </div>
                                       </div>
                                       <hr className="col-10 border-bottom" />
                                       <div className="col-md-6">
                                          <div className="misc-tests">
                                             <label className="control-label">
                                                <Translate id="com.tempedge.msg.label.drugtest" />
                                             </label>
                                             <label className="label-view-data">
                                                {selectedEmployee && this.constructor.mapYesNoOptions(selectedEmployee.drugTest)
                                                   ? this.constructor.mapYesNoOptions(selectedEmployee.drugTest)
                                                   : ''}
                                             </label>
                                             <label className="control-label">
                                                <Translate id="com.tempedge.msg.label.drugtestdate" />
                                             </label>
                                             <label className="label-view-data misc-test-space">
                                                {selectedEmployee && this.constructor.mapTestDates(selectedEmployee.drugTestDate)
                                                   ? this.constructor.mapTestDates(selectedEmployee.drugTest, drugTestDate)
                                                   : 'N/A'}
                                             </label>
                                          </div>
                                       </div>
                                       <div className="col-md-6">
                                          <div className="misc-tests">
                                             <label className="control-label">
                                                <Translate id="com.tempedge.msg.label.backgroundtest" />
                                             </label>
                                             <label className="label-view-data">
                                                {selectedEmployee && this.constructor.mapYesNoOptions(selectedEmployee.backgroundtest)}
                                             </label>
                                             <label className="control-label">
                                                <Translate id="com.tempedge.msg.label.backgroundtestdate" />
                                             </label>
                                             <label className="label-view-data misc-test-space">
                                                {selectedEmployee && this.constructor.mapTestDates(selectedEmployee.backgroundTestDate)
                                                   ? this.constructor.mapTestDates(selectedEmployee.backgroundtest, backgroundTestDate)
                                                   : 'N/A'}
                                             </label>
                                          </div>
                                       </div>
                                       <hr className="col-10 border-bottom" />
                                       <div className="col-md-6 ">
                                          <div className="misc-tests">
                                             <label className="control-label">
                                                <Translate id="com.tempedge.msg.label.datecreate" />
                                             </label>
                                             <label className="label-view-data">{selectedEmployee && selectedEmployee.dtCreate}</label>
                                          </div>
                                       </div>
                                       <div className="col-md-6 "></div>

                                       <div className="col-md-6 ">
                                          <div className="misc-tests">
                                             <label className="control-label">
                                                <Translate id="com.tempedge.msg.label.termdate" />
                                             </label>
                                             <label className="label-view-data">
                                                {selectedEmployee && selectedEmployee.termDate && selectedEmployee.termDate}
                                             </label>
                                          </div>
                                       </div>
                                       <div className="col-6">
                                          {selectedEmployee?.termDate !== null ? (
                                             <div className="misc-tests">
                                                <label className="control-label">
                                                   <Translate id="com.tempedge.msg.label.modifyemployeestatus" />
                                                </label>
                                                <SuccessButton onClick={() => this.activeEmployee()}>
                                                   <Translate id="com.tempedge.msg.label.activate" />
                                                </SuccessButton>
                                             </div>
                                          ) : (
                                             <div className="misc-tests">
                                                <label className="control-label">
                                                   <Translate id="com.tempedge.msg.label.modifyemployeestatus" />
                                                </label>
                                                <DangerButton onClick={() => this.setState({ showModal: true })}>
                                                   <Translate id="com.tempedge.msg.label.terminate" />
                                                </DangerButton>
                                             </div>
                                          )}
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <Modal
                                 content={this.modalContent()}
                                 open={this.state.showModal}
                                 onClose={() => this.setState({ showModal: false })}
                                 modalSize="modal-sm"
                                 buttons={this.modalButtons()}
                                 height="360px"
                              />
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </Container>
      );
   }
}

ViewEmployee.propTypes = {
   clearTempedgeStoreProp: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
   return {
      personId: state.tempEdge.personId,
      skillsList: state.tempEdge.skillsList?.data?.result ? state.tempEdge.skillsList.data.result : [],
      countryRegionList: state.tempEdge.country_region_list ? state.tempEdge.country_region_list : [],
      orgDepartmentList: state.tempEdge.orgDepartmentList?.data?.result?.data?.content
         ? state.tempEdge.orgDepartmentList.data.result.data.content
         : [],
      parametersFilterFind: state.tempEdge.parameters,
   };
};

export default withLocalize(
   connect(mapStateToProps, {
      push,
      clearTempedgeStoreProp,
      tempedgeAPI,
      getList,
   })(ViewEmployee),
);
