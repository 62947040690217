import { Row, Col } from 'react-bootstrap';
import { Translate } from 'react-localize-redux';
import EmployeeList from '../../Employee/EmployeeList/EmployeeList';

const EmployeeSearch = ({ setModal, findEmployee, employees, selectRow }) => {
  return (
    <div className="search mt-2">
      <Row className="align-items-end">
        <Col xs={12} lg={3}>
          <div className="form-group">
            <p className="text-left label-p">
              <Translate id="com.tempedge.msg.label.selectemployees" />
            </p>
            <button
              type="button"
              onClick={() => {
                setModal({
                  show: true,
                  title: <Translate id="com.tempedge.msg.label.selectemployees" />,
                  content: (
                    <EmployeeList
                      multipleRows={false}
                      isSelected={(personId) =>
                        employees?.find((employee) => employee.personId === personId)
                      }
                      onClickRows={(event) => {
                        selectRow(event);
                        setModal({
                          show: false,
                          title: '',
                          content: null,
                          buttons: null,
                          size: 'modal-lg',
                        });
                      }}
                    />
                  ),
                  buttons: null,
                  size: 'modal-lg',
                });
              }}
              className="btn btn-green w-100"
            >
              <Translate id="com.tempedge.msg.label.selectemployees" />
            </button>
          </div>
        </Col>
        <Col xs={12} lg={{ span: 3, offset: 6 }}>
          <div className="form-group">
            <button
              type="button"
              onClick={findEmployee}
              className="btn btn-blue w-100"
              style={{ marginTop: '2.8rem' }}
              disabled={employees.length === 0}
            >
              <Translate id="com.tempedge.msg.label.find" />
            </button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default EmployeeSearch;
