import moment from 'moment';
import { useEffect, useState } from 'react';
import { Translate } from 'react-localize-redux';
import { useDispatch } from 'react-redux';
import { addHursValidation } from '../../../../Redux/actions/payrollValidationActions';
import plus from '../../../../assets/icons/plus.png';
import useHeaderDate from '../../PayrollByWeek/useHeaderDate';
import CellAbsentPayrollValidation from './CellAbsentPayrollValidation';
import SetTableHeadingAbsentValidation from './SetTableHeadingAbsentValidation';

// css tables
import '../../../../assets/styles/components/tablepayroll.css';
import '../../../../assets/styles/components/table-inputs.css';

const PayrollAbsentValidation = ({ absentValidation, dateStart }) => {
   const findDate = useHeaderDate(dateStart);
   const dispatch = useDispatch();
   const [absent, setAbsent] = useState([]);

   useEffect(() => {
      setAbsent(absentValidation);
   }, [absentValidation]);

   const addEmployee = (employee) => {
      const data = {
         positionId: employee.position?.positionId,
         id: employee.id,
      };
      setAbsent((prevAbsent) => prevAbsent.filter((e) => e.id !== employee.id));

      dispatch(addHursValidation(data));
      window.scrollTo(0, 400);
   };
   return (
      <>
         <div className="form-group col-12 col-lg-6">
            <p style={{ textAlign: 'left' }}>
               <Translate id={'com.tempedge.msg.label.peoplewithouthours'} /> <span className="badge">{absent.length}</span>{' '}
            </p>
            <p style={{ color: 'red' }}>
               <Translate id={'com.tempedge.msg.label.notetransportationcost'} />
            </p>
         </div>
         <div className="row">
            <div className="form-check col-12 col-lg-12">
               <table className="table table-striped table-input-group">
                  <thead>{<SetTableHeadingAbsentValidation findDate={findDate} />}</thead>
                  <tbody>
                     <>
                        {absent?.map((e, index) => {
                           return (
                              <tr key={index + 1}>
                                 <td>{`${e?.person?.fullName}`}</td>
                                 <td>{e?.department !== null ? `${e?.department?.name} - ${e?.position?.name}` : e?.position?.name}</td>
                                 {Object.keys(findDate).map((currentDay, index) => {
                                    const payrollDate = e[findDate[currentDay].day]?.dateList;
                                    return moment(payrollDate).format('MM/DD/YYYY') === findDate[currentDay].date &&
                                       e[findDate[currentDay].day]?.totalRegHour === 0 &&
                                       e[findDate[currentDay].day]?.totalOtHour === 0 ? (
                                       <CellAbsentPayrollValidation key={index + 1} payroll={e} day={findDate[currentDay].day} />
                                    ) : (
                                       <td key={index + 1}></td>
                                    );
                                 })}
                                 <td className="text-center">
                                    <button type="button" className="btn" onClick={() => addEmployee(e)}>
                                       <img className="icon-widthPluss" src={plus} alt="plus icon" />
                                    </button>
                                 </td>
                              </tr>
                           );
                        })}
                     </>
                  </tbody>
               </table>
            </div>
         </div>
      </>
   );
};

export default PayrollAbsentValidation;
