import React from 'react';
import { Row, Col, Image, Button } from 'react-bootstrap';
import usaFlag from "./icons/usa.png";
import spaFlag from "./icons/spanish.png";
import { useTranslation } from '../../../Hooks/useTranslation';

const LanguageSelector = ({ languages }) => {
  const { changeLanguage, translate } = useTranslation();

  return (
    <Row className="align-items-center">
      <Col xs="auto">
        <span>
          { translate("com.tempedge.msg.label.language") }
        </span>
      </Col>
      <Col xs="auto">
        {languages.map(lang => (
          <Button
            key={lang.code}
            variant="link"
            onClick={() => changeLanguage(lang.code)}
            className="p-0 me-2"
          >
            <Image
              className="flag"
              src={lang.code === 'en' ? usaFlag : spaFlag}
              alt="Country Flag"
              fluid
            />
          </Button>
        ))}
      </Col>
    </Row>
  );
};

export default LanguageSelector;
