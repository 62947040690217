// URLs
export const REACT_APP_URL_AGENCY_FIND_ALL=`/api/agency/findAll`
export const REACT_APP_URL_AGENCY_APPROVE=`/api/agency/approve`
export const REACT_APP_URL_AGENCY_SAVE=`/api/agency/save`
export const REACT_APP_URL_CHANGE_PASSWORD=`/api/user/changePassword`
export const REACT_APP_URL_RESET_PASSWORD=`/api/user/resetPassword`
export const REACT_APP_URL_SAVE_NEW_PASSWORD=`/api/user/saveNewPassword`
export const REACT_APP_URL_CLIENT_LIST=`/api/client/list`
export const REACT_APP_URL_CLIENT_LIST_ALL=`/api/client/findAll` 
export const REACT_APP_URL_CLIENT_SAVE=`/api/client/save`
export const REACT_APP_URL_CLIENT_UPDATE=`/api/client/update`
export const REACT_APP_URL_CLIENT_VIEW=`/api/client/findById`
export const REACT_APP_URL_DELETE_CLIENT_DEPARTMENT=`/api/client/deleteDepartment`
export const REACT_APP_URL_USER_VIEW=`/api/user/findPendingById`
export const REACT_APP_URL_USER_PENDING_LIST=`/api/user/findPending`
export const REACT_APP_URL_USER_APPROVE=`/api/user/approve`
export const REACT_APP_URL_COUNTRY_LIST_ALL=`/api/country/listAll`
export const REACT_APP_URL_DICTIONARY_LIST_ALL=`/api/dictionary/listAll`
export const REACT_APP_URL_FUNDING_LIST_ALL=`/api/funding/listAll`
export const REACT_APP_URL_GET_AUTH_TOKEN=`/oauth/token`
export const REACT_APP_URL_INT_PAYROLL_ACTIVITY_LIST=`/api/intpayroll/activityList`
export const REACT_APP_URL_INT_PAYROLL_SAVE=`/api/intpayroll/save`
export const REACT_APP_URL_LOGIN=`/api/login`
export const REACT_APP_URL_OFFICE_FIND_ALL=`/api/office/findAll`
export const REACT_APP_URL_OFFICE_VIEW=`/api/office/findById`
export const REACT_APP_URL_ORG_DEPARTMENT_FIND_ALL=`/api/orgdepartment/findAll`
export const REACT_APP_URL_PERSON_VIEW=`/api/person/findById`
export const REACT_APP_URL_PERSON_INTERNAL_VIEW=`/api/person/findEmpById`
export const REACT_APP_URL_PERSON_LIST=`/api/person/list`
export const REACT_APP_URL_PERSON_SALESMAN_LIST=`/api/person/salesmanList`
export const REACT_APP_URL_PERSON_SAVE=`/api/person/save`
export const REACT_APP_URL_PERSON_SAVE_BY_REPORT=`/api/person/saveByReport` 
export const REACT_APP_URL_PERSON_INTERNAL_LIST=`/api/person/findEmp`
export const REACT_APP_URL_CLIENT_CODE_SAVE=`/api/clientCode/save`
export const REACT_APP_URL_CLIENT_CODE_DELETE =`/api/clientCode/delete`
export const REACT_APP_URL_PERSON_UPDATE=`/api/person/update`
export const REACT_APP_URL_COMMONS_COMMENT_SAVE=`/api/comment/save`
export const REACT_APP_URL_COMMONS_COMMENT_LIST=`/api/comment/list`
export const REACT_APP_URL_COMMONS_FILE_UPDATE=`/api/saveFile`
export const REACT_APP_URL_COMMONS_FILE_DELETE=`/api/deleteFile`
export const REACT_APP_URL_PERSON_SAVE_LIST=`/api/person/saveList`
export const REACT_APP_URL_PERSON_SKILL_LIST=`/api/person/skillList`
export const REACT_APP_URL_PERSON_VALIDATE=`/api/person/validate`
export const REACT_APP_URL_ROLE_LIST_ALL=`/api/role/listAll`
export const REACT_APP_URL_ROLE_VIEW=`/api/role/findById`
export const REACT_APP_URL_USER_SAVE=`/api/user/save`
export const REACT_APP_URL_USER_VALIDATE=`/api/user/validate`
export const REACT_APP_URL_USER_LIST=`/api/user/listAll` 
export const REACT_APP_URL_USER_ID=`/api/user/findByUsername` 
export const REACT_APP_URL_USER_UPDATE=`/api/user/update` 
export const REACT_APP_URL_USER_CHANGE_STATUS=`/api/user/changeStatus` 
export const REACT_APP_URL_USER_UNLOCK=`/api/user/unlockUser` 
export const REACT_APP_URL_PAYROLL_SAVE_LIST=`/api/payroll/saveList`
export const REACT_APP_URL_PAYROLL_FIND_BY_DAY=`/api/payroll/findByDay`
export const REACT_APP_URL_PAYROLL_DELETE_DAY=`/api/payroll/deleteDay`
export const REACT_APP_URL_PAYROLL_DELETE_EMPLOYEE=`/api/payroll/deleteEmployeeFromPayroll`
export const REACT_APP_URL_PAYROLL_FIND_BY_WEEK=`/api/payroll/findByWeek`
export const REACT_APP_URL_PAYROLL_SAVE_WEEK=`/api/payroll/saveByWeek`
export const REACT_APP_URL_PERSON_TERMINATE=`/api/person/terminate`
export const REACT_APP_URL_PERSON_ACTIVATE=`/api/person/activate`
export const REACT_APP_URL_PERSON_IDENTIFICATION=`/api/person/findIdentification`

// taxes employee
export const REACT_APP_URL_TAXES_EMPLOYEE_SAVE=`/api/person/taxes/save`
export const REACT_APP_URL_TAXES_EMPLOYEE_FIND_BY_PERSON_ID=`/api/person/taxes/findByPersonId`
export const REACT_APP_URL_TAXES_EMPLOYEE_FIND_BY_REGION=`/api/taxparams/findByRegion` 
export const REACT_APP_URL_TAXES_EMPLOYEE_DELETE=`/api/taxesEmployee/delete`

// client
export const REACT_APP_URL_CLIENT_PERSON_CODE_SAVE_BY_FILE= `/api/clientCode/uploadByFile`
export const REACT_APP_URL_CLIENT_DELETE_POSITION=`/api/client/deletePosition`
export const REACT_APP_URL_CLIENT_FIND_BY_ID=`/api/client/findById`

// payroll
export const REACT_APP_URL_PAYROLL_SAVE_BY_WEEK=`/api/payroll/saveByWeek`
export const REACT_APP_URL_PAYROLL_SAVE_BY_REPORT=`/api/payroll/saveReport`
export const REACT_APP_URL_PAYROLL_FIND_WEEK_ACTIVE=`/api/getWeekInfo`

// reports
export const REACT_APP_URL_VALIDATE_REPORT=`/api/reports/validateReport`
export const REACT_APP_URL_FIND_REPORT_CONFIGURATION=`/api/reports/findConfig`
export const REACT_APP_URL_SAVE_REPORT_CONFIGURATION=`/api/reports/saveConfig`
export const REACT_APP_URL_FIND_PAYROLL_REPORT=`/api/reports/findPayrollReport`
export const REACT_APP_URL_FIND_ATTENDANCE_REPORT=`/api/reports/findAttendanceReport`
export const REACT_APP_URL_FIND_INVOICE_REPORT=`/api/reports/findInvoiceReport`
export const REACT_APP_URL_GET_DEPARTMENTS_POSITION_LIST_REPORT=`/api/reports/getDeptPosList`

// custom reports
export const REACT_APP_URL_CUSTOM_REPORT_GENERATE = `/api/customReports/generateReport`
export const REACT_APP_URL_FIND_COLUMN_LIST_REPORT = `/api/customReports/getColumnsList`
export const REACT_APP_URL_SAVE_USER_CONF_REPORT = `/api/customReports/saveUserConf`

// Adjustment
export const REACT_APP_URL_SAVE_ADJUSTMENT=`/api/adjustment/save`
export const REACT_APP_URL_FIND_BY_PERSON_ADJUSTMENT=`/api/adjustment/findByPerson`
export const REACT_APP_URL_GET_ACTIVE_WEEK=`/api/getActiveWeek`
export const REACT_APP_URL_DELETE_ADJUSTMENT=`/api/adjustment/delete`

// custompayroll
export const REACT_APP_URL_FIND_PAYROLLCODE=`/api/globalParams/find`
export const REACT_APP_URL_SAVE_CUSTOM_PAYROLL=`/api/custompayroll/save`
export const REACT_APP_URL_FIND_CUSTOM_PAYROLL=`/api/custompayroll/find`
export const REACT_APP_URL_DELETE_CUSTOM_PAYROLL=`/api/custompayroll/delete`
export const REACT_APP_URL_FIND_CUSTOM_PAYROLL_WEEK=`/api/custompayroll/findByWeek`

// timeoff
export const REACT_APP_URL_SAVE_TIMEOFF=`/api/timeoff/request`
export const REACT_APP_URL_FIND_TIMEOFF=`/api/timeoff/findTimeOff`
export const REACT_APP_URL_FIND_TIMEOFF_BY_DATE=`/api/timeoff/findByDate`
export const REACT_APP_URL_REJECT_TIMEOFF=`/api/timeoff/reject`
export const REACT_APP_URL_APPROVE_TIMEOFF=`/api/timeoff/approve`
export const REACT_APP_URL_CANCEL_TIMEOFF=`/api/timeoff/remove`

// release notes
export const REACT_APP_URL_FIND_RELEASE_NOTES=`/api/globalParams/releasenotes/findAllByRelease/`

// attendance
export const REACT_APP_URL_FIND_ATTENDANCE=`/api/attendance/find`
export const REACT_APP_URL_SAVE_ATTENDANCE=`/api/attendance/save`

// mypayroll-payroll-history
export const REACT_APP_URL_FIND_PAYROLL_BY_PERSON=`/api/payrollHistory/findByEmployee`

// Dashboard Charts
export const REACT_APP_URL_FIND_LOGIN_BY_OFFICE=`/api/summary/findLoggedOffUsers`
export const REACT_APP_URL_FIND_LAST_MONTH_CLIENT_HOURS=`/api/summary/findTotalClientHours`
export const REACT_APP_URL_FIND_LAST_SEVEN_DAYS_HOURS_SUM_BY_ORG=`/api/summary/findTotalOrgHoursByDays`
export const REACT_APP_URL_FIND_TOTAL_CLIENT_EMPLOYEES_BY_DAY=`/api/summary/findTotalClientEmployeesByDay`
export const REACT_APP_URL_FIND_VARIATION_BY_ORG_ID=`/api/summary/findOrgVariation`

// recaptcha
export const REACT_APP_RECAPTCHA_SITE_KEY=process.env.REACT_APP_RECAPTCHA_SITE_KEY
export const REACT_APP_RECAPTCHA_V2_SITE_KEY=process.env.REACT_APP_RECAPTCHA_V2_SITE_KEY

// ip
export const REACT_APP_BASE_URL_TEMPEDGE=process.env.REACT_APP_BASE_URL_TEMPEDGE
export const REACT_APP_BASE_URL_FACERE_COGNITION=process.env.REACT_APP_BASE_URL_FACERE_COGNITION

// TEMPEDGE API AUTHENTICATION
export const REACT_APP_TEMPEDGE_USER=process.env.REACT_APP_TEMPEDGE_USER
export const REACT_APP_TEMPEDGE_PASSWORD=process.env.REACT_APP_TEMPEDGE_PASSWORD

// Candidate

export const REACT_APP_URL_CANDIDATE_SAVE=`/api/candidate/save`
export const REACT_APP_URL_CANDIDATE_FIND_ALL=`/api/candidate/findByRegistrationToken`
export const REACT_APP_URL_CANDIDATE_APPLY=`/api/candidate/apply`
export const REACT_APP_URL_CANDIDATE_FIND_BY_REGISTRATION=`/api/candidate/findByConfirmationToken`
// Employee Account 
export const REACT_APP_URL_ACCOUNT_CREATE=`/api/account/save`
export const REACT_APP_URL_ACCOUNT_FIND_PERSON=`/api/account/find`
export const REACT_APP_URL_ACCOUNT_DELETE= `/api/account/delete`
