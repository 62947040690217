import React from 'react';
import '../../../assets/styles/components/Range.css';


const ObjectiveRange = ({
  name,
  value = 0,
  onChange,
  min = 0,
  max = 100,
  step = 1,
  styleRange = {},
  className = '',
  ...rest
}) => {
  const handleChange = (event) => {
    if (onChange) onChange(event);
  };

  return (
    <input
      type="range"
      name={name}
      value={value}
      min={min}
      max={max}
      step={step}
      onChange={handleChange}
      className={`objective-range ${className}`}
      style={styleRange}
      {...rest}
    />
  );
};

export default ObjectiveRange;
