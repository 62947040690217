import React, { useState } from 'react';
import logo from './Logo_Tempedge.png';
import './TempEdgeLogoLabel.css';

const TextWithLogo = ({ fontSize, customClass, color }) => {
  const [isHovered, setIsHovered] = useState(false);

  const toggleHover = (hovered) => {
    setIsHovered(hovered);
  };

  return (
    <div
      className={`text-with-logo ${customClass}`}
      onMouseEnter={() => toggleHover(true)}
      onMouseLeave={() => toggleHover(false)}
      style={{ fontSize, color }}
    >
      <span className="temp-text">Temp</span>
      <span className="edge-text">Edge</span>
      {isHovered && <img src={logo} alt="TempEdge Logo" className="rounded-logo" />}
    </div>
  );
};

export default TextWithLogo;
