import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import TempEdgeApi from '../services/TempEdgeApi';
import { REACT_APP_URL_FIND_CUSTOM_PAYROLL_WEEK } from '../components/common/Routes/URLs';
import { addCustomPayrollList, addEmployee } from '../Redux/actions/customPayrollActions';
import { generateNumericId } from '../utils/PayrollUtils';
import { maxValuesSelected } from '../components/common/Constants/Constants';
import { normalizeDateSendReports } from '../utils/ReGexs';


export const getCustomPayrollTypeIDs = (arrayType, dataGlobal) => {
   const headersFinds = [];

   arrayType.forEach((customPayrollTypeId) => {
      const matchingHeader = dataGlobal.find((header) => header.customPayrollTypeId === customPayrollTypeId);

      if (!matchingHeader) {
         return;
      }

      const sameIdCount = headersFinds.filter((header) => header.customPayrollTypeId === customPayrollTypeId).length;

      const newHeader = {
         _idHeader: generateNumericId(),
         title: `${matchingHeader.reportTitle || 'Unknown'} - ${sameIdCount + 1}`,
         key: customPayrollTypeId,
         customPayrollTypeId,
      };

      headersFinds.push(newHeader);
   });

   return headersFinds;
};

export const mergeCustomPayrollTypes = (items) => {
   const arrayType = [];

   items.forEach((item) => {
      if (item.customPayrollList && item.customPayrollList.length > 0) {
         const localOccurrences = {};

         item.customPayrollList.forEach((payroll) => {
            const customPayrollTypeId = payroll.payCode?.customPayrollTypeId || payroll.billingCode?.customPayrollTypeId;

            if (customPayrollTypeId == null) return;

            localOccurrences[customPayrollTypeId] = (localOccurrences[customPayrollTypeId] || 0) + 1;

            const globalOccurrences = arrayType.filter((id) => id === customPayrollTypeId).length;

            if (localOccurrences[customPayrollTypeId] > globalOccurrences) {
               arrayType.push(customPayrollTypeId);
            }
         });
      }
   });
   return arrayType;
};

export const validateAndSetHeaders = ({ headersFindsOriginal, initialCodeHeaders, dataGlobal, maxValuesSelected }) => {
   if (headersFindsOriginal.length > 6) {
      return {
         headers: headersFindsOriginal.slice(0, 6),
         message: maxValuesSelected,
      };
   } else if (headersFindsOriginal.length < 3) {
      const headersToAdd = initialCodeHeaders
         .filter((code) => !headersFindsOriginal.some((header) => Number(header.customPayrollTypeId) === Number(code)))
         .slice(0, 3 - headersFindsOriginal.length)
         .map((code) => {
            const header = dataGlobal.find((item) => Number(item.customPayrollTypeId) === Number(code));
            return header
               ? {
                    _idHeader: generateNumericId(),
                    title: `${header.reportTitle} - 1`,
                    key: Number(code),
                    customPayrollTypeId: Number(code),
                 }
               : {
                    _idHeader: generateNumericId(),
                    title: `Default Title - ${Number(code)}`,
                    key: Number(code),
                    customPayrollTypeId: Number(code),
                 };
         });

      return {
         headers: [...headersFindsOriginal, ...headersToAdd],
         message: null,
      };
   } else {
      return {
         headers: headersFindsOriginal,
         message: null,
      };
   }
};

const useFindCustomPayrollWeek = ({
   orgIdFind,
   selectClient,
   headerCustomPayroll,
   dataGlobal,
   employees,
   setHeaderCustomPayroll,
   setShift,
   initialCodeHeaders,
}) => {
   const dispatch = useDispatch();
   const [loading, setLoading] = useState(false);
   const [message, setMessage] = useState(null);

   const findPayrollWeek = useCallback(async (date) => {
      setLoading(true);
      setMessage(null);
      const request = {
         orgId: orgIdFind,
         clientId: selectClient?.clientId?.toString(),
         dateList: normalizeDateSendReports(date ),
      };

      try {
         const { data: res } = await TempEdgeApi.post({
            url: REACT_APP_URL_FIND_CUSTOM_PAYROLL_WEEK,
            payload: request,
         });

         if (res.status === 200 && res.code === 'TE00') {
            const typesPayrollIds = mergeCustomPayrollTypes(res.result);

            const headersFindsOriginal = getCustomPayrollTypeIDs(typesPayrollIds, dataGlobal);

            const { headers, message: validationMessage } = validateAndSetHeaders({
               headersFindsOriginal,
               initialCodeHeaders,
               dataGlobal,
               maxValuesSelected,
            });

            // Actualizar el estado con los valores retornados
            setHeaderCustomPayroll(headers);
            if (validationMessage) {
               setMessage(validationMessage);
            }

            res.result.forEach((data) => {
               const { person, customPayrollList, client, department, position, shift, rate, billRate } = data;

               const uniqueId = generateNumericId();

               dispatch(
                  addEmployee({
                     id: uniqueId,
                     personId: person.personId,
                     employeeId: person.employeeId,
                     firstName: person.firstName,
                     lastName: person.lastName,
                     fullName: person.fullName,
                     client,
                     department,
                     position,
                     shift,
                     rate: rate || position?.pay || null,
                     bill: billRate || position?.bill || null,
                     labelPay: true,
                     labelBill: true,
                     deleteBill: false,
                     deletePay: false,
                     isNewEmployee: false,
                  }),
               );

               if (customPayrollList.length > 0) {
                  const headersFinds = [...headersFindsOriginal];

                  const updatedCustomPayrollList = customPayrollList.map((item) => {
                     const customPayrollTypeId = item.payCode?.customPayrollTypeId || item.billingCode?.customPayrollTypeId;

                     const headerIndex = headersFinds.findIndex((h) => h.customPayrollTypeId === Number(customPayrollTypeId));

                     let header = null;
                     if (headerIndex !== -1) {
                        header = headersFinds[headerIndex];
                        headersFinds.splice(headerIndex, 1);
                     }
                     return {
                        ...item,
                        _idHeader: header ? header._idHeader : generateNumericId(),
                        personId: person.personId,
                        id: uniqueId,
                        employeeId: person.employeeId,
                        customPayrollId: item.customPayrollId,
                        department,
                        position,
                     };
                  });

                  dispatch(addCustomPayrollList(updatedCustomPayrollList));
               }

               setShift(data.shift);
            });
         } else {
            setMessage(res);
            setHeaderCustomPayroll([...headerCustomPayroll]);
         }
      } catch (error) {
         setMessage(new Error(error));
      } finally {
         setLoading(false);
      }
   }, [
      dispatch,
      employees,
      headerCustomPayroll,
      dataGlobal,
      orgIdFind,
      selectClient,
      setHeaderCustomPayroll,
      setShift,
      initialCodeHeaders,
   ]);

   return { findPayrollWeek, loading, message };
};

export default useFindCustomPayrollWeek;