import React, { useEffect, useState } from 'react';
import ContainerBlue from '../../components/common/Container/ContainerBlue';
import { FieldArray, Form, Formik } from 'formik';
import { Translate, withLocalize } from 'react-localize-redux';
import GrayCommonBtn from '../../components/common/Buttons/GrayCommonBtn';
import { useDispatch } from 'react-redux';
import { updateCustomPayrollEntry } from '../../Redux/actions/customPayrollActions';
import OutcomeBar from '../../components/common/OutcomeBar';
import { validateSingleCustomPayroll } from './SingleCustomPayrollSchema';
import { Col, Row } from 'react-bootstrap';
import BlueCommonBtn from '../../components/common/Buttons/BlueCommonBtn';

const CustomPayrollForm = ({ person, closeModal, customPayrollEntityList, type, weekActive, label, dataGlobal }) => {
   const dispatch = useDispatch();
   const [newcustomPayroll, setNewcustomPayroll] = useState([]);
   const [messageCustom, setMessageCustom] = useState('');

   const initialValues = {
      id: person.id,
      _idHeader: label?._idHeader,
      billRate: type === 'bill' ? person?.bill : '',
      dateList: weekActive.end,
      payRate: type === 'pay' ? person?.rate : '',
      person: {
         personId: person.personId,
      },
      hoursPay: '',
      amountPay: '',
      hoursBill: '',
      amountBill: '',
      department: person.department,
      position: person.position,
      sepCheck: false,
      sepInvoice: false,
      payCode: { customPayrollTypeId: label?.customPayrollTypeId ?? '', reportTitle: label?.title ?? '' },
      billingCode: { customPayrollTypeId: label?.customPayrollTypeId ?? '', reportTitle: label?.title ?? '' },
      entryType: type,
   };

   function updateCustomPayrollCodes(globalHeaders, payrollData) {
      const dataAndHeaders = globalHeaders?.map((header) => {
         const matchingDataItem = payrollData.find((dataItem) => dataItem._idHeader === header._idHeader);

         if (matchingDataItem) {
            matchingDataItem.payCode = {
               ...matchingDataItem.payCode,
               customPayrollTypeId: header?.customPayrollTypeId,
               reportTitle: header?.title,
            };
            matchingDataItem.billingCode = {
               ...matchingDataItem.billingCode,
               customPayrollTypeId: header?.customPayrollTypeId,
               reportTitle: header?.title,
            };

            matchingDataItem.payRate = matchingDataItem.payRate || person.rate || '';
            matchingDataItem.billRate = matchingDataItem.billRate || person.bill || '';

            matchingDataItem.entryType = type;
            return matchingDataItem;
         } else {
            const newDataItem = {
               id: person?.id,
               _idHeader: header?._idHeader,
               billRate: person?.bill || '',
               dateList: weekActive?.end,
               payRate: person?.rate || '',
               person: {
                  personId: person?.personId,
               },
               hoursPay: '',
               amountPay: '',
               hoursBill: '',
               amountBill: '',
               sepCheck: false,
               sepInvoice: false,
               department: person?.department,
               position: person?.position,
               payCode:
                  type === 'pay'
                     ? { customPayrollTypeId: header?.customPayrollTypeId, reportTitle: header?.title }
                     : { customPayrollTypeId: '', reportTitle: '' },
               billingCode:
                  type === 'bill'
                     ? { customPayrollTypeId: header?.customPayrollTypeId, reportTitle: header?.title }
                     : { customPayrollTypeId: '', reportTitle: '' },
               entryType: type,
            };
            return newDataItem;
         }
      });
      return dataAndHeaders;
   }

   useEffect(() => {
      setMessageCustom('');
      if (customPayrollEntityList.length > 0) {
         const customPayroll = customPayrollEntityList.filter((entity) => entity.id === person.id);

         if (dataGlobal?.length > 0) {
            const updatedPayrollData = updateCustomPayrollCodes(dataGlobal, customPayroll);
            setNewcustomPayroll(updatedPayrollData);
         } else {
            setNewcustomPayroll(customPayroll);
         }
      } else if (dataGlobal?.length > 0) {
         const emptyPayrollData = updateCustomPayrollCodes(dataGlobal, []);
         setNewcustomPayroll(emptyPayrollData);
      } else {
         setNewcustomPayroll([initialValues]);
      }
   }, [customPayrollEntityList, person.personId]);

   const handleSubmit = (values) => {
      const { customPayroll } = values;
      customPayroll.forEach((entry) => {
         dispatch(updateCustomPayrollEntry(entry));
      });
      closeModal();
   };

   const handleCancel = () => {
      closeModal();
   };

   const renderFields = (custom, index, handleChange, handleBlur, errors, touched) => {
      const errorClass = (field) =>
         errors?.customPayroll?.[index]?.[field] && touched?.customPayroll?.[index]?.[field]
            ? 'has-error'
            : errors?.customPayroll?.[index]?.[field]
              ? 'has-error'
              : '';

      return (
         <>
            <Row className="form-group mt-3 justify-content-md-center px-4">
               <Col sm={4} md={4} lg={4}>
                  <label className="control-label">
                     <Translate id={type === 'pay' ? 'com.tempedge.msg.label.paycode' : 'com.tempedge.msg.label.billingcode'} />
                  </label>
                  <div className="form-group pl-4">
                     <p className="title-form-payroll">
                        {type === 'pay'
                           ? custom?.payCode?.reportTitle || custom.payCode?.description
                           : custom?.billingCode?.reportTitle || custom.billingCode?.description}
                     </p>
                  </div>
               </Col>

               <Col sm={4} md={4} lg={2}>
                  <label className="control-label">
                     <Translate id={type === 'pay' ? 'com.tempedge.msg.label.amount' : 'com.tempedge.msg.label.amountbill'} />
                  </label>
                  <input
                     type="number"
                     step="0.01"
                     name={`customPayroll.${index}.${type === 'pay' ? 'amountPay' : 'amountBill'}`}
                     value={type === 'pay' ? custom.amountPay || '' : custom.amountBill || ''}
                     placeholder="0.0"
                     className={`form-control text-center tempEdge-input-box ${errorClass(type === 'pay' ? 'amountPay' : 'amountBill')}`}
                     onChange={handleChange}
                     onBlur={handleBlur}
                  />
               </Col>

               <Col sm={4} md={4} lg={2}>
                  <label className="control-label">
                     <Translate id={type === 'pay' ? 'com.tempedge.msg.label.hourspay' : 'com.tempedge.msg.label.hoursbill'} />
                  </label>
                  <input
                     type="number"
                     step="0.01"
                     name={`customPayroll.${index}.${type === 'pay' ? 'hoursPay' : 'hoursBill'}`}
                     value={type === 'pay' ? custom.hoursPay || '' : custom.hoursBill || ''}
                     placeholder="0.0"
                     className={`form-control text-center tempEdge-input-box ${errorClass(type === 'pay' ? 'hoursPay' : 'hoursBill')}`}
                     onChange={handleChange}
                     onBlur={handleBlur}
                  />
               </Col>               

               <Col sm={4} md={{ span: 4, offset: 4 }} lg={{ span: 2, offset: 0 }}>
                  <label className="control-label">
                     <Translate id={type === 'pay' ? 'com.tempedge.msg.label.payRate' : 'com.tempedge.msg.label.billRate'} />
                  </label>
                  <input
                     type="number"
                     step="0.01"
                     name={`customPayroll.${index}.${type === 'pay' ? 'payRate' : 'billRate'}`}
                     value={type === 'pay' ? custom.payRate || '' : custom.billRate || ''}
                     placeholder="0.0"
                     className={`form-control text-center tempEdge-input-box ${errorClass(type === 'pay' ? 'payRate' : 'billRate')}`}
                     onChange={handleChange}
                     onBlur={handleBlur}
                  />
               </Col>

               <Col sm={4} md={4} lg={2}>
                  <label className="control-label">
                     <Translate id={type === 'pay' ? 'com.tempedge.msg.label.billRate' : 'com.tempedge.msg.label.payRate'} />
                  </label>
                  <input
                     type="number"
                     step="0.01"
                     name={`customPayroll.${index}.${type === 'pay' ? 'billRate' : 'payRate'}`}
                     value={type === 'pay' ? custom.billRate || '' : custom.payRate || ''}
                     placeholder="0.0"
                     className={`form-control text-center tempEdge-input-box ${errorClass(type === 'pay' ? 'billRate' : 'payRate')}`}
                     disabled
                  />
               </Col>
            </Row>
         </>
      );
   };

   return (
      <>
         <ContainerBlue label={`${person.firstName} ${person.lastName}`}>
            {messageCustom && <OutcomeBar personMessage={messageCustom} />}
            <Formik
               enableReinitialize
               initialValues={{
                  customPayroll: newcustomPayroll.length > 0 ? newcustomPayroll : [{ initialValues }],
               }}
               validate={validateSingleCustomPayroll}
               onSubmit={(values) => {
                  handleSubmit(values);
               }}
            >
               {({ values, errors, touched, handleBlur, handleChange }) => {
                  const filteredPayroll = values.customPayroll.filter((custom) => (type === 'pay' ? custom?.payCode : custom?.billingCode));
                  values.customPayroll.forEach((custom) => {
                     if (type === 'pay') {
                        if (!custom.payCode)
                           custom.payCode = { customPayrollTypeId: label?.customPayrollTypeId, reportTitle: label?.title };
                     } else {
                        if (!custom.billingCode)
                           custom.billingCode = { customPayrollTypeId: label?.customPayrollTypeId, reportTitle: label?.title };
                     }
                  });

                  return (
                     <Form className="mx-auto">
                        <FieldArray
                           name="customPayroll"
                           render={() => (
                              <div>
                                 <Col sm={12} md={3} lg={3}>
                                    <p className="green-text font-weight-bold text-uppercase tempedge-control-label ">{weekActive.end}</p>
                                 </Col>
                                 <div className="line-separate-custom-payroll"></div>
                                 {(filteredPayroll.length > 0 ? filteredPayroll : [{}]).map((custom, index) => (
                                    <React.Fragment key={index}>
                                       {renderFields(custom, index, handleChange, handleBlur, errors, touched)}
                                       <div className="line-separate-custom-payroll"></div>
                                    </React.Fragment>
                                 ))}

                                 <Row className="py-4">
                                    <Col sm={12} md={5} lg={5} className="offset-md-1 mb-3">
                                       <GrayCommonBtn tag="com.tempedge.msg.label.cancel" onClick={() => handleCancel(person)} 
                                          className="btn btn-default btn-block register-save-btn-100 previous w-100"
                                          />
                                    </Col>
                                    <Col sm={12} md={5} lg={5} className="mb-3">
                                       <BlueCommonBtn
                                          tag="com.tempedge.msg.label.save"
                                          type="submit"
                                          className="btn btn-primary btn-block register-save-btn save w-100"
                                       />
                                    </Col>
                                 </Row>
                              </div>
                           )}
                        />
                     </Form>
                  );
               }}
            </Formik>
         </ContainerBlue>
      </>
   );
};

export default withLocalize(CustomPayrollForm);
