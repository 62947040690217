import { useState } from 'react';
import ModalVideo from 'react-modal-video';
import { FaYoutube as IconYoutube } from "react-icons/fa6";
import { Translate } from 'react-localize-redux';
import './TutorialsCardVideo.css';

const TutorialsCardVideo = ({ url }) => {
    const [isOpen, setOpen] = useState(false);
    const videoId = new URL(url).searchParams.get('v'); // Extract YouTube video ID

    return (
        <div className="tutorials-card-container tutorials-card-video-container">
            <div
                onClick={() => setOpen(true)}
                className='tutorials-button-open-modal'
                style={{ cursor: 'pointer' }}
            >
                <i className="tutorials-play-icon-circle">
                    <IconYoutube size={'45'} />
                    <div className='tutorials-label-video-card'>
                        <Translate id="com.tempedge.msg.title.watch_video" />
                    </div>
                </i>
            </div>
            <ModalVideo
                youtube={{ mute: 0, autoplay: 0 }}
                channel='youtube'
                autoplay
                isOpen={isOpen}
                videoId={videoId}
                onClose={() => setOpen(false)}
            />
        </div>
    );
};

export default TutorialsCardVideo;
