import React from "react";
import { withLocalize } from "react-localize-redux";
import './landing.css';
import { WhatWeDoSection } from "./Sections/WhatWeDoSection";
import { WhatWeOfferSection } from "./Sections/WhatWeOfferSection";
import { VideosSection } from "./Sections/VideosSection";
import HeaderSection from "./Sections/HeaderSection";
import { GetStartedSection } from "./Sections/GetStartedSection";

const Home = () => {

    return (
        <>
            <HeaderSection/>
            <WhatWeDoSection />
            <WhatWeOfferSection />
            <GetStartedSection/>
        </>
    )
}

export default withLocalize(Home);